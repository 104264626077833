/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
// core components
import {
  AvForm,
  AvGroup,
  AvInput,
  AvField,
  AvFeedback,
} from "availity-reactstrap-validation";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";

class inputComplainGa extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      location_id_fk: "",
      requestor_id: "",
      category_id_fk: "",
      sub_location: "",
      complain_desc: "",
      complain_request_date: "",
      complain_status_id: "",
      photo: [],
      complain_photo_data: [],
      UserData: {
        data: [
          {
            user_id: null,
            user_name: null,
            user_gender: null,
            work_location_id_fk: null,
            room_name: null,
            location_name: null,
          },
        ],
      },
      complaintCategory: {
        data: [
          {
            complain_category_id: null,
            complain_category_name: null,
            complain_category_type: null,
          },
        ],
      },
      modalState: false,
      isLoading: true,
    };

    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }
  componentDidMount() {
    this.props.setTitle("Input New Complain");
    const idUser = localStorage.getItem("id_user");

    axios.get("/CategoryComplain").then((result) => {
      this.setState({
        complaintCategory: result.data,
        isLoading: false,
      });
    });

    axios.get(`/UserHomePage?id_user=${idUser}`).then((result) => {
      this.setState({
        UserData: result.data.data[0],
        isLoading: false,
      });
    });
  }

  handleSubmit() {
    var currentDate = new Date(),
      finalCurrentDate = [
        currentDate.getDate(),
        currentDate.getMonth() + 1,
        currentDate.getFullYear(),
      ].join("-");

    const id_user = localStorage.getItem("id_user");
    var location = this.state.UserData.location_id;

    var ComplaintData = new FormData();
    ComplaintData.append("location_id_fk2", parseInt(location));
    ComplaintData.append("requestor_id", parseInt(id_user));
    ComplaintData.append(
      "category_id_fk",
      parseInt(this.state.SelectedCategory)
    );
    ComplaintData.append("sub_location", this.state.sub_location);
    ComplaintData.append("complain_desc", this.state.complain_desc);
    ComplaintData.append("complain_request_date", finalCurrentDate);
    ComplaintData.append("complain_start_date", finalCurrentDate);

    if (this.state.photo.name) {
      ComplaintData.append("photo[]", this.state.photo, this.state.photo.name);
    }

    ComplaintData.append("complain_status_id", 1);
    ComplaintData.append("log", "Complaint Submitted");

    axios({
      method: "post",
      url: "https://parastay-api.pti-cosmetics.com/index.php/api/CreateComplaint",
      data: ComplaintData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Complaint Created",
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.assign("../");
          }
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Server Error: Failed to create Complain",
          text: error,
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      });
  }

  toggleModal() {
    this.setState((prev, props) => {
      const newState = !prev.modalState;

      return { modalState: newState };
    });
  }

  handleFile = (event) => {
    this.setState({ [event.target.name]: event.target.files[0] });
  };

  ChangeHandler(event) {
    this.setState({
      [event.target.name]:
        event.target.type === "number"
          ? parseInt(event.target.value)
          : event.target.value,
    });
  }

  render() {
    let complain_category = this.state.complaintCategory.data;
    const { isLoading } = this.state;
    return (
      <>
        <Container fluid>
          <Card>
            {isLoading ? (
              <CardBody>
                <div
                  className="justify-content-center text-center"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    opacity: "0.5",
                    height: "100%",
                    width: "100%",
                    zIndex: "1100",
                  }}
                >
                  <Loader
                    type="Bars"
                    color="#30C1FF"
                    height={100}
                    width={100}
                  />
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <AvForm onSubmit={this.handleSubmit}>
                  <h2 className="mb-4">Complain Detail</h2>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kategori
                          </label>
                          <AvField
                            placeholder="Kategori..."
                            type="select"
                            name="SelectedCategory"
                            id="SelectedCategory"
                            onChange={this.ChangeHandler}
                            value={this.state.SelectedCategory}
                            required
                          >
                            <option value={null} selected>
                              (Pilih Kategori)
                            </option>
                            {complain_category.map((char) => (
                              <option value={char.complain_category_id}>
                                {char.complain_category_name} (
                                {char.complain_category_type})
                              </option>
                            ))}
                          </AvField>
                          <AvFeedback>Please Choose Category</AvFeedback>
                        </AvGroup>
                      </Col>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Sub Lokasi
                          </label>
                          <AvInput
                            placeholder="Sub Lokasi..."
                            type="text"
                            name="sub_location"
                            id="sub_location"
                            onChange={this.ChangeHandler}
                            value={this.state.sub_location}
                            required
                          />
                          <AvFeedback>Please Insert Sublocation</AvFeedback>
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Deskripsi Komplain
                          </label>
                          <AvInput
                            placeholder="Deskripsi Komplain..."
                            type="textarea"
                            name="complain_desc"
                            id="complain_desc"
                            onChange={this.ChangeHandler}
                            value={this.state.complain_desc}
                            rows="7"
                            required
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Unggah Foto
                          </label>
                          <AvInput
                            type="file"
                            name="photo"
                            id="photo"
                            onChange={this.handleFile}
                            required
                            multiple="multiple"
                          />
                          <AvFeedback>
                            Ukuran foto max. <b>5 MB</b>, menerima gambar dengan
                            format <b>PNG, JPG dan JPEG</b>
                          </AvFeedback>
                        </AvGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xs="6">
                      <Button
                        color="danger"
                        onClick={this.handleCancel}
                        size="md"
                      >
                        Back
                      </Button>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button color="info" type="submit" size="md">
                        Send
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            )}
          </Card>
        </Container>
      </>
    );
  }
}

export default inputComplainGa;
