import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const filteredData = [
  { value: "Operasional", label: "Operasional" },
  { value: "Perjadin", label: "Perjadin" },
  { value: "Shuttle", label: "Shuttle" },
];

const FilterCategory = ({ searchParams, setSearchParams }) => {
  const [selectedFilter, setSelectedFilter] = useState(new Map());

  const handleCheckboxChange = (label, value) => {
    const selected = [];
    setSelectedFilter((prevSelectedFilter) => {
      const newSelectedFilter = new Map(prevSelectedFilter);
      if (newSelectedFilter.has(label)) {
        newSelectedFilter.delete(label);
      } else {
        newSelectedFilter.set(label, value);
      }
      newSelectedFilter.forEach((val) => selected.push(val));
      return newSelectedFilter;
    });
    return selected;
  };

  useEffect(() => {
    if (searchParams.car_category) {
      const car_category = searchParams.car_category.split(",");
      const newMap = new Map();
      filteredData.forEach((filter) => {
        if (car_category.includes(filter.value)) {
          newMap.set(filter.label, filter.value);
        }
      });
      setSelectedFilter(newMap);
    }
  }, [searchParams.car_category]);
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        color="black"
        style={{
          height: "42px",
          background: "white",
          border: "2px solid #EFEFEF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
          display: "flex",
        }}
      >
        <DirectionsCarIcon style={{ color: "grey" }} />
        <span style={{ marginRight: "10px", color: "grey", fontSize: "14px" }}>Kategori</span>{" "}
        <KeyboardArrowDownIcon />
      </DropdownToggle>

      <DropdownMenu>
        {filteredData.map((filter) => (
          <DropdownItem toggle={false} key={filter.value}>
            <FormGroup check>
              <Label
                check
                onClick={() => {
                  const selectedVal = handleCheckboxChange(filter.label, filter.value);
                    setSearchParams({
                        ...searchParams,
                        page: 1,
                        car_category: selectedVal.join(","),
                    });
                }}
              >
                <Input
                  value={filter.label}
                  type="checkbox"
                  onChange={() => {}}
                  checked={selectedFilter.has(filter.label)}
                />
                {filter.label}
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default FilterCategory;
