import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router";
import { Button, Modal, ModalBody } from "reactstrap";

import LobbyHeader from "components/Headers/LobbyHeader";
import LobbyMenuFooter from "components/Footers/LobbyMenuFooter";
import SearchAndFilter from "components/LobbyMenu/SearchAndFilter";
import ListView from "components/LobbyMenu/ListView";
import LobbyHeaderProfile from "components/Headers/LobbyHeaderProfile";
import GridView from "components/LobbyMenu/GridView";
import Axios from "utils/axiosService";

function LobbyMenu(props) {
  const location = useLocation();
  const { history } = props;
  const [activeOptionForm, setActiveOptionForm] = useState(null);
  const [sort, setSort] = useState(null);
  const [view, setView] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [userData, setUserData] = useState();
  const [isArchivedWorkspace, setIsArchivedWorkspace] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [appIdToBeDeleted, setAppIdToBeDeleted] = useState(null);

  useEffect(()=>{
    document.title = "Admin ParaSpace"
  },[])
  
  useEffect(() => {
    setIsLoadingUserData(true);
    const id_user = localStorage.getItem("id_user");
    Axios({
      method: "GET",
      url: `/admin?user_id=${id_user}`,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setUserData(r.data.data.data[0]);
        } else {
          throw new Error("Error fetching user data");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.assign("./logout")
        }
      })
      .finally(() => {
        setIsLoadingUserData(false);
      });
  }, []);

  useEffect(() => {
    if (userData) {
      setIsLoading(true);
      setMenuList([]);
      if (userData.role.toLowerCase() === "superadmin") {
        Axios({
          method: "GET",
          url: `/app?status=${!isArchivedWorkspace}`,
        })
          .then((r) => {
            if (r.data.errorStatus) {
              throw new Error("Error fetching menu");
            } else {
              setMenuList(r.data.data.data);
            }
          })
          .catch((e) => {
            console.error(e);
            if (e.response.status === 401) {
              window.location.assign("./logout")
            }
            setIsLoadingUserData(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        Axios({
          method: "GET",
          url: `/admin-relation-app?user_admin_id=${userData.id}`,
        })
          .then((r) => {
            if (r.data.errorStatus) {
              throw new Error("Error fetching menu");
            } else {
              const data = r?.data?.data?.data ?? [];
              data.forEach((item) => {
                setMenuList((prevState) => [
                  ...prevState,
                  item.list_application,
                ]);
              });
            }
          })
          .catch((e) => {
            console.error(e);
            if (e.response.status === 401) {
              window.location.assign("./logout")
            }
            setIsLoadingUserData(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [refetch, userData, isArchivedWorkspace]);

  useEffect(() => {
    setSearchValue("");
    setActiveOptionForm(null);
  }, [view]);

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      setView(searchParams.get("view"));
      setSort(searchParams.get("sort"));
    }
  }, [location.search]);

  const handleRefetch = () => {
    setRefetch(!refetch);
  };

  const handleCreateWorkspace = () => {
    setIsArchivedWorkspace(false);
    Axios({
      method: "POST",
      url: "/app",
      data: {
        name: "New Workspace",
        color: "#2ABCE9",
        description: "",
        icon: "fa-solid fa-envelope",
        status: true,
      },
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error creating workspace");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        handleRefetch();
      });
  };

  const handleArchiveWorkspace = () => {
    setIsArchivedWorkspace(!isArchivedWorkspace);
  };

  const handleDeleteWorkspace = (appId) => {
    setIsShowDeleteModal(true);
    setAppIdToBeDeleted(appId);
  };

  const handleDeleteApp = () => {
    Axios({
      method: "PATCH",
      url: `/app/${appIdToBeDeleted}`,
      data: {
        status: isArchivedWorkspace,
      },
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error deleting workspace");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsShowDeleteModal(false);
        handleRefetch();
      });
  }
  return (
    <>
      <LobbyHeader />
      <div style={{ minHeight: "100vh", background: "white" }} className="px-9">
        <LobbyHeaderProfile
          buttonText={"Manage Admin"}
          buttonColor="#DE9E21"
          onClickButton={() => history.push("/manage-admin")}
          isLoading={isLoadingUserData}
          userData={userData}
        />
        <div className="w-100 mb-3 d-flex justify-content-between">
          <h1>{isArchivedWorkspace ? "Archived " : ""}Workspaces</h1>
          <div className="d-flex align-items-center">
            {!isLoadingUserData &&
              userData?.role?.toLowerCase() === "superadmin" && (
                <>
                  <Button
                    className="border-0 shadow-none p-0 px-2"
                    color="primary"
                    style={{ borderRadius: "12px", height: "34px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCreateWorkspace();
                    }}
                  >
                    + Create Workspace
                  </Button>
                  <Button
                    className="border-0 shadow-none p-0 px-2"
                    style={{
                      borderRadius: "12px",
                      height: "34px",
                      background: "#DE9E21",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleArchiveWorkspace();
                    }}
                  >
                    {!isArchivedWorkspace
                      ? "Archived Workspaces"
                      : "Active Workspaces"}
                  </Button>
                </>
              )}
            <SearchAndFilter
              history={history}
              setActiveOptionForm={setActiveOptionForm}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
        </div>
        <div className="">
          {!isLoading && !isLoadingUserData && (
            <>
              {view === "grid" && (
                <GridView
                  menuList={menuList}
                  setMenuList={setMenuList}
                  activeOptionForm={activeOptionForm}
                  setActiveOptionForm={setActiveOptionForm}
                  searchValue={searchValue}
                  sort={sort}
                  handleRefetch={handleRefetch}
                  userData={userData}
                  isArchivedWorkspace={isArchivedWorkspace}
                  handleDeleteWorkspace={handleDeleteWorkspace}
                />
              )}
              {view === "list" && (
                <ListView
                  menuList={menuList}
                  setMenuList={setMenuList}
                  activeOptionForm={activeOptionForm}
                  setActiveOptionForm={setActiveOptionForm}
                  searchValue={searchValue}
                  sort={sort}
                  handleRefetch={handleRefetch}
                  userData={userData}
                  isArchivedWorkspace={isArchivedWorkspace}
                  handleDeleteWorkspace={handleDeleteWorkspace}
                />
              )}
            </>
          )}
          {(isLoading || isLoadingUserData) && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isShowDeleteModal}
        fade={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowDeleteModal(!isShowDeleteModal);
        }}
      >
        <ModalBody
          style={{
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 className="text-center mb-0">
            Are you sure to delete this workspace?
          </h2>
          <div className="d-flex justify-content-center">
            <Button
              color="danger"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                handleDeleteApp()
              }}
            >
              Delete
            </Button>
            <Button
              color="info"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                setIsShowDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <LobbyMenuFooter />
      <style jsx>
        {`
          .color-hover:hover {
            color: #2e1cff;
          }
        `}
      </style>
    </>
  );
}

export default LobbyMenu;
