/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardText,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
// import SimpleHeader from "components/Headers/SimpleHeader.js";
import Loader from 'react-loader-spinner';
import { Link } from "react-router-dom";
import axios from 'axios';



class DashboardGA extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      alert: null,
      countComplainRequest: 0,
      countComplainProcess: 0,
      countComplainDone: 0,
      countComplainAll: 0,
      countMessRequest: 0,
      countMessDone: 0,
      countMessCapacitySingle: 0,
      countMessCapacityCouple: 0,
      countMessCapacitySinggah: 0,
      countMessOccupantSingle: 0,
      countMessOccupantCouple: 0,
      countMessOccupantSinggah: 0,
      isLoading: true
    }
  }

  componentDidMount(){ 
    this.props.setTitle("Dashboard");
    axios.get('/ComplainGetRequest')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countComplainRequest: response.data.data ,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });
  
    axios.get('/ComplainGetProcess')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countComplainProcess: response.data.data ,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });
  
    axios.get('/ComplainGetDone')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countComplainDone: response.data.data ,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });
  
    axios.get('/ComplainGetAll')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countComplainAll: response.data.data ,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/MessGetRequest')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessRequest: response.data.data ,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/MessGetDone')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessDone: response.data.data ,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/CountMessCapacity?location_type=1')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessCapacitySingle: response.data.data[0].count,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/CountMessCapacity?location_type=2')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessCapacityCouple: response.data.data[0].count,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/CountMessCapacity?location_type=3')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessCapacitySinggah: response.data.data[0].count,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/CountMessOccupant?location_type=1')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessOccupantSingle: response.data.data[0].count,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/CountMessOccupant?location_type=2')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessOccupantCouple: response.data.data[0].count,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });

    axios.get('/CountMessOccupant?location_type=3')
    .then(response=> {
      this.setState({ 
        ...this.state,
        countMessOccupantSinggah: response.data.data[0].count,
        isLoading: false
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        this.setState({
          ...this.state,
          isLoading: false
        });
      }
    });
  
  } 
  
  render() {
    const username = localStorage.getItem('username')
    const {isLoading} = this.state
    return (
      <>
        {this.state.alert}
        {/* <SimpleHeader name="Dashboard" parentName="Admin GA" /> */}
        <Container fluid>
        {isLoading ? (
             <div className="justify-content-center text-center" style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)', 
              opacity: '0.5', 
              height: '100%',
              width: '100%',
              zIndex:'1100'
            }}>
              <Loader
                 type="Bars"
                 color="#30C1FF"
                 height={100}
                 width={100}
                />
            </div>
          ) : (
          <>
          <Row>
            <div className="col">
              <Card className="px-3 py-3" style={{background: '#EAF9FF'}}>
                <Row>
                    <Col md='6'>
                        <CardTitle tag="h3">Halo, {(username).split(' ')[0]}!</CardTitle>
                        <CardText>Semoga dalam keadaan sehat, kamu bisa memantau request dan komplain mess di sini</CardText>
                        <div className="pt-3">
                        <Link to={`/parastay/request-mess-list`}>
                        <Button color="info"  size="md">Daftar request</Button>
                        </Link>
                        <Link to={`/parastay/complain-list-ga`}>
                        <Button color="info"  size="md" style={{marginLeft: '10px'}}>Daftar komplain</Button>
                        </Link>
                        </div>
                    </Col>
                    <Col md='6' className="text-right">
                        <img width="30%" src={require('assets/img/brand/header.svg')} alt="header gomess" />
                    </Col>
                </Row>
              </Card>
                <h2 className="mb-3">
                     Overview Request Mess
                </h2>
                <Row>
                    <Col md='3' xs='6'>
                        <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="new">
                                  Request Mess Baru{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip 
                                  delay={0}
                                  placement="top"
                                  target = "new"
                                >
                                  Adalah seluruh request pengajuan booking dan pindah mess yang belum diselesaikan
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countMessRequest}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="done">
                                  Request Mess Selesai{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "done" >
                                  Adalah seluruh request pengajuan booking dan pindah mess yang telah selesai dalam jangka waktu per bulan / kuarter / semester / tahunan
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countMessDone}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    </Row>
                    <Row>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="single">
                                  Penghuni Mess Single{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "single" >
                                  Adalah seluruh penghuni yang menempati mess single 
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countMessOccupantSingle} /
                                </span>
                                <span className="h2 text-muted mb-0 pl-1">
                                    {this.state.countMessCapacitySingle}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="couple">
                                  Penghuni Mess Couple{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "couple" >
                                  Adalah seluruh penghuni yang menempati mess couple 
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                   {this.state.countMessOccupantCouple} /
                                </span>
                                <span className="h2 text-muted mb-0 pl-1">
                                    {this.state.countMessCapacityCouple}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="visit">
                                  Penghuni Mess Singgah{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "visit" >
                                  Adalah seluruh penghuni yang menempati mess singgah 
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                   {this.state.countMessOccupantSinggah} /
                                </span>
                                <span className="h2 text-muted mb-0 pl-1">
                                    {this.state.countMessCapacitySinggah}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <h2 className="mb-3">
                    Overview Komplain Mess
                </h2>
                <Row>
                    <Col md='3' xs='6'>
                        <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="newComplain">
                                  Komplain Mess Baru{' '}
                                 <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "newComplain" >
                                  Adalah seluruh pengajuan komplain yang belum ditujukan ke WPM/CREM
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countComplainRequest} 
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="progressComplain">
                                  Komplain On Progress{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "progressComplain" >
                                  Adalah seluruh pengajuan komplain yang sedang dikerjakan WPM/CREM
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countComplainProcess} 
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="doneComplain">
                                  Komplain Mess Selesai{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "doneComplain" >
                                  Adalah seluruh pengajuan komplain yang telah selesai dikerjakan oleh WPM/CREM dalam jangka waktu per bulan/kuarter/semester/tahunan
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countComplainDone} 
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
          </Row>
          </>
          )}
        </Container>
      </>
    );
  }
}

export default DashboardGA;






