import React, { useEffect, useReducer } from "react";
import { Card, Container } from "reactstrap";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";
import { Calendar, momentLocalizer } from "react-big-calendar";

import moment from "moment";
import "moment/locale/id";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MonitoringFilter from "components/bookingRoom/MonitoringFilter";

const localizer = momentLocalizer(moment);
moment.locale("id");

function MonitoringAula({ setTitle, match, history }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = React.useState(true);
  const [bookingDataList, setBookingDataList] = React.useState([]);
  const [bookingDataListFiltered, setBookingDataListFiltered] = React.useState(
    []
  );
  const [submitToggle, setSubmitToggle] = React.useState(false);
  const [filter, setFilter] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "LOCATION":
          return {
            ...state,
            location: action.payload,
          };
        case "ROOM":
          return {
            ...state,
            room: action.payload,
          };
        case "RESET":
          return {
            location: "default",
            room: "default",
          };
        default:
          return state;
      }
    },
    { location: "default", room: "default" }
  );

  useEffect(() => {
    setIsLoadingFilter(true);
    const filter = new URLSearchParams(history.location.search);
    const location = filter.get("loc");
    const room = filter.get("room");
    if (!location && !room) {
      setFilter({ type: "RESET" });
    } else {
      if (location) {
        setFilter({ type: "LOCATION", payload: location });
      }
      if (room) {
        setFilter({ type: "ROOM", payload: room });
      }
    }
    setIsLoadingFilter(false);
  }, [history.location.search]);

  useEffect(() => {
    setTitle("Monitoring Aula");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: `/room/get/booking?type=Aula&status=Booking Created&exclude_empty_room=true`,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          let dataBookingAulaAll = r.data.data.data;
          let bookingDataListTemp = [];
          dataBookingAulaAll.forEach((room) => {
            room.booking_room_booking_lists.forEach((bookinglist) => {
              bookinglist.booking_room_booking_occurances.forEach(
                (occurence) => {
                  let constructdatabooking = {
                    id: occurence.id,
                    group: parseInt(room.id),
                    title: bookinglist.booking_purpose,
                    start_time: moment(occurence.start_usage).toDate(),
                    end_time: moment(occurence.finish_usage).toDate(),
                    tooltip: `${bookinglist.booking_purpose} - ${
                      room.name
                    } (${moment(occurence.start_usage).format("LT")} - ${moment(
                      occurence.finish_usage
                    ).format("LT")})`,
                    room_id: bookinglist.room_id,
                    location_id: room.area_id,
                  };
                  bookingDataListTemp.push(constructdatabooking);
                }
              );
            });
          });
          setBookingDataList(bookingDataListTemp);
          setBookingDataListFiltered(bookingDataListTemp);
        } else {
          throw new Error("Error fetching data");
        }
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const { location, room } = filter;
    let tempData = [...bookingDataList];
    if (location !== "default") {
      tempData = tempData.filter((d) => d.location_id === location);
    }
    if (room !== "default") {
      tempData = tempData.filter((d) => d.room_id === room);
    }

    setBookingDataListFiltered([...tempData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitToggle, bookingDataList]);

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSubmitToggle((p) => !p);
  };

  return (
    <Container fluid>
      <Card className="m-2 p-3">
        {!isLoading && !isLoadingFilter && (
          <div>
            <MonitoringFilter
              filter={filter}
              history={history}
              handleSubmit={handleSubmitFilter}
              isAula={true}
            />
            <Calendar
              localizer={localizer}
              events={bookingDataListFiltered}
              startAccessor="start_time"
              endAccessor="end_time"
              defaultDate={moment().toDate()}
              style={{ height: "80vh" }}
              tooltipAccessor={"tooltip"}
              onSelectEvent={(e) =>
                history.push("/booking-room/booking/" + e.id)
              }
            />
          </div>
        )}
        {(isLoading || isLoadingFilter) && (
          <div className="w-100 d-flex justify-content-center">
            <Loader type="Oval" color="#30C1FF" height={100} width={100} />
          </div>
        )}
      </Card>
    </Container>
  );
}

export default MonitoringAula;
