import moment from "moment";

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const filteredParamKey = [
  "limit",
  "page",
  "order_sequence",
  "filter_start_usage",
  "filter_end_usage",
  "status",
  "query",
  "order_column",
];

const filteredDownload = [
  "order_sequence",
  "filter_start_usage",
  "filter_end_usage",
  "status",
  "query",
  "order_column",
];

const createMapFromArray = (listFilter = []) => {
  if (listFilter.length) {
    return new Map(listFilter.map((item) => [item, item]));
  }
  return new Map();
};
const filterParamKeyMap = createMapFromArray(filteredParamKey);
const filteredDownloadMap = createMapFromArray(filteredDownload);

export const filteredParamsForBookingCar = ({ params }) => {
  const selectedParams = {};
  Object.keys(params).forEach((d) => {
    if (filterParamKeyMap.has(d)) {
      selectedParams[d] = params[d];
    }
  });
  return selectedParams;
};

export const filteredParamsForDownloadBookingCar = ({ params }) => {
  const selectedParams = {};
  Object.keys(params).forEach((d) => {
    if (filteredDownloadMap.has(d)) {
      selectedParams[d] = params[d];
    }
  });
  return selectedParams;
};
