import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Loader from "react-loader-spinner";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Axios from "utils/axiosService";
import Swal from "sweetalert2";
import { Stack } from "@mui/material";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

function UserGroupList({ setTitle, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowGroupDataModal, setIsShowGroupDataModal] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState(0);

  const [formValue, setFormValue] = useState({
    group_name: "",
    description: "",
    group_type: "",
    select_users: [],
    select_companies: [],
    select_directorates: [],
    select_divisions: [],
  });

  const [inputType, setInputType] = React.useState("");
  const [id, setUserGroupId] = React.useState(null);
  const [initialUserList, setInitialUserList] = React.useState([]);
  const [companies, setCompanyList] = React.useState([]);
  const [directorates, setDirectorateList] = React.useState([]);
  const [departments, setDepartmentList] = React.useState([]);
  const [divisions, setDivisionList] = React.useState([]);

  useEffect(() => {
    setTitle("User Groups");
  });

  const getAllUserData = () => {
    Axios({
      method: "GET",
      url: `/user-simple?sort=user_name,asc`,
    }).then((r) => {
      setInitialUserList(r.data.data.data);
    });
  }

  const getAllCompanyData = () => {
    Axios({
      method: "GET",
      url: `/company`,
    }).then((r) => {
      setCompanyList(r.data.data.data);
    });
  }

  const getAllDirectorateData = () => {
    Axios({
      method: "GET",
      url: `/directorate`,
    }).then((r) => {
      setDirectorateList(r.data.data.data);
    });
  }

  const getAllDepartmentData = () => {
    Axios({
      method: "GET",
      url: `/department`,
    }).then((r) => {
      setDepartmentList(r.data.data.data);
    });
  }

  const getAllDivisionData = () => {
    Axios({
      method: "GET",
      url: `/division`,
    }).then((r) => {
      setDivisionList(r.data.data.data);
    });
  }

  useEffect(() => {
    getAllUserData();
    getAllCompanyData();
    getAllDirectorateData();
    getAllDepartmentData();
    getAllDivisionData();
  }, []);

  useEffect(() => {
    Axios({
      method: "GET",
      url: "/user-group",
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setData(r.data.data.userGroups);
        } else {
          throw new Error("Error fetching User Group");
        }
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const { SearchBar } = Search;

  const headerStyle = (column, colIndex) => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };

  const handleEditRow = (id) => {
    history.push(`/booking-room/user-groups/${id}`);
  };

  const handleClickDelete = (id) => {
    setIsShowDeleteModal(true);
    setIdToBeDeleted(id);
  };

  const handleClickView = (groupData) => {
    setIsShowGroupDataModal(true);
    setUserGroupId(groupData.id)
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      setInputType("edit");
      Axios({
        method: "GET",
        url: "/user-group/" + id,
      })
        .then((r) => {
          if (!r.data.errorStatus) {
            processFormData(r.data.data.userGroups)
          } else {
            throw new Error("Error fetching data");
          }
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setInputType("add");
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputType, id, companies, directorates, departments, divisions]);

  const processFormData = (userGroupData) => {
    const {
      group_name,
      group_type,
      description,
      included_users,
      included_companies,
      included_directorates,
      included_departments,
      included_divisions,
      id,
    } = userGroupData;

    let userData = []
    let companyData = []
    let directorateData = []
    let deptData = []
    let divisionData = []

    if (included_users.length > 0) {
      userData = initialUserList.filter(comp =>
        included_users.includes(parseInt(comp.user_id))
      )
    }
    if (included_companies.length > 0) {
      companyData = companies.filter(comp =>
        included_companies.includes(comp.company_id)
      )
    }
    if (included_directorates.length > 0) {
      directorateData = directorates.filter(comp =>
        included_directorates.includes(parseInt(comp.directorate_id))
      )
    }
    if (included_departments.length > 0) {
      deptData = departments.filter(comp =>
        included_departments.includes(parseInt(comp.department_id))
      )
    }
    if (included_divisions.length > 0) {
      divisionData = divisions.filter(comp =>
        included_divisions.includes(parseInt(comp.division_id))
      )
    }

    setFormValue({
      ...formValue,
      select_users: userData,
      select_companies: companyData,
      select_directorates: directorateData,
      select_departments: deptData,
      select_divisions: divisionData,
      group_name,
      group_type,
      description,
      id,
    })
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: `/user-group/${idToBeDeleted}`,
    })
      .then((r) => {
        if (!r?.data?.errorStatus) {
          Swal.fire({
            icon: "success",
            text: `User group ${idToBeDeleted} has been deleted`,
            confirmButtonColor: "#30C1FF",
          });

          setTimeout(() => {
            history.replace("/booking-room/user-groups");
          }, 1000);
        } else {
          throw new Error("Something Wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: `Cannot delete User Group`,
          confirmButtonColor: "#30C1FF",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <>
              <ToolkitProvider
                data={data}
                keyField="id"
                search
                columns={[
                  {
                    dataField: "id",
                    text: "Group ID",
                    filter: textFilter(),
                    sort: true,
                    headerStyle: headerStyle,
                    headerClasses: "header-style-admin",
                  },
                  {
                    dataField: "group_name",
                    text: "Group Name",
                    filter: textFilter(),
                    sort: true,
                    headerStyle: headerStyle,
                    headerClasses: "header-style-admin",
                  },
                  {
                    dataField: "group_type",
                    text: "Grouping Type",
                    filter: textFilter(),
                    sort: true,
                    headerStyle: headerStyle,
                    headerClasses: "header-style-admin",
                  },
                  {
                    dataField: "id",
                    text: "Action",
                    headerStyle: headerStyle,
                    formatter: (cell, row) => {
                      return (
                        <div>
                          <Button
                            className="border-0 text-white p-0"
                            style={{
                              background: "#3E7CA8",
                              width: "68px",
                              height: "22px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              handleClickView(row);
                            }}
                          >
                            View
                          </Button>
                          <Button
                            className="border-0 text-white p-0"
                            style={{
                              background: "#DAA743",
                              width: "68px",
                              height: "22px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              handleEditRow(row.id);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            className="border-0 text-white p-0"
                            style={{
                              background: "#C70000",
                              width: "68px",
                              height: "22px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              handleClickDelete(row.id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
              >
                {(props) => (
                  <div className="table-responsive">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <div className="p-3">
                        <label className="mr-3" style={{ fontSize: "15px" }}>
                          Search:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="Search"
                          {...props.searchProps}
                        />
                      </div>
                      <Button
                        onClick={() => {
                          history.push(
                            "/booking-room/user-groups/add"
                          );
                        }}
                        style={{ background: "#3E7CA8", height: "32px" }}
                        size="sm"
                        className="border-0 text-white"
                      >
                        Add User Group
                      </Button>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      filter={filterFactory()}
                      handleClickView     bordered={false}
                      id="react-bs-table"
                      noDataIndication="No User Groups Found"
                      rowClasses={(row, rowIndex) => "row-style-admin"}
                      pagination={pagination}
                      bootstrap4={true}
                    />
                  </div>
                )}
              </ToolkitProvider>
              <style jsx>
                {`
                  .row-style-admin td {
                    padding: 12px;
                    vertical-align: middle;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                  .row-style-admin td div {
                    max-height: 19.5px;
                  }
                  .header-style-admin label input {
                    height: 30px;
                  }
                  .header-style-admin label select {
                    height: 30px;
                    padding: 6px;
                  }
                  .header-style-admin-number div label input {
                    height: 30px;
                    min-width: 50px;
                    width: 100%;
                    padding: 4px;
                  }
                  .header-style-admin-number div label select {
                    height: 30px;
                    width: 44px !important;
                    padding: 2px;
                  }
                `}
              </style>
            </>
          )}

          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </CardBody>
      </Card>
      {/* delete modal */}
      <Modal
        isOpen={isShowDeleteModal}
        fade={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowDeleteModal(!isShowDeleteModal);
        }}
      >
        <ModalBody
          style={{
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 className="text-center mb-0">
            Apakah anda yakin untuk menghapus User Group ini?
          </h2>
          <div className="d-flex justify-content-center">
            <Button
              color="danger"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              color="info"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                setIsShowDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* view data modal */}
      <Modal
        isOpen={isShowGroupDataModal}
        fade={false}
        style={{
          position: "absolute",
          top: "33%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowGroupDataModal(!isShowGroupDataModal);
        }}
      >
        <ModalHeader toggle={() => {
          setIsShowGroupDataModal(!isShowGroupDataModal);
        }}>View User Group</ModalHeader>
        <ModalBody
          style={{
            width: "640px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h3 className="text-left mb-0">
            Name
          </h3>
          <p>{formValue.group_name}</p>
          <h3 className="text-left mb-0">
            Description
          </h3>
          <p>{formValue.description}</p>
          <h3 className="text-left mb-0">
          {formValue.group_type === "user" ? "Members" : "Classification"}
          </h3>
          {formValue.group_type === "user" ?
            formValue?.select_users.map((userData) => (
              <Stack spacing={"10px"} sx={{ marginTop: "10px"}}>
                <p className="text-left mb-0">
                  {userData?.user_name} - {userData?.user_email}
                </p>
              </Stack>
            ))
           :
            <>
              <Stack spacing={"10px"} sx={{ marginTop: "10px"}}>
                {
                  formValue?.select_companies?.map((companyData) => (
                    <div sx={{ marginBottom: "2em", width: "50%" }}>
                      <Label>Company</Label>
                      <p className="text-left mb-0">
                        {companyData?.company_name}
                      </p>
                    </div>
                  ))
                }
                {
                  formValue?.select_directorates?.map((directorateData) => (
                    <div sx={{ marginBottom: "2em", width: "50%" }}>
                      <Label>Directorate</Label>
                      <p className="text-left mb-0">
                        {directorateData?.directorate_name}
                      </p>
                    </div>
                  ))
                }
                {
                  formValue?.select_departments?.map((deptData) => (
                    <div sx={{ marginBottom: "2em", width: "50%" }}>
                      <Label>Department</Label>
                      <p className="text-left mb-0">
                        {deptData?.department_name}
                      </p>
                    </div>
                  ))
                }
                {
                  formValue?.select_divisions?.map((divData) => (
                    <div sx={{ marginBottom: "2em", width: "50%" }}>
                      <Label>Division</Label>
                      <p className="text-left mb-0">
                        {divData?.division_name}
                      </p>
                    </div>
                  ))
                }
              </Stack>
            </>
          }
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default UserGroupList;
