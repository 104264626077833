import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";

import ThreeDotsIcon from "components/Icons/ThreeDotsIcon";
import Axios from "utils/axiosService";

function LobbyHeaderProfile({
  onClickButton,
  buttonText,
  buttonColor,
  isLoading,
  userData,
}) {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const handleLogOut = () => {
    Axios({
      method: "POST",
      url: "/auth/logout",
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        localStorage.clear();
      });
  };

  if (!isLoading) {
    return (
      <div
        className="w-100"
        style={{
          transform: "translateY(-50%)",
        }}
      >
        <div
          className="d-flex justify-content-between p-4 bg-white"
          style={{
            boxShadow: "0px 1px 30px 6px rgba(0, 0, 0, 0.25)",
            borderRadius: "32px",
          }}
        >
          <div className="d-flex" style={{ gap: "34px" }}>
            <img
              style={{
                borderRadius: "100%",
                width: "105px",
                height: "105px",
                objectFit: "cover",
              }}
              src={require("assets/img/menu/avatar.png")}
              alt="profile"
            />
            <div className="d-flex m-0 flex-column justify-content-center text-black">
              <h3
                className="mb-0"
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                }}
              >
                {userData?.name ?? ""}
              </h3>
              <p
                className="mb-1"
                style={{
                  fontSize: "16px",
                  color: "rgba(0, 0, 0, 0.7",
                }}
              >
                {userData?.user_email ?? ""}
              </p>
              <p
                className="px-3 py-1 text-white"
                style={{
                  fontSize: "16px",
                  borderRadius: "36px",
                  background: "rgba(33, 154, 222, 0.5)",
                  width: "min-content",
                  whiteSpace: "nowrap",
                }}
              >
                {userData?.role?.toLowerCase() === "superadmin"
                  ? "Super Admin"
                  : "Admin"}
              </p>
            </div>
          </div>
          <div
            className="d-flex align-items-center py-3"
            style={{ gap: "12px" }}
          >
            {userData?.role?.toLowerCase() === "superadmin" && (
              <Button
                className="border-0"
                style={{
                  background: buttonColor,
                  borderRadius: "36px",
                  width: "150px",
                  height: "min-content",
                  margin: "0",
                }}
                type="button"
                onClick={onClickButton}
              >
                <span className="btn-inner--text text-white">{buttonText}</span>
              </Button>
            )}
            <Dropdown
              isOpen={isOpenDropdown}
              toggle={() => setIsOpenDropdown(!isOpenDropdown)}
            >
              <DropdownToggle className="shadow-none bg-white">
                <ThreeDotsIcon width="10px" height="42px" fill="#D9D9D9" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link
                    to={"/parastay/user-details/"}
                    className="d-flex align-items-center text-muted"
                    style={{ gap: "5px" }}
                  >
                    <i className="ni ni-single-02" />
                    <span>Edit Profile</span>
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/logout"
                    className="d-flex align-items-center text-muted"
                    style={{ gap: "5px" }}
                    onClick={handleLogOut}
                  >
                    <i className="ni ni-user-run" />
                    <span>Sign Out</span>
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default LobbyHeaderProfile;
