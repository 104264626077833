import React from "react";

import { Button, Label } from "reactstrap";

const ImageUploader = ({
  image,
  imagePreview,
  name,
  label,
  handleImageChange,
  handleRemoveImage,
}) => {
  return (
    <div style={{ width: "100%", display: "flex", gap: "8px" }}>
      {imagePreview ? (
        <div
          style={{
            position: "relative",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            minHeight: "300px",
            width: "100%",
            backgroundColor: "#F0F0F0",
            border: "1px dashed #73777F",
            padding: "8px",
            borderRadius: "8px",
            fontSize: "12px",
            fontWeight: 400,
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
          }}
        >
          <Button
            type="button"
            size="md"
            name={`${name}ImageRemove`}
            id={`${name}ImageRemove`}
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              padding: "0 4px",
              fontSize: "16px",
              color: "white",
              background: "black",
              borderRadius: "100px",
            }}
            onClick={handleRemoveImage}
          >
            <i className="bi bi-x"></i>
          </Button>
          <img
            src={imagePreview}
            alt="Car Preview"
            style={{ maxHeight: "200px", maxWidth: "100%" }}
          />
          {image && (
            <>
              <div style={{ color: "#222222" }}>{image.name}</div>
              <div style={{ color: "#5E5E5E" }}>
                {(image.size / 1024).toFixed(2)} KB
              </div>
            </>
          )}
        </div>
      ) : (
        <Label
          htmlFor={`${name}Image`}
          style={{ cursor: "pointer", width: "100%" }}
        >
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              rowGap: "4px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px dashed #73777F",
              padding: "16px 8px",
              borderRadius: "8px",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 400,
              boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
            }}
          >
            <i
              className="bi bi-file-earmark-arrow-up"
              style={{ fontSize: "42px" }}
            ></i>
            <div style={{ color: "#222222" }}>{label}</div>
            <div style={{ color: "#5E5E5E" }}>Maksimal 5MB</div>
          </div>
        </Label>
      )}
      <input
        id={`${name}Image`}
        type="file"
        name={`${name}Image`}
        accept=".jpg, .jpeg, .png, .heic, .heif"
        onChange={handleImageChange}
        style={{
          visibility: "hidden",
          position: "absolute",
          pointerEvents: "none",
          width: "1px",
          height: "1px",
        }}
      />
    </div>
  );
};

export default ImageUploader;
