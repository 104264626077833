import React from "react";

function MagnifyIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.80873 1.20149C3.85221 1.20149 2.93487 1.58147 2.25851 2.25783C1.58216 2.93419 1.20218 3.85152 1.20218 4.80804C1.20218 5.76455 1.58216 6.68189 2.25851 7.35825C2.93487 8.03461 3.85221 8.41458 4.80873 8.41458C5.76524 8.41458 6.68258 8.03461 7.35894 7.35825C8.0353 6.68189 8.41527 5.76455 8.41527 4.80804C8.41527 3.85152 8.0353 2.93419 7.35894 2.25783C6.68258 1.58147 5.76524 1.20149 4.80873 1.20149ZM2.97037e-08 4.80804C0.000109595 4.04277 0.182863 3.28857 0.533071 2.60813C0.88328 1.92769 1.39083 1.34066 2.01354 0.89583C2.63625 0.450996 3.35613 0.161206 4.11336 0.0505435C4.87059 -0.0601193 5.64329 0.0115414 6.36726 0.25957C7.09122 0.507599 7.74554 0.924832 8.27582 1.47659C8.80611 2.02836 9.19705 2.69871 9.41615 3.43195C9.63525 4.16518 9.67619 4.94013 9.53557 5.69237C9.39495 6.44461 9.07682 7.15243 8.60762 7.75699L11.8457 10.9951C11.9552 11.1084 12.0158 11.2603 12.0144 11.4179C12.013 11.5755 11.9498 11.7262 11.8384 11.8377C11.7269 11.9491 11.5762 12.0124 11.4186 12.0137C11.261 12.0151 11.1091 11.9545 10.9958 11.845L7.75768 8.60693C7.04686 9.15879 6.19546 9.50012 5.30028 9.59211C4.4051 9.6841 3.50207 9.52305 2.69388 9.12729C1.88568 8.73152 1.20476 8.11691 0.728537 7.35335C0.252313 6.5898 -9.99737e-05 5.70793 2.97037e-08 4.80804V4.80804Z"
        fill="#0D0D0D"
      />
    </svg>
  );
}

export default MagnifyIcon;
