import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import Axios from "utils/axiosService";

import { Button, Form } from "reactstrap";
import Loader from "react-loader-spinner";

import FormReturnFieldsCard from "components/bookingCar/FormReturnFieldsCard";
import { useSnackbar } from "components/Snackbar/snackbar";

function FormReturn({ setTitle }) {
  const { bookingId } = useParams();
  const history = useHistory();
  const { setSnackbarState } = useSnackbar();

  useEffect(() => {
    setTitle(`Form Kelengkapan - #${bookingId}`);
    // eslint-disable-next-line
  }, [bookingId]);

  const [data, setData] = useState({
    odoEnd: "",
    fuelEnd: "",
    mirror: "",
    horn: "",
    lights: "",
    brakes: "",
    dashboardIndicator: "",
    jackKey: "",
    apar: "",
    umbrella: "",
    safetyTriangle: "",
    bodyDamage: "",
    damageDescription: "",
    trash: "",
  });

  const [damageImage, setDamageImage] = useState(null);
  const [damageImagePreview, setDamageImagePreview] = useState(null);
  const [trashImage, setTrashImage] = useState(null);
  const [trashImagePreview, setTrashImagePreview] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    let { name, value } = event.target;

    if ((name === "odoEnd" || name === "fuelEnd") && value !== "") {
      value = parseInt(value);
      if (value < 0) {
        value = 0;
      } else if (name === "fuelEnd" && value > 100) {
        value = 100;
      }
    }

    setData({ ...data, [name]: value });
  };

  const handleImageChange = (event) => {
    const { name, files } = event.target;

    if (files[0] && files[0].size <= 5242880) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "bodyDamageImage") {
          setDamageImage(files[0]);
          setDamageImagePreview(reader.result);
        } else if (name === "trashImage") {
          setTrashImage(files[0]);
          setTrashImagePreview(reader.result);
        }
      };
      reader.readAsDataURL(files[0]);
    } else {
      console.error("File too large");
    }
  };

  const handleRemoveImage = (event) => {
    const { name } = event.currentTarget;

    if (name === "bodyDamageImageRemove") {
      setDamageImage(null);
      setDamageImagePreview(null);
    } else if (name === "trashImageRemove") {
      setTrashImage(null);
      setTrashImagePreview(null);
    }
  };

  const fields = [
    {
      name: "odoEnd",
      title: "Kilometer Akhir",
      description:
        "Kilometer yang ditunjukkan pada odometer saat mobil dikembalikan",
      placeholder: "contoh: 12341",
      type: "number",
      value: data.odoEnd,
      onChange: handleChange,
      unit: "Km",
    },
    {
      name: "fuelEnd",
      title: "Bensin Akhir",
      description:
        "Jumlah bensin dalam persen yang tersisa saat mobil dikembalikan",
      placeholder: "contoh: 90",
      type: "number",
      value: data.fuelEnd,
      onChange: handleChange,
      unit: "%",
    },
    {
      name: "mirror",
      title: "Kaca Spion",
      description: " Kaca spion lengkap di kiri dan kanan. Tidak buram.",
      type: "radio",
      options: ["Ada", "Tidak Ada"],
      value: data.mirror,
      onChange: handleChange,
    },
    {
      name: "horn",
      title: "Klakson",
      description: "Dapat mengeluarkan suara dengan baik.",
      type: "radio",
      options: ["Ya", "Tidak"],
      value: data.horn,
      onChange: handleChange,
    },
    {
      name: "lights",
      title: "Lampu Besar, Lampu Rem, dan Lampu Sein",
      description: "Dapat mengeluarkan cahaya yang terang.",
      type: "radio",
      options: ["Ya", "Tidak"],
      value: data.lights,
      onChange: handleChange,
    },
    {
      name: "brakes",
      title: "Rem Tangan dan Rem Kaki",
      description: "Dapat melakukan pengereman dengan pakem.",
      type: "radio",
      options: ["Ya", "Tidak"],
      value: data.brakes,
      onChange: handleChange,
    },
    {
      name: "dashboardIndicator",
      title: "Indikator Dashboard Pengemudi",
      description:
        "Tidak ada indikator yang rusak / tidak jelas. <span style='font-style: italic;'>cth: Indikator bensin dan speedometer terlihat jelas.</span>",
      type: "radio",
      options: ["Ya", "Tidak"],
      value: data.dashboardIndicator,
      onChange: handleChange,
    },
    {
      name: "jackKey",
      title: "Kunci Dongkrak",
      description:
        "Mobil Ayla: Lokasi di bawah bangku pengemudi. Selain mobil Ayla: Lokasi di bagasi belakang mobil.",
      type: "radio",
      options: ["Ada", "Tidak Ada"],
      value: data.jackKey,
      onChange: handleChange,
    },
    {
      name: "apar",
      title: "Alat Pemadam Api Ringan (APAR)",
      description:
        "Mobil Innova dan Xenia: Lokasi di bawah bangku penumpang depan. Mobil Freed dan Ayla: Lokasi di bagasi belakang mobil.",
      type: "radio",
      options: ["Ada", "Tidak Ada"],
      value: data.apar,
      onChange: handleChange,
    },
    {
      name: "umbrella",
      title: "Payung",
      type: "radio",
      options: ["Ada", "Tidak Ada"],
      value: data.umbrella,
      onChange: handleChange,
    },
    {
      name: "safetyTriangle",
      title: "Segitiga Pengaman",
      type: "radio",
      options: ["Ada", "Tidak Ada"],
      value: data.safetyTriangle,
      onChange: handleChange,
    },
    {
      name: "bodyDamage",
      title: "Apakah terdapat kerusakan pada body mobil?",
      description:
        "<span style='font-style: italic;'>cth: baret, cat terkelupas, dll</span>",
      type: "radio",
      options: ["Ya", "Tidak"],
      value: data.bodyDamage,
      onChange: handleChange,
      damageDescriptionValue: data.damageDescription,
      onChangeDamageDescription: handleChange,
      image: damageImage,
      imagePreview: damageImagePreview,
      handleImageChange: handleImageChange,
      handleRemoveImage: handleRemoveImage,
    },
    {
      name: "trash",
      title: "Apakah ada sampah?",
      description:
        "<span style='font-style: italic;'>cth: tissue, bungkus makanan, dll</span>",
      type: "radio",
      options: ["Ya", "Tidak"],
      value: data.trash,
      onChange: handleChange,
      image: trashImage,
      imagePreview: trashImagePreview,
      handleImageChange: handleImageChange,
      handleRemoveImage: handleRemoveImage,
    },
  ];

  const isValidForm = () => {
    const requiredFields = [
      data.odoEnd,
      data.fuelEnd,
      data.mirror,
      data.horn,
      data.lights,
      data.brakes,
      data.dashboardIndicator,
      data.jackKey,
      data.apar,
      data.umbrella,
      data.safetyTriangle,
      data.bodyDamage,
      data.trash,
    ];

    const isValid = requiredFields.every(
      (field) => field !== "" && field !== undefined
    );

    if (data.bodyDamage === "Ya" && !data.damageDescription) {
      return false;
    }

    if (data.trash === "Ya" && !trashImage) {
      return false;
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("odo_end", data.odoEnd);
    formData.append("fuel_end", data.fuelEnd);
    formData.append("kaca_spion", data.mirror === "Ada" ? true : false);
    formData.append("klakson", data.horn === "Ya" ? true : false);
    formData.append("lampu", data.lights === "Ya" ? true : false);
    formData.append("rem", data.brakes === "Ya" ? true : false);
    formData.append(
      "dashboard",
      formData.dashboardIndicator === "Ya" ? true : false
    );
    formData.append(
      "kunci_dongkrak",
      formData.jackKey === "Ada" ? true : false
    );
    formData.append("apar", data.apar === "Ada" ? true : false);
    formData.append("payung", data.umbrella === "Ada" ? true : false);
    formData.append(
      "segitiga_pengaman",
      formData.safetyTriangle === "Ada" ? true : false
    );

    const bodyDamage = data.bodyDamage === "Ya" ? true : false;
    const trash = data.trash === "Ya" ? true : false;

    if (bodyDamage && data.damageDescription) {
      formData.append("damage_desc", data.damageDescription);
    }

    if (bodyDamage && damageImage) {
      formData.append("damage_image", damageImage);
    }

    if (trash && trashImage) {
      formData.append("sampah_image", trashImage);
    }

    Axios({
      method: "POST",
      url: `/car/return/${bookingId}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        setSnackbarState({
          open: true,
          message: "Form pengembalian mobil berhasil disubmit",
          severity: "success",
        });
        history.push("/booking-car/booking-monitoring");
      })
      .catch((error) => {
        setSnackbarState({
          open: true,
          message: "Form pengembalian mobil gagal disubmit",
          severity: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container fluid style={{ background: "#F8F9FE" }}>
      <p
        style={{
          fontSize: "16px",
          color: "#22222",
          fontWeight: "500",
          marginBottom: "8px",
        }}
      >
        Lengkapi form berikut ini saat menerima pengembalian mobil
      </p>
      <p style={{ fontSize: "12px", color: "#5e5e5e" }}>
        Isi sesuai form dengan keadaan mobil sesungguhnya sebagai bentuk
        kepedulian kita terhadap keselamatan
      </p>

      <Form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
          <FormReturnFieldsCard key={index} number={index + 1} {...field} />
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "16px",
          }}
        >
          <Button
            type="submit"
            color="primary"
            style={{ color: "white", width: "200px", background: "#3182CE" }}
            disabled={!isValidForm() || isLoading}
          >
            {isLoading ? (
              <Loader type="Oval" color="white" height={20} width={20} />
            ) : (
              "Selesai"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default FormReturn;
