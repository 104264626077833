export const workSpaces = [
  {
    name: "Parastay",
    path: "/parastay",
    logo: require("assets/img/brand/Gomess-sidebar.png"),
  },
  {
    name: "Booking Room",
    path: "/booking-room",
    logo: require("assets/img/brand/Booking_Room-sidebar.png"),
  },
  {
    name: "Booking Car",
    path: "/booking-car",
    logo: require("assets/img/brand/Booking_Car-sidebar.png"),
  },
  {
    name: "ParaPark",
    path: "#",
    logo: "",
  },
  {
    name: "ParaSpace",
    path: "#",
    logo: "",
  },
];
