import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { Button } from "reactstrap";

import "react-quill/dist/quill.snow.css";
import "./quill.css";

function AddTermConditionForm({
  handleSubmit,
  handleChange,
  formValue,
  appList,
  handleChangeQuil,
  setFormValue,
}) {
  useEffect(() => {
    if (appList.length > 0) {
      const app = appList.filter(
        (app) => app.slug === window.location.pathname.split("/")[1]
      )[0];
      setFormValue({
        ...formValue,
        app_id: app.id,
      });
      console.log(app);
    }
    // eslint-disable-next-line
  }, [appList]);
  return (
    <AvForm onSubmit={handleSubmit}>
      <h2 className="mb-4">Add Terms & Conditions</h2>
      <AvGroup>
        <label className="form-control-label" htmlFor="content_title">
          Title*
        </label>
        <AvInput
          id="content_title"
          type="text"
          placeholder="content_title"
          name="content_title"
          value={formValue.content_title}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input title</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="content_author">
          Author*
        </label>
        <AvInput
          id="content_author"
          type="text"
          placeholder="content_author"
          name="content_author"
          value={formValue.content_author}
          required
          disabled
        />
        <AvFeedback>Please input author</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="content_text">
          Content*
        </label>
        <ReactQuill
          id="content_text"
          name="content_text"
          value={formValue.content_text}
          onChange={handleChangeQuil}
        />
      </AvGroup>
      <div>
        <Button color="info" type="submit" size="md">
          Add Terms & Conditions
        </Button>
      </div>
    </AvForm>
  );
}

export default AddTermConditionForm;
