import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import AddTermConditionForm from "components/ContentManagement/term-and-condition/AddTermConditionForm";

function AddTermCondition({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [appList, setAppList] = useState([]);
  const [formValue, setFormValue] = useState({
    app_id: "",
    content_title: "",
    content_text: "",
    content_author: localStorage.getItem("username")
  });
  useEffect(() => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: `/app?status=true`,
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error fetching menu");
        } else {
          setAppList(r.data.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleChangeQuil = (val) => {
    setFormValue({...formValue, content_text: val})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formValue.app_id &&
      formValue.content_author &&
      formValue.content_title &&
      formValue.content_text
    ) {
      setIsLoading(true);
      Axios({
        method: "POST",
        url: "/terms-and-conditions",
        data: {
          app_id: parseInt(formValue.app_id),
          content_author: formValue.content_author,
          content_title: formValue.content_title,
          content_text: formValue.content_text,
        },
      })
        .then((r) => {
          if (!r?.data?.errorStatus) {
            Swal.fire({
              icon: "success",
              text: `Berhasil menambahkan Terms and Conditions`,
              confirmButtonColor: "#30C1FF",
            });
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            text: `Gagal menambahkan Terms and Conditions`,
            confirmButtonColor: "#30C1FF",
          });
        })
        .finally(() => {
          setIsLoading(false);
          setTimeout(() => {
            history.goBack();
          }, 1000);
        });
    }
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <AddTermConditionForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              formValue={formValue}
              appList={appList}
              handleChangeQuil={handleChangeQuil}
              setFormValue={setFormValue}
            />
          )}

          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default AddTermCondition;
