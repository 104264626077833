/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Button,
  Row,
  Col,
  Collapse,
  Table,
} from "reactstrap";
// core components
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

const initialState = {
  room_id_fk: "",
  location_id_fk1: "",
  occupant_type: "",
  staying_type: "",
  data_id: "",
  booking_status_id_fk: "",
  pic_id: "",
  start_date: "",
  end_date: "",
};

class RequestAssignMess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: initialState,
      user: {},
      isLoading: true,
      isEdited: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.setTitle("Pemilihan Kamar Mess");
    const id_request = this.props.match.params.id_request;
    const id_user = localStorage.getItem("id_user");

    axios
      .all([
        axios.get(`/ManageMess?data_id=${id_request}`),
        axios.get("/CategoryMess"),
        axios.get("/TimePeriodMess"),
        axios.get("/StatusMess"),
        axios.get(`/CategoryMess?id_room=1`),
        axios.get(`/MessList`),
        axios.get(`/RoomList`),
        axios.get(`/MessInfo`),
      ])
      .then(
        axios.spread(
          (
            reqResp,
            categoryResp,
            timeResp,
            statusResp,
            exCategoryResp,
            messResp,
            roomResp,
            infoResp
          ) => {
            const request = reqResp.data.data[0];
            if (request) {
              this.setState({
                formData: {
                  room_id_fk: request.room_id_fk,
                  location_id_fk1: request.location_id_fk1,
                  occupant_type: request.occupant_type,
                  staying_type: request.staying_type,
                  data_id: id_request,
                  booking_status_id_fk: request.booking_status_id_fk,
                  pic_id: id_user,
                  start_date: request.start_date,
                  end_date: request.end_date,
                },
                user: request,
                categories: categoryResp.data.data,
                excategories: exCategoryResp.data.data,
                timePeriods: timeResp.data.data,
                states: statusResp.data.data,
                homes: messResp.data.data,
                rooms: roomResp.data.data,
                messInfos: infoResp.data.data,
                isLoading: false,
              });
            }
          }
        )
      )
      .catch((error) => {
        console.error(error.response);
      });
  }

  handleChange = (e) => {
    const { name, value, type } = e.target;
    let finalValue;

    if (type === "select-one" || type === "number") {
      finalValue = parseInt(value);
    } else {
      finalValue = value;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: finalValue,
      },
      isEdited: true,
    });
  };

  handleStartDateChange = (date) => {
    const inputDate = date._d;
    const stringDate = `${inputDate.getFullYear()}-${
      inputDate.getMonth() + 1
    }-${inputDate.getDate()}`;

    this.setState({
      formData: { ...this.state.formData, start_date: stringDate },
      isEdited: true,
    });
  };

  handleEndDateChange = (date) => {
    const inputDate = date._d;
    const stringDate = `${inputDate.getFullYear()}-${
      inputDate.getMonth() + 1
    }-${inputDate.getDate()}`;

    this.setState({
      formData: { ...this.state.formData, end_date: stringDate },
      isEdited: true,
    });
  };

  valid = (current) => {
    let startDate = moment(this.state.formData.start_date);
    return current.isAfter(startDate);
  };

  handleClick = () => {
    this.setState({ ...this.state, hidden: !this.state.hidden });
  };

  handleCancel = () => {
    if (this.state.isEdited) {
      Swal.fire({
        title: "Are you sure you want to go back?",
        text: "If you leave before saving, your changes will be lost",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#30C1FF",
        cancelButtonColor: "#F8AFB8",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.history.goBack();
        }
      });
    } else {
      this.props.history.goBack();
    }
  };

  handleSubmit = (e, errors) => {
    e.preventDefault();

    const {
      room_id_fk,
      location_id_fk1,
      occupant_type,
      staying_type,
      data_id,
      booking_status_id_fk,
      pic_id,
      start_date,
      end_date,
    } = this.state.formData;

    if (errors.length === 0) {
      this.setState({ ...this.state, isLoading: true });

      const formData = {
        room_id_fk,
        location_id_fk1,
        occupant_type,
        staying_type,
        data_id,
        booking_status_id_fk,
        pic_id,
        start_date,
        end_date,
      };

      const user = this.state.user;
      const id_user = localStorage.getItem("id_user");

      let dataLog = new URLSearchParams();
      dataLog.append("sender_id", id_user);
      dataLog.append("booking_id_fk1", data_id);
      dataLog.append("booking_status_id", booking_status_id_fk);

      let messLog = new URLSearchParams();
      messLog.append("mess_user_id", user.user_id_fk);
      messLog.append("mess_room_type", staying_type);
      messLog.append("mess_name", location_id_fk1);
      messLog.append("mess_room", room_id_fk);
      messLog.append("mess_check_in", start_date);
      messLog.append("mess_check_out", end_date);
      messLog.append("booking_status_id", booking_status_id_fk);

      axios
        .put("/ManageMess", formData)
        .then((response) => {
          var status = response.data.status;
          if (status === true) {
            axios
              .post(`/BookingLog`, dataLog)
              .then((response) => {
                if (response.data.status === true) {
                  if (
                    booking_status_id_fk === 4 ||
                    booking_status_id_fk === 9
                  ) {
                    axios.post(`/MessList`, messLog).then((response) => {
                      if (response.data.status === true) {
                        Swal.fire({
                          icon: "success",
                          text: "Mess Request has been approved.",
                          confirmButtonColor: "#30C1FF",
                        });
                        this.props.history.push(`/parastay/request-mess-list`);
                      } else {
                        Swal.fire({
                          icon: "error",
                          text: "Error to update approval",
                          confirmButtonColor: "#30C1FF",
                        });
                      }
                    });
                  } else if (booking_status_id_fk === 10) {
                    axios.post(`/MessList`, messLog).then((response) => {
                      if (response.data.status === true) {
                        Swal.fire({
                          icon: "success",
                          text: "Mess Request has been approved.",
                          confirmButtonColor: "#30C1FF",
                        });
                        this.props.history.push(`/parastay/request-mess-list`);
                      } else {
                        Swal.fire({
                          icon: "error",
                          text: "Error to update approval",
                          confirmButtonColor: "#30C1FF",
                        });
                      }
                    });
                  } else {
                    Swal.fire({
                      icon: "success",
                      text: "Mess Request has been updated.",
                      confirmButtonColor: "#30C1FF",
                    });
                    this.props.history.push(`/parastay/request-mess-list`);
                  }
                } else {
                  Swal.fire({
                    icon: "error",
                    text: "Error to update log",
                    confirmButtonColor: "#30C1FF",
                  });
                }
              })
              .catch((error) => {});
          } else {
            Swal.fire({
              icon: "error",
              text: "Error to update",
              confirmButtonColor: "#30C1FF",
            });
          }
        })
        .catch((error) => {
          console.error(error.response);
          Swal.fire({
            icon: "error",
            text: "Failed to update!",
            confirmButtonColor: "#30C1FF",
          });
          this.setState({ isLoading: false });
        });
    } else {
      console.log(errors);
    }
  };

  render() {
    const {
      formData,
      isLoading,
      categories,
      user,
      timePeriods,
      states,
      excategories,
      homes,
      rooms,
      messInfos,
    } = this.state;
    return (
      <>
        <Container fluid>
          <Card className="mb-4">
            {isLoading ? (
              <CardBody>
                <div
                  className="justify-content-center text-center"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    opacity: "0.5",
                    height: "100%",
                    width: "100%",
                    zIndex: "1100",
                  }}
                >
                  <Loader
                    type="Bars"
                    color="#30C1FF"
                    height={100}
                    width={100}
                  />
                </div>
              </CardBody>
            ) : (
              <Row>
                <Col md="4" xs="12">
                  <CardBody>
                    <RequesterMessDetail user={user} />
                  </CardBody>
                </Col>
                <Col md="6" xs="12">
                  <CardBody>
                    <h1 className="mb-3">Form Penempatan</h1>
                    <AvForm onSubmit={this.handleSubmit}>
                      <Row>
                        <Col md="8">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1AvField"
                            >
                              Status
                            </label>
                            <AvField
                              type="select"
                              name="booking_status_id_fk"
                              onChange={this.handleChange}
                              value={formData.booking_status_id_fk}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please Select State",
                                },
                              }}
                            >
                              {formData.booking_status_id_fk === 1 ? (
                                <>
                                  <option value="" selected disabled>
                                    -Pilih Status-
                                  </option>
                                  {states &&
                                    states.length > 0 &&
                                    states.slice(0, 4).map((stat, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={stat.booking_status_id}
                                        >
                                          {stat.booking_status_name}
                                        </option>
                                      );
                                    })}
                                </>
                              ) : formData.booking_status_id_fk === 3 ||
                                formData.booking_status_id_fk === 4 ? (
                                <>
                                  <option value="" selected disabled>
                                    -Pilih Status-
                                  </option>
                                  {states &&
                                    states.length > 0 &&
                                    states.slice(1, 4).map((stat, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={stat.booking_status_id}
                                        >
                                          {stat.booking_status_name}
                                        </option>
                                      );
                                    })}
                                </>
                              ) : formData.booking_status_id_fk === 7 ||
                                formData.booking_status_id_fk === 10 ? (
                                <>
                                  <option value="" selected disabled>
                                    -Pilih Status-
                                  </option>
                                  {states &&
                                    states.length > 0 &&
                                    states
                                      .filter(
                                        ({ booking_status_id }) =>
                                          booking_status_id === 7 ||
                                          booking_status_id === 10 ||
                                          booking_status_id === 5
                                      )
                                      .map((statOne, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={statOne.booking_status_id}
                                          >
                                            {statOne.booking_status_name}
                                          </option>
                                        );
                                      })
                                      .reverse()}
                                </>
                              ) : formData.booking_status_id_fk === 8 ||
                                formData.booking_status_id_fk === 9 ? (
                                <>
                                  <option value="" selected disabled>
                                    -Pilih Status-
                                  </option>
                                  {states &&
                                    states.length > 0 &&
                                    states
                                      .filter(
                                        ({ booking_status_id }) =>
                                          booking_status_id === 8 ||
                                          booking_status_id === 9 ||
                                          booking_status_id === 5
                                      )
                                      .map((statTwo, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={statTwo.booking_status_id}
                                          >
                                            {statTwo.booking_status_name}
                                          </option>
                                        );
                                      })
                                      .reverse()}
                                </>
                              ) : (
                                <>
                                  <option value="" selected disabled>
                                    -Pilih Status-
                                  </option>
                                  {states &&
                                    states.length > 0 &&
                                    states
                                      .filter(
                                        ({ booking_status_id }) =>
                                          booking_status_id === 5
                                      )
                                      .map((stat, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={stat.booking_status_id}
                                          >
                                            {stat.booking_status_name}
                                          </option>
                                        );
                                      })}
                                </>
                              )}
                            </AvField>
                          </AvGroup>
                        </Col>
                        <Col md="8">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1AvField"
                            >
                              Jangka Waktu
                            </label>
                            <AvField
                              disabled
                              type="select"
                              name="staying_type"
                              onChange={this.handleChange}
                              value={formData.staying_type}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please Select Period",
                                },
                              }}
                            >
                              {timePeriods &&
                                timePeriods.length > 0 &&
                                timePeriods.map((period, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={period.room_type_id}
                                    >
                                      {period.room_type_name}
                                    </option>
                                  );
                                })}
                            </AvField>
                          </AvGroup>
                        </Col>
                        <Col md="8">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1AvField"
                            >
                              Kategori Mess
                            </label>
                            {formData.staying_type === 1 ? (
                              <AvField
                                disabled
                                type="select"
                                name="occupant_type"
                                onChange={this.handleChange}
                                value={formData.occupant_type}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please Select Category",
                                  },
                                }}
                              >
                                {excategories &&
                                  excategories.length > 0 &&
                                  excategories.map((excategory, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={excategory.location_type_id}
                                      >
                                        {excategory.location_type_name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            ) : (
                              <AvField
                                disabled
                                type="select"
                                name="occupant_type"
                                onChange={this.handleChange}
                                value={formData.occupant_type}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please Select Category",
                                  },
                                }}
                              >
                                {categories &&
                                  categories.length > 0 &&
                                  categories.map((category, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.location_type_id}
                                      >
                                        {category.location_type_name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            )}
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          {formData.booking_status_id_fk === 7 ||
                          formData.booking_status_id_fk === 10 ? (
                            <AvGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1AvField"
                              >
                                Penempatan Mess
                              </label>
                              <AvField
                                disabled
                                type="select"
                                name="location_id_fk1"
                                onChange={this.handleChange}
                                value={formData.location_id_fk1}
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                              >
                                <option value="" selected disabled>
                                  -Pilih Mess-
                                </option>
                                {homes &&
                                  homes.length > 0 &&
                                  homes
                                    .filter((home) => home.location_type !== 2)
                                    .map((home, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={home.location_id}
                                        >
                                          {home.location_name}
                                        </option>
                                      );
                                    })}
                              </AvField>
                            </AvGroup>
                          ) : (
                            <AvGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1AvField"
                              >
                                Penempatan Mess
                              </label>
                              {formData.staying_type === 1 ? (
                                <AvField
                                  type="select"
                                  name="location_id_fk1"
                                  onChange={this.handleChange}
                                  value={formData.location_id_fk1}
                                  validate={{
                                    required: {
                                      value: false,
                                    },
                                  }}
                                >
                                  <option value="" selected disabled>
                                    -Pilih Mess-
                                  </option>
                                  {homes &&
                                    homes.length > 0 &&
                                    homes
                                      .filter(
                                        (home) => home.location_type !== 2
                                      )
                                      .map((home, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={home.location_id}
                                          >
                                            {home.location_name}
                                          </option>
                                        );
                                      })}
                                </AvField>
                              ) : (
                                <>
                                  {formData.occupant_type === 1 ? (
                                    <AvField
                                      type="select"
                                      name="location_id_fk1"
                                      onChange={this.handleChange}
                                      value={formData.location_id_fk1}
                                      validate={{
                                        required: {
                                          value: false,
                                        },
                                      }}
                                    >
                                      <option value="" selected disabled>
                                        -Pilih Mess-
                                      </option>
                                      {homes &&
                                        homes.length > 0 &&
                                        homes
                                          .filter(
                                            (homeSingle) =>
                                              homeSingle.location_type === 1
                                          )
                                          .map((homeSingle, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={homeSingle.location_id}
                                              >
                                                {homeSingle.location_name}
                                              </option>
                                            );
                                          })}
                                    </AvField>
                                  ) : (
                                    <AvField
                                      type="select"
                                      name="location_id_fk1"
                                      onChange={this.handleChange}
                                      value={formData.location_id_fk1}
                                      validate={{
                                        required: {
                                          value: false,
                                        },
                                      }}
                                    >
                                      <option value="" selected disabled>
                                        -Pilih Mess-
                                      </option>
                                      {homes &&
                                        homes.length > 0 &&
                                        homes
                                          .filter(
                                            (homeCouple) =>
                                              homeCouple.location_type === 2
                                          )
                                          .map((homeCouple, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={homeCouple.location_id}
                                              >
                                                {homeCouple.location_name}
                                              </option>
                                            );
                                          })}
                                    </AvField>
                                  )}
                                </>
                              )}
                            </AvGroup>
                          )}
                        </Col>
                        <Col md="6">
                          {formData.booking_status_id_fk === 7 ||
                          formData.booking_status_id_fk === 10 ? (
                            <AvGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1AvField"
                              >
                                Penempatan Kamar
                              </label>
                              <AvField
                                disabled
                                type="select"
                                name="room_id_fk"
                                onChange={this.handleChange}
                                value={formData.room_id_fk}
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                              >
                                <option value="" selected disabled>
                                  -Pilih Kamar-
                                </option>
                                {rooms &&
                                  rooms.length > 0 &&
                                  rooms
                                    .fliter(
                                      (room) =>
                                        room.location_id_fk ===
                                        formData.location_id_fk1
                                    )
                                    .map((room, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={room.room_id}
                                        >
                                          {room.room_name}
                                        </option>
                                      );
                                    })}
                              </AvField>
                            </AvGroup>
                          ) : (
                            <AvGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1AvField"
                              >
                                Penempatan Kamar
                              </label>
                              <AvField
                                type="select"
                                name="room_id_fk"
                                onChange={this.handleChange}
                                value={formData.room_id_fk}
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                              >
                                <option value="" selected disabled>
                                  -Pilih Kamar-
                                </option>
                                {rooms &&
                                  rooms.length > 0 &&
                                  rooms
                                    .filter(
                                      (room) =>
                                        room.location_id_fk ===
                                        formData.location_id_fk1
                                    )
                                    .map((room, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={room.room_id}
                                        >
                                          {room.room_name}
                                        </option>
                                      );
                                    })}
                              </AvField>
                            </AvGroup>
                          )}
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1AvField"
                            >
                              Check In
                            </label>
                            <ReactDatetime
                              selected={this.state.start_date}
                              onChange={this.handleStartDateChange}
                              timeFormat={false}
                              value={formData.start_date}
                            />
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1AvField"
                            >
                              Check Out
                            </label>
                            <ReactDatetime
                              selected={this.state.end_date}
                              onChange={this.handleEndDateChange}
                              isValidDate={this.valid}
                              timeFormat={false}
                              value={formData.end_date}
                            />
                          </AvGroup>
                        </Col>
                        <Col md="12">
                          <InfoMess
                            messInfos={messInfos}
                            roomPick={formData.room_id_fk}
                            messPick={formData.location_id_fk1}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Button color="info" type="submit" size="md">
                            Send
                          </Button>
                          <Button
                            color="secondary"
                            onClick={this.handleCancel}
                            size="md"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Col>
              </Row>
            )}
          </Card>
        </Container>
      </>
    );
  }
}
export default RequestAssignMess;

const RequesterMessDetail = (props) => {
  const [collapse, setCollapse] = useState(true);

  const toggle = () => setCollapse(!collapse);

  return (
    <Card style={{ backgroundColor: "#eeeef4" }}>
      <Row className="mx-2 my-3">
        <Col md="8" xs="8">
          <h3 className="mb-2">Detail Pemohon</h3>
        </Col>
        <Col md="4" xs="4">
          <Button
            className="text-info justify-content-right"
            onClick={toggle}
            style={{ marginBottom: "1rem", float: "right" }}
          >
            <i className="ni ni-bold-down" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={collapse}>
        <div>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-user mr-2 text-info" />
                  Nama
                </td>
                <td className="text-wrap">{props.user.user_name}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-phone mr-2 text-info" />
                  Nomor Telepon
                </td>
                <td className="text-wrap">{props.user.user_phone}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-map-marker-alt mr-2 text-info" />
                  Lokasi Kerja
                </td>
                <td className="text-wrap">{props.user.work_area}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-home mr-2 text-info" />
                  Booking
                </td>
                <td className="text-wrap">
                  {props.user.location_name} - {props.user.room_name}
                </td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-users mr-2 text-info" />
                  Membawa Keluarga
                </td>
                <td className="text-wrap">
                  {props.user.bring_family === true ? "ya" : "tidak"}
                </td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-calendar-alt mr-2 text-info" />
                  Check In
                </td>
                <td className="text-wrap">
                  {moment(props.user.start_date).format("DD MMMM YYYY")}
                </td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="mr-3 text-info" />
                  Check Out
                </td>
                <td className="text-wrap">
                  {props.user.end_date
                    ? moment(props.user.end_date).format("DD MMMM YYYY")
                    : "-"}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Collapse>
    </Card>
  );
};

const InfoMess = (props) => {
  const [collapse, setCollapse] = useState(true);

  const toggle = () => setCollapse(!collapse);

  return (
    <Card style={{ backgroundColor: "#eeeef4" }}>
      <Row className="mx-2 my-3">
        <Col md="8" xs="8">
          <h3 className="mb-2">Informasi Mess</h3>
        </Col>
        <Col md="4" xs="4">
          <Button
            className="text-info justify-content-right"
            onClick={toggle}
            style={{ marginBottom: "1rem", float: "right" }}
          >
            <i className="ni ni-bold-down" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={collapse}>
        {props.messInfos &&
          props.messInfos.length > 0 &&
          props.messInfos
            .filter((mess) => mess.room_id === props.roomPick)
            .map((mess) => {
              return (
                <CardBody>
                  <Row className="mt--4">
                    <Col md="6" xs="12">
                      <i className="fas fa-home mr-2 text-info" />
                      <span>
                        {" "}
                        Kamar tersedia : {mess.count_room_occupant}/
                        {mess.room_capacity}{" "}
                      </span>
                    </Col>
                    <Col md="6" xs="12">
                      <i className="fas fa-users mr-2 text-info" />
                      <span> Jumlah penghuni : {mess.count_occupant} </span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="12">
                      <span>
                        {" "}
                        PJ Mess : {mess.pic_name} / {mess.pic_phone}{" "}
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              );
            })}
      </Collapse>
    </Card>
  );
};
