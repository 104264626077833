import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Axios from "utils/axiosService";
import Box from "components/Login/Box";

function Login(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const keyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleChange = (input) => (event) => {
    if (input === "email") {
      setEmail(event.target.value);
    } else if (input === "password") {
      setPassword(event.target.value);
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (email && password) {
        Axios({
          method: "POST",
          url: "/auth/login",
          data: {
            email: email,
            password: password,
          },
        })
          .then((r) => {
            localStorage.setItem("token", r.data.data.token);
            checkIsAdmin(r.data.data.token, email)
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e);
            Swal.fire({
              icon: "error",
              text: "Wrong E-mail / Password",
              confirmButtonColor: "blue",
            });
            setIsLoading(false);
          });
    } else {
      Swal.fire({
        icon: "error",
        text: "Please Insert Username and Password",
        confirmButtonColor: "blue",
      });
      setIsLoading(false);
    }
  };

  const checkIsAdmin = async (token, email) => {
    try {
      const roleResponse = await Axios({
        method: "GET",
        url: `/role`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const eligibleUsers = roleResponse.data.data.data.map((role) => role.role_name);
  
      const userResponse = await Axios({
        method: "GET",
        url: `user?user_email=${email}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const admin = userResponse.data.data.data[0];
  
      const adminCheckResponse = await Axios({
        method: "GET",
        url: `admin?user_id=${admin.user_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const checkResult = adminCheckResponse.data.data;
      if (checkResult.status === "OK" && eligibleUsers.includes(checkResult.data[0].role)) {
        localStorage.setItem("id_user", admin.user_id);
        localStorage.setItem("username", admin.user_name);
        localStorage.setItem("region_user", admin.work_location_id_fk);
        localStorage.setItem("role_user", admin.user_role ?? 3);
        localStorage.setItem("login", "true");
        localStorage.setItem("role", checkResult.data[0].role);
        setIsLoading(false);
        if (checkResult.data[0].role === "Satpam") {
          window.location.assign("/booking-car");
        } else {
          window.location.assign("/");
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Login Unauthorized",
          confirmButtonColor: "blue",
        });
        setIsLoading(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Login Failed",
        confirmButtonColor: "blue",
      });
      setIsLoading(false);
    }
  };
  
  

  return (
    <>
      {isLoading && (
        <div
          className="position-absolute d-flex justify-content-center align-items-center text-center"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            opacity: "0.5",
            height: "100%",
            width: "100%",
            zIndex: "1100",
          }}
        >
          <Loader type="Bars" color="#30C1FF" height={100} width={100} />
        </div>
      )}
      <div className="w-100vw h-100vh d-flex">
        <div
          className="w-100 h-100 position-relative"
          style={{
            overflow: "hidden",
          }}
        >
          {[...Array(20)].map((_, i) => (
            <Box key={i} />
          ))}
        </div>
        <div
          className="h-100 d-flex flex-column justify-content-center align-items-center"
          style={{ minWidth: "850px", background: "#3E7CA8", zIndex: 10 }}
        >
          <div
            className="d-flex flex-column align-items-center pt-5"
            style={{
              background: "#3E7CA8",
            }}
          >
            <h1
              className="text-white"
              style={{
                fontWeight: "700",
                fontSize: "44px",
              }}
            >
              ParaSpace
            </h1>
            <p
              className="text-white"
              style={{
                fontSize: "22px",
              }}
            >
              BOOKING APPS FOR PARAGONIAN
            </p>
          </div>
          <Card className="px-4 pt-3 mt-4" style={{ width: "317px" }}>
            <h1 className="text-center mb-2" style={{ color: "#006F95" }}>
              Sign in here
            </h1>
            <Form role="form" onSubmit={handleLogin}>
              <FormGroup>
                <small style={{ color: "#006F95" }}>Email</small>
                <InputGroup className="input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="e.g. pti@pti-cosmetics.com"
                    type="email"
                    name="email_admin"
                    onChange={handleChange("email")}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <small style={{ color: "#006F95" }}>Password</small>
                <InputGroup className="input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="password email PTI"
                    name="password_admin"
                    type={passwordType}
                    onChange={handleChange("password")}
                    onKeyDown={keyPress}
                  />
                  <InputGroupAddon
                    addonType="prepend"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (passwordType === "password") setPasswordType("text");
                      else setPasswordType("password");
                    }}
                  >
                    <InputGroupText>
                      <i
                        className={
                          passwordType === "password"
                            ? "fa-sharp fa-solid fa-eye-slash"
                            : "fa-sharp fa-solid fa-eye"
                        }
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-3 py-2 w-100"
                  type="submit"
                  color="light"
                  style={{ background: "#006F95" }}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Login;
