import React from "react";

function LobbyMenuFooter() {
  return (
    <footer
      style={{
        background: "rgba(238, 238, 238, 1)",
        padding: "24px 0",
      }}
    >
      <p style={{ textAlign: 'center', margin: '0' }}>
        @2022 Paragon Technology and Innovation
      </p>
    </footer>
  );
}

export default LobbyMenuFooter;
