import React, { useEffect, useState } from "react";
import Axios from "utils/axiosService";
import { Container } from "reactstrap";
import Loader from "react-loader-spinner";

import UserManagementTable from "components/bookingCar/UserManagementTable";
import { generateQueryFilter } from "components/bookingCar/UserManagementTable";

function UserManagement({ setTitle }) {
  useEffect(() => {
    setTitle("User Management");
    // eslint-disable-next-line
  }, []);

  const [usersData, setUsersData] = useState([]);
  const [isLoadingUsersData, setIsLoadingUsersData] = useState(false);
  const [refetch, setIsRefetch] = useState(false);
  const toggleRefetch = () => setIsRefetch(!refetch);

  const [filter, setFilter] = useState({ statuses: [], query: "" });
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const fetchData = async () => {
    setIsLoadingUsersData(true);
    let queryFilter = "";
    queryFilter += `&limit=${sizePerPage}&page=${page}`;
    queryFilter += generateQueryFilter(filter);

    await Axios({
      method: "GET",
      url: "/admin/car/user?order_column=id&order_sequence=DESC" + queryFilter,
    })
      .then((r) => {
        const res = r.data.data;
        setUsersData(res.users.data);
        setTotalSize(res.total_size);
      })
      .catch((error) => {
        console.error(error);
        return {
          totalSize: 0,
          users: [],
        };
      })
      .finally(() => {
        setIsLoadingUsersData(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [refetch, filter, sizePerPage, page]);

  return (
    <Container fluid style={{ background: "#F8F9FE" }}>
      {!isLoadingUsersData && (
        <>
          <UserManagementTable
            data={usersData}
            filter={filter}
            setFilter={setFilter}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            setPage={setPage}
            setSizePerPage={setSizePerPage}
            toggleRefetch={toggleRefetch}
          />
        </>
      )}
      {isLoadingUsersData && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Loader type="Oval" color="#30C1FF" height={50} width={50} />
        </div>
      )}
    </Container>
  );
}

export default UserManagement;
