import Monitoring from "views/pages/booking_car/Monitoring";
import UserManagement from "views/pages/booking_car/UserManagement";
import CarsManagement from "views/pages/booking_car/CarsManagement";

const routes = [
  {
    path: "/booking-monitoring",
    name: "Booking Car Monitoring",
    icon: "bi bi-laptop",
    component: Monitoring,
    layout: "/booking-car",
  },
  {
    path: "/user-management",
    name: "User Management",
    icon: "bi bi-people-fill",
    component: UserManagement,
    layout: "/booking-car",
  },
  {
    path: "/car-management",
    name: "Cars Management",
    icon: "bi bi-car-front-fill",
    component: CarsManagement,
    layout: "/booking-car",
  },
];

export default routes;
