/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import axios from 'axios';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      alert: null,
      isLoading: true,
      isEmpty: false,
      user_img:null
    };
    this.handleSubmit=this.handleSubmit.bind(this);
    this.handleFile=this.handleFile.bind(this);
  }
  
  componentDidMount() {
    const id_user = localStorage.getItem('id_user');

    axios
      .get(`/Userlist?id_user=${id_user}`)
      .then((response) => {
        this.setState({
          ...this.state,
          users: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error.response);

        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: 'info',
            text: 'List is empty!',
            confirmButtonColor: '#30C1FF',
          });
        }
      });
  }

  handleFile = event => {
    this.setState({[event.target.name]: event.target.files[0]})
  } 

  handleSubmit(event) {
    event.preventDefault();
    const id_user = localStorage.getItem('id_user')
    var dataPhoto = new FormData();
    dataPhoto.append('user_img', this.state.user_img);
    dataPhoto.append('id_user', id_user);
   
    const options={
      onUploadProgress: (progressEvent) => {
        const{loaded,total} = progressEvent;
        let percent = Math.floor((loaded*100)/total)
     
        if(percent < 100){
          this.setState({ uploadPercentage: percent})
        }
      }
    }

    axios.post('/admin', dataPhoto, options)
      .then(response => {
        this.setState({uploadPercentage:100}, ()=>{
          setTimeout(()=>{
            this.setState({uploadPercentage:0})
          },1000)
        })
        var status = response.data.status
        if(status===true){
          Swal.fire({
               icon: 'success',
               text: 'Photo has been uploaded.',
               confirmButtonColor: '#30C1FF',
            });
          this.props.history.goBack();
        }
        else {
          Swal.fire({
               icon: 'error',
               text: 'Error to save',
              confirmButtonColor: '#30C1FF',
          });
        }

      })
      .catch(error => {
        Swal.fire({
               icon: 'error',
               text: 'failed to update new photo profile!',
              confirmButtonColor: '#30C1FF',
          });
        this.props.history.goBack();
      })
      
  }

  render() {
    const { users, isLoading, alert, isEmpty } = this.state;

    return (
      <>
        {alert}
        <ProfileHeader />
        <Container className="mt--6" fluid>
        {isLoading && !isEmpty ? (
          <div className="justify-content-center text-center" style={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)', 
            opacity: '0.5', 
            height: '100%',
            width: '100%',
            zIndex:'1100'
          }}>
            <Loader
                type="Bars"
                color="#30C1FF"
                height={100}
                width={100}
              />
          </div>
        ) : (
          <>
          {isEmpty ? (
              <CardBody>
                <h1>User Detail is Empty !</h1>
              </CardBody>
          ) : (
          <Row>
            <Col className="order-xl-2" xl="4">
              <Card className="card-profile">
                <CardImg
                  alt="..."
                  src={require("assets/img/theme/img-1-1000x600.jpg")}
                  top
                />
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <ChangePhoto 
                        handleSubmit={this.handleSubmit}
                        handleFile={this.handleFile}
                        uploadPercentage = {this.state.uploadPercentage}
                        userImg = {users[0].user_img}
                        user_img = {this.state.user_img}
                      />
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                </CardHeader>
                <CardBody className="pt-4 text-center">
                  <div className="text-center">
                    <h5 className="h3 mt-1">
                    {users[0].user_name}
                    </h5>
                    <div className="h5 mt-2">
                      <i className="ni business_briefcase-24 mr-2" />
                      {users[0].directorate_id_fk}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">INFORMASI USER</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Informasi User
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nama Lengkap
                            </label>
                            <Input
                              defaultValue={users[0].user_name}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Jenis Kelamin
                            </label>
                            <Input
                             defaultValue={users[0].user_gender}
                             type="text"
                             readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Tanggal Lahir
                            </label>
                            <Input
                              defaultValue={users[0].user_birth_place+', '+users[0].user_birth}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        { users[0].user_role !== null ? (
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Role
                            </label>
                            <Input
                              defaultValue={users[0].role_name}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                         ): (null) }
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Nomor Telepon
                            </label>
                            <Input
                             defaultValue={users[0].user_phone}
                             type="text"
                             readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                             Email
                            </label>
                            <Input
                              defaultValue={users[0].user_email}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          )}
        </>
        )}
        </Container>
      </>
    );
  }
}

export default UserDetails;




const ChangePhoto = (props) => {

  const [modal, setModal] = useState(false);
  const [toggleOpen, setToggleopen] = React.useState(null);
  const toggle = () => setModal(!modal);
  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  const handleClick = () => {
    setToggleopen(toggle);
  }

  let photouser;
    if(props.userImg===null)
        (
        photouser=  <img
                      alt="..."
                      className="rounded-circle"
                      style={{width: 140, height:140}}
                      src={require("assets/img/theme/team-4.jpg")}
                      onClick={handleClick}
                    />
        )
    else(
        photouser = <img
                      alt="..."
                      className="rounded-circle"
                      style={{width: 140, height:140}}
                      src={`https://parastay-api.pti-cosmetics.com/assets/upload_profile/${props.userImg}`}
                      onClick={handleClick}
                    />
        )

  return (
  <>
    <Button>
      {photouser}
    </Button>
    <Modal isOpen={modal} toggle={toggleOpen} centered>
      <ModalHeader toggle={toggle} close={closeBtn}>Ingin mengganti photo profile?</ModalHeader>
      <ModalBody className="text-center">
        <Form onSubmit={props.handleSubmit}>
          <Input
            name="user_img"
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={props.handleFile}
            style={{ 
                      padding:'50px 12px',
                      border:'1px dashed rgb(11 69 80)',
                      fontSize: '1em',
                      transition: 'all .4s',
                      cursor: 'pointer',
                      borderRadius: '10px'}}
          />
           {props.uploadPercentage > 0 && <Progress color="success" value={props.uploadPercentage}>{`${props.uploadPercentage}%`}</Progress>}
        </Form>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button color="info" onClick={props.handleSubmit}>Save</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  </>
);
}