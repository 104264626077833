/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import axios from "axios";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { CSVLink } from "react-csv";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class OccupantMessListAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list_mess: [],
      alert: null,
      isLoading: true,
      isEmpty: false,
    };
  }

  componentDidMount() {
    this.props.setTitle("Daftar Penghuni Mess");
    axios
      .get("/OccupantMessList")
      .then((response) => {
        this.setState({
          ...this.state,
          list_mess: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: "info",
            text: "List is empty!",
            confirmButtonColor: "#30C1FF",
          });
        }
      });
  }

  render() {
    const { isLoading, isEmpty } = this.state;

    const headers_table = [
      { label: "Nama", key: "user_name" },
      { label: "Gender", key: "user_gender" },
      { label: "Direktorat", key: "directorate_id_fk" },
      { label: "Lokasi kerja", key: "work_location_id_fk" },
      { label: "Jangka Waktu", key: "room_type_name" },
      { label: "Nama Mess", key: "location_name" },
      { label: "Kamar", key: "room_name" },
      { label: "Check In", key: "mess_check_in" },
    ];

    return (
      <>
        {this.state.alert}
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                {isLoading && !isEmpty ? (
                  <div
                    className="justify-content-center text-center"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      opacity: "0.5",
                      height: "100%",
                      width: "100%",
                      zIndex: "1100",
                    }}
                  >
                    <Loader
                      type="Bars"
                      color="#30C1FF"
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <>
                    {isEmpty ? (
                      <CardBody>
                        <h1>Penghuni Mess List is Empty !</h1>
                      </CardBody>
                    ) : (
                      <ToolkitProvider
                        data={this.state.list_mess}
                        keyField="name"
                        columns={[
                          {
                            dataField: "mess_id",
                            text: "ID",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "user_name",
                            text: "Nama",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "user_gender",
                            text: "Gender",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "directorate_id_fk",
                            text: "Direktorat",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "work_location_id_fk",
                            text: "Lokasi Kerja",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "room_type_name",
                            text: "Jangka Waktu",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "location_name",
                            text: "Nama Mess",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "room_name",
                            text: "Kamar",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "mess_check_in",
                            text: "Check In",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <Container fluid>
                              <Row>
                                <Col xs={12} sm={6} md={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <CSVLink
                                      data={this.state.list_mess}
                                      headers={headers_table}
                                      filename={"Penghuni Mess List.csv"}
                                    >
                                      <Button
                                        style={{ marginRight: "5px" }}
                                        color="default"
                                        size="sm"
                                        className="buttons-copy buttons-html5"
                                        id="print-tooltip"
                                      >
                                        Export to CSV
                                      </Button>
                                    </CSVLink>

                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="default"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          this.copyToClipboardAsTable(
                                            document.getElementById(
                                              "react-bs-table"
                                            )
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => this.componentRef}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <label>
                                      Search:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={(el) => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                              id="react-bs-table"
                              filter={filterFactory()}
                              noDataIndication="There is no data"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default OccupantMessListAll;
