/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Badge,
  ButtonGroup,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
// core components
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import axios from "axios";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import { CSVLink } from "react-csv";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class RequestMessHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list_mess: [],
      mess_detail: "",
      alert: null,
      isLoading: true,
      isEmpty: false,
    };
  }

  componentDidMount() {
    this.props.setTitle("Riwayat Request Mess");
    axios
      .get("/ManageMessHistory")
      .then((response) => {
        this.setState({
          ...this.state,
          list_mess: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: "info",
            text: "List is empty!",
            confirmButtonColor: "#30C1FF",
          });
        }
      });
  }

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      ),
    });
  };

  render() {
    const { isLoading, isEmpty } = this.state;

    const headers_table = [
      { label: "Tanggal", key: "request_date" },
      { label: "Lokasi kerja", key: "work_area" },
      { label: "Request", key: "occupant_type_name" },
      { label: "Nama", key: "user_name" },
      { label: "Gender", key: "user_gender" },
      { label: "Directorate", key: "directorate_id_fk" },
      { label: "Check In", key: "start_date" },
      { label: "Check Out", key: "end_date" },
      { label: "Tanggal Proses", key: "process_date" },
      { label: "Status", key: "booking_status_name" },
    ];

    return (
      <>
        {this.state.alert}
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                {isLoading && !isEmpty ? (
                  <div
                    className="justify-content-center text-center"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      opacity: "0.5",
                      height: "100%",
                      width: "100%",
                      zIndex: "1100",
                    }}
                  >
                    <Loader
                      type="Bars"
                      color="#30C1FF"
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <>
                    {isEmpty ? (
                      <CardBody>
                        <h1>Request Mess History List is Empty !</h1>
                      </CardBody>
                    ) : (
                      <ToolkitProvider
                        data={this.state.list_mess}
                        keyField="name"
                        columns={[
                          {
                            dataField: "data_id",
                            text: "ID",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "request_date",
                            text: "Tanggal",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  {moment(row.request_date).format(
                                    "DD MMMM YYYY"
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            dataField: "work_area",
                            text: "Lokasi Kerja",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "occupant_type_name",
                            text: "Request",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "user_name",
                            text: "Nama",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "user_gender",
                            text: "Gender",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "directorate_id_fk",
                            text: "Directorate",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          // {
                          //     dataField: "mess_category_name",
                          //     text: "Kategori Mess",
                          //     sort: true,
                          //     editable: true,
                          // },
                          // {
                          //   dataField: "time_range",
                          //   text: "Jangka Waktu",
                          //   sort: true,
                          //   editable: true,
                          // },
                          {
                            dataField: "start_date",
                            text: "Check In",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  {moment(row.start_date).format(
                                    "DD MMMM YYYY"
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            dataField: "end_date",
                            text: "Check Out",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              if (row.end_date === null) {
                                return <>-</>;
                              } else {
                                return (
                                  <>
                                    {moment(row.end_date).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </>
                                );
                              }
                            },
                          },
                          {
                            dataField: "process_date",
                            text: "Tanggal Proses",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              if (row.process_date === null) {
                                return <>-</>;
                              } else {
                                return (
                                  <>
                                    {moment(row.process_date).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </>
                                );
                              }
                            },
                          },
                          {
                            dataField: "databasePkey",
                            text: "Status",
                            editable: false,
                            formatter: (cell, row) => {
                              if (
                                row.booking_status_id_fk === 4 ||
                                row.booking_status_id_fk === 6
                              ) {
                                return (
                                  <Badge color="success" pill>
                                    {row.booking_status_name}
                                  </Badge>
                                );
                              } else if (
                                row.booking_status_id_fk === 5 ||
                                row.booking_status_id_fk === 10
                              ) {
                                return (
                                  <Badge color="danger" pill>
                                    {row.booking_status_name}
                                  </Badge>
                                );
                              } else if (row.booking_status_id_fk === 9) {
                                return (
                                  <Badge color="warning" pill>
                                    {row.booking_status_name}
                                  </Badge>
                                );
                              }
                            },
                          },
                          {
                            dataField: "databasePkey",
                            text: "",
                            editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <RequestMessDetail request={row} />
                                </>
                              );
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <Container fluid>
                              <Row>
                                <Col>
                                  <Form className="col-xl-4 col-md-6">
                                    <Select2
                                      className="form-control-sm"
                                      defaultValue={this.state.filterBy}
                                      options={{
                                        placeholder: "Pilih Lokasi Kerja",
                                      }}
                                      data={[
                                        { id: 1, text: "DC" },
                                        { id: 2, text: "HO" },
                                        { id: 3, text: "Plant" },
                                        { id: 99, text: "Semua" },
                                      ]}
                                      // update={value}
                                      onChange={this.toggleNavs}
                                    />
                                  </Form>
                                </Col>
                                <Col>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <CSVLink
                                      data={this.state.list_mess}
                                      headers={headers_table}
                                      filename={"Riwayat Request Mess.csv"}
                                    >
                                      <Button
                                        style={{ marginRight: "5px" }}
                                        color="default"
                                        size="sm"
                                        className="buttons-copy buttons-html5"
                                        id="print-tooltip"
                                      >
                                        Export to CSV
                                      </Button>
                                    </CSVLink>

                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="default"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          this.copyToClipboardAsTable(
                                            document.getElementById(
                                              "react-bs-table"
                                            )
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => this.componentRef}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                                <Col>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <label>
                                      Search:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={(el) => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                              filter={filterFactory()}
                              id="react-bs-table"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default RequestMessHistory;

const RequestMessDetail = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const handleClick = () => {
    setModal(true);
  };

  return (
    <div>
      <Button color="primary" size="md" onClick={handleClick}>
        <i className="fa fa-info-circle" />
      </Button>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Request Mess Detail #{props.request.data_id}
        </ModalHeader>
        <ModalBody>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td colspan="3">
                  {props.request.booking_status_id_fk === 4 ||
                  props.request.booking_status_id_fk === 6 ? (
                    <Badge color="success" pill>
                      {props.request.booking_status_name}
                    </Badge>
                  ) : (
                    <>
                      {props.request.booking_status_id_fk === 5 ||
                      props.request.booking_status_id_fk === 10 ? (
                        <Badge color="danger" pill>
                          {props.request.booking_status_name}
                        </Badge>
                      ) : (
                        <Badge color="warning" pill>
                          {props.request.booking_status_name}
                        </Badge>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-user" />
                </td>
                <td width="40%" className="text-muted">
                  Nama Lengkap
                </td>
                <td className="text-wrap">{props.request.user_name}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-venus-mars" />
                </td>
                <td width="40%" className="text-muted">
                  Gender
                </td>
                <td className="text-wrap">{props.request.user_gender}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-building" />
                </td>
                <td width="40%" className="text-muted">
                  Direktorat
                </td>
                <td className="text-wrap">{props.request.directorate_id_fk}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-phone" />
                </td>
                <td width="40%" className="text-muted">
                  Nomor Telepon
                </td>
                <td className="text-wrap">{props.request.user_phone}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-map-marker-alt" />
                </td>
                <td width="40%" className="text-muted">
                  Lokasi Kerja
                </td>
                <td className="text-wrap">
                  {props.request.work_location_id_fk}
                </td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-home" />
                </td>
                <td width="40%" className="text-muted">
                  Booking
                </td>
                <td className="text-wrap">
                  {props.request.location_name} - {props.request.room_name}
                </td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-clock" />
                </td>
                <td width="40%" className="text-muted">
                  Jangka Waktu
                </td>
                <td className="text-wrap">{props.request.staying_type_name}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-users" />
                </td>
                <td width="40%" className="text-muted">
                  Membawa Keluarga
                </td>
                <td className="text-wrap">
                  {props.request.bring_family === true ? "ya" : "tidak"}
                </td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-calendar-alt" />
                </td>
                <td width="40%" className="text-muted">
                  Check in
                </td>
                <td className="text-wrap">{props.request.start_date}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="" />
                </td>
                <td width="40%" className="text-muted">
                  Check out
                </td>
                <td className="text-wrap">
                  {props.request.end_date ? props.request.end_date : "-"}
                </td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Deskripsi
              </h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p
                classname="text-wrap"
                style={{ fontSize: 13, fontWeight: 400 }}
              >
                {props.request.notes}
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center"></ModalFooter>
      </Modal>
    </div>
  );
};
