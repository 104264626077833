import React, { useEffect, useState } from "react";
import { Card, Container } from "reactstrap";
import Loader from "react-loader-spinner";

import RoomListTable from "components/bookingRoom/roomManagement/RoomListTable";
import Axios from "utils/axiosService";

function ListRoom({ setTitle, history }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTitle("List Room");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: "/room",
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setData(r.data.data.data);
        } else {
          throw new Error("Error fetching data");
        }
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const csv_header = [
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Room Name",
      key: "name",
    },
    {
      label: "Image",
      key: "image",
    },
    {
      label: "Location Detail",
      key: "location_detail",
    },
    {
      label: "Capacity",
      key: "capacity",
    },
    {
      label: "Type",
      key: "type",
    },
    {
      label: "Active",
      key: "active",
    },
    {
      label: "Office name",
      key: "office_area.name",
    },
    {
      label: "Office description",
      key: "office_area.description",
    },
    {
      label: "Created At",
      key: "createdAt",
    },
    {
      label: "Updated At",
      key: "updatedAt",
    },
  ];

  const handleEditRow = (id) => {
    history.push(`/booking-room/room-list/${id}/edit`);
  };

  const handleViewRow = (id) => {
    history.push(`/booking-room/room-list/${id}`);
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        {!isLoading && (
          <RoomListTable
            data={data}
            csv_header={csv_header}
            handleEditRow={handleEditRow}
            handleViewRow={handleViewRow}
          />
        )}
        {isLoading && (
          <div className="w-100 d-flex justify-content-center">
            <Loader type="Oval" color="#30C1FF" height={100} width={100} />
          </div>
        )}
      </Card>
    </Container>
  );
}

export default ListRoom;
