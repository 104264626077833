import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";

function DetailRoom({ setTitle, match, history }) {
  const [idRoom] = React.useState(match.params.id_room);
  const [data, setData] = React.useState({});
  const [facilities, setFacilities] = React.useState([]);
  const [userGroups, setUserGroups] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isShowDeleteModal, setIsShowDeleteModal] = React.useState(false);

  useEffect(() => {
    setTitle("Detail Room");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (idRoom) {
      Axios({
        method: "GET",
        url: `/room/${idRoom}`,
      })
        .then(async (r) => {
          if (!r.data.errorStatus) {
            let data = r.data.data.data;
            data.booking_room_facilities = data.booking_room_facilities.sort(
              (a, b) => {
                return a.id - b.id;
              }
            );
            setData(data);
            setFacilities(data.booking_room_facilities);
            getUserGroupData(data.user_groups_id);
          } else {
            throw new Error("Error fetching data");
          }
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [idRoom]);

  const getUserGroupData = async (data) => {
    const getUserDataRequest = data.map(async (element) => {
      const response = await Axios({
        method: "GET",
        url: "/user-group/" + element,
      });
      return response.data.data.userGroups;
    });

    const userGroupDataArray = await Promise.all(getUserDataRequest);
    const flattenedUserGroupData = userGroupDataArray.flat(); // If userGroups is an array

    setUserGroups(flattenedUserGroupData);
  };

  const handleDelete = () => {
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: `/room/${idRoom}`,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          history.push("/booking-room/room-list");
        } else {
          throw new Error("Error deleting data");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        <h1 className="text-center mt-3">{data.name}</h1>
        <Container>
          {!isLoading && (
            <Row className="py-3">
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Nama</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">{data?.name}</p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Lokasi</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">
                  {data?.office_area?.name}
                  {". "}
                  {data?.office_area?.description}
                </p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Detail Lokasi</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">{data?.location_detail}</p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Kapasitas</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">{data?.capacity}</p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Tipe</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">{data?.type}</p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Available</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">{data?.active ? "Yes" : "No"}</p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Fasilitas</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                {facilities.map((facility, index) => (
                  <div key={index} className="mb-2">
                    <h3 className="mb-0">Fasilitas {index + 1}</h3>
                    <Row>
                      <Col sm="4" md="3" lg="2">
                        <div className="w-100 d-flex justify-content-between align-items-center">
                          <h3 className="mb-0 font-weight-normal">Nama</h3>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col sm="8" md="9" lg="10" className="p-0">
                        <p className="mb-0 text-dark">{facility?.item_name}</p>
                      </Col>
                      <Col sm="4" md="3" lg="2">
                        <div className="w-100 d-flex justify-content-between align-items-center">
                          <h3 className="mb-0 font-weight-normal">Deskripsi</h3>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col sm="8" md="9" lg="10" className="p-0">
                        <p className="mb-0 text-dark">
                          {facility?.description ?? "-"}
                        </p>
                      </Col>
                      {facility?.image && (
                        <>
                          <Col sm="4" md="3" lg="2">
                            <div className="w-100 d-flex justify-content-between align-items-center">
                              <h3 className="mb-0 font-weight-normal">Foto</h3>
                              <span>:</span>
                            </div>
                          </Col>
                          <Col sm="8" md="9" lg="10" className="p-0">
                            <img
                              width={300}
                              src={facility?.image}
                              alt={facility?.item_name ?? ""}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                ))}
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">description</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">{data?.description || "-"}</p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Notes</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <p className="mb-0 text-dark">{data?.note || "-"}</p>
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">Images</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                <img width={500} src={data?.image} alt={data?.name ?? ""} />
              </Col>
              <Col sm="4" md="3" lg="2">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-weight-normal">User Groups</h3>
                  <span>:</span>
                </div>
              </Col>
              <Col sm="8" md="9" lg="10" className="p-0">
                {userGroups.map((userGroup, index) => (
                  <div key={index} className="mb-2">
                    <h3 className="mb-0">
                      <a href={`/booking-room/user-groups/${userGroup.id}`}>
                        {userGroup.group_name}
                      </a>
                    </h3>
                  </div>
                ))}
              </Col>
            </Row>
          )}
          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </Container>
      </Card>
      <div className="mt-3 d-flex justify-content-end">
        <Button
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            setIsShowDeleteModal(true);
          }}
        >
          Delete
        </Button>
        <Button
          color="info"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/booking-room/room-list/${idRoom}/edit`);
          }}
        >
          Edit
        </Button>
      </div>
      <Modal
        isOpen={isShowDeleteModal}
        fade={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowDeleteModal(!isShowDeleteModal);
        }}
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 className="text-center mb-0">
            Semua data mengenai room ini (fasilitas, booking data, dan history)
            akan ikut dihapus. Apakah anda yakin untuk delete room ini?
          </h2>
          <div className="d-flex justify-content-center mt-5">
            <Button
              color="danger"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleDelete();
                setIsShowDeleteModal(false);
              }}
            >
              Delete
            </Button>
            <Button
              color="info"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsShowDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default DetailRoom;
