import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const filteredData = [
  { value: "Booking Created", label: "Mendatang" },
  { value: "Booking Cancelled", label: "Dibatalkan" },
  { value: "In Progress", label: "Sedang Digunakan" },
  { value: "Done", label: "Selesai" },
];

const FilterStatus = ({ searchParams, setSearchParams }) => {
  const [selectedFilter, setSelectedFilter] = useState(new Map());

  const handleCheckboxChange = (label, value) => {
    const selected = [];
    setSelectedFilter((prevSelectedFilter) => {
      const newSelectedFilter = new Map(prevSelectedFilter);
      if (newSelectedFilter.has(label)) {
        newSelectedFilter.delete(label);
      } else {
        newSelectedFilter.set(label, value);
      }
      newSelectedFilter.forEach((val) => selected.push(val));
      return newSelectedFilter;
    });
    return selected;
  };

  useEffect(() => {
    if (searchParams.status) {
      const status = searchParams.status.split(",");
      const newMap = new Map();
      filteredData.forEach((filter) => {
        if (status.includes(filter.value)) {
          newMap.set(filter.label, filter.value);
        }
      });
      setSelectedFilter(newMap);
    }
  }, [searchParams.status]);
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        color="black"
        style={{
          height: "42px",
          background: "white",
          border: "2px solid #EFEFEF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
          display: "flex",
        }}
      >
        <DirectionsCarIcon style={{ color: "grey" }} />
        <span style={{ color: "grey", fontSize: "14px" }}>Status</span> <KeyboardArrowDownIcon />
      </DropdownToggle>

      <DropdownMenu>
        {filteredData.map((filter) => (
          <DropdownItem toggle={false} key={filter.value}>
            <FormGroup check>
              <Label check>
                <Input
                  id={`checkbox-${filter.value}`}
                  value={filter.label}
                  type="checkbox"
                  onChange={() => {
                    const selectedVal = handleCheckboxChange(filter.label, filter.value);
                    setSearchParams({
                        ...searchParams,
                        page: 1,
                        status: selectedVal.join(","),
                        });
                    }}
                  checked={selectedFilter.has(filter.label)}
                />
                {filter.label}
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default FilterStatus;
