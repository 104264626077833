import ArrowSwapIcon from "components/Icons/ArrowSwapIcon";
import GridIcon from "components/Icons/GridIcon";
import ListIcon from "components/Icons/ListIcon";
import MagnifyIcon from "components/Icons/MagnifyIcon";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Button, Form, Input, InputGroup, InputGroupText } from "reactstrap";

function SearchAndFilter({
  history,
  setActiveOptionForm,
  searchValue,
  setSearchValue,
}) {
  const location = useLocation();
  const [activeView, setActiveView] = React.useState("");

  useEffect(() => {
    if (!activeView) {
      if (location.search) {
        const searchParams = new URLSearchParams(location.search);
        setActiveView(searchParams.get("view"));
      } else {
        setActiveView("grid");
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    history.replace(`${location.pathname}?view=${activeView}`);
    // eslint-disable-next-line
  }, [activeView]);

  const handleClickSort = () => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const sortValue = searchParams.get("sort");
      const viewValue = searchParams.get("view");
      if (!sortValue) {
        history.replace(`${location.pathname}?view=${viewValue}&sort=asc`);
      } else if (sortValue === "asc") {
        history.replace(`${location.pathname}?view=${viewValue}&sort=desc`);
      } else if (sortValue === "desc") {
        history.replace(`${location.pathname}?view=${viewValue}&sort=asc`);
      }
    }
  };

  const handleChangeInputSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(searchValue);
  };

  return (
    <div className="d-flex align-items-center">
      <Button
        className="shadow-none bg-white border-0"
        onClick={() => setActiveView("grid")}
      >
        <GridIcon fill={activeView === "grid" ? "#64B8FF" : "black"} />
      </Button>
      <Button
        className="shadow-none bg-white border-0"
        onClick={() => setActiveView("list")}
      >
        <ListIcon fill={activeView === "list" ? "#64B8FF" : "black"} />
      </Button>
      <Button
        className="shadow-none bg-white border-0 d-flex align-items-center"
        onClick={handleClickSort}
      >
        <ArrowSwapIcon />{" "}
        <span style={{ whiteSpace: "nowrap" }}>Created at</span>
      </Button>
      <Form onSubmit={handleSearch}>
        <InputGroup
          className="d-flex align-items-center border"
          style={{ borderRadius: "5px" }}
        >
          <Input
            onChange={handleChangeInputSearch}
            value={searchValue}
            className="py-0 border-0"
            style={{ height: "30px" }}
            placeholder="Search project"
          />
          <InputGroupText
            style={{
              borderStyle: "none none none solid",
              borderRadius: "0 5px 5px 0",
            }}
          >
            <MagnifyIcon />
          </InputGroupText>
        </InputGroup>
      </Form>
    </div>
  );
}

export default SearchAndFilter;
