import React, { useState } from "react";

import { Button, CircularProgress } from "@mui/material";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Axios from "utils/axiosService";
import { utils, writeFile } from "xlsx";
import { filteredParamsForDownloadBookingCar } from "utils/util";
import { formatDate } from "utils/util";

const DownloadData = ({ searchParams }) => {
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const handleDownload = async () => {
    setIsLoadingDownload(true);

    const searchParamsObj = {
      ...searchParams,
      order_column: "id",
      order_sequence: "DESC",
    };

    const filteredSearchParams = filteredParamsForDownloadBookingCar({
      params: searchParamsObj,
    });

    const newUrlSearchParams = new URLSearchParams(
      filteredSearchParams
    ).toString();

    const { data } = await Axios({
      url: `/admin/car/booking/export?${newUrlSearchParams}`,
      method: "GET",
    });

    const listBookingCar = data.data.bookings;

    const formattedBookingCar = listBookingCar.map((booking) => {
      let bookingStatus = "Selesai";
      // eslint-disable-next-line default-case
      switch (booking.booking_status) {
        case "Booking Created":
          bookingStatus = "Mendatang";
          break;
        case "Booking Cancelled":
          bookingStatus = "Dibatalkan";
          break;
        case "In Progress":
          bookingStatus = "Sedang Digunakan";
          break;
      }

      return {
        ID: booking.id,
        Brand: booking.car.brand,
        "Nama Mobil": booking.car.name,
        "Plat Nomor": booking.car.license_plate,
        Status: bookingStatus,
        "Tanggal Pickup": formatDate(booking.start_usage),
        "Inspeksi Awal":
          booking.pre_use_inspection === true
            ? "Sudah"
            : booking.pre_use_inspection === false
            ? "Tidak"
            : "Belum",
        "Tanggal Kembali": formatDate(booking.finish_usage),
        "Inspeksi Akhir":
          booking.after_use_inspection === true
            ? "Sudah"
            : booking.after_use_inspection === false
            ? "Tidak"
            : "Belum",
        "Waktu Pergi": booking.actual_start_usage,
        "Waktu Pulang": booking.actual_finish_usage,
        "Bensin Awal": booking.fuel_start,
        "Bensin Akhir": booking.fuel_end,
        "Kilometer Awal": booking.odo_start,
        "Kilometer Akhir": booking.odo_end,
        "Keperluan Booking": booking.purpose,
        "Car License Expiry": booking.car.license_expiry,
        Bensin: booking.car.fuel,
        Kapasitas: booking.car.capacity,
        Gambar: booking.car.image,
        Aktif: booking.car.is_active ? "Ya" : "Tidak",
        "Status Mobil": booking.car.car_status,
        "Current Location": booking.car.current_location,
        "License Plate Type": booking.car.license_plate_type,
        "URL Lokasi": booking.car.location_url,
        "Kategori Mobil": booking.car.car_category,
        "User ID": booking.user.user_id,
        "Nama User": booking.user.user_name,
        "User Email": booking.user.user_email,
        "User Contact": booking.user.user_phone,
        "User Image": booking.user.user_img,
        "User Position ID": booking.user.position_id_fk,
        "User Department ID": booking.user.department_id_fk,
        "User Division ID": booking.user.division_id_fk,
        "User Directorate ID": booking.user.directorate_id_fk,
        "User Company": booking.user.user_company,
        "User Gender": booking.user.user_gender,
        "Work Location": booking.user.work_location_id_fk,
        "User NIK": booking.user.user_nik,
        "User Status": booking.user.user_status,
      };
    });

    const worksheet = utils.json_to_sheet(formattedBookingCar);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Booking");

    const date = new Date();
    const dateString = date
      .toISOString()
      .replace(/[-T:.Z]/g, "")
      .slice(0, 14);
    const filename = `Booking Car - Data Booking - ${dateString}.xlsx`;

    writeFile(workbook, filename);
  };
  return (
    <Button
      variant="contained"
      style={{
        height: "42px",
        display: "flex",
        columnGap: "8px",
        background: "#3E7CA8",
        padding: "8px 16px",
        fontSize: "16px",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        border: "2px solid #3E7CA8",
        textTransform: "none",
      }}
      className="border-0 text-white"
      onClick={handleDownload}
    >
      {isLoadingDownload ? (
        <CircularProgress sx={{ color: () => "white", padding: "8px" }} />
      ) : (
        <FileDownloadOutlinedIcon style={{ color: "white" }} />
      )}
      <span
        style={{
          marginRight: "10px",
          color: "white",
          fontSize: "14px",
        }}
      >
        Download
      </span>
    </Button>
  );
};

export default DownloadData;
