import React, { useEffect, useState } from "react";
import Axios from "utils/axiosService";
import { Container } from "reactstrap";
import Loader from "react-loader-spinner";

import CarManagementTable from "components/bookingCar/CarManagementTable";
import { generateQueryFilter } from "components/bookingCar/CarManagementTable";

function CarsManagement({ setTitle }) {
  useEffect(() => {
    setTitle("Car Management");
    // eslint-disable-next-line
  }, []);

  const [carsData, setCarsData] = useState([]);
  const [isLoadingCarsData, setIsLoadingCarsData] = useState(false);
  const [refetch, setIsRefetch] = useState(false);
  const toggleRefetch = () => setIsRefetch(!refetch);

  const [filter, setFilter] = useState({
    statuses: [],
    categories: [],
    query: "",
  });
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const fetchData = async () => {
    setIsLoadingCarsData(true);
    let queryFilter = "";
    queryFilter += `&limit=${sizePerPage}&page=${page}`;
    queryFilter += generateQueryFilter(filter);

    await Axios({
      method: "GET",
      url: "/admin/car/cars?order_column=id&order_sequence=ASC" + queryFilter,
    })
      .then((r) => {
        const res = r.data.data;
        setCarsData(res.cars.data);
        setTotalSize(res.total_size);
      })
      .catch((error) => {
        console.error(error);
        return {
          totalSize: 0,
          cars: [],
        };
      })
      .finally(() => {
        setIsLoadingCarsData(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [refetch, filter, sizePerPage, page]);

  return (
    <Container fluid style={{ background: "#F8F9FE" }}>
      {!isLoadingCarsData && (
        <CarManagementTable
          data={carsData}
          filter={filter}
          setFilter={setFilter}
          page={page}
          setPage={setPage}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          totalSize={totalSize}
          toggleRefetch={toggleRefetch}
        />
      )}
      {isLoadingCarsData && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Loader type="Oval" color="#30C1FF" height={50} width={50} />
        </div>
      )}
    </Container>
  );
}

export default CarsManagement;
