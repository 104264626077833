import React from "react";

import {
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import ImageUploader from "./ImageUploader";

const FormReturnFieldsCard = ({
  number,
  name,
  title,
  description,
  placeholder,
  type,
  value,
  onChange,
  damageDescriptionValue = "",
  onChangeDamageDescription = () => {},
  unit = "",
  options = "",
  image = "",
  imagePreview = "",
  handleImageChange = () => {},
  handleRemoveImage = () => {},
}) => {
  return (
    <FormGroup
      style={{
        width: "100%",
        borderRadius: "12px",
        padding: "12px",
        display: "flex",
        columnGap: "8px",
        background: "white",
        boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div>
        <p style={{ fontSize: "12px", color: "#22222", fontWeight: "500" }}>
          {number}.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          rowGap: "8px",
        }}
      >
        <Label
          for={name}
          style={{
            fontSize: "12px",
            color: "#22222",
            marginBottom: "0px",
          }}
        >
          {title}
        </Label>
        <FormText
          style={{ fontSize: "12px", color: "#5e5e5e", marginTop: "0" }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {type === "number" ? (
          <InputGroup
            style={{
              maxWidth: "362px",
              boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              background: "#D9D9D9",
            }}
          >
            <Input
              type={type}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              className="placeholder-style"
              style={{
                height: "100%",
                fontSize: "12px",
              }}
            />
            {unit && (
              <InputGroupAddon
                addonType="append"
                style={{
                  borderRadius: "0 8px 8px 0",
                }}
              >
                <InputGroupText
                  style={{
                    background: "#D9D9D9",
                    color: "#222222",
                  }}
                >
                  {unit}
                </InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        ) : (
          options && (
            <div style={{ display: "flex", columnGap: "24px" }}>
              {options.map((option, index) => (
                <FormGroup check key={index}>
                  <Label check style={{ fontSize: "12px" }}>
                    <Input
                      type="radio"
                      name={name}
                      value={option}
                      checked={value === option}
                      onChange={onChange}
                    />
                    {option}
                  </Label>
                </FormGroup>
              ))}
            </div>
          )
        )}
        {name === "bodyDamage" && value === "Ya" && (
          <>
            <Label
              for="bodyDamageDescription"
              style={{
                fontSize: "12px",
                color: "#22222",
                marginBottom: "0px",
              }}
            >
              Jelaskan kerusakan yang terjadi
            </Label>
            <Input
              type="text"
              name="damageDescription"
              value={damageDescriptionValue}
              onChange={onChangeDamageDescription}
              placeholder="Deskripsi kerusakan"
              className="placeholder-style"
              style={{ fontSize: "12px", borderRadius: "8px" }}
            />

            <Label
              style={{
                fontSize: "12px",
                color: "#22222",
                marginBottom: "0px",
              }}
            >
              Masukkan foto bukti (opsional)
            </Label>

            <ImageUploader
              name={name}
              image={image}
              label="Upload satu gambar kerusakan yang terjadi"
              imagePreview={imagePreview}
              handleImageChange={handleImageChange}
              handleRemoveImage={handleRemoveImage}
            />
          </>
        )}

        {name === "trash" && value === "Ya" && (
          <>
            <Label
              style={{
                fontSize: "12px",
                color: "#22222",
                marginBottom: "0px",
              }}
            >
              Masukkan foto bukti
            </Label>

            <ImageUploader
              name={name}
              image={image}
              imagePreview={imagePreview}
              label="Upload satu gambar sampah yang ada"
              handleImageChange={handleImageChange}
              handleRemoveImage={handleRemoveImage}
            />
          </>
        )}
      </div>

      <style jsx>
        {`
          .placeholder-style::placeholder {
            color: #aaaaaa;
            font-weight: 400;
            font-style: italic;
          }
        `}
      </style>
    </FormGroup>
  );
};

export default FormReturnFieldsCard;
