/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import Loader from "react-loader-spinner";
import Axios from "utils/axiosService";
import Swal from "sweetalert2";

function FormModal({
  handleToggleAdminModal,
  isOpenModal,
  title,
  handleRefetch,
  formValue,
  setFormValue,
  isEdit,
  appData,
  isDelete,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [userSearchData, setUserSearchData] = useState([]);
  const [isAnyNameSelected, setIsAnyNameSelected] = useState(false);
  const [isAccessCheckedAll, setIsAccessCheckedAll] = useState(false);
  const [createdAdminId, setCreatedAdminId] = useState(null);
  const [isAppRelationCreated, setIsAppRelationCreated] = useState(false);
  const [oldAccess, setOldAccess] = useState([]);
  const [accessToBeRemoved, setAccessToBeRemoved] = useState([]);
  const [accessToBeAdded, setAccessToBeAdded] = useState([]);

  useEffect(() => {
    if (isEdit && !isOpenModal) {
      setAccessToBeAdded([]);
      setAccessToBeRemoved([]);
      setOldAccess([]);
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (formValue.email && !isAnyNameSelected && !isEdit) {
      Axios({
        method: "GET",
        url: `/user?user_email=${formValue.email}`,
      }).then((r) => {
        setUserSearchData(r.data.data.data);
      });
    } else {
      setUserSearchData([]);
    }
  }, [formValue.email]);

  useEffect(() => {
    let isAll = true;
    if (formValue?.access?.length > 0) {
      appData.forEach((app) => {
        isAll = isAll && formValue?.access?.includes(app.id);
      });
    } else {
      isAll = false;
    }
    setIsAccessCheckedAll(isAll);
  }, [formValue]);

  useEffect(() => {
    if (isOpenModal && formValue.email) {
      const access =
        formValue?.access?.length > 0 ? [...formValue?.access] : [];
      setOldAccess(access);
    }
  }, [formValue.email, isOpenModal]);

  useEffect(() => {
    if (!isAppRelationCreated && createdAdminId) {
      if (isEdit) {
        //
      } else {
        let accesses = [];
        formValue.access.forEach((access) => {
          const postAccess = Axios({
            method: "POST",
            url: `/admin-relation-app`,
            data: {
              user_admin_id: parseInt(createdAdminId),
              application_id: parseInt(access),
            },
          });
          accesses.push(postAccess);
        });

        Promise.all(accesses)
          .then((r) => {
            r.forEach((res) => {
              if (res.data.errorStatus) {
                throw new Error("Something went wrong");
              }
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setIsAppRelationCreated(true);
            handleRefetch();
            handleToggleAdminModal();
          });
      }
    }
  }, [isAppRelationCreated, createdAdminId]);

  const modalStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleChangeCheckbox = (e) => {
    const newFormValue = { ...formValue };
    const value = e.target.value;
    if (e.target.checked) {
      if (value === "all") {
        appData.forEach((app) => {
          if (!newFormValue.access.includes(app.id)) {
            newFormValue.access.push(app.id);
          }
        });
        if (isEdit) {
          const toBeAdded = [...accessToBeAdded];
          appData.forEach((app) => {
            if (!oldAccess.includes(app.id)) {
              toBeAdded.push(app.id);
              if (accessToBeRemoved.includes(app.id)) {
                const toberemoved = accessToBeRemoved.filter((item) => {
                  return item !== app.id;
                });
                setAccessToBeRemoved(toberemoved);
              }
            }
          });
          setAccessToBeAdded(toBeAdded);
        }
      } else {
        const newValue = value;
        if (!newFormValue.access.includes(newValue)) {
          newFormValue.access.push(newValue);
          if (isEdit) {
            if (!oldAccess.includes(newValue)) {
              setAccessToBeAdded([...accessToBeAdded, newValue]);
            }
            if (accessToBeRemoved.includes(newValue)) {
              const toberemoved = accessToBeRemoved.filter((item) => {
                return item !== newValue;
              });
              setAccessToBeRemoved(toberemoved);
            }
          }
        }
      }
    } else {
      if (value === "all") {
        newFormValue.access = [];
        if (isEdit) {
          setAccessToBeRemoved([...oldAccess]);
        }
      } else {
        const newValue = value;
        newFormValue.access = newFormValue.access.filter(
          (item) => item.toString() !== newValue.toString()
        );
        if (isEdit) {
          if (oldAccess.includes(newValue)) {
            setAccessToBeRemoved([...accessToBeRemoved, newValue]);
          }
          if (accessToBeAdded.includes(newValue)) {
            const tobeadded = accessToBeAdded.filter((item) => {
              return item !== newValue;
            });
            setAccessToBeAdded(tobeadded);
          }
        }
      }
    }
    setFormValue({ ...newFormValue });
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (formValue.role !== "superuser") {
      if (formValue.access.length <= 0) {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          text: "Please select access type",
          confirmButtonColor: "blue",
        });
        return;
      }
    }

    if (isEdit) {
      const promises = [];
      const patch = Axios({
        method: "PATCH",
        url: `/admin/${formValue.id}`,
        data: {
          user_id: parseInt(formValue.user_id),
          user_email: formValue.email,
          name: formValue.name,
          role: formValue.role,
          is_sub_admin: false,
          sub_admin: "",
        },
      });

      promises.push(patch);

      if (accessToBeAdded.length > 0) {
        accessToBeAdded.forEach((access) => {
          const add = Axios({
            method: "POST",
            url: `/admin-relation-app`,
            data: {
              user_admin_id: parseInt(formValue.id),
              application_id: parseInt(access),
            },
          });
          promises.push(add);
        });
      }

      if (accessToBeRemoved.length > 0) {
        accessToBeRemoved.forEach((access) => {
          Axios({
            method: "GET",
            url: `/admin-relation-app?user_admin_id=${formValue.id}&application_id=${access}`,
          }).then((r) => {
            if (!r.data.errorStatus) {
              const remove = Axios({
                method: "DELETE",
                url: `/admin-relation-app/${r.data.data.data[0].id}`,
              });
              promises.push(remove);
            }
          });
        });
      }

      Promise.all(promises)
        .then((r) => {
          setIsLoading(false);
          r.forEach((res) => {
            if (res.data.errorStatus) {
              Swal.fire({
                icon: "error",
                text: `Failed editing Admin Data: ${res.data.errorStatus}`,
                confirmButtonColor: "#30C1FF",
              });
              throw new Error("Something went wrong");
            }
          });
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            text: `Failed editing Admin Data`,
            confirmButtonColor: "#30C1FF",
          });
          console.error(err);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
            handleRefetch();
            handleToggleAdminModal();
          }, 1000);
        });
    } else {
      Axios({
        method: "POST",
        url: "/admin",
        data: {
          user_id: parseInt(formValue.user_id),
          user_email: formValue.email,
          name: formValue.name,
          role: formValue.role,
          is_sub_admin: false,
          sub_admin: "",
        },
      }).then((r) => {
        setIsLoading(false);
        if (!r.data.errorStatus) {
          setCreatedAdminId(r.data.data.data.id);
        } else {
          Swal.fire({
            icon: "error",
            text: `Error creating admin data`,
            confirmButtonColor: "#30C1FF",
          });
          throw new Error("Error creating admin data");
        }
      });
    }
  };

  const handleDelete = (e) => {
    setIsLoading(true);
    e.preventDefault();

    Axios({
      method: "DELETE",
      url: `/admin/${formValue.id}`,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setTimeout(() => {
            setIsLoading(false);
            handleRefetch();
            handleToggleAdminModal();
          }, 1000);
        } else {
          throw new Error("Error deleting admin");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        throw new Error("Something wrong while deleting admin");
      });
  };

  return (
    <Modal
      isOpen={isOpenModal}
      fade={false}
      style={modalStyle}
      toggle={handleToggleAdminModal}
    >
      <ModalBody>
        <h2 className="text-center">{title}</h2>
        {isDelete ? (
          <Form onSubmit={handleDelete}>
            <h3 className="text-center">
              Are you sure you want to delete this admin?
            </h3>
            <div className="justify-content-center mt-3 mb-3">
              <h4 className="text-left">Email:</h4>
              <p className="text-left">{formValue?.email}</p>
              <h4 className="text-left">Name:</h4>
              <p className="text-left">{formValue?.name}</p>
              <h4 className="text-left">Role:</h4>
              <p className="text-left">{formValue?.role}</p>
              <h4 className="text-left">Admin Apps:</h4>
              {formValue?.access &&
                formValue?.access.length > 0 &&
                formValue?.access.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))}
              {formValue?.access?.length === 0 && <p>-</p>}
            </div>
            <div className="d-flex justify-content-center mt-4">
              {isLoading ? (
                <div className="w-30 d-flex justify-content-right mr-2">
                  <Loader
                    type="ThreeDots"
                    color="#30C1FF"
                    height={30}
                    width={30}
                  />
                </div>
              ) : (
                <Input
                  type="submit"
                  value="Delete"
                  className="p-0"
                  style={{
                    background: "#62A461",
                    width: "160px",
                    height: "33px",
                    color: "white",
                    borderRadius: "26px",
                  }}
                />
              )}
            </div>
          </Form>
        ) : (
          <Form onSubmit={handleSubmit}>
            <FormGroup className="d-flex align-items-center justify-content-between position-relative">
              <Label for="email">Email*</Label>
              <Input
                value={formValue.email}
                onChange={handleChange}
                name="email"
                type="email"
                placeholder="Enter email"
                disabled={isEdit}
                required
                style={{ width: "340px" }}
              />
              {userSearchData.length > 0 && (
                <div
                  className="position-absolute"
                  style={{
                    width: "340px",
                    right: "0",
                    top: "50px",
                    zIndex: "2",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {userSearchData.slice(0, 5).map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="name-dropdown"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setFormValue({
                            ...formValue,
                            name: item.user_name,
                            email: item.user_email,
                            user_id: item.user_id,
                          });
                          setIsAnyNameSelected(true);
                          setUserSearchData([]);
                        }}
                      >
                        {item.user_email}
                      </div>
                    );
                  })}
                </div>
              )}
            </FormGroup>
            <FormGroup
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "12px" }}
            >
              <Label for="name">Name*</Label>
              <Input
                value={formValue.name}
                onChange={handleChange}
                name="name"
                placeholder="Enter name"
                disabled={isEdit}
                required
                style={{ width: "340px" }}
              />
            </FormGroup>
            <FormGroup className="d-flex align-items-center justify-content-between">
              <Label for="role">Role*</Label>
              <Input
                name="role"
                type="select"
                required
                style={{ width: "340px" }}
                defaultValue=""
                onChange={handleChange}
                value={formValue.role}
              >
                <option value="" disabled>
                  Select role
                </option>
                <option value="Superadmin">Super Admin</option>
                <option value="Superuser">Super User</option>
                <option value="Admin">Admin</option>
              </Input>
            </FormGroup>
            <FormGroup check className="d-flex justify-content-between p-0">
              <Label for="access">Access*</Label>
              <Row style={{ width: "300px" }}>
                <Col md={6}>
                  <FormGroup className="mb-0">
                    <Input
                      type="checkbox"
                      id="all"
                      name="all"
                      value="all"
                      onChange={handleChangeCheckbox}
                      checked={isAccessCheckedAll}
                    />
                    <Label check for="all">
                      All
                    </Label>
                  </FormGroup>
                  {appData.map((item, index) => {
                    return (
                      <FormGroup className="mb-0" key={index}>
                        <Input
                          type="checkbox"
                          id={`app ${item.id}`}
                          name={item.name}
                          value={item.id}
                          onChange={handleChangeCheckbox}
                          checked={formValue?.access?.includes(item.id)}
                        />
                        <Label check for={item.id}>
                          {item.name}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </Col>
              </Row>
            </FormGroup>
            <div className="d-flex justify-content-end mt-2">
              {isLoading ? (
                <div className="w-50 d-flex justify-content-center">
                  <Loader
                    type="ThreeDots"
                    color="#30C1FF"
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <Input
                  type="submit"
                  value="Submit"
                  className="p-0"
                  style={{
                    background: "#62A461",
                    width: "160px",
                    height: "33px",
                    color: "white",
                    borderRadius: "26px",
                  }}
                />
              )}
            </div>
          </Form>
        )}
      </ModalBody>
      <style jsx>
        {`
          .name-dropdown {
            background: #f5f5f5;
            padding: 10px;
          }
          .name-dropdown:hover {
            background: #e5e5e5;
          }
        `}
      </style>
    </Modal>
  );
}

export default FormModal;
