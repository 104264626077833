import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import Loader from "react-loader-spinner";
import Axios from "utils/axiosService";
import Swal from "sweetalert2";

import moment from "moment";
import "moment/locale/id";

moment.locale("id");

function BookingDetail({ setTitle, match, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowDeleteSeriesModal, setIsShowDeleteSeriesModal] = useState(false);
  const [isShowCancelReason, setIsShowCancelReason] = useState(false);
  const [isShowCancelReasonSeries, setIsShowCancelReasonSeries] =
    useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [refetch, setIsRefetch] = useState(false);
  const [data, setData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const occurrenceId = match.params.id;

  useEffect(() => {
    setTitle("Booking Detail");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (occurrenceId) {
      setIsLoading(true);
      Axios({
        method: "GET",
        url: `/room/occurance/${occurrenceId}`,
      })
        .then((r) => {
          setData(r.data.data.data);
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [occurrenceId, refetch]);

  useEffect(() => {
    const areaId =
      data?.booking_room_booking_list?.booking_room_master_datum?.area_id;
    if (areaId) {
      setIsLoading(true);
      Axios({
        method: "GET",
        url: "/area/" + areaId,
      })
        .then((r) => setAreaData(r.data.data.data))
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [data]);

  const handleCancel = () => {
    if (!cancelReason) {
      Swal.fire({
        icon: "error",
        text: "Cancellation reason can't be blank",
      });
    } else if (cancelReason.length > 255) {
      Swal.fire({
        icon: "error",
        text: "Cancellation reason can't be more than 255 characters",
      });
    } else {
      Axios({
        method: "PATCH",
        url: `/room/booking/${data.booking_id}/occurrence/${occurrenceId}/cancel`,
        data: {
          is_cancel: true,
          status: "Booking Cancelled",
          cancel_reason: cancelReason,
          cancelled_by: localStorage.getItem("username"),
          cancel_timestamp: moment(new Date())
            .format("YYYY-MM-DD HH:mm:ss")
            .toString(),
        },
      })
        .then((r) => {
          setIsRefetch((prev) => !prev);
          Swal.fire({
            icon: "success",
            text: "Booking Cancelled.",
            confirmButtonColor: "#30C1FF",
          });
        })
        .catch((error) => {
          console.error(error.response);
          Swal.fire({
            icon: "error",
            text: "Something went wrong",
          });
        })
        .finally(() => {
          setIsLoading(false);
          setIsShowDeleteModal(false);
          setCancelReason("");
        });
    }
  };

  const handleCancelSeries = () => {
    if (!cancelReason) {
      Swal.fire({
        icon: "error",
        text: "Cancellation reason can't be blank",
      });
    } else if (cancelReason.length > 255) {
      Swal.fire({
        icon: "error",
        text: "Cancellation reason can't be more than 255 characters",
      });
    } else {
      Axios({
        method: "PATCH",
        url: `/room/booking/${data.booking_id}/cancel-series`,
        data: {
          is_cancel: true,
          status: "Booking Cancelled",
          cancel_reason: cancelReason,
          cancelled_by: localStorage.getItem("username"),
          cancel_timestamp: moment(new Date())
            .format("YYYY-MM-DD HH:mm:ss")
            .toString(),
        },
      })
        .then((r) => {
          setIsRefetch((prev) => !prev);
          Swal.fire({
            icon: "success",
            text: "Series Booking has been Cancelled.",
            confirmButtonColor: "#30C1FF",
          });
        })
        .catch((error) => {
          console.error(error.response);
          Swal.fire({
            icon: "error",
            text: "Something went wrong",
          });
        })
        .finally(() => {
          setIsLoading(false);
          setIsShowDeleteSeriesModal(false);
          setCancelReason("");
        });
    }
  };

  return (
    <Container fluid>
      <Card className="m-2 p-3">
        {!isLoading && (
          <>
            {(() => {
              const occurrenceData = data.booking_room_booking_list ?? {};
              const roomData = occurrenceData?.booking_room_master_datum ?? {};

              const { user_email, user_name } = occurrenceData.user ?? {};
              const {
                attendees_amount,
                period_repeat,
                booking_purpose,
                status,
                user_contact,
                booking_room_booking_aula,
              } = occurrenceData ?? {};
              const { name, type, location_detail } = roomData ?? {};
              const { name: area_name } = areaData ?? {};
              const {
                finish_usage,
                start_usage,
                cancelled_by,
                cancel_reason,
                cancel_timestamp,
              } = data ?? {};
              const {
                aula_layout,
                aula_layout_detail,
                cable_roll_amount,
                chair_per_table_amount,
                flipchart_amount,
                kursi_kampus_amount,
                kursi_standard_amount,
                lesehan_area,
                seat_option,
                square_table_amount,
                table_amount,
                whiteboard_amount,
                additional_info_aula,
              } = booking_room_booking_aula ?? {};
              return (
                <>
                  <h1 className="text-center mt-3">{booking_purpose}</h1>
                  <Row className="py-3">
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Name</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{user_name}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Contact</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{user_contact}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Email</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{user_email}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Status</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{status}</p>
                    </Col>
                    {cancelled_by && (
                      <>
                        <Col sm="5" md="4" lg="3">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Cancelled by
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">{cancelled_by}</p>
                        </Col>
                        <Col sm="5" md="4" lg="3">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Cancellation Reason
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">{cancel_reason}</p>
                        </Col>
                        <Col sm="5" md="4" lg="3">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Canceled At
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">
                            {moment(cancel_timestamp)
                              // .add(7, "h")
                              .format("Do MMMM YYYY, LT")}
                          </p>
                        </Col>
                      </>
                    )}
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">
                          Attendees Amount
                        </h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">
                        {attendees_amount || "-"}
                      </p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Period</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{period_repeat}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Room</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{name || "-"}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Room Type</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{type}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Location</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{area_name}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Detail</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{location_detail}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">Time</h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">{`${moment(start_usage)
                        // .add(7, "h")
                        .format("Do MMMM YYYY, LT")} - ${moment(finish_usage)
                        // .add(7, "h")
                        .format("Do MMMM YYYY, LT")}`}</p>
                    </Col>
                    <Col sm="5" md="4" lg="3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="mb-0 font-weight-normal">
                          Additional Info
                        </h3>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col sm="7" md="8" lg="9" className="p-0">
                      <p className="mb-0 text-dark">
                        {additional_info_aula || "-"}
                      </p>
                    </Col>
                    {booking_room_booking_aula && (
                      <>
                        <Col sm="5" md="4" lg="3" className="mt-4">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Layout Information
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0"></Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">Layout</h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">
                            {aula_layout.charAt(0).toUpperCase() +
                              aula_layout.slice(1) ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3
                              className={`mb-0 font-weight-normal ${
                                aula_layout !== "others" ? "text-muted" : ""
                              }`}
                            >
                              Layout Detail
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p
                            className={`mb-0 ${
                              aula_layout !== "others"
                                ? "text-muted"
                                : "text-dark"
                            }`}
                          >
                            {aula_layout_detail ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3
                              className={`mb-0 font-weight-normal ${
                                aula_layout !== "lesehan" ? "text-muted" : ""
                              }`}
                            >
                              Lesehan Area
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p
                            className={`mb-0 ${
                              aula_layout !== "lesehan"
                                ? "text-muted"
                                : "text-dark"
                            }`}
                          >
                            {lesehan_area || "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3
                              className={`mb-0 font-weight-normal ${
                                aula_layout !== "roundTable" ? "text-muted" : ""
                              }`}
                            >
                              Table(s)
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p
                            className={`mb-0 ${
                              aula_layout !== "roundTable"
                                ? "text-muted"
                                : "text-dark"
                            }`}
                          >
                            {table_amount ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3
                              className={`mb-0 font-weight-normal ${
                                aula_layout !== "roundTable" ? "text-muted" : ""
                              }`}
                            >
                              Chair per Table
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p
                            className={`mb-0 ${
                              aula_layout !== "roundTable"
                                ? "text-muted"
                                : "text-dark"
                            }`}
                          >
                            {chair_per_table_amount ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3
                              className={`mb-0 font-weight-normal ${
                                aula_layout !== "uShape" ||
                                aula_layout !== "classroom"
                                  ? "text-muted"
                                  : ""
                              }`}
                            >
                              Standard Chair
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p
                            className={`mb-0 ${
                              aula_layout !== "uShape" ||
                              aula_layout !== "classroom"
                                ? "text-muted"
                                : "text-dark"
                            }`}
                          >
                            {kursi_standard_amount ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3
                              className={`mb-0 font-weight-normal ${
                                aula_layout !== "uShape" ||
                                aula_layout !== "classroom"
                                  ? "text-muted"
                                  : ""
                              }`}
                            >
                              Kursi Kampus
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p
                            className={`mb-0 ${
                              aula_layout !== "uShape" ||
                              aula_layout !== "classroom"
                                ? "text-muted"
                                : "text-dark"
                            }`}
                          >
                            {kursi_kampus_amount ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3
                              className={`mb-0 font-weight-normal ${
                                aula_layout !== "uShape" ||
                                aula_layout !== "classroom"
                                  ? "text-muted"
                                  : ""
                              }`}
                            >
                              Seat Option
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p
                            className={`mb-0 ${
                              aula_layout !== "uShape" ||
                              aula_layout !== "classroom"
                                ? "text-muted"
                                : "text-dark"
                            }`}
                          >
                            {seat_option || "-"}
                          </p>
                        </Col>

                        <Col sm="5" md="4" lg="3" className="mt-4">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Supporting Facilities
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0"></Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Flipchart
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">
                            {flipchart_amount ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Whiteboard
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">
                            {whiteboard_amount ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Square Table(s)
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">
                            {square_table_amount ?? "-"}
                          </p>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="4" md="3" lg="2">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h3 className="mb-0 font-weight-normal">
                              Cable Roll
                            </h3>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col sm="7" md="8" lg="9" className="p-0">
                          <p className="mb-0 text-dark">
                            {cable_roll_amount ?? "-"}
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              );
            })()}
            {new Date(data?.finish_usage) > new Date() && (
              <div className="mt-3 d-flex justify-content-end">
                <Button
                  style={{
                    cursor: data?.is_cancel ? "not-allowed" : "pointer",
                  }}
                  color={data?.is_cancel ? "secondary" : "danger"}
                  disabled={data?.is_cancel}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsShowDeleteModal(true);
                  }}
                >
                  Cancel Booking
                </Button>
                {data.booking_room_booking_list.period_repeat !== "Once" && (
                  <Button
                    style={{
                      cursor: data?.is_cancel ? "not-allowed" : "pointer",
                    }}
                    color={data?.is_cancel ? "secondary" : "warning"}
                    disabled={data?.is_cancel}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowDeleteSeriesModal(true);
                    }}
                  >
                    Cancel Booking Series
                  </Button>
                )}
              </div>
            )}
          </>
        )}
        {isLoading && (
          <div className="w-100 d-flex justify-content-center">
            <Loader type="Oval" color="#30C1FF" height={100} width={100} />
          </div>
        )}

        <Modal
          isOpen={isShowDeleteModal}
          fade={false}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          toggle={() => {
            setIsShowDeleteModal(!isShowDeleteModal);
          }}
        >
          <ModalBody
            style={{
              minHeight: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {!isShowCancelReason && (
              <>
                <h2 className="text-center mb-0">
                  Are you sure to cancel this book?
                </h2>
                <div className="d-flex justify-content-center">
                  <Button
                    color="danger"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowCancelReason(true);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    color="info"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowDeleteModal(false);
                    }}
                  >
                    No
                  </Button>
                </div>
              </>
            )}
            {isShowCancelReason && (
              <>
                <div
                  style={{
                    width: "420px",
                  }}
                >
                  <h2 className="text-center mb-0 pb-3">Cancellation Reason</h2>
                  <Input
                    type="textarea"
                    rows="6"
                    maxlength="255"
                    name="text"
                    id="cancelReason"
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-center pt-2">
                  <Button
                    color="danger"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCancel();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="info"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowDeleteModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isShowDeleteSeriesModal}
          fade={false}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          toggle={() => {
            setIsShowDeleteSeriesModal(!isShowDeleteSeriesModal);
          }}
        >
          <ModalBody
            style={{
              minHeight: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {!isShowCancelReasonSeries && (
              <>
                <h2 className="text-center mb-0">
                  Are you sure to cancel this booking and its series?
                </h2>
                <div className="d-flex justify-content-center">
                  <Button
                    color="danger"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowCancelReasonSeries(true);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    color="info"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowDeleteSeriesModal(false);
                    }}
                  >
                    No
                  </Button>
                </div>
              </>
            )}
            {isShowCancelReasonSeries && (
              <>
                <div
                  style={{
                    width: "420px",
                  }}
                >
                  <h2 className="text-center mb-0 pb-3">Cancellation Reason</h2>
                  <Input
                    type="textarea"
                    rows="6"
                    name="text"
                    id="cancelReason"
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-center pt-2">
                  <Button
                    color="danger"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCancelSeries();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="info"
                    className="p-0 px-2"
                    style={{
                      height: "30px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowDeleteSeriesModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </Card>
    </Container>
  );
}

export default BookingDetail;
