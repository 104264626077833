import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { Button } from "reactstrap";

function AdminListTable({ adminData, handleEdit, handleRevokeAdmin }) {
  const handleClickEdit = (id) => {
    handleEdit(id);
  };
  const handleRevoke = (id) => {
    handleRevokeAdmin(id);
  };
  const headerStyle = (column, colIndex) => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };
  return (
    <ToolkitProvider
      data={adminData}
      keyField="id"
      columns={[
        {
          dataField: "id",
          text: "No",
          headerStyle: headerStyle,
          formatter: (cell, row) => {
            return <div>{adminData.indexOf(row) + 1}</div>;
          },
        },
        {
          dataField: "name",
          text: "Name",
          filter: textFilter(),
          sort: true,
          headerStyle: headerStyle,
          headerClasses: "header-style-admin",
        },
        {
          dataField: "user_email",
          text: "Email",
          filter: textFilter(),
          sort: true,
          headerStyle: headerStyle,
          headerClasses: "header-style-admin",
        },
        {
          dataField: "role",
          text: "Role",
          filter: textFilter(),
          sort: true,
          headerStyle: headerStyle,
          headerClasses: "header-style-admin",
          formatter: (cell, row) => {
            return (
              <span
                style={{
                  textTransform: "capitalize",
                }}
              >
                {row.role}
              </span>
            );
          },
        },
        {
          dataField: "listAdmin",
          text: "List Admin",
          filter: textFilter(),
          sort: true,
          headerStyle: headerStyle,
          headerClasses: "header-style-admin",
          formatter: (cell, row) => {
            return (
              <ul className="mb-0">
                {row.access ?
                  row.access?.length > 0 &&
                  row.access?.map((item, index) => (
                    <li key={index}>{item.name}</li>
                  )) : <li>-</li>}
                  {
                    row.access?.length === 0 && <p>-</p>
                  }
              </ul>
            );
          },
        },
        {
          dataField: "Photo",
          text: "Photo",
          headerStyle: headerStyle,
          formatter: (cell, row) => {
            return (
              <img
                style={{
                  borderRadius: "100%",
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                }}
                src={require("assets/img/menu/avatar.png")}
                alt="profile"
              />
            );
          },
        },
        {
          dataField: "id",
          text: "Action",
          headerStyle: headerStyle,
          formatter: (cell, row) => {
            return (
              <>
                <Button
                  className="border-0 text-white p-0"
                  style={{ background: "#DAA743", width: "68px", height: "27px" }}
                  onClick={() => handleClickEdit(row.id)}
                >
                  Edit
                </Button>
                <Button
                  className="border-0 text-white p-0"
                  style={{ background: "red", width: "68px", height: "27px" }}
                  onClick={() => handleRevoke(row.id)}
                >
                  Delete
                </Button>
              </>
            );
          },
        },
      ]}
    >
      {(props) => (
        <div className="table-responsive">
          <BootstrapTable
            {...props.baseProps}
            bootstrap4={true}
            filter={filterFactory()}
            bordered={false}
            id="react-bs-table"
            noDataIndication="No Admin User Found"
            rowClasses={(row, rowIndex) => "row-style-admin"}
          />
        </div>
      )}
    </ToolkitProvider>
  );
}

export default AdminListTable;
