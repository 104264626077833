import React from "react";

function ListIcon({fill}) {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55 6.3C8.79853 6.3 9 6.50147 9 6.75C9 6.99853 8.79853 7.2 8.55 7.2H2.25C2.00147 7.2 1.8 6.99853 1.8 6.75C1.8 6.50147 2.00147 6.3 2.25 6.3H8.55ZM8.55 3.15C8.79853 3.15 9 3.35147 9 3.6C9 3.84853 8.79853 4.05 8.55 4.05H2.25C2.00147 4.05 1.8 3.84853 1.8 3.6C1.8 3.35147 2.00147 3.15 2.25 3.15H8.55ZM8.55 0C8.79853 0 9 0.201472 9 0.45C9 0.698528 8.79853 0.9 8.55 0.9H2.25C2.00147 0.9 1.8 0.698528 1.8 0.45C1.8 0.201472 2.00147 0 2.25 0H8.55ZM0 0H0.9V0.9H0V0ZM0 3.15H0.9V4.05H0V3.15ZM0 6.3H0.9V7.2H0V6.3Z"
        fill={fill ?? "black"}
      />
    </svg>
  );
}

export default ListIcon;
