import React from "react";
function SignOutIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4319 2.43951V4.58959L13.8101 3.22511V0.689127L13.121 0H0.716692L0.0275651 0.689127V1.35896L0 1.37825V15.5605L0.496171 16.1945L7.38744 18.5651L8.26953 17.9173V16.5391H13.121L13.8101 15.8499V13.3553L12.4319 11.977V15.1608H8.26953V3.73507L7.8147 3.10107L2.80613 1.37825H12.4319V2.43951ZM6.89127 16.925L1.37825 15.0781V2.3706L6.89127 4.21746V16.925V16.925ZM15.8775 8.93109H9.02757V7.55283H15.8224L13.6172 5.34763L14.5957 4.38285L18 7.77335V8.75191L14.5681 12.17L13.6034 11.2052L15.8775 8.93109V8.93109Z"
        fill="#7F7F7F"
      />
    </svg>
  );
}

export default SignOutIcon;
