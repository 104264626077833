import CarMonitoringList from "./TableMonitoringBookingCar";
import React from "react";

const BookingCarMonitoringSection = ({ data, searchParams, setSearchParams, totalPage, total_size }) => {
  return (
    <div>
      <CarMonitoringList
        csv_header={{}}
        data={data.length ? data : []}
        sizePerPage={searchParams.limit || 10}
        totalPage={totalPage}
        currentPage={searchParams.page || 1}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        total_size={total_size}
      />
    </div>
  );
};

export default BookingCarMonitoringSection;
