import React from "react";

import "./style.css";

function getRandomNumber(min, max) {
    
    return Math.random() * (max - min) + min;
      
  }

function Box() {
  const colors = [
    "#6A6DBF",
    "#E08787",
    "#61AAAE",
    "#D962A4",
    "#3E7CA8",
    "#62A461",
  ];

  return (
    <div className="x" style={{
        animation: `x ${getRandomNumber(2, 20)}s linear infinite alternate`
    }}>
      <div
        className="box y"
        style={{
          background: colors[Math.floor(getRandomNumber(0, 6))],
        animation: `y ${getRandomNumber(3, 20)}s linear infinite alternate`

        }}
        s
      ></div>
    </div>
  );
}

export default Box;
