import React from "react";

function UploadIcon() {
  return (
    <svg
      width="72"
      height="57"
      viewBox="0 0 72 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.0991 15.5542H56.3535C58.3547 15.5545 60.3361 15.9497 62.1843 16.7171C64.0326 17.4845 65.7112 18.609 67.1241 20.0263C69.9817 22.8905 71.5866 26.7713 71.5866 30.8173C71.5866 34.8632 69.9817 38.744 67.1241 41.6082C65.7112 43.0255 64.0326 44.15 62.1843 44.9174C60.3361 45.6848 58.3547 46.08 56.3535 46.0803H46.2035V40.9926H56.3535C58.9734 40.8754 61.4472 39.7522 63.2597 37.8568C65.0723 35.9613 66.0838 33.4398 66.0838 30.8173C66.0838 28.1947 65.0723 25.6732 63.2597 23.7778C61.4472 21.8823 58.9734 20.7591 56.3535 20.6419H51.7033L51.0775 16.2817C50.6957 13.55 49.4328 11.0176 47.4806 9.06904C45.5284 7.12049 42.9935 5.86234 40.2611 5.48568C37.5271 5.1122 34.746 5.64603 32.3446 7.00528C29.9432 8.36452 28.0541 10.4741 26.967 13.0104L25.2728 16.8872L21.1569 15.9256C20.216 15.6922 19.2515 15.5676 18.2823 15.5542C14.9143 15.5542 11.6836 16.8923 9.30764 19.2784C7.53697 21.0588 6.33218 23.323 5.84473 25.7862C5.35729 28.2495 5.60895 30.8018 6.56808 33.1224C7.5272 35.4431 9.15099 37.4283 11.2353 38.8287C13.3196 40.229 15.7713 40.9819 18.2823 40.9926H30.971V46.0803H18.2823C15.7623 46.103 13.2664 45.5883 10.9609 44.5707C8.65544 43.5531 6.59329 42.0558 4.91188 40.1786C2.3696 37.3474 0.800485 33.7767 0.434284 29.9893C0.0680833 26.2018 0.924034 22.3966 2.87681 19.1308C4.17018 16.9672 5.90857 15.103 7.97679 13.662C10.045 12.2209 12.3959 11.2357 14.8736 10.7718C17.3462 10.3139 19.8951 10.38 22.3423 10.9804C23.8843 7.4496 26.5341 4.51698 29.891 2.62604C33.2478 0.735108 37.1292 -0.0112868 40.948 0.499744C44.7683 1.01671 48.3154 2.76684 51.0501 5.48412C53.7849 8.2014 55.5577 11.7372 56.0991 15.5542ZM47.6383 35.1571L40.953 28.4769V56.1336H35.8959V28.6601L29.3938 35.1621L25.7968 31.5601L36.715 20.6419H40.3171L51.2353 31.5601L47.6383 35.1571V35.1571Z"
        fill="black"
      />
    </svg>
  );
}

export default UploadIcon;
