import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  dateFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVDownload } from "react-csv";
import { Button, FormGroup, Input } from "reactstrap";
import moment from "moment";

moment.locale("id");

function BookingHistoryTable({
  data,
  dataToDownload,
  csv_header,
  handleViewRow,
  handleCancel,
  filter,
  setFilter,
  setIsShowDeleteModal,
  page,
  sizePerPage,
  totalSize,
  setPage,
  setSizePerPage,
  handleExportCSV,
  isDownloadCSV
}) {
  const [initiateDownload, setInitiateDownload] = useState(false)
  const handleTableChange = (type, { newPage, newSizePerPage }) => {
    setPage(newPage);
    setSizePerPage(newSizePerPage);
  };

  const options = {
    page,
    sizePerPage,
    totalSize,
    showTotal: true,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    onPageChange: (newPage, newSizePerPage) => {
      console.log(`New page: ${newPage}`); // Debugging log
      handleTableChange('pagination', { newPage, newSizePerPage })
    },
    onSizePerPageChange: (newSizePerPage) => {
      handleTableChange('pagination', { newPage: 1, newSizePerPage })
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              value={currSizePerPage}
              onChange={(e) => onSizePerPageChange(Number(e.target.value))}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const { SearchBar } = Search;

  useEffect(() => {
    if (dataToDownload.length > 0) {
      setInitiateDownload(true)
    }
  }, [dataToDownload])
  
  useEffect(() => {
    // Enables user to download data more than once
    if (initiateDownload) {
      setInitiateDownload(false)
    }
  }, [initiateDownload])

  const headerStyle = (column, colIndex) => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };

  return (
    <>
      <ToolkitProvider
        data={data}
        keyField="id"
        search
        columns={[
          {
            dataField: "id",
            text: "ID",
            headerStyle: headerStyle,
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
              if (order === "asc") return a - b;
              else return b - a;
            },
          },
          {
            dataField: "user_name",
            text: "User",
            filter: textFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "room_data.office_area.name",
            text: "Area",
            filter: textFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "room_data.name",
            text: "Room Name",
            filter: textFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "start_usage",
            text: "Start",
            filter: dateFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin-date",
            formatter: (cell) => moment(cell).format("Do/MM/YYYY, LT"),
          },
          {
            dataField: "finish_usage",
            text: "End",
            filter: dateFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin-date",
            formatter: (cell) => moment(cell).format("Do/MM/YYYY, LT"),
          },
          {
            dataField: "booking_purpose",
            text: "Purpose",
            filter: textFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "id",
            text: "Action",
            headerStyle: headerStyle,
            formatter: (cell, row) => {
              return (
                <div>
                  <Button
                    className="border-0 text-white p-0"
                    style={{
                      background: "#4ABA9F",
                      width: "68px",
                      height: "22px",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      handleViewRow(row.id);
                    }}
                  >
                    View
                  </Button>
                  <Button
                    className="border-0 text-white p-0"
                    style={{
                      background:
                        row.is_cancel || new Date(row.finish_usage) < new Date()
                          ? "#BEBEBE"
                          : "#C70000",
                      width: "68px",
                      height: "22px",
                      fontSize: "12px",
                      cursor:
                        row.is_cancel || new Date(row.finish_usage) < new Date()
                          ? "not-allowed"
                          : "pointer",
                    }}
                    disabled={
                      row.is_cancel || new Date(row.finish_usage) < new Date()
                    }
                    onClick={() => {
                      if (
                        !row.is_cancel ||
                        new Date(row.finish_usage) >= new Date()
                      ) {
                        setIsShowDeleteModal(true);
                        handleCancel({
                          booking_id: row.booking_id,
                          occurrence_id: row.id,
                        });
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              );
            },
          },
        ]}
      >
        {(props) => (
          <div className="table-responsive">
            <div className="d-flex w-100 justify-content-between">
              <div className="d-flex">
                <div className="p-3">
                  <label className="mr-3" style={{ fontSize: "15px" }}>
                    Search:
                  </label>
                  <SearchBar
                    className="form-control-sm"
                    placeholder="Search"
                    {...props.searchProps}
                  />
                </div>
                <div className="p-3">
                  <FormGroup>
                    <Input
                      bsSize="sm"
                      id="status"
                      name="status"
                      type="select"
                      value={filter.status}
                      onChange={(e) =>
                        setFilter({ type: "STATUS", payload: e.target.value })
                      }
                    >
                      <option value="all">All</option>
                      <option value="ongoing">Ongoing</option>
                      <option value="completed">Completed</option>
                      <option value="cancelled">Cancelled</option>
                    </Input>
                  </FormGroup>
                </div>
                <div className="p-3">
                  <FormGroup>
                    <Input
                      bsSize="sm"
                      id="type"
                      name="type"
                      type="select"
                      value={filter.type}
                      onChange={(e) =>
                        setFilter({ type: "TYPE", payload: e.target.value })
                      }
                    >
                      <option value="all">All</option>
                      <option value="aula">Aula</option>
                      <option value="non-aula">Non Aula</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>
              {isDownloadCSV ?
                <Button
                  disabled
                  style={{ background: "#3E7CA8", marginTop: "auto", marginBottom: "auto"}}
                  onClick={handleExportCSV}
                  size="sm"
                  className="border-0 text-white p-3"
                >
                  Exporting CSV...
                </Button>
              :
                <Button
                  style={{ background: "#3E7CA8", marginTop: "auto", marginBottom: "auto"}}
                  onClick={handleExportCSV}
                  size="sm"
                  className="border-0 text-white p-3"
                >
                  Export as CSV
                </Button>
              }
              {initiateDownload && (
                <CSVDownload
                  data={dataToDownload}
                  headers={csv_header}
                  filename={`booking_history_export_${filter.status}_${filter.type}.csv`}
                  target="_blank"
                />
              )}
            </div>
            <BootstrapTable
              {...props.baseProps}
              filter={filterFactory()}
              remote
              onTableChange={() => {}}
              bordered={false}
              id="react-bs-table"
              bootstrap4={true}
              noDataIndication="No Booking History Found"
              rowClasses={(row, rowIndex) => "row-style-admin"}
              pagination={paginationFactory(options)}
            />
          </div>
        )}
      </ToolkitProvider>
      <style jsx>
        {`
          .row-style-admin td {
            padding: 12px;
            vertical-align: middle;
          }
          .header-style-admin label input {
            height: 30px;
          }
          .header-style-admin label select {
            height: 30px;
            padding: 6px;
          }
          .header-style-admin-number div label input {
            height: 30px;
            min-width: 50px;
            width: 100%;
            padding: 4px;
          }
          .header-style-admin-number div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
          .header-style-admin-date div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
          .header-style-admin-date div label input {
            height: 30px;
            min-width: 120px;
            width: 100%;
            padding: 4px;
          }
        `}
      </style>
    </>
  );
}

export default BookingHistoryTable;
