import React from "react";


function LobbyHeader() {
  return (
    <div
      className="d-flex flex-column align-items-center pt-5"
      style={{
        height: "266px",
        background: "#3E7CA8",
      }}
    >
      <h1
        className="text-white"
        style={{
          fontWeight: "700",
          fontSize: "44px",
        }}
      >
        ParaSpace
      </h1>
      <p
        className="text-white"
        style={{
          fontSize: "22px",
        }}
      >
        BOOKING APPS FOR PARAGONIAN
      </p>
    </div>
  );
}

export default LobbyHeader;
