/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
// core components
import AdminNavbarNew from "components/Navbars/AdminNavbarNew";
import AdminFooter from "components/Footers/AdminFooter.js";
import SidebarNew from "components/Sidebar/SidebarNew";

import superAdminBookingRoom from "routes/bookingRoom/superAdmin";
import superAdminBookingCar from "routes/bookingCar/superAdmin";
import adminSatpamBookingCar from "routes/bookingCar/satpam";
import superAdminParastay from "routes/parastay/superAdmin";
import adminCremRoutesParastay from "routes/parastay/adminCremRoutes";
import adminGaRoutesParastay from "routes/parastay/adminGaRoutes";

function AdminLayout(props) {
  const { location } = props;
  const [routes, setRoutes] = React.useState([]);
  const [isRoutesReady, setIsRoutesReady] = React.useState(false);
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const [entryPoint, setEntryPoint] = React.useState("");
  const [title, setTitle] = React.useState("");

  const role_user = localStorage.getItem("role_user");
  const roleName = localStorage.getItem("role");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setEntryPoint(location.pathname.split("/")[1]);
  }, [location.pathname]);

  useEffect(() => {
    setIsRoutesReady(false);
    if (entryPoint) {
      switch (entryPoint) {
        case "parastay":
          switch (role_user) {
            case "1":
              setRoutes(superAdminParastay);
              break;
            case "2":
              setRoutes(adminGaRoutesParastay);
              break;
            case "3":
              setRoutes(adminCremRoutesParastay);
              break;
            default:
              setRoutes([]);
              break;
          }
          break;
        case "booking-room":
          setRoutes(superAdminBookingRoom);
          break;
        case "booking-car":
          switch (roleName) {
            case "Satpam":
              setRoutes(adminSatpamBookingCar);
              break;
            default:
              setRoutes(superAdminBookingCar);
              break;
          }
          break;
        default:
          setRoutes([]);
          break;
      }
    }
    setIsRoutesReady(true);
    // eslint-disable-next-line
  }, [entryPoint, role_user]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === `/${entryPoint}`) {
        return (
          <Route
            exact={prop.exact}
            path={prop.layout + prop.path}
            component={(props) => (
              <prop.component {...props} setTitle={setTitle} />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (path.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  if (isRoutesReady) {
    return (
      <>
        <SidebarNew
          {...props}
          routes={routes}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: `/${entryPoint}/dashboard`,
            imgSrc: props.sidebarLogo,
            imgAlt: "...",
          }}
        />
        <div className="main-content">
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: "5",
              height: "167px",
              background: "#F8F9FE",
            }}
          >
            <AdminNavbarNew
              {...props}
              // theme={"dark"}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              brandText={getBrandText(props.location.pathname)}
              entryPoint={entryPoint}
            />
            <div className="bg-white">
              <h2
                className="text-dark mb-0"
                style={{
                  padding: "24px 30px",
                }}
              >
                {title}
              </h2>
            </div>
          </div>
          <div style={{ minHeight: "calc(100vh - 167px - 51px - 1.5rem)" }}>
            <Switch>{getRoutes(routes)}</Switch>
            {/* {entryPoint && <Redirect from={`/${entryPoint}`} to={`/${entryPoint}/dashboard`} />} */}
          </div>
          <div className="mt-4">
            <AdminFooter />
          </div>
        </div>
        {sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={toggleSidenav} />
        ) : null}
      </>
    );
  } else {
    return <></>;
  }
}

export default AdminLayout;
