/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardText,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
// import SimpleHeader from "components/Headers/SimpleHeader.js";
import Loader from 'react-loader-spinner';
import { Link } from "react-router-dom";
import axios from 'axios';


class DashboardCrem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    alert: null,
    countComplainCremRequest: 0,
    countComplainCremProcess: 0,
    countComplainCremDone: 0,
    isLoading: true
  }
}

componentDidMount(){ 
  this.props.setTitle("Dashboard");
  const id_user = localStorage.getItem('id_user');
  const crem_id = localStorage.getItem('id_user');
  const role_id = localStorage.getItem('role_user');

  axios
      .get(`/ComplainCremGetRequest`)
      .then((response) => {
        this.setState({
          ...this.state,
          countComplainCremRequest: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error) {
          this.setState({
            ...this.state,
            isLoading: false,
          });
        }
      });

      axios
      .get(`/ComplainCremGetProcess?crem_admin_pic_id=${id_user}`)
      .then((response) => {
        this.setState({
          ...this.state,
          countComplainCremProcess: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
          });
        }
      });

      axios
      .get(`/ComplainCremGetDone?crem_id=${crem_id}&role_id=${role_id}`)
      .then((response) => {
        this.setState({
          ...this.state,
          countComplainCremDone: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
          });
        }
      });
} 

  
  render() {
    const username = localStorage.getItem('username');
    const {isLoading} = this.state
    return (
      <>
        {this.state.alert}
        {/* <SimpleHeader name="Dashboard" parentName="Admin Crem" /> */}
        <Container fluid>
          {isLoading ? (
             <div className="justify-content-center text-center" style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)', 
              opacity: '0.5', 
              height: '100%',
              width: '100%',
              zIndex:'1100'
            }}>
              <Loader
                 type="Bars"
                 color="#30C1FF"
                 height={100}
                 width={100}
                />
            </div>
          ) : (
          <>
          <Row>
            <div className="col">
              <Card className="px-3 py-3" style={{background: '#EAF9FF'}}>
                <Row>
                    <Col md='6'>
                        <CardTitle tag="h3">Halo, {(username).split(' ')[0]}!</CardTitle>
                        <CardText>Semoga dalam keadaan sehat, kamu bisa memantau request dan komplain mess di sini</CardText>
                        <div className="pt-3">
                        <Link to={`/parastay/complain-list`}>
                        <Button color="info"  size="md">Daftar komplain</Button>
                        </Link>
                        </div>
                    </Col>
                    <Col md='6' className="text-right">
                        <img width="30%" src={require('assets/img/brand/header.svg')} alt="header gomess" />
                    </Col>
                </Row>
              </Card>
                <h2 className="mb-3">
                     Overview
                </h2>
                <Row>
                    <Col md='3' xs='6'>
                        <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="newComplain">
                                  Komplain Mess Baru{' '}
                                 <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "newComplain" >
                                  Adalah seluruh pengajuan komplain yang belum ditujukan ke WPM/CREM
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                  {this.state.countComplainCremRequest}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="progressComplain">
                                  Komplain On Progress{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "progressComplain" >
                                  Adalah seluruh pengajuan komplain yang sedang dikerjakan WPM/CREM
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countComplainCremProcess}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3' xs='6'>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col">
                                <CardTitle tag="h4" id="doneComplain">
                                  Komplain Mess Selesai{' '}
                                  <i className = "fas fa-info-circle"></i>
                                </CardTitle>
                                <UncontrolledTooltip
                                  delay = {0}
                                  placement = "top"
                                  target = "doneComplain" >
                                  Adalah seluruh pengajuan komplain yang telah selesai dikerjakan oleh WPM/CREM dalam jangka waktu per bulan/ kuarter/ semester/ tahunan
                                </UncontrolledTooltip>
                                <span className="h1 font-weight-bold mb-0">
                                    {this.state.countComplainCremDone}
                                </span>
                                </div>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
          </Row>
          </>
          )}
        </Container>
      </>
    );
  }
}

export default DashboardCrem;






