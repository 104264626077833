import React from "react";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { Button } from "reactstrap";

function AddContactForm({ handleSubmit, handleChange, formValue }) {
  const contactTypeOption = [
    'WhatsApp'
  ]
  return (
    <AvForm onSubmit={handleSubmit}>
      <h2 className="mb-4">Add Contact</h2>
      <AvGroup>
        <label className="form-control-label" htmlFor="contact_name">
          Name*
        </label>
        <AvInput
          id="contact_name"
          type="text"
          placeholder="Nama"
          name="contact_name"
          value={formValue.contact_name}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input name</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="contact_number">
          Contact Number*
        </label>
        <AvInput
          id="contact_number"
          type="text"
          placeholder="Contact Number"
          name="contact_number"
          value={formValue.contact_number}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input contact number</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="contact_link">
          Contact Link*
        </label>
        <AvInput
          id="contact_link"
          type="text"
          placeholder="Contact Number"
          name="contact_link"
          value={formValue.contact_link}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input contact link</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="contact_type">
          Contact Type
        </label>
        <AvField
          type="select"
          name="contact_type"
          onChange={handleChange}
          value={formValue.contact_type}
          defaultValue=""
        >
          <option value="" disabled>
            Select Type
          </option>
          {contactTypeOption.map((type, index) => {
            return (
              <option key={index} value={type}>
                {type}
              </option>
            );
          })}
        </AvField>
        <AvFeedback>Please select app</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="description">
          Description
        </label>
        <textarea
          id="description"
          name="description"
          placeholder="Contact description"
          rows={5}
          value={formValue.description}
          onChange={handleChange}
          // required
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "0.875rem",
            color: "#8898aa",
            border: "1px solid #dee2e6",
            borderRadius: "0.25rem",
          }}
        ></textarea>
      </AvGroup>
      <div>
        <Button color="info" type="submit" size="md">
          Add Contact
        </Button>
      </div>
    </AvForm>
  );
}

export default AddContactForm;
