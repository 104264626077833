/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { withGetScreen } from "react-getscreen";
// core components
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { CSVLink } from "react-csv";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class ComplainList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complaints: [],
      alert: null,
      isLoading: true,
      isEmpty: false,
    };
  }

  componentDidMount() {
    this.props.setTitle("Complain List");
    const id_user = localStorage.getItem("id_user");
    axios
      .get(`/ComplainCremPicked?crem_admin_pic_id=${id_user}`)
      .then((response) => {
        this.setState({
          ...this.state,
          complaints: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: "info",
            text: "List is empty!",
            confirmButtonColor: "#30C1FF",
          });
        }
      });
  }

  render() {
    const { complaints, isLoading, isEmpty } = this.state;

    const headers_table = [
      { label: "Tanggal Dibuat", key: "complain_request_date" },
      { label: "Tanggal Respond", key: "complain_respond_date" },
      { label: "Nama", key: "user_name" },
      { label: "Item Pekerjaan", key: "complain_category_name" },
      { label: "Lokasi", key: "location_name" },
      { label: "Sub Lokasi", key: "sub_location" },
      { label: "Status", key: "comp_status_name" },
    ];

    return (
      <>
        {this.state.alert}
        {/* <SimpleHeader name="Complain List" parentName="Crem Management" /> */}
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                {isLoading && !isEmpty ? (
                  <div
                    className="justify-content-center text-center"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      opacity: "0.5",
                      height: "100%",
                      width: "100%",
                      zIndex: "1100",
                    }}
                  >
                    <Loader
                      type="Bars"
                      color="#30C1FF"
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <>
                    {isEmpty ? (
                      <CardBody>
                        <h1>Complaint List is Empty !</h1>
                      </CardBody>
                    ) : (
                      <ToolkitProvider
                        data={complaints}
                        keyField="complain_id"
                        columns={[
                          {
                            dataField: "complain_id",
                            text: "ID",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "complain_request_date",
                            text: "Tanggal Dibuat",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  {moment(row.complain_request_date).format(
                                    "DD MMMM YYYY"
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            dataField: "complain_respond_date",
                            text: "Tanggal Respon",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  {moment(row.complain_respond_date).format(
                                    "DD MMMM YYYY HH:mm:ss"
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            dataField: "user_name",
                            text: "Nama",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "complain_category_name",
                            text: "Item Pekerjaan",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "location_name",
                            text: "Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "sub_location",
                            text: "Sub Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "",
                            text: "Details",
                            editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <ComplainDetail comp={row} />
                                </>
                              );
                            },
                          },
                          {
                            dataField: "comp_status_name",
                            text: "Status",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <Badge color="warning" pill>
                                    {row.comp_status_name}
                                  </Badge>
                                </>
                              );
                            },
                          },
                          {
                            dataField: "",
                            text: "Actions",
                            editable: false,
                            formatter: (cell, row) => {
                              return (
                                <Row>
                                  <Button
                                    color="success"
                                    size="md"
                                    className="mr-2"
                                    href={`/parastay/complain-report/${row.complain_id}`}
                                  >
                                    Update
                                  </Button>
                                  {row.complain_status_id > 3 &&
                                  row.complain_status_id < 6 ? (
                                    ""
                                  ) : (
                                    <ReleaseComplain
                                      detail_id_complain={row.complain_id}
                                    />
                                  )}
                                </Row>
                              );
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <Container fluid>
                              <Row>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <CSVLink
                                      data={complaints}
                                      headers={headers_table}
                                      filename={"Complain list.csv"}
                                    >
                                      <Button
                                        style={{ marginRight: "5px" }}
                                        color="default"
                                        size="sm"
                                        className="buttons-copy buttons-html5"
                                        id="print-tooltip"
                                      >
                                        Export to CSV
                                      </Button>
                                    </CSVLink>

                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="default"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          this.copyToClipboardAsTable(
                                            document.getElementById(
                                              "react-bs-table"
                                            )
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => this.componentRef}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <label>
                                      Search:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={(el) => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                              id="react-bs-table"
                              filter={filterFactory()}
                              noDataIndication="There is no data"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withGetScreen(ComplainList);

const ComplainDetail = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [photolist, setPhotolist] = React.useState(null);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  const handleClick = () => {
    axios
      .get(`/ComplainCremPhoto?complain_id=${props.comp.complain_id}`)
      .then((photoRes) => {
        setPhotolist(photoRes.data.data);
        setModal(true);
      })
      .catch((error) => {
        console.error(error);
        setModal(true);
      });
  };

  return (
    <div>
      <Button color="primary" size="md" onClick={handleClick}>
        <i className="fa fa-info-circle" />
      </Button>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Complain Detail #1245
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-auto mt--3 mb-3" md="12" xs="12">
              <Badge color="warning" pill>
                {props.comp.comp_status_name}
              </Badge>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Deskripsi Komplain
              </h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p
                classname="text-wrap"
                style={{ fontSize: 13, fontWeight: 400 }}
              >
                {props.comp.complain_desc}
              </p>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                {photolist ? "Bukti Foto" : "Tidak Ada Bukti Foto"}
              </h4>
              <Slider {...settings}>
                {photolist &&
                  photolist.map((list, idx) => {
                    return (
                      <div>
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            style={{ maxHeight: "300px" }}
                            src={`https://parastay-api.pti-cosmetics.com/assets/upload_complain_photos/${list.photo}`}
                            alt={list.photo_id}
                          />
                        </Card>
                      </div>
                    );
                  })}
              </Slider>
            </Col>
          </Row>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="40%" className="text-muted">
                  Lokasi
                </td>
                <td className="text-wrap">{props.comp.location_name}</td>
              </tr>
              <tr>
                <td width="40%" className="text-muted">
                  Sub Lokasi
                </td>
                <td className="text-wrap">{props.comp.sub_location}</td>
              </tr>
              <tr>
                <td width="40%" className="text-muted">
                  Prioritas
                </td>
                <td className="text-wrap">{props.comp.priority_name}</td>
              </tr>
              <tr>
                <td width="40%" className="text-muted">
                  Tindak Lanjut
                </td>
                <td className="text-wrap">
                  {props.comp.complain_category_type}
                </td>
              </tr>
              <tr>
                <td width="40%" className="text-muted">
                  Tanggal Mulai
                </td>
                <td className="text-wrap">
                  {props.comp.complain_start_date
                    ? moment(props.comp.complain_start_date).format(
                        "DD MMMM YYYY"
                      )
                    : "-"}
                </td>
              </tr>
              <tr>
                <td width="40%" className="text-muted">
                  Tanggal Selesai
                </td>
                <td className="text-wrap">
                  {props.comp.complain_end_date
                    ? moment(props.comp.complain_end_date).format(
                        "DD MMMM YYYY"
                      )
                    : "-"}
                </td>
              </tr>
              <tr>
                <td width="40%" className="text-muted">
                  Rincian Perbaikan
                </td>
                <td className="text-wrap">
                  <Editor
                    apiKey="gtr9kvxdsu5tjpm7szjln7t2m60nld35etyunod55c5alprj"
                    initialValue={
                      props.comp.solution ? props.comp.solution : "-"
                    }
                    inline={true}
                    disabled={true}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="success"
            href={`/parastay/complain-report/${props.comp.complain_id}`}
          >
            Update
          </Button>
          <ReleaseComplain detail_id_complain={props.comp.complain_id} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

const ReleaseComplain = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const id_complain = props.detail_id_complain;
  const id_user = localStorage.getItem("id_user");
  const id_status = 2;

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const handleClick = () => {
    setModal(true);
  };

  const handleRelease = () => {
    let params = new URLSearchParams();
    params.append("complain_id", id_complain);
    params.append("complain_status_id", id_status);

    let dataLog = new URLSearchParams();
    dataLog.append("sender_id", id_user);
    dataLog.append("complain_id_fk2", id_complain);
    dataLog.append("complain_status_id", id_status);

    axios({
      method: "put",
      url: "/ComplainCremRelease",
      data: params,
    }).then((response) => {
      if (response.status === 201) {
        axios
          .post(`/ComplainLog`, dataLog)
          .then((response) => {
            if (response.data.status === true) {
              Swal.fire("Released!", `Complain Has been Released.`, "success");
              window.location.reload();
            } else {
              Swal.fire("Error!", "Error to release Complain Status", "error");
            }
          })
          .catch((error) => {});
      } else {
        Swal.fire("Error!", "Error to release Complain", "error");
      }
    });
  };

  return (
    <div>
      <Button color="danger" size="md" onClick={handleClick}>
        Release
      </Button>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Complain #{id_complain}
        </ModalHeader>
        <ModalBody>
          <h3 className="text-center" style={{ marginTop: "-25px" }}>
            Apakah Anda yakin ingin melepas komplain ini?
          </h3>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="danger" onClick={handleRelease}>
            Yes
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
