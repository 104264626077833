/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import DashboardCrem from "views/pages/parastay/dashboards/DashboardCrem.js";
import UserList from "views/pages/parastay/manage_user/UserList.js";
import UserDetails from "views/pages/parastay/manage_user/UserDetails.js";
import UserEdit from "views/pages/parastay/manage_user/UserEdit.js";
import UserAdd from "views/pages/parastay/manage_user/UserAdd.js";
import ComplainList from "views/pages/parastay/manage_crem/complainList.js";
import ComplainAssign from "views/pages/parastay/manage_crem/complainAssign.js";
import ComplainListHistory from "views/pages/parastay/manage_crem/complainListHistory.js";
import ComplainListPicked from "views/pages/parastay/manage_crem/complainListPicked.js";
import ComplainReport from "views/pages/parastay/manage_crem/complainReport.js";
import ComplainPerMember from "views/pages/parastay/manage_crem/ComplainPerMember.js";
import ComplainDetailPerMember from "views/pages/parastay/manage_crem/ComplainDetailPerMember.js";

const adminCremRoutes = [
  {
    path: "/dashboardCrem",
    name: "Dashboard Crem",
    icon: "ni ni-shop text-primary",
    component: DashboardCrem,
    layout: "/parastay"
  },
  {
    collapse: true,
    name: "User Management",
    icon: "ni ni-ungroup text-orange",
    state: "userCollapse",
    views: [
      {
        path: "/user-list",
        name: "User List",
        miniName: "U",
        component: UserList,
        layout: "/parastay"
      },
      {
        path: "/user-add",
        name: "Add User",
        miniName: "UA",
        component: UserAdd,
        layout: "/parastay"
      },
    ]
  },
  {
    collapse: true,
    name: "Crem Management",
    icon: "ni ni-ungroup text-orange",
    state: "cremCollapse",
    views: [
      {
        path: "/complain-list",
        name: "Complain List",
        miniName: "C",
        component: ComplainList,
        layout: "/parastay"
      },
      {
        path: "/complain-picked",
        name: "Complain Picked",
        miniName: "C",
        component: ComplainListPicked,
        layout: "/parastay"
      },
      {
        path: "/complain-per-member",
        name: "Complain Per Member",
        miniName: "C",
        component: ComplainPerMember,
        layout: "/parastay"
      },
      {
        path: "/complain-history",
        name: "Complain History",
        miniName: "C",
        component: ComplainListHistory,
        layout: "/parastay"
      },
    ]
  },
  {
    redirect: true,
    collapse: false,
    path: '/user-details',
    component: UserDetails,
    layout: "/parastay",
  },
  {
    redirect: true,
    collapse: false,
    path: '/user-update/:id_user',
    component: UserEdit,
    layout: "/parastay",
  },
  {
    redirect: true,
    collapse: false,
    path: "/complain-assign/:complain_id",
    name: "Complain Assign",
    component: ComplainAssign,
    layout: "/parastay"
  },
  {
    redirect: true,
    collapse: false,
    path: "/complain-report/:complain_id",
    name: "Complain Report",
    component: ComplainReport,
    layout: "/parastay"
  },
  {
    redirect: true,
    collapse: false,
    path: "/complain-detail-per-member/:id_crem",
    name: "Complain Detail Per Member",
    component: ComplainDetailPerMember,
    layout: "/parastay"
  },
];

export default adminCremRoutes;
