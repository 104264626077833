import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useLocation } from "react-router-dom";

const SearchInput = ({ searchParams, setSearchParams }) => {
  const [search, setSearch] = useState(searchParams.query || "");
  const { pathname } = useLocation();

  useEffect(() => {
    const debounceFn = () => {
      if (searchParams.query === search) {
        return;
      }
      if (search === "") {
        setSearch("");
      }

      setSearchParams((prevSearchParams) => ({
          ...prevSearchParams,
          page: 1,
          query: search,
      }));
    };

    const toDebounce = setTimeout(() => {
      debounceFn();
    }, 600);

    return () => {
      clearTimeout(toDebounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search, searchParams]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        className="form-control-sm font-size-16"
        placeholder="Type to search..."
        style={{
          height: "42px",
          width: "400px",
          color: "#929191",
          padding: "8px 32px 8px 8px",
          background: "white",
          borderRadius: "8px",
          border: "2px solid #EFEFEF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          display: "relative",
        }}
        value={search}
        onChange={handleSearchChange}
      />
      <SearchOutlinedIcon
        style={{
          color: "grey",
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />
    </div>
  );
};

export default SearchInput;
