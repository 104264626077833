import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import Loader from "react-loader-spinner";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Axios from "utils/axiosService";
import Swal from "sweetalert2";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

function TermsConditions({ setTitle, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState(0);

  useEffect(() => {
    setTitle("Terms & Conditions");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Axios({
      method: "GET",
      url: "/terms-and-conditions",
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setData(r.data.data.data);
        } else {
          throw new Error("Error fetching data");
        }
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const { SearchBar } = Search;

  const headerStyle = (column, colIndex) => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };

  const handleEditRow = (id) => {
    history.push(`/booking-room/terms-and-conditions/${id}`);
  };

  const handleClickDelete = (id) => {
    setIsShowDeleteModal(true);
    setIdToBeDeleted(id);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: "/terms-and-conditions/" + idToBeDeleted,
    })
      .then((r) => {
        if (!r?.data?.errorStatus) {
          Swal.fire({
            icon: "success",
            text: `Berhasil menghapus terms and conditions`,
            confirmButtonColor: "#30C1FF",
          });

          setTimeout(() => {
            history.replace("/booking-room/terms-and-conditions");
          }, 1000);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: `Gagal menghapus terms and conditions`,
          confirmButtonColor: "#30C1FF",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <>
              <ToolkitProvider
                data={data}
                keyField="id"
                search
                columns={[
                  {
                    dataField: "id",
                    text: "ID",
                    headerStyle: headerStyle,
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === "asc") return a - b;
                      else return b - a;
                    },
                  },
                  {
                    dataField: "content_title",
                    text: "Title",
                    filter: textFilter(),
                    sort: true,
                    headerStyle: headerStyle,
                    headerClasses: "header-style-admin",
                  },
                  {
                    dataField: "content_text",
                    text: "Text",
                    filter: textFilter(),
                    sort: true,
                    headerStyle: headerStyle,
                    headerClasses: "header-style-admin",
                    formatter: (cell, row) => {
                      return (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: row.content_text,
                            }}
                          />
                        </>
                      );
                    },
                  },
                  {
                    dataField: "content_author",
                    text: "Author",
                    filter: textFilter(),
                    sort: true,
                    headerStyle: headerStyle,
                    headerClasses: "header-style-admin",
                  },
                  {
                    dataField: "id",
                    text: "Action",
                    headerStyle: headerStyle,
                    formatter: (cell, row) => {
                      return (
                        <div>
                          <Button
                            className="border-0 text-white p-0"
                            style={{
                              background: "#DAA743",
                              width: "68px",
                              height: "22px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              handleEditRow(row.id);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            className="border-0 text-white p-0"
                            style={{
                              background: "#C70000",
                              width: "68px",
                              height: "22px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              handleClickDelete(row.id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
              >
                {(props) => (
                  <div className="table-responsive">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <div className="p-3">
                        <label className="mr-3" style={{ fontSize: "15px" }}>
                          Search:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="Search"
                          {...props.searchProps}
                        />
                      </div>
                      <Button
                        onClick={() => {
                          history.push(
                            "/booking-room/terms-and-conditions/add"
                          );
                        }}
                        style={{ background: "#3E7CA8", height: "32px" }}
                        size="sm"
                        className="border-0 text-white"
                      >
                        Add Terms and Conditions
                      </Button>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      filter={filterFactory()}
                      bordered={false}
                      id="react-bs-table"
                      noDataIndication="No Terms and Conditions Found"
                      rowClasses={(row, rowIndex) => "row-style-admin"}
                      pagination={pagination}
                      bootstrap4={true}
                    />
                  </div>
                )}
              </ToolkitProvider>
              <style jsx>
                {`
                  .row-style-admin td {
                    padding: 12px;
                    vertical-align: middle;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                  .row-style-admin td div {
                    max-height: 19.5px;
                  }
                  .header-style-admin label input {
                    height: 30px;
                  }
                  .header-style-admin label select {
                    height: 30px;
                    padding: 6px;
                  }
                  .header-style-admin-number div label input {
                    height: 30px;
                    min-width: 50px;
                    width: 100%;
                    padding: 4px;
                  }
                  .header-style-admin-number div label select {
                    height: 30px;
                    width: 44px !important;
                    padding: 2px;
                  }
                `}
              </style>
            </>
          )}

          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isShowDeleteModal}
        fade={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowDeleteModal(!isShowDeleteModal);
        }}
      >
        <ModalBody
          style={{
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 className="text-center mb-0">
            Are you sure to delete this terms and conditions?
          </h2>
          <div className="d-flex justify-content-center">
            <Button
              color="danger"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              color="info"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                setIsShowDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default TermsConditions;
