/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardImg,
  Container,
  Button,
  Row,
  Col,
  Collapse,
  Table,
} from "reactstrap";
// core components
// import SimpleHeader from "components/Headers/SimpleHeader.js";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Slider from "react-slick";
import axios from "axios";
import Cookies from "universal-cookie";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Editor } from "@tinymce/tinymce-react";

const initialState = {
  solution: "",
  complain_id: "",
  complain_status_id: "",
  complain_start_date: "",
  complain_end_date: "",
  ga_admin_pic_id: "",
  crem_admin_pic_id: "",
  category_id_fk: "",
  loc: "",
  sub_loc: "",
  desc_comp: "",
};

class RequestApprove extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list_crem: [],
      list_ga: [],
      list_status: [],
      list_category: [],
      list_detail: "",
      user_detail: "",
      complain_id: "",
      complain_status: "",
      complain_category: "",
      complain_photo: "",
      alert: null,
      isLoading: true,
      isEmpty: false,
      isEdited: false,
      formData: initialState,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentDidMount() {
    this.props.setTitle("Tindak Lanjut Komplain");
    const cookies = new Cookies();
    this.setState({ complain_id: cookies.get("complain_id") });

    axios
      .get(`/ManageComplain?complain_id=${cookies.get("complain_id")}`)
      .then((response) => {
        const request = response.data.data[0];
        this.setState(
          {
            ...this.state,
            list_detail: response.data.data[0],
          },
          () => {
            axios
              .get(`/Userlist?id_user=${response.data.data[0].requestor_id}`)
              .then((res) => {
                this.setState({
                  ...this.state,
                  user_detail: res.data.data[0],
                  formData: {
                    solution: request.solution,
                    complain_id: request.complain_id,
                    complain_status_id: request.complain_status_id,
                    complain_start_date: request.complain_start_date,
                    complain_end_date: request.complain_end_date,
                    ga_admin_pic_id: request.ga_admin_pic_id,
                    crem_admin_pic_id: request.crem_admin_pic_id,
                    category_id_fk: request.category_id_fk,
                    loc: request.location_name,
                    sub_loc: request.sub_location,
                    desc_comp: request.complain_desc,
                  },
                  isLoading: false,
                });
              })
              .catch(function (err) {
                console.log(err);
              });
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get("/Statuslist")
      .then((response) => {
        this.setState({ list_status: response.data.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get("/CategoryComplain")
      .then((res) => {
        this.setState({ list_category: res.data.data });
      })
      .catch(function (err) {
        console.log(err);
      });

    axios
      .get(`/ComplainCremPhoto?complain_id=${cookies.get("complain_id")}`)
      .then((res) => {
        this.setState({ complain_photo: res.data.data });
      })
      .catch(function (err) {
        console.log(err);
      });

    axios
      .get("/UserlistGA")
      .then((res) => {
        this.setState({ list_ga: res.data.data });
      })
      .catch(function (err) {
        console.log(err);
      });

    axios
      .get("/UserlistCrem")
      .then((res) => {
        this.setState({ list_crem: res.data.data });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  handleCancel = () => {
    if (this.state.isEdited) {
      Swal.fire({
        title: "Are you sure you want to go back?",
        text: "If you leave before saving, your changes will be lost",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#30C1FF",
        cancelButtonColor: "#F8AFB8",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.history.goBack();
        }
      });
    } else {
      this.props.history.goBack();
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      formData: { ...this.state.formData, [name]: value },
      isEdited: true,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        solution: e.target.getContent(),
      },
      isEdited: true,
    });
  };

  handleSubmit = (e, errors) => {
    e.preventDefault();

    const {
      solution,
      complain_id,
      complain_status_id,
      complain_start_date,
      complain_end_date,
      ga_admin_pic_id,
      crem_admin_pic_id,
      category_id_fk,
      loc,
      sub_loc,
      desc_comp,
    } = this.state.formData;

    if (errors.length === 0) {
      this.setState({ ...this.state, isLoading: true });

      const formData = {
        solution,
        complain_id,
        complain_status_id,
        complain_start_date,
        complain_end_date,
        ga_admin_pic_id,
        crem_admin_pic_id,
        category_id_fk,
        loc,
        sub_loc,
        desc_comp,
      };

      const id_user = localStorage.getItem("id_user");

      let dataLog = new URLSearchParams();
      dataLog.append("sender_id", id_user);
      dataLog.append("complain_status_id", complain_status_id);
      dataLog.append("complain_id_fk2", complain_id);
      dataLog.append("log", solution);

      axios
        .post("/ManageComplain", formData)
        .then((response) => {
          var status = response.data.status;
          if (status === true) {
            axios
              .post(`/ComplainLog`, dataLog)
              .then((response) => {
                Swal.fire({
                  icon: "success",
                  text: "Complain Log has been updated.",
                  confirmButtonColor: "#30C1FF",
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  text: `Error to update log: ${error.response.data.message}`,
                  confirmButtonColor: "#30C1FF",
                });
              });
          } else {
            Swal.fire({
              icon: "error",
              text: "Failed to assign Complaint to GA",
              confirmButtonColor: "#30C1FF",
            });
          }
        })
        .catch((error) => {
          const message = error.response.data.message.toLowerCase();
          if (message.includes("message") || message.includes("email")) {
            axios.post(`/ComplainLog`, dataLog)
            Swal.fire({
              icon: "warning",
              text: `Complaint status has been updated but ${message}`,
              confirmButtonColor: "#30C1FF",
            });
          } else {
            Swal.fire({
              icon: "error",
              text: message || "Failed to assign Complaint to GA",
              confirmButtonColor: "#30C1FF",
            });
          }
          this.setState({ isLoading: false });
        });
    } else {
      console.log(errors);
    }
  };

  render() {
    const {
      list_detail,
      user_detail,
      list_status,
      list_category,
      isLoading,
      complain_photo,
      formData,
    } = this.state;
    return (
      <>
        {/* <SimpleHeader name="Tindak Lanjut Komplain" parentName="Mess Management" /> */}
        <Container fluid>
          <Card className="mb-4">
            {isLoading ? (
              <div
                className="justify-content-center text-center"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  opacity: "0.5",
                  height: "100%",
                  width: "100%",
                  zIndex: "1100",
                }}
              >
                <Loader type="Bars" color="#30C1FF" height={100} width={100} />
              </div>
            ) : (
              <>
                <Row>
                  <Col md="4" xs="12">
                    <CardBody>
                      <RequesterDetail
                        user_detail={user_detail}
                        complain_photo={complain_photo}
                        list_detail={list_detail}
                      />
                    </CardBody>
                  </Col>
                  <Col md="8" xs="12">
                    <CardBody>
                      <h1 className="mb-3">FORM TINDAK LANJUT</h1>
                      <AvForm onSubmit={this.handleSubmit}>
                        <Row>
                          <Col md="6">
                            <AvGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1Input2"
                              >
                                Status
                              </label>
                              <AvField
                                type="select"
                                name="complain_status_id"
                                onChange={this.handleChange}
                                value={formData.complain_status_id}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please Select State",
                                  },
                                }}
                              >
                                <option value="" selected disabled>
                                  -Pilih Status-
                                </option>
                                {list_status &&
                                  list_status.length > 0 &&
                                  list_status.map((stat, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={stat.comp_status_id}
                                      >
                                        {stat.comp_status_name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col md="6">
                            <AvGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1Input2"
                              >
                                Kategori
                              </label>
                              <AvField
                                type="select"
                                name="category_id_fk"
                                onChange={this.handleChange}
                                value={formData.category_id_fk}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please Select Category",
                                  },
                                }}
                              >
                                <option value="" selected disabled>
                                  -Pilh Kategori-
                                </option>
                                {list_category &&
                                  list_category.length > 0 &&
                                  list_category.map((category, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.complain_category_id}
                                      >
                                        {category.complain_category_name} (
                                        {category.complain_category_type})
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <AvGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1Input"
                              >
                                Rincian Perbaikan
                              </label>
                              <Editor
                                initialValue={
                                  !formData.solution
                                    ? "Tulis deskripsi tindakan..."
                                    : formData.solution
                                }
                                apiKey="gtr9kvxdsu5tjpm7szjln7t2m60nld35etyunod55c5alprj"
                                init={{
                                  height: 250,
                                  menubar: false,
                                  plugins: [
                                    "advlist autolink lists link image",
                                    "charmap print preview anchor help",
                                    "searchreplace visualblocks code",
                                    "insertdatetime media table paste wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
                                }}
                                onChange={this.handleEditorChange}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        {/* 
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1Input"
                              >
                                User GA
                              </label>
                              <AvField
                                type='select'
                                name='ga_admin_pic_id'
                                onChange={this.handleChange}
                                value={formData.ga_admin_pic_id}
                              >
                              <option value='' selected disabled>-Pilh user yang menangangi-</option>
                                {list_ga &&
                                  list_ga.length > 0 &&
                                  list_ga.map((ga, index) => {
                                    return (
                                      <option key={index} value={ga.user_name}>
                                        {ga.user_name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example2cols1Input"
                              >
                                User Crem
                              </label>

                              <AvField
                                type='select'
                                name='crem_admin_pic_id'
                                onChange={this.handleChange}
                                value={formData.crem_admin_pic_id}
                              >
                            <option value='' selected disabled>-Pilh user yang menangangi-</option>
                                {list_crem &&
                                  list_crem.length > 0 &&
                                  list_crem.map((crem, index) => {
                                    return (
                                      <option key={index} value={crem.user_name}>
                                        {crem.user_name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </FormGroup>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col md="12">
                            <Button color="info" type="submit" size="md">
                              Submit
                            </Button>
                            <Button
                              color="secondary"
                              onClick={this.handleCancel}
                              size="md"
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Container>
      </>
    );
  }
}
export default RequestApprove;

const RequesterDetail = (props) => {
  const [collapse, setCollapse] = useState(true);

  const toggle = () => setCollapse(!collapse);

  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <Card style={{ backgroundColor: "#eeeef4" }}>
      <Row className="mx-2 my-3">
        <Col md="8" xs="8">
          <h3 className="mb-2">Detail Pemohon</h3>
        </Col>
        <Col md="4" xs="4">
          <Button
            className="text-info justify-content-right"
            onClick={toggle}
            style={{ marginBottom: "1rem", float: "right" }}
          >
            <i className="ni ni-bold-down" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={collapse}>
        <div>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-user mr-2 text-info" />
                  Nama Lengkap
                </td>
                <td className="text-wrap">{props.user_detail.user_name}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-phone mr-2 text-info" />
                  Nomor Telepon
                </td>
                <td className="text-wrap">{props.user_detail.user_phone}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-info-circle mr-2 text-info" />
                  Item Pekerjaan
                </td>
                <td className="text-wrap">
                  {props.list_detail.complain_category_name}
                </td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-home mr-2 text-info" />
                  Lokasi
                </td>
                <td className="text-wrap">{props.list_detail.location_name}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-home mr-2 text-info" />
                  Alamat Lokasi
                </td>
                <td className="text-wrap">
                  {props.user_detail.location_address}
                </td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-map-marker-alt mr-2 text-info" />
                  Sub Lokasi
                </td>
                <td className="text-wrap">{props.list_detail.sub_location}</td>
              </tr>
            </tbody>
          </Table>
          <Row className="mx-2 mb-4">
            <Col className="col-auto mt-3" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Deskripsi Komplain
              </h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p
                classname="text-wrap"
                style={{ fontSize: 13, fontWeight: 400 }}
              >
                {props.list_detail.complain_desc}
              </p>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                {props.complain_photo ? "Bukti Foto" : "Tidak Ada Bukti Foto"}
              </h4>
              <Slider {...settings}>
                {props.complain_photo &&
                  props.complain_photo.map((list, idx) => {
                    return (
                      <div>
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            style={{ maxHeight: "300px" }}
                            src={`https://parastay-api.pti-cosmetics.com/assets/upload_complain_photos/${list.photo}`}
                            alt={list.photo_id}
                          />
                        </Card>
                      </div>
                    );
                  })}
              </Slider>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
};
