import React, { useEffect, useState } from "react";
import CardMenuGrid from "components/LobbyMenu/CardMenuGrid";

function GridView({
  menuList,
  setMenuList,
  activeOptionForm,
  setActiveOptionForm,
  searchValue,
  sort,
  handleRefetch,
  userData,
  isArchivedWorkspace,
  handleDeleteWorkspace,
}) {
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    setFilteredList([...menuList]);
    // eslint-disable-next-line
  }, [menuList?.length]);

  useEffect(() => {
    if (searchValue) {
      const filteredList = menuList.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredList(filteredList);
    } else {
      setFilteredList([...menuList]);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    if (sort && filteredList.length > 0) {
      const sortedList = filteredList.sort((a, b) => {
        if (sort === "asc") {
          return a["createdAt"] > b["createdAt"] ? 1 : -1;
        } else {
          return a["createdAt"] < b["createdAt"] ? 1 : -1;
        }
      });
      setFilteredList([...sortedList]);
    } else {
      setFilteredList([...menuList]);
    }
    // eslint-disable-next-line
  }, [sort, menuList]);

  return (
    <div className="row">
      {filteredList.map((item, index) => (
        <CardMenuGrid
          menuList={menuList}
          setMenuList={setMenuList}
          activeOptionForm={activeOptionForm}
          setActiveOptionForm={setActiveOptionForm}
          item={item}
          key={index}
          handleRefetch={handleRefetch}
          userData={userData}
          isArchivedWorkspace={isArchivedWorkspace}
          handleDeleteWorkspace={handleDeleteWorkspace}
        />
      ))}
    </div>
  );
}

export default GridView;
