/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { withGetScreen } from 'react-getscreen'
// core components
import axios from 'axios';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import { CSVLink } from 'react-csv';


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class ComplainList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complaints: [],
      alert: null,
      isLoading: true,
      isEmpty: false,
    };
  }


  componentDidMount() {
    this.props.setTitle("Complain List")
    axios
      .get(`/ComplainCrem`)
      .then((response) => {
        this.setState({
          ...this.state,
          complaints: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: 'info',
            text: 'List is empty!',
            confirmButtonColor: '#30C1FF',
          });
        }
      });
  }

  render() {
    const { complaints, isLoading, isEmpty } = this.state;

    const headers_table = [
      { label: 'Tanggal', key: 'complain_request_date' },
      { label: 'Requester', key: 'user_name' },
      { label: 'Item Pekerjaan', key: 'complain_category_name' },
      { label: 'Lokasi', key: 'location_name' },
      { label: 'Sub Lokasi', key: 'sub_location' },

    ];
    // if (this.props.isMobile())
    // return (
    //   <>
    //   <SimpleHeader name="Complain List" parentName="Crem Management" />
    //   <Container className="mt-3" fluid>
    //     <Row>
    //         <div className="col">
    //           <Card>
    //           <ListGroup flush className="my-2">
    //               <ListGroupItem>
    //                 <Row gutter={16} style={{ width: "100%" }}>
    //                   <Col className="gutter-row" span={6}>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         <b>ID</b>
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         <b>Requestor</b>
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         <b>Category</b>
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         <b>Sub Location</b>
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         <b>Assigned To</b>
    //                       </h4>
    //                   </Col>
    //                   <Col className="gutter-row" span={18}>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         saksakslaklska
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         saksakslaklska
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         saksakslaklska
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         saksakslaklska
    //                       </h4>
    //                       <h4 style={{ margin: "0px", padding: "0px" }}>
    //                         saksakslaklska
    //                       </h4>
    //                   </Col>
    //                 </Row>
    //                 <Row gutter={16} className='ml-1 mt-3' style={{ width: "100%" }}>
    //                 <ComplainDetail/>
    //                 </Row>
    //               </ListGroupItem>
    //           </ListGroup>
    //           </Card>
    //         </div>
    //     </Row>
    //   </Container>
    //   </>
    // );
    return (
      <>
        {this.state.alert}
        {/* <SimpleHeader name="Complain List" parentName="Crem Management" /> */}
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                {isLoading && !isEmpty ? (
                  <div className="justify-content-center text-center" style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    opacity: '0.5',
                    height: '100%',
                    width: '100%',
                    zIndex: '1100'
                  }}>
                    <Loader
                      type="Bars"
                      color="#30C1FF"
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <>
                    {isEmpty ? (
                      <CardBody>
                        <h1>Complaint List is Empty !</h1>
                      </CardBody>
                    ) : (
                      <ToolkitProvider
                        data={complaints}
                        keyField="complain_id"
                        columns={[
                          {
                            dataField: "complain_id",
                            text: "ID",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "complain_request_date",
                            text: "Tanggal",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  {moment(row.complain_request_date).format('DD MMMM YYYY')}
                                </>
                              );
                            }
                          },
                          {
                            dataField: "user_name",
                            text: "Requestor",
                            sort: true,
                            editable: true,
                            filter: textFilter()
                          },
                          {
                            dataField: "complain_category_name",
                            text: "Item Pekerjaan",
                            sort: true,
                            editable: true,
                            filter: textFilter()
                          },
                          {
                            dataField: "location_name",
                            text: "Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter()
                          },
                          {
                            dataField: "sub_location",
                            text: "Sub Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter()
                          },
                          {
                            dataField: "", text: "Details", editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <ComplainDetail detail_complain={row.complain_id} />
                                </>
                              );
                            }
                          },
                          {
                            dataField: "", text: "Actions", editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <Row>
                                    <Button
                                      href={`/parastay/complain-assign/${row.complain_id}`}
                                      size="md"
                                      color="info"
                                      className="mb-1"
                                    >
                                      Pick
                                    </Button>
                                    <DeclineComplain detail_id_complain={row.complain_id} />
                                  </Row>
                                </>

                              );
                            }
                          }
                        ]}
                        search
                      >
                        {props => (
                          <div className="py-4 table-responsive">
                            <Container fluid>
                              <Row>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <CSVLink
                                      data={complaints}
                                      headers={headers_table}
                                      filename={'Complain list.csv'}>

                                      <Button
                                        style={{ marginRight: "5px" }}
                                        color="default"
                                        size="sm"
                                        className="buttons-copy buttons-html5"
                                        id="print-tooltip"
                                      >
                                        Export to CSV
                                      </Button>
                                    </CSVLink>

                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="default"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          this.copyToClipboardAsTable(
                                            document.getElementById("react-bs-table")
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => this.componentRef}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the visible rows
                                      of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the visible rows
                                      of the table.
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <label>
                                      Search:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={el => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                              id="react-bs-table"
                              filter={filterFactory()}
                              noDataIndication="There is no data"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withGetScreen(ComplainList);


const ComplainDetail = (props) => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [detailcomplain, setDetailcomplain] = React.useState(null);
  const [photolist, setPhotolist] = React.useState(null);

  const id_complain = props.detail_complain;

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  const handleClick = () => {
    axios
    .get(`/ComplainCrem?complain_id=${id_complain}`)
    .then(complainRes=>{
      setDetailcomplain(complainRes.data.data[0]);
      setModal(true);
    })
    .catch(error => {
    })

    axios
    .get(`/ComplainCremPhoto?complain_id=${id_complain}`)
    .then(photoRes=>{
      setPhotolist(photoRes.data.data);
      setModal(true);
    })
    .catch(error => {
    })
  }

  return (
    <div>
      <Button
        color="primary"
        size="md"
        onClick={handleClick}
      >
        <i className='fa fa-info-circle' />
      </Button>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>Complain Detail #1245</ModalHeader>
        <ModalBody>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="1%" className="text-info"><i className='fas fa-user' /></td>
                <td width="40%" className="text-muted">Nama Lengkap</td>
                <td className="text-wrap">{detailcomplain?.user_name}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info"><i className='fas fa-phone' /></td>
                <td width="40%" className="text-muted">Nomor Telepon</td>
                <td className="text-wrap">{detailcomplain?.user_phone}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info"><i className='fas fa-info-circle' /></td>
                <td width="40%" className="text-muted">Item Pekerjaan</td>
                <td className="text-wrap">{detailcomplain?.complain_category_name}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info"><i className='fas fa-home' /></td>
                <td width="40%" className="text-muted">Lokasi</td>
                <td className="text-wrap">{detailcomplain?.location_name}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info"><i className='fas fa-map-marker-alt' /></td>
                <td width="40%" className="text-muted">Sub Lokasi</td>
                <td className="text-wrap">{detailcomplain?.sub_location}</td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>Deskripsi Komplain</h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p classname="text-wrap" style={{ fontSize: 13, fontWeight: 400 }}>{detailcomplain?.complain_desc}</p>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>{photolist? 'Bukti Foto' : 'Tidak Ada Bukti Foto'}</h4>
              {photolist &&
              <Slider {...settings}>
                {photolist?.map((list, idx) => {
                  return (
                    <div>
                      <Card>
                        <CardImg top width="100%" style={{ maxHeight: '300px' }} src={`https://parastay-api.pti-cosmetics.com/assets/upload_complain_photos/${list.photo}`} alt={list.photo_id} />
                      </Card>
                    </div>
                  );
                })}
              </Slider>
              }
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="info" href={`/parastay/complain-assign/${id_complain}`}>Pick</Button>
          <DeclineComplain detail_id_complain={id_complain} />
        </ModalFooter>
      </Modal>
    </div>
  );
}

const DeclineComplain = (props) => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const id_complain = props.detail_id_complain;
  const id_user = localStorage.getItem('id_user');
  const id_status = 6;

  const [values, setValues] = React.useState({
    solution: null,
    complain_id: '',
    complain_status_id: ''
  });

  const closeBtn = <Button className="close" onClick={toggle}>&times;</Button>;

  const handleClick = () => {
    setModal(true);
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };


  const handleDecline = () => {
    let params = new URLSearchParams();
        params.append('solution', values?.solution);
        params.append('complain_id', id_complain);
        params.append('complain_status_id', id_status);
        params.append('crem_admin_pic_id', id_user);
    
    let dataLog = new URLSearchParams();
    dataLog.append('sender_id', id_user);
    dataLog.append('complain_id_fk2', id_complain);
    dataLog.append('complain_status_id', id_status);
    dataLog.append('log', values?.solution);

    axios
      .put(`/ComplainReason?complain_id=${id_complain}`, params)
      .then(response => {
        if (response.status === 201) {
          axios
            .post(`/ComplainLog`, dataLog)
            .then(response => {
              if (response.data.status === true) {
                Swal.fire('Rejected!', `Complain Has been Rejected.`, 'success');
                window.location.reload()
              } else {
                Swal.fire('Error!', 'Error to reject Complain Status', 'error');
              }
            })
            .catch(error => {
            })
        } else {
          Swal.fire('Error!', 'Error to reject Complain', 'error');
        }
      })
      .catch(error => {
      })
  }


  return (
    <div>
      <Button
        size="md"
        color="danger"
        className="mr-2 mb-1"
        onClick={handleClick}
      >
        Decline
      </Button>

      <Modal isOpen={modal} toggle={toggle} centered>
        <AvForm onSubmit={handleDecline}>
          <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
          <h3 className="text-center" style={{ marginTop: '-25px' }}>Tolak komplain ini?</h3>
          <h4 className="text-center mb-4">Beri tahu alasan penolakan kepada pemohon</h4>
          <ModalBody>
            <Row className="align-items-center">
              <Col md="12" xs="12">
                <AvGroup>
                  <AvInput
                    placeholder='Tuliskan catatan'
                    name='solution'
                    type="textarea"
                    onChange={handleChange}
                    value={values.solution}
                    required
                  />
                  <AvFeedback>Please Insert Reason !</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="danger" type="submit">Send</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </div>
  );
}
