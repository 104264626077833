import React, {useEffect, useState} from "react";
import {Card, Container} from "reactstrap";
import {Button} from "@mui/material";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";
import BookingCarMonitoringSection from "components/bookingCar/BookingCarMonitoringSection";
import FilterStatus from "components/bookingCar/FilterStatus";
import SearchInput from "components/bookingCar/SearchInput";
import FilterCategory from "components/bookingCar/FilterCategory";
import FilterDate from "components/bookingCar/FilterDate";
import {filteredParamsForBookingCar} from "utils/util";
import DownloadData from "components/bookingCar/DownloadData";

function Monitoring({setTitle}) {
    const role = localStorage.getItem("role");
    const date = new Date();
    const gmt7Offset = 7 * 60 * 60 * 1000;
    const localDate = new Date(date.getTime() + gmt7Offset);

    const year = localDate.getUTCFullYear();
    const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(localDate.getUTCDate()).padStart(2, '0');

    const currentDate = `${year}-${month}-${day}`;
    const [searchParams, setSearchParams] = useState({
        page: 1,
        limit: 10,
        query: "",
        status: "",
        filter_start_usage: (role === "Satpam") ? currentDate : "",
        filter_end_usage: (role === "Satpam") ? currentDate : "",
        car_category: "",
    });

    useEffect(() => {
        setTitle("Booking Car Monitoring");
    }, [setTitle]);

    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(false);

    const [totalPage, setTotalPage] = useState(1);
    const [totalData, setTotalData] = useState(1);

    const handleResetFilter = () => {
        setSearchParams({
            page: 1,
            limit: 10,
            query: "",
            filter_start_usage: "",
            filter_end_usage: "",
            car_category: "",
            status: "",
        });
    };

    const isAnyFilterActive = () => {
        return (
            searchParams.query ||
            searchParams.status ||
            searchParams.car_category ||
            searchParams.filter_start_usage ||
            searchParams.filter_end_usage
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsPending(true);
            try {
                const searchParamsObj = {
                    ...searchParams,
                    order_column: "start_usage",
                    order_sequence: "DESC",
                };

                const filteredSearchParams = filteredParamsForBookingCar({
                    params: searchParamsObj,
                });

                const newUrlSearchParams = new URLSearchParams(
                    filteredSearchParams
                ).toString();

                const {data} = await Axios({
                    url: `/admin/car/booking?${newUrlSearchParams}`,
                    method: "GET",
                });

                if (!data.errorStatus) {
                    const responseBookingCars = data?.data?.bookings?.data;

                    setTotalPage(data?.data.total_page);
                    setTotalData(data?.data.total_size);
                    const filterStatus = responseBookingCars.map((booking) => {
                        let bookingStatus = "Selesai";
                        // eslint-disable-next-line default-case
                        switch (booking.booking_status) {
                            case "Booking Created":
                                bookingStatus = "Mendatang";
                                break;
                            case "Booking Cancelled":
                                bookingStatus = "Dibatalkan";
                                break;
                            case "In Progress":
                                bookingStatus = "Sedang Digunakan";
                                break;
                        }

                        return {...booking, booking_status: bookingStatus};
                    });
                    setData(filterStatus);
                }
            } catch (error) {
                setData([]);
            } finally {
                setIsPending(false);
            }
        }
        fetchData();
    }, [searchParams]);

    if (isPending) {
        return (
            <div
                className="w-100 d-flex justify-content-center "
                style={{
                    alignContent: "center",
                    padding: "50px 0px",
                }}
            >
                <Loader type="Oval" color="#30C1FF" height={100} width={100}/>
            </div>
        );
    }
    return (
        <Container fluid style={{background: "#F8F9FE"}}>
            <div
                className="d-flex"
                style={{
                    marginBottom: "15px",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div
                    className="d-flex"
                    style={{
                        width: "30%",
                    }}
                >
                    <SearchInput searchParams={searchParams} setSearchParams={setSearchParams}/>
                </div>
                <div
                    className="d-flex gap-2 justify-content-end"
                    style={{
                        width: "65%",
                    }}
                >
                    {isAnyFilterActive() && (
                        <Button
                            variant="text"
                            style={{
                                color: "#3E7CA8",
                                fontSize: "16px",
                                fontWeight: "bold",
                                textTransform: "none",
                            }}
                            onClick={handleResetFilter}
                        >
                            Reset Filter
                        </Button>
                    )}
                    <FilterStatus searchParams={searchParams} setSearchParams={setSearchParams}/>
                    <FilterCategory searchParams={searchParams} setSearchParams={setSearchParams}/>
                    <FilterDate
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        label="Tanggal Awal"
                        objectKey="filter_start_usage"
                    />
                    <FilterDate
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        label="Tanggal Akhir"
                        objectKey="filter_end_usage"
                    />
                    <DownloadData searchParams={searchParams}/>
                </div>
            </div>
            <Card className="mb-0">
                <BookingCarMonitoringSection
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    data={data}
                    totalPage={totalPage}
                    total_size={totalData}
                />
            </Card>
        </Container>
    );
}

export default Monitoring;
