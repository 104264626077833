/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Card,
  CardBody,
  CardImg,
  Container,
  Button,
  Row,
  Col,
  Collapse,
  Table,
} from "reactstrap";
// core components
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";

const initialState = {
  solution: "",
  complain_id: "",
  complain_status_id: "",
  complain_start_date: "",
  complain_end_date: "",
};

class ComplainReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: initialState,
      isLoading: true,
      isEdited: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    this.props.setTitle("Penyelesaian Komplain");
    const complain_id = this.props.match.params.complain_id;

    axios
      .all([
        axios.get(`/ComplainCrem?complain_id=${complain_id}`),
        axios.get("/Statuslist"),
      ])
      .then(
        axios.spread((complainResp, statusResp) => {
          const complain = complainResp.data.data[0];
          if (complain) {
            const detailData = { ...initialState };

            for (const key in complain) {
              if (key in detailData) detailData[key] = complain[key];
            }

            this.setState({
              formData: detailData,
              user: complain,
              isLoading: false,
              states: statusResp.data.data,
            });
          }
        })
      )
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/ComplainCremPhoto?complain_id=${complain_id}`)
      .then((photoResp) => {
        this.setState({ photos: photoResp.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      formData: { ...this.state.formData, [name]: value },
      isEdited: true,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        solution: e.target.getContent(),
      },
      isEdited: true,
    });
  };

  handleStartDateChange = (date) => {
    const inputDate = date._d;
    const stringDate = `${inputDate.getFullYear()}-${
      inputDate.getMonth() + 1
    }-${inputDate.getDate()}`;

    this.setState({
      formData: { ...this.state.formData, complain_start_date: stringDate },
      isEdited: true,
    });
  };

  handleEndDateChange = (date) => {
    const inputDate = date._d;
    const stringDate = `${inputDate.getFullYear()}-${
      inputDate.getMonth() + 1
    }-${inputDate.getDate()}`;

    this.setState({
      formData: { ...this.state.formData, complain_end_date: stringDate },
      isEdited: true,
    });
  };

  valid = (current) => {
    let nextMonth = moment(this.state.complain_start_date).add(1, "y");
    return (
      current.isBefore(nextMonth.add(1, "d")) &&
      current.isAfter(this.state.complain_start_date)
    );
  };

  handleClick = () => {
    this.setState({ ...this.state, hidden: !this.state.hidden });
  };

  handleCancel = () => {
    if (this.state.isEdited) {
      Swal.fire({
        title: "Are you sure you want to go back?",
        text: "If you leave before saving, your changes will be lost",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#30C1FF",
        cancelButtonColor: "#F8AFB8",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.history.goBack();
        }
      });
    } else {
      this.props.history.goBack();
    }
  };

  handleSubmit = (e, errors) => {
    e.preventDefault();

    const {
      solution,
      complain_id,
      complain_status_id,
      complain_start_date,
      complain_end_date,
    } = this.state.formData;

    if (errors.length === 0) {
      this.setState({ ...this.state, isLoading: true });

      const formData = {
        solution,
        complain_id,
        complain_status_id,
        complain_start_date,
        complain_end_date,
      };

      const id_user = localStorage.getItem("id_user");
      let dataLog = new URLSearchParams();
      dataLog.append("sender_id", id_user);
      dataLog.append("complain_id_fk2", complain_id);
      dataLog.append("complain_status_id", complain_status_id);
      dataLog.append("log", solution);

      axios
        .put("/ComplainCremPicked", formData)
        .then((response) => {
          var status = response.data.status;
          if (status === true) {
            axios
              .post(`/ComplainLog`, dataLog)
              .then((response) => {
                if (response.data.status === true) {
                  Swal.fire({
                    icon: "success",
                    text: "Complain has been updated.",
                    confirmButtonColor: "#30C1FF",
                  });
                  this.props.history.push(`/parastay/complain-picked`);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: "Error to update",
                    confirmButtonColor: "#30C1FF",
                  });
                }
              })
              .catch((error) => {});
          } else {
            Swal.fire({
              icon: "error",
              text: "Error to update",
              confirmButtonColor: "#30C1FF",
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            text: "Failed to update!",
            confirmButtonColor: "#30C1FF",
          });
          this.setState({ isLoading: false });
        });
    } else {
      console.log(errors);
    }
  };

  render() {
    const { formData, isLoading, states, user, photos } = this.state;
    return (
      <>
        <Container fluid>
          <Card className="mb-4">
            {isLoading ? (
              <CardBody>
                <div
                  className="justify-content-center text-center"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    opacity: "0.5",
                    height: "100%",
                    width: "100%",
                    zIndex: "1100",
                  }}
                >
                  <Loader
                    type="Bars"
                    color="#30C1FF"
                    height={100}
                    width={100}
                  />
                </div>
              </CardBody>
            ) : (
              <Row>
                <Col md="4" xs="12">
                  <CardBody>
                    <RequesterDetail user={user} photos={photos} />
                  </CardBody>
                </Col>
                <Col md="8" xs="12">
                  <CardBody>
                    <h1 className="mb-3">Form Laporan</h1>
                    <AvForm onSubmit={this.handleSubmit}>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1Input2"
                            >
                              Status
                            </label>
                            <AvField
                              type="select"
                              name="complain_status_id"
                              onChange={this.handleChange}
                              value={formData.complain_status_id}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please Select State",
                                },
                              }}
                            >
                              <option value="" selected disabled>
                                -Pilih Status-
                              </option>
                              {states &&
                                states.length > 0 &&
                                states
                                  .filter(
                                    ({ comp_status_id }) =>
                                      comp_status_id === 4 ||
                                      comp_status_id === 5 ||
                                      comp_status_id === 7
                                  )
                                  .map((stat, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={stat.comp_status_id}
                                      >
                                        {stat.comp_status_name}
                                      </option>
                                    );
                                  })}
                            </AvField>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1Input"
                            >
                              Tanggal Mulai
                            </label>
                            <ReactDatetime
                              selected={this.state.complain_start_date}
                              onChange={this.handleStartDateChange}
                              timeFormat={false}
                              value={formData.complain_start_date}
                              required
                            />
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1Input"
                            >
                              Tanggal Selesai
                            </label>
                            <ReactDatetime
                              selected={this.state.complain_end_date}
                              onChange={this.handleEndDateChange}
                              isValidDate={this.valid}
                              timeFormat={false}
                              value={formData.complain_end_date}
                              required
                            />
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1Input"
                            >
                              Rincian Perbaikan
                            </label>
                            <Editor
                              initialValue={
                                !formData.solution
                                  ? "Tulis deskripsi tindakan..."
                                  : formData.solution
                              }
                              apiKey="gtr9kvxdsu5tjpm7szjln7t2m60nld35etyunod55c5alprj"
                              init={{
                                height: 250,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image",
                                  "charmap print preview anchor help",
                                  "searchreplace visualblocks code",
                                  "insertdatetime media table paste wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
                              }}
                              onChange={this.handleEditorChange}
                            />
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Button color="info" type="submit" size="md">
                            Send
                          </Button>
                          <Button
                            color="secondary"
                            onClick={this.handleCancel}
                            size="md"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Col>
              </Row>
            )}
          </Card>
        </Container>
      </>
    );
  }
}
export default ComplainReport;

const RequesterDetail = (props) => {
  const [collapse, setCollapse] = useState(true);

  const toggle = () => setCollapse(!collapse);

  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <Card style={{ backgroundColor: "#eeeef4" }}>
      <Row className="mx-2 my-3">
        <Col md="8" xs="8">
          <h3 className="mb-2">Detail Pemohon</h3>
        </Col>
        <Col md="4" xs="4">
          <Button
            className="text-info justify-content-right"
            onClick={toggle}
            style={{ marginBottom: "1rem", float: "right" }}
          >
            <i className="ni ni-bold-down" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={collapse}>
        <div>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-user mr-2 text-info" />
                  Nama Lengkap
                </td>
                <td className="text-wrap">{props.user.user_name}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-phone mr-2 text-info" />
                  Nomor Telepon
                </td>
                <td className="text-wrap">{props.user.user_phone}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-info-circle mr-2 text-info" />
                  Item Pekerjaan
                </td>
                <td className="text-wrap">
                  {props.user.complain_category_name}
                </td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-home mr-2 text-info" />
                  Lokasi
                </td>
                <td className="text-wrap">{props.user.location_name}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-home mr-2 text-info" />
                  Alamat Lokasi
                </td>
                <td className="text-wrap">{props.user.location_address}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-map-marker-alt mr-2 text-info" />
                  Sub Lokasi
                </td>
                <td className="text-wrap">{props.user.sub_location}</td>
              </tr>
            </tbody>
          </Table>
          <Row className="mx-2 mb-4">
            <Col className="col-auto mt-3" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Deskripsi Komplain
              </h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p
                classname="text-wrap"
                style={{ fontSize: 13, fontWeight: 400 }}
              >
                {props.user.complain_desc}
              </p>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                {props.photos ? "Bukti Foto" : "Tidak Ada Bukti Foto"}
              </h4>
              <Slider {...settings}>
                {props.photos &&
                  props.photos.map((list, idx) => {
                    return (
                      <div>
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            style={{ maxHeight: "300px" }}
                            src={`https://parastay-api.pti-cosmetics.com/assets/upload_complain_photos/${list.photo}`}
                            alt={list.photo_id}
                          />
                        </Card>
                      </div>
                    );
                  })}
              </Slider>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
};
