import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";

import SuitcaseIcon from "components/Icons/SuitcaseIcon";
import Axios from "utils/axiosService";
import iconOptions from "const/iconOptions";

function OptionForm({
  willFormOverFlow,
  item,
  setActiveOptionForm,
  handleRefetch,
  userData,
  isArchivedWorkspace,
  handleDeleteWorkspace
}) {
  const [itemName, setItemName] = useState(item.name);
  const [activeTab, setActiveTab] = useState("1");
  const [details, setDetails] = useState(item.description ?? "-");

  const handleChangeName = (e) => {
    e.preventDefault();
    setItemName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios({
      method: "PATCH",
      url: `/app/${item.id}`,
      data: {
        name: itemName,
        description: details,
      },
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error updating menu");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        handleRefetch();
        setActiveOptionForm(null);
      });
  };

  const handleClickColor = (color) => {
    Axios({
      method: "PATCH",
      url: `/app/${item.id}`,
      data: {
        color: color,
      },
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error updating menu");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        handleRefetch();
        setActiveOptionForm(null);
      });
  };

  const handleUpdateIcon = (icon) => {
    Axios({
      method: "PATCH",
      url: `/app/${item.id}`,
      data: {
        icon: icon,
      },
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error updating menu");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        handleRefetch();
        setActiveOptionForm(null);
      });
  };

  const handleUpdateStatus = () => {
    Axios({
      method: "PATCH",
      url: `/app/${item.id}`,
      data: {
        status: isArchivedWorkspace,
      },
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error deleting menu");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        handleRefetch();
        setActiveOptionForm(null);
      });
  };
  const colorPallette = [
    {
      name: "blue",
      colors: ["#2019C6", "#2E1CFF", "#4F7EFF"],
    },
    {
      name: "tosca",
      colors: ["#0077AA", "#009BDE", "#2ABCE9"],
    },
    {
      name: "green",
      colors: ["#008119", "#00A921", "#54C763"],
    },
    {
      name: "purple",
      colors: ["#6F00AC", "#9100E0", "#B26FEA"],
    },
    {
      name: "pink",
      colors: ["#B10089", "#E700B3", "#F300CA"],
    },
    {
      name: "orange",
      colors: ["#C14900", "#FB5E00", "#FC9748"],
    },
    {
      name: "red",
      colors: ["#B80024", "#EF002E", "#F75171"],
    },
    {
      name: "dark-green",
      colors: ["#035673", "#007196", "#5A9DB6"],
    },
    {
      name: "yellow",
      colors: ["#BF9800", "#F9C600", "#FADB44"],
    },
  ];

  return (
    <div
      name="option form"
      className="bg-white p-2 d-flex flex-column justify-content-between"
      style={{
        position: "absolute",
        right: !willFormOverFlow ? "-300px" : "0px",
        top: "30px",
        width: "320px",
        zIndex: "3",

        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "8px",
      }}
    >
      <form onSubmit={handleSubmit}>
        <input
          name="option input"
          value={itemName}
          onChange={handleChangeName}
          style={{
            width: "100%",
            fontSize: "15px",
            border: "1px solid #BFBFBF",
            borderRadius: "3px",
            padding: "3px 6px",
          }}
        />
        <Nav tabs className="mt-1" style={{ cursor: "pointer" }}>
          <NavItem>
            <NavLink
              className={`text-xs ${activeTab === "1" ? "active" : ""}`}
              onClick={function noRefCheck() {
                setActiveTab("1");
              }}
            >
              Colors
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`text-xs ${activeTab === "2" ? "active" : ""}`}
              onClick={function noRefCheck() {
                setActiveTab("2");
              }}
            >
              Icons
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col
                sm="12"
                className="d-flex justify-content-around mt-2"
                style={{ gap: "4px" }}
              >
                {colorPallette.map((color) => (
                  <div
                    key={color.name}
                    className="d-flex flex-column"
                    style={{ gap: "4px" }}
                  >
                    {color.colors.map((color) => (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleClickColor(color);
                        }}
                        key={color}
                        className="rounded-circle border-0"
                        style={{
                          width: "24px",
                          height: "24px",
                          background: color,
                        }}
                      ></button>
                    ))}
                  </div>
                ))}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <div className="py-2">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(10, 1fr)",
                  gap: "8px",
                  maxHeight: "200px",
                  overflowY: "scroll",
                }}
              >
                {iconOptions.map((icon) => (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdateIcon(icon);
                    }}
                    key={icon}
                    style={{
                      color: "#BFBFBF",
                      cursor: "pointer",
                    }}
                  >
                    <i className={icon}></i>
                  </div>
                ))}
              </div>
            </div>
          </TabPane>
        </TabContent>

        <div className="d-flex flex-column align-items-start mt-3">
          <h5
            className="mb-1"
            style={{
              fontSize: "15px",
              fontWeight: 700,
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <span className="mr-2">
              <SuitcaseIcon />
            </span>
            Project Details
          </h5>
          <textarea
            value={details}
            rows="4"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSubmit(e);
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              setDetails(e.target.value);
            }}
            style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.7)" }}
            className="text-left m-0 w-100 border-0"
          />
        </div>
      </form>
      {userData?.role?.toLowerCase() === "superadmin" && (
        <>
          {isArchivedWorkspace && (
            <button
              className="mb-1 border-0 d-flex justify-content-start"
              style={{
                background: "transparent",
                fontSize: "15px",
                fontWeight: 700,
                color: "#228c22",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleUpdateStatus();
              }}
            >
              <span className="mr-2">
              <i class="fa-solid fa-arrow-rotate-left"></i>
              </span>
              Restore Workspace
            </button>
          )}
          {!isArchivedWorkspace && (
            <button
              className="mb-1 border-0 d-flex justify-content-start"
              style={{
                background: "transparent",
                fontSize: "15px",
                fontWeight: 700,
                color: "#D74242",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleDeleteWorkspace(item.id);
              }}
            >
              <span className="mr-2">
                <i class="fa-solid fa-trash-can"></i>
              </span>
              Archive Workspace
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default OptionForm;
