import React from "react";

function BinIcon({width, height}) {
  return (
    <svg
      width={width ?? "20"}
      height={height ?? "21"}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 4.8V2.9C5.75 1.85066 6.60066 1 7.65 1H11.45C12.4994 1 13.35 1.85066 13.35 2.9V4.8M1 4.8H18.1H1ZM2.9 4.8V18.1C2.9 19.1494 3.75066 20 4.8 20H14.3C15.3494 20 16.2 19.1494 16.2 18.1V4.8H2.9Z"
        stroke="#D74242"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.45 9.54999V15.25"
        stroke="#D74242"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.64999 9.54999V15.25"
        stroke="#D74242"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BinIcon;
