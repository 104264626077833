import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, Table } from "reactstrap";
import moment from "moment";

import OptionForm from "./OptionForm";
import "moment/locale/id";

import { Link } from "react-router-dom";

function ListViewGrid({
  menuList,
  setMenuList,
  activeOptionForm,
  setActiveOptionForm,
  searchValue,
  sort,
  handleRefetch,
  userData,
  isArchivedWorkspace,
  handleDeleteWorkspace,
}) {
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    moment.locale("id");
  }, []);

  useEffect(() => {
    setFilteredList([...menuList]);
    // eslint-disable-next-line
  }, [menuList?.length]);

  useEffect(() => {
    if (searchValue) {
      const filteredList = menuList.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredList(filteredList);
    } else {
      setFilteredList([...menuList]);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    if (sort && filteredList.length > 0) {
      const sortedList = filteredList.sort((a, b) => {
        if (sort === "asc") {
          return a["created_at"] > b["created_at"] ? 1 : -1;
        } else {
          return a["created_at"] < b["created_at"] ? 1 : -1;
        }
      });
      setFilteredList([...sortedList]);
    } else {
      setFilteredList([...menuList]);
    }
    // eslint-disable-next-line
  }, [sort, menuList]);

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Details</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {filteredList.map((item, index) => {
          const href = `/${item.name.toLowerCase().split(" ").join("-")}`;
          return (
            <tr key={index}>
              <td>
                {
                  <Link to={href} className="text-dark">
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "32px", cursor: "pointer" }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "97px",
                          height: "56px",
                          background: isArchivedWorkspace
                            ? "#b8a88a"
                            : item.color,
                          borderRadius: "4px",
                        }}
                      >
                        <div style={{ color: "#fff", fontSize: "30px" }}>
                          <i className={item.icon}></i>
                        </div>
                      </div>
                      <p
                        className="m-0 color-hover"
                        style={{ fontSize: ".8125rem" }}
                      >
                        {item.name}
                      </p>
                    </div>
                  </Link>
                }
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                }}
              >
                {item.description}
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                }}
              >
                {moment(item.createdAt).format("LL")}
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                }}
              >
                <div className="position-relative">
                  <Dropdown
                    isOpen={activeOptionForm === item.id}
                    toggle={() => {
                      if (activeOptionForm === item.id) {
                        setActiveOptionForm(null);
                      } else {
                        setActiveOptionForm(item.id);
                      }
                    }}
                  >
                    <DropdownToggle className="shadow-none bg-white border-0">
                      ...
                    </DropdownToggle>
                    <DropdownMenu
                      className="bg-transparent shadow-none"
                      style={{
                        position: "absolute",
                        right: "0",
                        transform: "translateX(-50%)",
                        top: "10px",
                      }}
                    >
                      <OptionForm
                        willFormOverFlow={true}
                        menuList={menuList}
                        setMenuList={setMenuList}
                        item={item}
                        setActiveOptionForm={setActiveOptionForm}
                        activeOptionForm={activeOptionForm}
                        handleRefetch={handleRefetch}
                        userData={userData}
                        isArchivedWorkspace={isArchivedWorkspace}
                        handleDeleteWorkspace={handleDeleteWorkspace}
                      />
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default ListViewGrid;
