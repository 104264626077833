import React from "react";

function GridIcon({ fill }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33333 1H1.55556C1.24873 1 1 1.24873 1 1.55556V4.33333C1 4.64016 1.24873 4.88889 1.55556 4.88889H4.33333C4.64016 4.88889 4.88889 4.64016 4.88889 4.33333V1.55556C4.88889 1.24873 4.64016 1 4.33333 1Z"
        stroke={fill ?? "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33333 7.11108H1.55556C1.24873 7.11108 1 7.35981 1 7.66664V10.4444C1 10.7512 1.24873 11 1.55556 11H4.33333C4.64016 11 4.88889 10.7512 4.88889 10.4444V7.66664C4.88889 7.35981 4.64016 7.11108 4.33333 7.11108Z"
        stroke={fill ?? "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4444 1H7.66664C7.35981 1 7.11108 1.24873 7.11108 1.55556V4.33333C7.11108 4.64016 7.35981 4.88889 7.66664 4.88889H10.4444C10.7512 4.88889 11 4.64016 11 4.33333V1.55556C11 1.24873 10.7512 1 10.4444 1Z"
        stroke={fill ?? "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4444 7.11108H7.66664C7.35981 7.11108 7.11108 7.35981 7.11108 7.66664V10.4444C7.11108 10.7512 7.35981 11 7.66664 11H10.4444C10.7512 11 11 10.7512 11 10.4444V7.66664C11 7.35981 10.7512 7.11108 10.4444 7.11108Z"
        stroke={fill ?? "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GridIcon;
