/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Button, Card, Container, Input, Modal, ModalBody } from "reactstrap";
import Loader from "react-loader-spinner";
import BookingHistoryTable from "components/bookingRoom/BookingHistoryTable";
import Axios from "utils/axiosService";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/id";

moment.locale("id");

function BookingHistory({ setTitle, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloadCSV, setIsDownloadCSV] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowCancelReason, setIsShowCancelReason] = useState(false);
  const [rowToCancel, setRowToCancel] = useState({});
  const [cancelReason, setCancelReason] = useState("");
  const [dataToDownload, setDataForDownload] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [refetch, setIsRefetch] = useState(false);
  const [filter, setFilter] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "STATUS":
          return {
            ...state,
            status: action.payload,
          };
        case "TYPE":
          return {
            ...state,
            type: action.payload,
          };
        default:
          return state;
      }
    },
    { status: "all", type: "all" }
  );

  useEffect(() => {
    setTitle("Booking History");
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    let queryFilter = '';
    const { status, type } = filter;

    queryFilter += `&limit=${sizePerPage}&offset=${(page - 1) * sizePerPage}`

    if (status === "all") {
      queryFilter += `&start_usage_lte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&is_cancel=false`;
    } else if (status === "ongoing") {
      queryFilter += `&start_usage_lte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&finish_usage_gte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&is_cancel=false`;
    } else if (status === "completed") {
      queryFilter += `&finish_usage_lte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&is_cancel=false`;
    } else if (status === "cancelled") {
      queryFilter += `&is_cancel=true`;
    }

    if (type === "aula") {
      queryFilter += `&type=Aula`;
    } else if (type === "non-aula") {
      queryFilter += `&type=Ruang Meeting`;
    }

    await Axios({
      method: "GET",
      url: '/room/v2/booking?order_column=id&order_sequence=DESC' + queryFilter
    })
      .then((r) => {
        setTotalSize(r.data.data.count);
        setFilteredData(r.data.data.data);
      })
      .catch((e) => {
        console.error(e)
        return {
          totalSize: 0,
          filteredData: []
        }
      }).finally(() => setIsLoading(false))
  }

  const fetchDataToDownload = async () => {
    setIsDownloadCSV(true);
    let queryFilter = '';
    let result = {
      totalSize: 0,
      filteredDataToDownload: []
    }
    const { status, type } = filter;

    if (status === "all") {
      queryFilter += `&start_usage_lte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}`;
    } else if (status === "ongoing") {
      queryFilter += `&status=Booking Created&start_usage_lte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&finish_usage_gte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&is_cancel=false`;
    } else if (status === "completed") {
      queryFilter += `&status=Booking Created&finish_usage_lte=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&is_cancel=false`;
    } else if (status === "cancelled") {
      queryFilter += `&is_cancel=true`;
    }

    if (type === "aula") {
      queryFilter += `&type=Aula`;
    } else if (type === "non-aula") {
      queryFilter += `&type=Ruang Meeting`;
    }

    await Axios({
      method: "GET",
      url: '/room/v2/booking?order_column=id&order_sequence=DESC' + queryFilter
    })
      .then((r) => {
        result.totalSize = r.data.data.length
        result.filteredDataToDownload = r.data.data.data
      })
      .catch((e) => {
        console.error(e)
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Something went wrong: ${e}`
        });
      })
      .finally(() => setIsDownloadCSV(false))

      return result
  }

  const handleExportCSV = async () => {
    let downloadResult = await fetchDataToDownload()
    const tempFilteredData = downloadResult?.filteredDataToDownload?.map((d) => {
      return {
        ...d,
        start_date: moment(d.start_usage).format("Do/MM/YYYY"),
        finish_date: moment(d.finish_usage).format("Do/MM/YYYY"),
        start_time: moment(d.start_usage).format("LT"),
        finish_time: moment(d.finish_usage).format("LT"),
        cancel_date: d.cancel_timestamp
          ? moment(d.cancel_timestamp).format("Do/MM/YYYY")
          : "-",
        cancel_time: d.cancel_timestamp
          ? moment(d.cancel_timestamp).format("LTS")
          : "-",
      };
    })
    setDataForDownload(tempFilteredData);
  };

  useEffect(() => {
    fetchData()
  }, [refetch, filter, sizePerPage, page]);

  const csv_header = useMemo(() => {
    let aulaHeader = [];
    if (filter.type !== "non-aula") {
      aulaHeader = [
        {
          label: "Layout",
          key: "aula_data.aula_layout",
        },
        {
          label: "Layout Detail",
          key: "aula_data.detail_aula_layout",
        },
        {
          label: "Lesehan Area",
          key: "aula_data.lesehan_area",
        },
        {
          label: "Table",
          key: "aula_data.table_amount",
        },
        {
          label: "Chair per Table",
          key: "aula_data.chair_per_table_amount",
        },
        {
          label: "Standard Chair",
          key: "aula_data.kursi_standard_amount",
        },
        {
          label: "Kursi Kampus",
          key: "aula_data.kursi_kampus_amount",
        },
        {
          label: "Seat Option",
          key: "aula_data.seat_option",
        },
        {
          label: "Flipchart",
          key: "aula_data.flipchart_amount",
        },
        {
          label: "Whiteboard",
          key: "aula_data.whiteboard_amount",
        },
        {
          label: "Square Table",
          key: "aula_data.square_table_amount",
        },
        {
          label: "Cable Roll",
          key: "aula_data.cable_roll_amount",
        },
      ];
    }

    const default_header = [
      {
        label: "ID",
        key: "id",
      },
      {
        label: "User",
        key: "user_id",
      },
      {
        label: "Username",
        key: "user_name",
      },
      {
        label: "Email",
        key: "user_email",
      },
      {
        label: "Contact",
        key: "user_contact",
      },
      {
        label: "Area",
        key: "room_data.office_area.name",
      },
      {
        label: "Room Name",
        key: "room_data.name",
      },
      {
        label: "Location Detail",
        key: "room_data.location_detail",
      },
      {
        label: "Start Date",
        key: "start_date",
      },
      {
        label: "Start Time",
        key: "start_time",
      },
      {
        label: "End Date",
        key: "finish_date",
      },
      {
        label: "End Time",
        key: "finish_time",
      },
      {
        label: "Booking Id",
        key: "booking_id",
      },
      {
        label: "Purpose",
        key: "booking_purpose",
      },
      {
        label: "Attendees Amount",
        key: "attendees_amount",
      },
      {
        label: "Period Repeat",
        key: "period_repeat",
      },
      {
        label: "Is Cancelled",
        key: "is_cancel",
      },
      {
        label: "Cancelled by",
        key: "cancelled_by",
      },
      {
        label: "Cancellation reason",
        key: "cancel_reason",
      },
      {
        label: "Cancelled At (Date)",
        key: "cancel_date",
      },
      {
        label: "Cancelled At (Time)",
        key: "cancel_time",
      },
    ];

    return [...default_header, ...aulaHeader];
  }, [filter.type]);

  const handleViewRow = (id) => {
    history.push(`/booking-room/booking/${id}`);
  };

  const cancleBooking = ({ booking_id, occurrence_id }) => {
    if (!cancelReason) {
      Swal.fire({
        icon: "error",
        text: "Cancellation reason can't be blank",
      });
    } else if (cancelReason.length > 255) {
      Swal.fire({
        icon: "error",
        text: "Cancellation reason can't be more than 255 characters",
      });
    } else {
      Axios({
        method: "PATCH",
        url: `/room/booking/${booking_id}/occurrence/${occurrence_id}/cancel`,
        data: {
          is_cancel: true,
          status: "Booking Cancelled",
          cancelled_by: localStorage.getItem("username"),
          cancel_timestamp: moment(new Date())
            .format("YYYY-MM-DD HH:mm:ss")
            .toString(),
          cancel_reason: cancelReason,
        },
      })
        .then((r) => {
          setIsRefetch((prev) => !prev);
          Swal.fire({
            icon: "success",
            text: "Booking Cancelled.",
            confirmButtonColor: "#30C1FF",
          });
        })
        .catch((error) => {
          console.error(error.response);
          Swal.fire({
            icon: "error",
            text: "Something went wrong",
          });
        })
        .finally(() => {
          setIsShowDeleteModal(false);
          setCancelReason("");
          setRowToCancel({});
        });
    }
  };

  const handleCancel = ({ booking_id, occurrence_id }) => {
    setRowToCancel({ booking_id, occurrence_id });
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        {!isLoading && (
          <BookingHistoryTable
            data={filteredData}
            dataToDownload={dataToDownload}
            csv_header={csv_header}
            handleViewRow={handleViewRow}
            handleCancel={handleCancel}
            filter={filter}
            setFilter={setFilter}
            setIsShowDeleteModal={setIsShowDeleteModal}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            setPage={setPage}
            setSizePerPage={setSizePerPage}
            handleExportCSV={handleExportCSV}
            isDownloadCSV={isDownloadCSV}
          />
        )}
        {isLoading && (
          <div className="w-100 d-flex justify-content-center">
            <Loader type="Oval" color="#30C1FF" height={100} width={100} />
          </div>
        )}
      </Card>
      <Modal
        isOpen={isShowDeleteModal}
        fade={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowDeleteModal(!isShowDeleteModal);
        }}
      >
        <ModalBody
          style={{
            minHeight: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {!isShowCancelReason && (
            <>
              <h2 className="text-center mb-0">
                Are you sure to cancel this book?
              </h2>
              <div className="d-flex justify-content-center">
                <Button
                  color="danger"
                  className="p-0 px-2"
                  style={{
                    height: "30px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsShowCancelReason(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  color="info"
                  className="p-0 px-2"
                  style={{
                    height: "30px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsShowDeleteModal(false);
                  }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {isShowCancelReason && (
            <>
              <div
                style={{
                  width: "420px",
                }}
              >
                <h2 className="text-center mb-0 pb-3">Cancellation Reason</h2>
                <Input
                  type="textarea"
                  rows="6"
                  maxlength="255"
                  name="text"
                  id="cancelReason"
                  value={cancelReason}
                  onChange={(e) => setCancelReason(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center pt-2">
                <Button
                  color="danger"
                  className="p-0 px-2"
                  style={{
                    height: "30px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (rowToCancel.booking_id && rowToCancel.occurrence_id) {
                      cancleBooking(rowToCancel);
                    }
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="info"
                  className="p-0 px-2"
                  style={{
                    height: "30px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsShowDeleteModal(false);
                    setCancelReason("");
                    setIsShowCancelReason(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default BookingHistory;
