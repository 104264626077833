/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardImg,
  Container,
  Button,
  Row,
  Col,
  Collapse,
  Table,
} from "reactstrap";
// core components
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

const initialState = {
  category_id_fk: "",
  priority: "",
  complain_id: "",
  complain_status_id: "",
  crem_admin_pic_id: "",
};

class ComplainAssign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: initialState,
      isLoading: true,
      isEdited: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.setTitle("Tindak Lanjut Komplain");
    const complain_id = this.props.match.params.complain_id;
    const id_user = localStorage.getItem("id_user");

    axios
      .all([
        axios.get(`/ComplainCrem?complain_id=${complain_id}`),
        axios.get("/CategoryComplain"),
        axios.get("/Prioritylist"),
      ])
      .then(
        axios.spread((complainResp, categoryResp, priorityResp) => {
          const complain = complainResp.data.data[0];
          if (complain) {
            this.setState({
              formData: {
                category_id_fk: complain.category_id_fk,
                priority: complain.priority,
                complain_id: complain.complain_id,
                complain_status_id: 3,
                crem_admin_pic_id: id_user,
              },
              user: complain,
              isLoading: false,
              categories: categoryResp.data.data,
              priorities: priorityResp.data.data,
            });
          }
        })
      )
      .catch((error) => {
        console.log(error.response);
      });

    axios
      .get(`/ComplainCremPhoto?complain_id=${complain_id}`)
      .then((photoResp) => {
        this.setState({ photos: photoResp.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "category_id_fk") {
      const filteredCategory = this.state.categories.filter((category) => {
        return category.complain_category_name === e.target.value;
      });

      const filteredPriority = this.state.priorities.filter((prior) => {
        return prior.priority_name === e.target.value;
      });

      if (filteredCategory.length > 0) {
        this.setState({
          formData: {
            ...this.state.formData,
            [name]: value,
            category_id_fk: filteredCategory[0].complain_category_id,
          },
          isEdited: true,
        });
      }
      if (filteredPriority.length > 0) {
        this.setState({
          formData: {
            ...this.state.formData,
            [name]: value,
            priority: filteredPriority[0].priority_id,
          },
          isEdited: true,
        });
      }
    } else {
      this.setState({
        formData: { ...this.state.formData, [name]: value },
        isEdited: true,
      });
    }
  };

  handleClick = () => {
    this.setState({ ...this.state, hidden: !this.state.hidden });
  };

  handleCancel = () => {
    if (this.state.isEdited) {
      Swal.fire({
        title: "Are you sure you want to go back?",
        text: "If you leave before saving, your changes will be lost",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#30C1FF",
        cancelButtonColor: "#F8AFB8",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.history.goBack();
        }
      });
    } else {
      this.props.history.goBack();
    }
  };

  handleSubmit = (e, errors) => {
    e.preventDefault();

    const {
      category_id_fk,
      priority,
      complain_id,
      complain_status_id,
      crem_admin_pic_id,
    } = this.state.formData;

    if (errors.length === 0) {
      this.setState({ ...this.state, isLoading: true });

      const formData = {
        category_id_fk,
        priority,
        complain_id,
        complain_status_id,
        crem_admin_pic_id,
      };

      let dataLog = new URLSearchParams();
      dataLog.append("sender_id", crem_admin_pic_id);
      dataLog.append("complain_id_fk2", complain_id);
      dataLog.append("complain_status_id", complain_status_id);

      axios
        .put("/ComplainCrem", formData)
        .then((response) => {
          var status = response.data.status;
          if (status === true) {
            axios
              .post(`/ComplainLog`, dataLog)
              .then(() => {
                Swal.fire({
                  icon: "success",
                  text: "Complain has been updated.",
                  confirmButtonColor: "#30C1FF",
                });
                this.props.history.push(`/parastay/complain-list`);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  text: `Failed to update: ${error}`,
                  confirmButtonColor: "#30C1FF",
                });
              });
          } else {
            Swal.fire({
              icon: "error",
              text: `Failed to update: ${response.data.status.message}`,
              confirmButtonColor: "#30C1FF",
            });
          }
        })
        .catch((error) => {
          console.error(error.response.data.message);
          Swal.fire({
            icon: "error",
            text: `Failed to update: ${error.response.data.message}`,
            confirmButtonColor: "#30C1FF",
          });
          this.setState({ isLoading: false });
        });
    } else {
      console.error(errors);
    }
  };

  render() {
    const { formData, isLoading, categories, priorities, user, photos } =
      this.state;
    return (
      <>
        <Container fluid>
          <Card className="mb-4">
            {isLoading ? (
              <CardBody>
                <div
                  className="justify-content-center text-center"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    opacity: "0.5",
                    height: "100%",
                    width: "100%",
                    zIndex: "1100",
                  }}
                >
                  <Loader
                    type="Bars"
                    color="#30C1FF"
                    height={100}
                    width={100}
                  />
                </div>
              </CardBody>
            ) : (
              <Row>
                <Col md="4" xs="12">
                  <CardBody>
                    <RequesterDetail user={user} photos={photos} />
                  </CardBody>
                </Col>
                <Col md="8" xs="12">
                  <CardBody>
                    <h1 className="mb-3">Form Tindak Lanjut</h1>
                    <AvForm onSubmit={this.handleSubmit}>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1Input"
                            >
                              Item Pekerjaan
                            </label>
                            <AvField
                              type="select"
                              name="category_id_fk"
                              onChange={this.handleChange}
                              value={formData.category_id_fk}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please Select Category",
                                },
                              }}
                            >
                              <option value="" selected disabled>
                                -Pilh Item Pekerjaan-
                              </option>
                              {categories &&
                                categories.length > 0 &&
                                categories
                                  .filter(
                                    (category) =>
                                      category.complain_category_type === "CREM"
                                  )
                                  .map((category, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.complain_category_id}
                                      >
                                        {category.complain_category_name}
                                      </option>
                                    );
                                  })}
                            </AvField>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1Input"
                            >
                              Prioritas
                            </label>
                            <AvField
                              type="select"
                              name="priority"
                              onChange={this.handleChange}
                              value={formData.priority}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please Select Priority",
                                },
                              }}
                            >
                              <option value="" selected disabled>
                                -Pilh Prioritas-
                              </option>
                              {priorities &&
                                priorities.length > 0 &&
                                priorities.map((prior, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={prior.priority_id}
                                    >
                                      {prior.priority_name}
                                    </option>
                                  );
                                })}
                            </AvField>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Button color="info" type="submit" size="md">
                            Send
                          </Button>
                          <Button
                            color="secondary"
                            onClick={this.handleCancel}
                            size="md"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Col>
              </Row>
            )}
          </Card>
        </Container>
      </>
    );
  }
}
export default ComplainAssign;

const RequesterDetail = (props) => {
  const [collapse, setCollapse] = useState(true);

  const toggle = () => setCollapse(!collapse);

  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <Card style={{ backgroundColor: "#eeeef4" }}>
      <Row className="mx-2 my-3">
        <Col md="8" xs="8">
          <h3 className="mb-2">Detail Pemohon</h3>
        </Col>
        <Col md="4" xs="4">
          <Button
            className="text-info justify-content-right"
            onClick={toggle}
            style={{ marginBottom: "1rem", float: "right" }}
          >
            <i className="ni ni-bold-down" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={collapse}>
        <div>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-user mr-2 text-info" />
                  Nama Lengkap
                </td>
                <td className="text-wrap">{props.user.user_name}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-phone mr-2 text-info" />
                  Nomor Telepon
                </td>
                <td className="text-wrap">{props.user.user_phone}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-info-circle mr-2 text-info" />
                  Item Pekerjaan
                </td>
                <td className="text-wrap">
                  {props.user.complain_category_name}
                </td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-home mr-2 text-info" />
                  Lokasi
                </td>
                <td className="text-wrap">{props.user.location_name}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-home mr-2 text-info" />
                  Alamat Lokasi
                </td>
                <td className="text-wrap">{props.user.location_address}</td>
              </tr>
              <tr>
                <td width="10%" className="text-muted">
                  <i className="fas fa-map-marker-alt mr-2 text-info" />
                  Sub Lokasi
                </td>
                <td className="text-wrap">{props.user.sub_location}</td>
              </tr>
            </tbody>
          </Table>
          <Row className="mx-2 mb-4">
            <Col className="col-auto mt-3" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Deskripsi Komplain
              </h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p
                classname="text-wrap"
                style={{ fontSize: 13, fontWeight: 400 }}
              >
                {props.user.complain_desc}
              </p>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                {props.photos ? "Bukti Foto" : "Tidak Ada Bukti Foto"}
              </h4>
              <Slider {...settings}>
                {props.photos &&
                  props.photos.map((list, idx) => {
                    return (
                      <div>
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            style={{ maxHeight: "300px" }}
                            src={`https://parastay-api.pti-cosmetics.com/assets/upload_complain_photos/${list.photo}`}
                            alt={list.photo_id}
                          />
                        </Card>
                      </div>
                    );
                  })}
              </Slider>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
};
