/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Badge,
  ButtonGroup,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Table,
} from "reactstrap";
import { withGetScreen } from "react-getscreen";
// core components
import Swal from "sweetalert2";
import Slider from "react-slick";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import moment from "moment";
import { CSVLink } from "react-csv";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class ComplainListHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list_comp_history: [],
      list_detail_comp_history: "",
      alert: null,
      isLoading: true,
      isEmpty: false,
    };
  }

  componentDidMount() {
    this.props.setTitle("Complain List History");
    axios
      .get("/ManageComplainHistory")
      .then((response) => {
        this.setState({
          ...this.state,
          list_comp_history: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: "info",
            text: "List is empty!",
            confirmButtonColor: "#30C1FF",
          });
        }
      });
  }

  getDataTable(data, event) {
    const cookies = new Cookies();
    cookies.set("complain_id", data, { path: "/" });
  }

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      ),
    });
  };
  render() {
    const { isEmpty, isLoading } = this.state;

    const headers_table = [
      { label: "Tanggal", key: "complain_request_date" },
      { label: "Nama", key: "requestor" },
      { label: "Kategori", key: "complain_category_name" },
      { label: "Lokasi kerja", key: "work_area" },
      { label: "Lokasi", key: "location_name" },
      { label: "Sub Lokasi", key: "sub_location" },
      { label: "Status", key: "comp_status_name" },
      { label: "Tanggal proses", key: "process_date" },
    ];

    return (
      <>
        {this.state.alert}
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                {isLoading && !isEmpty ? (
                  <div
                    className="justify-content-center text-center"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      opacity: "0.5",
                      height: "100%",
                      width: "100%",
                      zIndex: "1100",
                    }}
                  >
                    <Loader
                      type="Bars"
                      color="#30C1FF"
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <>
                    {isEmpty ? (
                      <CardBody>
                        <h1>Complain List History is Empty !</h1>
                      </CardBody>
                    ) : (
                      <ToolkitProvider
                        data={this.state.list_comp_history}
                        keyField="name"
                        columns={[
                          {
                            dataField: "complain_id",
                            text: "ID",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "complain_request_date",
                            text: "Tanggal",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  {moment(row.complain_request_date).format(
                                    "DD MMMM YYYY"
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            dataField: "requestor",
                            text: "Nama",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "complain_category_name",
                            text: "Kategori",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "work_area",
                            text: "Lokasi Kerja",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "location_name",
                            text: "Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "sub_location",
                            text: "Sub Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "databasePkey",
                            text: "Details",
                            editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <ComplainDetail
                                    det_complain_id={row.complain_id}
                                  />
                                </>
                              );
                            },
                          },
                          {
                            dataField: "databasePkey",
                            text: "Action",
                            editable: false,
                            formatter: (cell, row) => {
                              if (
                                row.complain_status_id === 5 ||
                                row.complain_status_id === 6
                              ) {
                              } else {
                                return (
                                  <>
                                    <Link
                                      to="/parastay/complain-update-ga"
                                      className="text-primary mr-2"
                                      title="More Info"
                                      onClick={this.getDataTable.bind(
                                        this,
                                        row.complain_id
                                      )}
                                    >
                                      Update
                                    </Link>
                                  </>
                                );
                              }
                            },
                          },
                          {
                            dataField: "databasePkey",
                            text: "Status",
                            editable: false,
                            formatter: (cell, row) => {
                              if (row.complain_status_id === 5) {
                                return (
                                  <>
                                    <Badge color="success" pill>
                                      {row.comp_status_name}
                                    </Badge>
                                  </>
                                );
                              } else if (row.complain_status_id === 6) {
                                return (
                                  <>
                                    <Badge color="danger" pill>
                                      {row.comp_status_name}
                                    </Badge>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <Badge color="primary" pill>
                                      {row.comp_status_name}
                                    </Badge>
                                  </>
                                );
                              }
                            },
                          },
                          {
                            dataField: "process_date",
                            text: "Tanggal Proses",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              if (row.process_date === null) {
                                return <>-</>;
                              } else {
                                return (
                                  <>
                                    {moment(row.process_date).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </>
                                );
                              }
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <Container fluid>
                              <Row>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <CSVLink
                                      data={this.state.list_comp_history}
                                      headers={headers_table}
                                      filename={"Complain list history.csv"}
                                    >
                                      <Button
                                        style={{ marginRight: "5px" }}
                                        color="default"
                                        size="sm"
                                        className="buttons-copy buttons-html5"
                                        id="print-tooltip"
                                      >
                                        Export to CSV
                                      </Button>
                                    </CSVLink>

                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="default"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          this.copyToClipboardAsTable(
                                            document.getElementById(
                                              "react-bs-table"
                                            )
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => this.componentRef}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <label>
                                      Search:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={(el) => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                              filter={filterFactory()}
                              id="react-bs-table"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withGetScreen(ComplainListHistory);

const ComplainDetail = (props) => {
  const [modal, setModal] = useState(false);
  const [detailcomplain, setDetailcomplain] = React.useState(null);
  const [photocomplain, setPhotocomplain] = React.useState(null);
  const [toggleOpen, setToggleopen] = React.useState(null);
  const toggle = () => setModal(!modal);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  const complain_id = props.det_complain_id;

  const handleClick = () => {
    axios
      .get(`/ManageComplainHistory?complain_id=${complain_id}`)
      .then((response) => {
        setDetailcomplain(response.data.data[0]);
        setToggleopen(toggle);
      })
      .catch((error) => {});

    axios
      .get(`/ComplainCremPhoto?complain_id=${complain_id}`)
      .then((response) => {
        setPhotocomplain(response.data.data);
        setToggleopen(toggle);
      })
      .catch((error) => {});
  };

  if (detailcomplain === null)
    return (
      <>
        <Button color="primary" size="md" onClick={handleClick}>
          <i className="fa fa-info-circle" />
        </Button>
      </>
    );

  return (
    <div>
      <Button color="primary" size="md" onClick={handleClick}>
        <i className="fa fa-info-circle" />
      </Button>

      <Modal isOpen={modal} toggle={toggleOpen} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Complain Detail #{detailcomplain.complain_id}
        </ModalHeader>
        <ModalBody>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-user" />
                </td>
                <td width="40%" className="text-muted">
                  Nama Lengkap
                </td>
                <td className="text-wrap">{detailcomplain.requestor}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-phone" />
                </td>
                <td width="40%" className="text-muted">
                  Nomor Telepon
                </td>
                <td className="text-wrap">{detailcomplain.user_phone}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-info-circle" />
                </td>
                <td width="40%" className="text-muted">
                  Item Pekerjaan
                </td>
                <td className="text-wrap">
                  {detailcomplain.complain_category_name}
                </td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-home" />
                </td>
                <td width="40%" className="text-muted">
                  Lokasi
                </td>
                <td className="text-wrap">{detailcomplain.location_name}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-map-marker-alt" />
                </td>
                <td width="40%" className="text-muted">
                  Sub Lokasi
                </td>
                <td className="text-wrap">{detailcomplain.sub_location}</td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Deskripsi Komplain
              </h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p
                classname="text-wrap"
                style={{ fontSize: 13, fontWeight: 400 }}
              >
                {detailcomplain.complain_desc}
              </p>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Bukti Foto
              </h4>
              <Slider {...settings}>
                {photocomplain?.map((list, idx) => {
                  return (
                    <div>
                      <Card>
                        <CardImg
                          top
                          width="100%"
                          style={{ maxHeight: "300px" }}
                          src={`https://parastay-api.pti-cosmetics.com/assets/upload_complain_photos/${list.photo}`}
                          alt={list.photo_id}
                        />
                      </Card>
                    </div>
                  );
                })}
              </Slider>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="success" onClick={toggle}>Assign</Button>{' '} */}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
