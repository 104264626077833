import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import Axios from "utils/axiosService";

function MonitoringFilter({ filter, handleSubmit, isAula, history }) {
  const [location, setLocation] = useState([]);
  const [room, setRoom] = useState([]);
  const [filteredRoom, setFilteredRoom] = useState([]);
  const { pathname, search } = history.location;
  const handleReset = (e) => {
    history.replace(pathname);
    handleSubmit(e);
  };

  useEffect(() => {
    if (location.length === 0) {
      Axios({
        method: "GET",
        url: `/area?room_type=${isAula ? "Aula" : "Ruang Meeting"}&order_column=id&order_sequence=ASC`,
      })
        .then((r) => {
          if (!r.data.errorStatus) {
            setLocation(r.data.data.data);
          } else {
            throw new Error("Error fetching data");
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  }, [location, isAula]);

  useEffect(() => {
    Axios({
      method: "GET",
      url: `/room?type=${isAula ? "Aula" : "Ruang Meeting"}&order_column=id&order_sequence=ASC`,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setRoom(r.data.data.data);
        } else {
          throw new Error("Error fetching data");
        }
      })
      .catch((error) => {
        console.error(error.response);
      });
  }, [isAula]);

  useEffect(() => {
    if (filter.location.length > 0 && room.length > 0) {
      setFilteredRoom(room.filter((d) => d.area_id === filter.location));
    }
  }, [filter.location, room]);

  return (
    <form
      className="d-flex w-100"
      style={{ gap: "60px" }}
      onSubmit={handleSubmit}
    >
      <div className="d-flex" style={{ gap: "24px" }}>
        <FormGroup className="d-flex" style={{ gap: "12px" }}>
          <Label for="location">Location</Label>
          <Input
            bsSize="sm"
            id="location"
            name="location"
            type="select"
            value={filter.location}
            onChange={(e) => {
              const filter = new URLSearchParams(search);
              filter.set("loc", e.target.value);
              console.log(filter.toString());
              history.replace(`${pathname}?${filter.toString()}`);
            }}
          >
            <option selected value="default">
              Select Location
            </option>
            {location.map((r, i) => (
              <option key={i} value={r.id}>
                {r.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="d-flex" style={{ gap: "12px" }}>
          <Label for="room-name" style={{ whiteSpace: "nowrap" }}>
            Room Name
          </Label>
          <Input
            disabled={filter.location === "default"}
            bsSize="sm"
            id="room-name"
            name="room-name"
            type="select"
            value={filter.room}
            onChange={(e) => {
              const filter = new URLSearchParams(search);
              filter.set("room", e.target.value);
              history.replace(`${pathname}?${filter.toString()}`);
            }}
          >
            <option selected value="default">
              Select Room
            </option>
            {filteredRoom.map((r, i) => (
              <option key={i} value={r.id}>
                {r.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      </div>
      <div className="d-flex h-25">
        <Button
          size="sm"
          outline
          color="danger"
          onClick={handleReset}
          style={{ border: "0px" }}
        >
          Reset
        </Button>
        <Button size="sm" type="submit" color="primary">
          Filter
        </Button>
      </div>
    </form>
  );
}

export default MonitoringFilter;
