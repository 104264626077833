import Monitoring from "views/pages/booking_car/Monitoring";
import FormReturn from "views/pages/booking_car/FormReturn";

const routes = [
  {
    path: "/booking-monitoring",
    name: "Booking Car Monitoring",
    icon: "bi bi-laptop",
    component: Monitoring,
    layout: "/booking-car",
  },
  {
    path: "/return/:bookingId/",
    name: "Form Return",
    icon: "bi bi-laptop",
    component: FormReturn,
    layout: "/booking-car",
    redirect: true,
  },
];

export default routes;
