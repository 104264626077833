/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
// core components
import {
  AvForm,
  AvGroup,
  AvInput,
  AvField,
  AvFeedback,
} from "availity-reactstrap-validation";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";

class UserAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user_name: "",
      user_email: "",
      user_phone: "",
      user_gender: "",
      position_id_fk: "",
      department_id_fk: "",
      division_id_fk: "",
      directorate_id_fk: "",
      user_birth: "",
      user_birth_place: "",
      work_location_id_fk: "",
      user_role: null,
      user_nik: "",
      isLoading: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.props.setTitle("Add New User");
    axios
      .all([
        axios.get("/Genderlist"),
        axios.get("/Directoratelist"),
        axios.get("/Departmentlist"),
        axios.get("/Divisionlist"),
        axios.get("/Positionlist"),
        axios.get("/Rolelist"),
      ])
      .then(
        axios.spread(
          (genderResp, dirResp, deptResp, divResp, posResp, roleResp) => {
            const gender = genderResp.data.data[0];
            if (gender) {
              this.setState({
                isLoading: false,
                genders: genderResp.data.data,
                dirs: dirResp.data.data,
                depts: deptResp.data.data,
                divs: divResp.data.data,
                positions: posResp.data.data,
                roles: roleResp.data.data,
              });
            }
          }
        )
      )
      .catch((error) => {
        console.log(error.response);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCancel = () => {
    Swal.fire({
      title: "Are you sure you want to go back?",
      text: "If you leave before saving, your changes will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#30C1FF",
      cancelButtonColor: "#F8AFB8",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.history.goBack();
      }
    });
  };

  handleClick = () => {
    this.setState({ ...this.state, hidden: !this.state.hidden });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/Userlist", this.state)
      .then((response) => {
        var status = response.data.status;
        if (status === true) {
          Swal.fire({
            icon: "success",
            text: "User has been created.",
            confirmButtonColor: "#30C1FF",
          });
          this.props.history.push("/parastay/user-list");
        } else {
          Swal.fire({
            icon: "error",
            text: "Error to create",
            confirmButtonColor: "#30C1FF",
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          text: "failed to input user!",
          confirmButtonColor: "#30C1FF",
        });
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      user_name,
      user_email,
      user_phone,
      user_gender,
      position_id_fk,
      department_id_fk,
      division_id_fk,
      directorate_id_fk,
      user_birth,
      user_birth_place,
      work_location_id_fk,
      user_role,
      user_nik,
      isLoading,
      genders,
      dirs,
      divs,
      depts,
      positions,
      roles,
    } = this.state;
    return (
      <>
        <Container fluid>
          <Card>
            {isLoading ? (
              <CardBody>
                <div
                  className="justify-content-center text-center"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    opacity: "0.5",
                    height: "100%",
                    width: "100%",
                    zIndex: "1100",
                  }}
                >
                  <Loader
                    type="Bars"
                    color="#30C1FF"
                    height={100}
                    width={100}
                  />
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <AvForm onSubmit={this.handleSubmit}>
                  <h2 className="mb-4">Informasi User</h2>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Nama Lengkap
                          </label>
                          <AvInput
                            placeholder="Nama Lengkap..."
                            type="text"
                            name="user_name"
                            onChange={this.handleChange}
                            value={user_name}
                            required
                          />
                          <AvFeedback>Please Insert Full Name</AvFeedback>
                        </AvGroup>
                      </Col>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            NIK
                          </label>
                          <AvInput
                            placeholder="NIK..."
                            name="user_nik"
                            onChange={this.handleChange}
                            value={user_nik}
                            required
                          />
                          <AvFeedback>Please Insert NIK</AvFeedback>
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Tempat Lahir
                          </label>
                          <AvInput
                            placeholder="Tempat Lahir..."
                            type="text"
                            name="user_birth_place"
                            onChange={this.handleChange}
                            value={user_birth_place}
                          />
                        </AvGroup>
                      </Col>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Tanggal Lahir
                          </label>
                          <AvInput
                            type="text"
                            placeholder="dd-mm-yyyy"
                            name="user_birth"
                            onChange={this.handleChange}
                            value={user_birth}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Jenis Kelamin
                          </label>
                          <AvField
                            type="select"
                            name="user_gender"
                            onChange={this.handleChange}
                            value={user_gender}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please Select Gender",
                              },
                            }}
                          >
                            <option value="" selected disabled>
                              Pilh Jenis Kelamin...
                            </option>
                            {genders &&
                              genders.length > 0 &&
                              genders.map((gender, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={gender.gender_name}
                                  >
                                    {gender.gender_name}
                                  </option>
                                );
                              })}
                          </AvField>
                        </AvGroup>
                      </Col>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Role
                          </label>
                          <AvField
                            type="select"
                            name="user_role"
                            onChange={this.handleChange}
                            value={user_role}
                          >
                            <option value="" selected disabled>
                              Pilh Role untuk admin...
                            </option>
                            {roles &&
                              roles.length > 0 &&
                              roles.map((role, index) => {
                                return (
                                  <option key={index} value={role.role_id}>
                                    {role.role_name}
                                  </option>
                                );
                              })}
                          </AvField>
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Direktorat
                          </label>
                          <AvField
                            type="select"
                            name="directorate_id_fk"
                            onChange={this.handleChange}
                            value={directorate_id_fk}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please Select Directorate",
                              },
                            }}
                          >
                            <option value="" selected disabled>
                              Pilih Direktorat...
                            </option>
                            {dirs &&
                              dirs.length > 0 &&
                              dirs.map((dir, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={dir.directorate_name}
                                  >
                                    {dir.directorate_name}
                                  </option>
                                );
                              })}
                          </AvField>
                        </AvGroup>
                      </Col>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Divisi
                          </label>
                          <AvField
                            type="select"
                            name="division_id_fk"
                            onChange={this.handleChange}
                            value={division_id_fk}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please Select Division",
                              },
                            }}
                          >
                            <option value="" selected disabled>
                              Pilih Divisi...
                            </option>
                            {divs &&
                              divs.length > 0 &&
                              divs.map((div, index) => {
                                return (
                                  <option key={index} value={div.division_name}>
                                    {div.division_name}
                                  </option>
                                );
                              })}
                          </AvField>
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Departemen
                          </label>
                          <AvField
                            type="select"
                            name="department_id_fk"
                            onChange={this.handleChange}
                            value={department_id_fk}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please Select Department",
                              },
                            }}
                          >
                            <option value="" selected disabled>
                              Pilih Departemen...
                            </option>
                            {depts &&
                              depts.length > 0 &&
                              depts.map((dept, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={dept.department_name}
                                  >
                                    {dept.department_name}
                                  </option>
                                );
                              })}
                          </AvField>
                        </AvGroup>
                      </Col>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Posisi
                          </label>
                          <AvField
                            type="select"
                            name="position_id_fk"
                            onChange={this.handleChange}
                            value={position_id_fk}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please Select Position",
                              },
                            }}
                          >
                            <option value="" selected disabled>
                              Pilih Posisi...
                            </option>
                            {positions &&
                              positions.length > 0 &&
                              positions.map((position, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={position.position_name}
                                  >
                                    {position.position_name}
                                  </option>
                                );
                              })}
                          </AvField>
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Lokasi Kerja
                          </label>
                          <AvField
                            placeholder="Lokasi kerja..."
                            name="work_location_id_fk"
                            type="text"
                            onChange={this.handleChange}
                            value={work_location_id_fk}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please insert work location",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                  </div>

                  <h2 className="mb-4">Informasi Kontak</h2>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Nomor Telepon
                          </label>
                          <AvField
                            placeholder="Phone number..."
                            name="user_phone"
                            onChange={this.handleChange}
                            value={user_phone}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please insert phone number",
                              },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage:
                                  "Your phone number must be composed only with numbers",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col lg="4">
                        <AvGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Email
                          </label>
                          <AvInput
                            placeholder="Email..."
                            type="email"
                            name="user_email"
                            onChange={this.handleChange}
                            value={user_email}
                            required
                          />
                          <AvFeedback>Please Insert Email</AvFeedback>
                        </AvGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <Button
                        color="danger"
                        onClick={this.handleCancel}
                        size="md"
                      >
                        Back
                      </Button>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button color="info" type="submit" size="md">
                        Send
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            )}
          </Card>
        </Container>
      </>
    );
  }
}

export default UserAdd;
