import React, { useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";

import Swal from "sweetalert2";
import AddContactForm from "components/ContentManagement/contact/AddContactForm";

function AddContactPerson({setTitle, history}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    contact_name: "",
    description: "",
    contact_number: "",
    contact_link: "",
    contact_type: ""
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(formValue.contact_name && formValue.contact_number && formValue.contact_link){
      setIsLoading(true);
    Axios({
      method: "POST",
      url: "/contact-us",
      data: {
        contact_name: formValue.contact_name,
        contact_number: formValue.contact_number,
        description: formValue.description,
        contact_type: formValue.contact_type,
        contact_link: formValue.contact_link
      },
    })
      .then((r) => {
        if (!r?.data?.errorStatus) {
          Swal.fire({
            icon: "success",
            text: `Berhasil menambahkan contact`,
            confirmButtonColor: "#30C1FF",
          });
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: `Gagal menambahkan contact`,
          confirmButtonColor: "#30C1FF",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
            history.goBack();
          }, 1000);
      });
    }
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <AddContactForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              formValue={formValue}
            />
          )}
          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default AddContactPerson;
