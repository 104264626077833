/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardImg,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { withGetScreen } from "react-getscreen";
// core components
// import SimpleHeader from "components/Headers/SimpleHeader.js";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import axios from "axios";
import Cookies from "universal-cookie";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import moment from "moment";
import { CSVLink } from "react-csv";

const initialState = {
  solution: "",
  complain_id: "",
  complain_status_id: "",
  complain_start_date: "",
  complain_end_date: "",
  ga_admin_pic_id: "",
  crem_admin_pic_id: "",
  category_id_fk: "",
};

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class ComplainList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list_complain: [],
      list_ga: [],
      list_detail: "",
      memberGA: "",
      alert: null,
      isLoading: true,
      isEmpty: false,
      formData: initialState,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.setTitle("Complain List");
    axios
      .get("/ManageComplain")
      .then((response) => {
        this.setState({
          ...this.state,
          list_complain: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: "info",
            text: "List is empty!",
            confirmButtonColor: "#30C1FF",
          });
        }
      });
  }

  handleChange = (e) => {
    this.setState({
      memberGA: e.target.value
    })
  };

  getDataTable(data, event) {
    const cookies = new Cookies();
    cookies.set("complain_id", data, { path: "/" });
  }

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      ),
    });
  };
  render() {
    const { isLoading, isEmpty, memberGA } = this.state;

    const headers_table = [
      { label: "Tanggal", key: "complain_request_date" },
      { label: "Nama", key: "requestor" },
      { label: "Kategori", key: "complain_category_name" },
      { label: "Lokasi kerja", key: "work_area" },
      { label: "Lokasi", key: "location_name" },
      { label: "Sub Lokasi", key: "sub_location" },
    ];

    return (
      <>
        {this.state.alert}
        {/* <SimpleHeader name="Complain List" parentName="Crem Management" /> */}
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                {isLoading && !isEmpty ? (
                  <div
                    className="justify-content-center text-center"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      opacity: "0.5",
                      height: "100%",
                      width: "100%",
                      zIndex: "1100",
                    }}
                  >
                    <Loader
                      type="Bars"
                      color="#30C1FF"
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <>
                    {isEmpty ? (
                      <CardBody>
                        <h1>Complain List is Empty !</h1>
                      </CardBody>
                    ) : (
                      <ToolkitProvider
                        data={this.state.list_complain}
                        keyField="name"
                        columns={[
                          {
                            dataField: "complain_id",
                            text: "ID",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "complain_request_date",
                            text: "Tanggal",
                            sort: true,
                            editable: true,
                            filter: dateFilter(),
                            formatter: (cell, row) => {
                              return (
                                <>
                                  {moment(row.complain_request_date).format(
                                    "DD MMMM YYYY HH:mm:ss"
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            dataField: "requestor",
                            text: "Nama",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "complain_category_name",
                            text: "Kategori",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "work_area",
                            text: "Lokasi Kerja",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "location_name",
                            text: "Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "sub_location",
                            text: "Sub Lokasi",
                            sort: true,
                            editable: true,
                            filter: textFilter(),
                          },
                          {
                            dataField: "databasePkey",
                            text: "Details",
                            editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <ComplainDetail
                                    det_complain_id={row.complain_id}
                                    changeMember={this.handleChange}
                                    memberGA={memberGA}
                                  />
                                </>
                              );
                            },
                          },
                          {
                            dataField: "databasePkey",
                            text: "Actions",
                            editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <Row>
                                    <Button
                                      href="/parastay/complain-assign-ga"
                                      size="md"
                                      color="success"
                                      onClick={this.getDataTable.bind(
                                        this,
                                        row.complain_id
                                      )}
                                      className="mb-1"
                                    >
                                      Assign
                                    </Button>
                                    <DeclineComplain
                                      id_complain={row.complain_id}
                                      category_id_fk={row.category_id_fk}
                                    />
                                  </Row>
                                </>
                              );
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <Container fluid>
                              <Row>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <CSVLink
                                      data={this.state.list_complain}
                                      headers={headers_table}
                                      filename={"Complain list.csv"}
                                    >
                                      <Button
                                        style={{ marginRight: "5px" }}
                                        color="default"
                                        size="sm"
                                        className="buttons-copy buttons-html5"
                                        id="print-tooltip"
                                      >
                                        Export to CSV
                                      </Button>
                                    </CSVLink>

                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="default"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          this.copyToClipboardAsTable(
                                            document.getElementById(
                                              "react-bs-table"
                                            )
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => this.componentRef}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <label>
                                      Search:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={(el) => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                              filter={filterFactory()}
                              id="react-bs-table"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withGetScreen(ComplainList);

const ComplainDetail = (props) => {
  const [modal, setModal] = useState(false);
  const [detailcomplain, setDetailcomplain] = React.useState(null);
  const [photocomplain, setPhotocomplain] = React.useState(null);
  const [toggleOpen, setToggleopen] = React.useState(null);

  const toggle = () => setModal(!modal);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  const complain_id = props.det_complain_id;

  const handleClick = () => {
    axios
      .get(`/ManageComplain?complain_id=${complain_id}`)
      .then((response) => {
        setDetailcomplain(response.data.data[0]);
        setToggleopen(toggle);
      })
      .catch((error) => {});

    axios
      .get(`/ComplainCremPhoto?complain_id=${complain_id}`)
      .then((response) => {
        setPhotocomplain(response.data.data);
        setToggleopen(toggle);
      })
      .catch((error) => {});
  };

  if (detailcomplain === null)
    return (
      <>
        <Button color="primary" size="md" onClick={handleClick}>
          <i className="fa fa-info-circle" />
        </Button>
      </>
    );

  return (
    <div>
      <Button color="primary" size="md" onClick={handleClick}>
        <i className="fa fa-info-circle" />
      </Button>

      <Modal isOpen={modal} toggle={toggleOpen} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Complain Detail #{detailcomplain.complain_id}
        </ModalHeader>
        <ModalBody>
          <Table borderless size="sm" className="mt--4">
            <tbody>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-user" />
                </td>
                <td width="40%" className="text-muted">
                  Nama Lengkap
                </td>
                <td className="text-wrap">{detailcomplain.requestor}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-phone" />
                </td>
                <td width="40%" className="text-muted">
                  Nomor Telepon
                </td>
                <td className="text-wrap">{detailcomplain.user_phone}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-info-circle" />
                </td>
                <td width="40%" className="text-muted">
                  Item Pekerjaan
                </td>
                <td className="text-wrap">
                  {detailcomplain.complain_category_name}
                </td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-home" />
                </td>
                <td width="40%" className="text-muted">
                  Lokasi
                </td>
                <td className="text-wrap">{detailcomplain.location_name}</td>
              </tr>
              <tr>
                <td width="1%" className="text-info">
                  <i className="fas fa-map-marker-alt" />
                </td>
                <td width="40%" className="text-muted">
                  Sub Lokasi
                </td>
                <td className="text-wrap">{detailcomplain.sub_location}</td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Deskripsi Komplain
              </h4>
            </Col>
            <Col className="col-auto mt--1" md="12" xs="12">
              <p
                classname="text-wrap"
                style={{ fontSize: 13, fontWeight: 400 }}
              >
                {detailcomplain.complain_desc}
              </p>
            </Col>
            <Col className="col-auto" md="12" xs="12">
              <h4 classname="text-muted" style={{ fontSize: 13 }}>
                Bukti Foto
              </h4>
              <Slider {...settings}>
                {photocomplain?.map((list, idx) => {
                  return (
                    <div>
                      <Card>
                        <CardImg
                          top
                          width="100%"
                          style={{ maxHeight: "300px" }}
                          src={`https://parastay-api.pti-cosmetics.com/assets/upload_complain_photos/${list.photo}`}
                          alt={list.photo_id}
                        />
                      </Card>
                    </div>
                  );
                })}
              </Slider>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="success" onClick={toggle}>Assign</Button>{' '} */}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const DeclineComplain = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [toggleOpen, setToggleopen] = React.useState(null);
  const id_status = 6;
  const id_complain = props.id_complain;
  const category_id_fk = props.category_id_fk;
  const id_user = localStorage.getItem("id_user");

  const [values, setValues] = React.useState({
    notes: null,
  });

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const handleClick = () => {
    setToggleopen(toggle);
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleDecline = () => {
    let params = new URLSearchParams();
    params.append("solution", values?.notes);
    params.append("complain_status_id", id_status);
    params.append("complain_id", id_complain);
    params.append("category_id_fk", category_id_fk);

    let dataLog = new URLSearchParams();
    dataLog.append("sender_id", id_user);
    dataLog.append("complain_status_id", id_status);
    dataLog.append("complain_id_fk2", id_status);
    dataLog.append("log", values?.notes);

    axios
      .post(`/ManageComplain`, params)
      .then((response) => {
        var status = response.data.status;
        if (status === true) {
          axios
            .post(`/ComplainLog`, dataLog)
            .then((response) => {
              if (response.data.status === true) {
                Swal.fire({
                  icon: "success",
                  text: "Complain Log has been updated.",
                  confirmButtonColor: "#30C1FF",
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Error to update log",
                  confirmButtonColor: "#30C1FF",
                });
              }
            })
            .catch((error) => {});
        } else {
          Swal.fire({
            icon: "error",
            text: "Error to update",
            confirmButtonColor: "#30C1FF",
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          text: "Failed to update!",
          confirmButtonColor: "#30C1FF",
        });
        this.setState({ isLoading: false });
      });
  };

  return (
    <div>
      <Button
        size="md"
        color="danger"
        className="mr-2 mb-1"
        onClick={handleClick}
      >
        Decline
      </Button>

      <Modal isOpen={modal} toggle={toggleOpen} centered>
        <AvForm onSubmit={handleDecline}>
          <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 className="mb-0">Tolak komplain ini?</h4>
          </div>
          <ModalBody>
            <Row className="align-items-center">
              <Col md="12" xs="12">
                <AvGroup>
                  <AvInput
                    placeholder="Tuliskan catatan"
                    name="notes"
                    type="textarea"
                    onChange={handleChange}
                    value={values.notes}
                    required
                  />
                  <AvFeedback>Please Insert Reason !</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Send
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </div>
  );
};
