import DateRangeIcon from "@mui/icons-material/DateRange";
import React, { useEffect, useRef, useState } from "react";
import { formatDate } from "utils/util";

const FilterDate = ({ searchParams, label, objectKey, setSearchParams }) => {

  const refInput = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    searchParams[objectKey] || ""
  );
  const startDate = searchParams["filter_start_usage"] || "";
  const endDate = searchParams["filter_end_usage"] || "";

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const newSearchParams = { ...searchParams };

        if (!!selectedDate) {
            setSelectedDate(selectedDate);
            newSearchParams[objectKey] = formatDate(selectedDate);
        } else {
            setSelectedDate(null);
            newSearchParams[objectKey] = "";
        }

        setSearchParams(newSearchParams);
    };

  const handleButtonClick = () => {
    refInput.current.showPicker();
  };

  useEffect(() => {
    if (label === "Tanggal Akhir" && startDate) {
      refInput.current.min = formatDate(startDate);
    }

    if (label === "Tanggal Awal" && endDate) {
      refInput.current.max = formatDate(endDate);
    }
  }, [label, startDate, endDate]);

  return (
    <div>
      <input
        type="date"
        ref={refInput}
        style={{
          visibility: "hidden",
          position: "absolute",
          margin: "20px 0",
        }}
        value={selectedDate}
        min={
          label === "Tanggal Akhir" && startDate ? formatDate(startDate) : ""
        }
        max={label === "Tanggal Awal" && endDate ? formatDate(endDate) : ""}
        onChange={handleDateChange}
        onInput={handleDateChange}
      />
      <button
        color="black"
        style={{
          height: "42px",
          background: "white",
          border: "2px solid #EFEFEF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
          display: "flex",
          borderRadius: "5px",
        }}
        onClick={handleButtonClick}
      >
        <DateRangeIcon style={{ color: "grey" }} />
        <span style={{ margin: "0 10px", color: "grey", fontSize: "14px" }}>
          {selectedDate || label}
        </span>
      </button>
    </div>
  );
};

export default FilterDate;
