import React, { useState } from "react";
import Axios from "utils/axiosService";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button,
  ModalFooter,
} from "reactstrap";

import Loader from "react-loader-spinner";

import CarFormFields from "components/bookingCar/CarFormFields";
import { isValidLicensePlate } from "components/bookingCar/CarFormFields";

import { useSnackbar } from "components/Snackbar/snackbar";

const AddCarModal = ({ isOpen, toggle }) => {
  const { setSnackbarState } = useSnackbar();
  const [carImage, setCarImage] = useState(null);
  const [carImagePreview, setCarImagePreview] = useState(null);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [carData, setCarData] = useState({
    name: "",
    brand: "",
    licensePlate: "",
    licenseExpiry: "",
    fuel: "",
    capacity: "",
    currentLocation: "",
    locationURL: "",
    maintenanceStatus: "",
    carStatus: "",
  });

  const isValidForm = () => {
    return (
      Object.values(carData).every((value) => value !== "") &&
      isValidLicensePlate(carData.licensePlate)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoadingSubmit(true);

    const formData = new FormData();
    formData.append("name", carData.name);
    formData.append("brand", carData.brand);
    formData.append("license_plate", carData.licensePlate);
    formData.append("license_expiry", carData.licenseExpiry);

    const lastNumberPlate = carData.licensePlate.split(" ")[1].slice(-1);
    formData.append(
      "license_plate_type",
      lastNumberPlate % 2 === 0 ? "genap" : "ganjil"
    );

    formData.append("fuel", carData.fuel);
    formData.append("capacity", carData.capacity);
    formData.append("current_location", carData.currentLocation);
    formData.append("location_url", carData.locationURL);
    formData.append(
      "is_active",
      carData.carStatus === "Aktif" ? "true" : "false"
    );
    formData.append(
      "car_status",
      carData.maintenanceStatus === "Ya" ? "maintenance" : "available"
    );
    if (carImage) {
      formData.append("image", carImage);
    }

    Axios({
      method: "POST",
      url: "/admin/car",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((r) => {
        setSnackbarState({
          open: true,
          message: "Berhasil menambahkan mobil",
          severity: "success",
        });
        toggle();
      })
      .catch((error) => {
        setSnackbarState({
          open: true,
          message: `Gagal menambahkan mobil: ${error.response.data.errors[0].message}`,
          severity: "error",
        });
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fade={false}
      style={{
        height: "100vh",
        minWidth: "75vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        padding: "0",
      }}
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>
          <p className="fw-bold mb-0" style={{ fontSize: "24px" }}>
            Tambah Mobil
          </p>
        </ModalHeader>
        <ModalBody style={{ paddingTop: "0" }}>
          <CarFormFields
            carData={carData}
            setCarData={setCarData}
            carImage={carImage}
            setCarImage={setCarImage}
            carImagePreview={carImagePreview}
            setCarImagePreview={setCarImagePreview}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            disabled={!isValidForm() || isLoadingSubmit}
            style={{
              width: "200px",
              color: "white",
              background: isValidForm() ? "#3E7CA8" : "#AAAAAA",
            }}
          >
            {isLoadingSubmit ? (
              <Loader type="Oval" color="white" height={20} width={20} />
            ) : (
              "Tambahkan Mobil"
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddCarModal;
