/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Button } from "reactstrap";

import LobbyMenuFooter from "components/Footers/LobbyMenuFooter";
import LobbyHeader from "components/Headers/LobbyHeader";
import LobbyHeaderProfile from "components/Headers/LobbyHeaderProfile";
import AdminListTable from "components/ManageAdmin/AdminListTable";
import FormModal from "components/ManageAdmin/FormModal";
import Axios from "utils/axiosService";

function ManageAdmin(props) {
  const [token] = useState(localStorage.getItem("token"));
  const [isOpenAddAdminModal, setIsOpenAddAdminModal] = useState(false);
  const [isOpenEditAdminModal, setIsOpenEditAdminModal] = useState(false);
  const [isOpenRevokeAdminModal, setIsOpenRevokeAdminModal] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [userData, setUserData] = useState();
  const [appRelationData, setAppRelationData] = useState([]);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isLoadingAdminData, setIsLoadingAdminData] = useState(true);
  const [isLoadingAppRelationData, setIsLoadingAppRelationData] =
    useState(true);
  const [appData, setAppData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    role: "",
    access: [],
  });

  useEffect(() => {
    if (userData?.role?.toLowerCase() === "admin") {
      props.history.replace("/");
    }
  }, [userData, refetch]);

  useEffect(() => {
    setAppRelationData([]);
    setIsLoadingAppRelationData(true);
  }, [refetch]);

  useEffect(() => {
    setIsLoadingUserData(true);
    const id_user = localStorage.getItem("id_user");
    Axios({
      method: "GET",
      url: `/admin?user_id=${id_user}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setUserData(r.data.data.data[0]);
        } else {
          throw new Error("Error fetching user data");
        }
      })
      .catch((error) => {
        console.error(error.response);
        if (error.response?.status === 401) {
          window.location.assign("./logout");
        }
      })
      .finally(() => {
        setIsLoadingUserData(false);
      });
  }, [refetch]);

  useEffect(() => {
    Axios({
      method: "GET",
      url: "/app?status=true",
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setAppData(r.data.data.data);
        } else {
          throw new Error("Error fetching app data");
        }
      })
      .catch((e) => {
        console.error(e);
        if (e.response?.status === 401) {
          window.location.assign("./logout");
        }
      });
  }, []);

  useEffect(() => {
    setIsLoadingAdminData(true);
    Axios({
      method: "GET",
      url: "/admin",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error fetching admin data");
        } else {
          setAdminData(r.data.data.data ?? []);
        }
      })
      .catch((e) => {
        console.error(e);
        if (e.response?.status === 401) {
          window.location.assign("./logout");
        }
      })
      .finally(() => {
        setIsLoadingAdminData(false);
      });
  }, [refetch]);

  useEffect(() => {
    if (!isOpenEditAdminModal) {
      setFormValue({
        name: "",
        email: "",
        role: "",
        access: [],
      });
    }
  }, [isOpenEditAdminModal]);

  useEffect(() => {
    if (!isLoadingAdminData) {
      setIsLoadingAppRelationData(true);
      Axios({
        method: "GET",
        url: "/admin-relation-app",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((r) => {
          if (r.data.errorStatus) {
            throw new Error("Error fetching admin relation app data");
          } else {
            setAppRelationData(r.data.data.data ?? []);
          }
        })
        .catch((e) => {
          console.error(e);
          if (e.response.status === 401) {
            window.location.assign("./logout");
          }
        })
        .finally(() => {
          setIsLoadingAppRelationData(false);
        });
    }
  }, [isLoadingAdminData]);

  useEffect(() => {
    if (!isLoadingAppRelationData && appRelationData.length > 0) {
      let mappedAppData = adminData.map((admin) => {
        let adminData = {
          ...admin,
          access: [],
        };
        appRelationData.forEach((app) => {
          if (admin.id === app.user_admin_id) {
            adminData.access.push(app.list_application);
          }
        });
        return adminData;
      });
      setAdminData(mappedAppData);
    }
  }, [isLoadingAppRelationData, appRelationData]);

  const handleToggleAddAdminModal = () =>
    setIsOpenAddAdminModal(!isOpenAddAdminModal);
  const handleToggleEditAdminModal = () =>
    setIsOpenEditAdminModal(!isOpenEditAdminModal);
  const handleToggleRevokeModal = () =>
    setIsOpenRevokeAdminModal(!isOpenRevokeAdminModal);

  const handleEdit = (id) => {
    const admin = adminData.find((item) => item.id === id);
    setFormValue({
      id: admin.id,
      user_id: admin.user_id,
      name: admin.name,
      email: admin.user_email,
      role: admin.role,
      access: admin?.access?.map((item) => item.id) ?? [],
    });
    handleToggleEditAdminModal();
  };

  const handleRevokeAdmin = (id) => {
    const admin = adminData.find((item) => item.id === id);
    setFormValue({
      id: admin.id,
      user_id: admin.user_id,
      name: admin.name,
      email: admin.user_email,
      role: admin.role,
      access: admin?.access,
    });
    handleToggleRevokeModal();
  };

  const handleRefetch = () => {
    setRefetch(!refetch);
  };

  return (
    <>
      <LobbyHeader />
      <div style={{ minHeight: "100vh", background: "white" }} className="px-9">
        <LobbyHeaderProfile
          buttonText="Lobby Page"
          buttonColor="#3E7CA8"
          onClickButton={() => props.history.push("/")}
          isLoading={isLoadingUserData}
          userData={userData}
        />
        {isLoadingUserData || isLoadingAdminData || isLoadingAppRelationData ? (
          <div className="w-100 mt-5 d-flex justify-content-center">
            <Loader type="Oval" color="#30C1FF" height={100} width={100} />
          </div>
        ) : (
          <main>
            <div className="w-100 mb-3 d-flex" style={{ gap: "10px" }}>
              <h1>Manage User Admin</h1>
              <Button
                onClick={handleToggleAddAdminModal}
                className="border-0 text-white px-0"
                style={{
                  background: "#62A461",
                  width: "140px",
                  height: "40px",
                  fontSize: "14px",
                }}
              >
                Add New Admin
              </Button>
            </div>
            {
              <>
                <FormModal
                  handleToggleAdminModal={handleToggleAddAdminModal}
                  isOpenModal={isOpenAddAdminModal}
                  title="Add New Admin"
                  handleRefetch={handleRefetch}
                  formValue={formValue}
                  setFormValue={setFormValue}
                  appData={appData}
                />
                <FormModal
                  handleToggleAdminModal={handleToggleEditAdminModal}
                  isOpenModal={isOpenEditAdminModal}
                  title="Edit Admin"
                  handleRefetch={handleRefetch}
                  formValue={formValue}
                  setFormValue={setFormValue}
                  isEdit={true}
                  appData={appData}
                />
                <FormModal
                  handleToggleAdminModal={handleToggleRevokeModal}
                  isOpenModal={isOpenRevokeAdminModal}
                  title="Delete Admin"
                  handleRefetch={handleRefetch}
                  formValue={formValue}
                  setFormValue={setFormValue}
                  appData={appData}
                  isDelete={true}
                />
              </>
            }
            {userData?.role?.toLowerCase() !== "admin" && (
              <AdminListTable
                adminData={adminData}
                handleEdit={handleEdit}
                handleRevokeAdmin={handleRevokeAdmin}
              />
            )}
          </main>
        )}
      </div>
      <LobbyMenuFooter />
      <style jsx="true">
        {`
          .row-style-admin td {
            padding: 6px;
            vertical-align: middle;
          }
          .header-style-admin label input {
            height: 30px;
          }
        `}
      </style>
    </>
  );
}

export default ManageAdmin;
