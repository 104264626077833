import React from "react";

function ThreeDotsIcon( { width, height, fill } ) {
  return (
    <svg
      width={width ?? "2"}
      height={height ?? "10"}
      viewBox="0 0 2 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2" height="2" rx="1" fill={fill ?? "#4A4949"} />
      <rect y="4" width="2" height="2" rx="1" fill={fill ?? "#4A4949"} />
      <rect y="8" width="2" height="2" rx="1" fill={fill ?? "#4A4949"} />
    </svg>
  );
}

export default ThreeDotsIcon;
