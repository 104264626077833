import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import React, { useState } from "react";
import BookingCarDetailModal from "./BookingCarDetailModal";
const formatedDate = (dateStr) => {
  return moment(dateStr).format("DD-MMM-YYYY HH:mm");
};

const role = localStorage.getItem("role");

const pagination = ({
  sizePerPage,
  totalPage,
  currentPage,
  pathname,
  searchParams,
  setSearchParams,
  total_size,
}) => {
  return paginationFactory({
    alwaysShowAllBtns: true,

    sizePerPage: sizePerPage,
    pageListRenderer: ({ ...props }) => {
      const numberCurrentPage = Number(currentPage);
      return (
        <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
          <ul className="pagination react-bootstrap-table-page-btns-ul">
            <li
              className={` page-item ${
                numberCurrentPage === 1 ? "disabled" : ""
              }`}
              title="previous page"
            >
              <div
                className="page-link"
                onClick={() => {
                    setSearchParams({
                        ...searchParams,
                        page: numberCurrentPage - 1,
                    })
                }}
              >
                &lt;
              </div>
            </li>
            {[...Array(totalPage)].map((_, idx) => (
              <li
                className={` page-item ${
                  numberCurrentPage === idx + 1 ? "active" : ""
                }`}
                title="1"
              >
                <div
                  className="page-link"
                  onClick={() => {
                      setSearchParams({
                          ...searchParams,
                          page: idx + 1,
                      })
                  }}
                >
                  {idx + 1}
                </div>
              </li>
            ))}

            <li
              className={` page-item ${
                numberCurrentPage === totalPage ? "disabled" : ""
              }`}
              title="next page"
            >
              <div
                className="page-link"
                onClick={() => {
                    setSearchParams({
                        ...searchParams,
                        page: numberCurrentPage + 1,
                    })
                }}
              >
                &gt;
              </div>
            </li>
          </ul>
        </div>
      );
    },
    sizePerPageRenderer: () => {
      const startIndex = (currentPage - 1) * sizePerPage + 1;
      let endIndex = currentPage * sizePerPage;
      endIndex = endIndex > total_size ? total_size : endIndex;

      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Show {sizePerPage} entries. Showing rows {startIndex} to {endIndex}{" "}
            of {total_size}
          </label>
        </div>
      );
    },

    withFirstAndLast: false,
  });
};

function CarMonitoringList({
  data,
  totalPage,
  sizePerPage,
  currentPage,
  searchParams,
  setSearchParams,
  total_size,
}) {
  const { pathname } = useLocation();
  const history = useHistory();

  const headerStyle = () => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };

  const [selectedBookingId, setSelectedBookingId] = useState();
  const [isBookingDetailModalOpen, setIsBookingDetailModalOpen] =
    useState(false);
  const toggleBookingDetailModal = () =>
    setIsBookingDetailModalOpen((prevState) => !prevState);

  return (
    <>
      <ToolkitProvider
        data={data}
        keyField="id"
        search
        columns={[
          {
            dataField: "id",
            text: "ID",

            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "license_plate",
            text: "Plat Nomor",

            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "user_name",
            text: "Nama User",

            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "start_usage",
            text: "Tanggal Pick Up",
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
            formatter: (cell) => {
              return formatedDate(cell);
            },
          },
          {
            dataField: "pre_use_inspection",
            text: "Inspeksi Awal",

            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
            formatter: (cell) => {
              return cell === true ? (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <CheckCircleOutlineIcon style={{ color: "#0FB900" }} />
                </div>
              ) : cell === false ? (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <CancelOutlinedIcon style={{ color: "#E53E3E" }} />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </div>
              );
            },
          },
          {
            dataField: "finish_usage",
            text: "Tanggal Kembali",
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
            formatter: (cell) => {
              return formatedDate(cell);
            },
          },
          {
            dataField: "after_use_inspection",
            text: "Inspeksi Akhir",

            headerStyle: headerStyle,
            headerClasses: "header-style-admin",

            formatter: (cell, restObject) => {
              if (restObject["booking_status"] === "Sedang Digunakan") {
                return (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {role === "Satpam" ? (
                      <Button
                        className="p-0"
                        variant="outline"
                        onClick={() => {
                          history.push(`/booking-car/return/${restObject.id}`);
                        }}
                        style={{
                          color: "#3182CE",
                          background: "white",
                          width: "84px",
                          height: "32px",
                          fontSize: "14px",
                          border: "2px solid #3E7CA8",
                          textTransform: "none",
                        }}
                      >
                        Isi Form
                      </Button>
                    ) : null}
                  </div>
                );
              }
              return (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {cell === true ? (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <CheckCircleOutlineIcon style={{ color: "#0FB900" }} />
                    </div>
                  ) : cell === false ? (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <CancelOutlinedIcon style={{ color: "#E53E3E" }} />
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <RemoveCircleOutlineIcon />
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            dataField: "booking_status",
            text: "Status",

            headerStyle: headerStyle,
            headerClasses: "header-style-admin",

            formatter: (cell) => {
              const color =
                cell === "Sedang Digunakan"
                  ? "#3182CE"
                  : cell === "Selesai"
                  ? "#0FB900"
                  : "black";

              return (
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    margin: "0px",
                    color,
                  }}
                >
                  {cell}
                </p>
              );
            },
          },

          {
            dataField: "id",
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
            formatter: (cell, row) => {
              return (
                <div>
                  <Button
                    className="p-0"
                    variant="outline"
                    style={{
                      color: "#3182CE",
                      background: "white",
                      width: "84px",
                      height: "32px",
                      fontSize: "14px",
                      border: "2px solid #3E7CA8",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setSelectedBookingId(cell);
                      setIsBookingDetailModalOpen(true);
                    }}
                  >
                    Detail
                  </Button>
                </div>
              );
            },
          },
        ]}
      >
        {(props) => (
          <>
            <div className="table-responsive ">
              <div className="d-flex" style={{ background: "#F8F9FE" }}></div>
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                bordered={false}
                id="react-bs-table"
                noDataIndication="No Data Found"
                rowClasses={(row, rowIndex) => "row-style-admin"}
                pagination={pagination({
                  sizePerPage,
                  totalPage,
                  currentPage,
                  pathname,
                  searchParams,
                  setSearchParams,
                  total_size,
                })}
                bootstrap4={true}
              />
            </div>
          </>
        )}
      </ToolkitProvider>
      <BookingCarDetailModal
        isOpen={isBookingDetailModalOpen}
        toggle={toggleBookingDetailModal}
        bookingId={selectedBookingId}
      />

      <style jsx>
        {`
          .row-style-admin td {
            padding: 12px;
            vertical-align: middle;
            color: #8898aa;
          }
          .header-style-admin {
            background-color: #f6f9fc !important;
            color: #8898aa !important;
          }
          .header-style-admin label input {
            height: 30px;
          }
          .header-style-admin label select {
            height: 30px;
            padding: 6px;
          }
          .header-style-admin-number div label input {
            height: 30px;
            min-width: 50px;
            width: 100%;
            padding: 4px;
          }
          .header-style-admin-number div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
          .page-item.active .page-link {
            background-color: #3e7ca8;
            border-color: #3e7ca8;
            cursor: pointer;
          }
          .page-link {
            color: #3e7ca8;
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
}

export default CarMonitoringList;
