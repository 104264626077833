import React from "react";
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import { Button, Input } from "reactstrap";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddUserGroupForm({
  type,
  filteredUserList,
  companies,
  directorates,
  departments,
  divisions,
  handleSubmit,
  handleChange,
  handleSelectUserData,
  handleSearchUserChange,
  handleDeleteUser,
  formValue,
  personName,
  companyName,
  directorateName,
  departmentName,
  divisionName,
}) {
  return (
    <AvForm onSubmit={handleSubmit}>
      <h2 className="mb-4">{type === "edit" ? "Edit" : "Add" } User Group</h2>
      <AvGroup>
        <label className="form-control-label" htmlFor="group_name">
          Name*
        </label>
        <AvInput
          id="group_name"
          type="text"
          placeholder="Nama"
          name="group_name"
          value={formValue.group_name}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input name</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="description">
          Description
        </label>
        <textarea
          id="description"
          name="description"
          placeholder="User Group description"
          rows={5}
          value={formValue.description}
          onChange={handleChange}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "0.875rem",
            color: "#8898aa",
            border: "1px solid #dee2e6",
            borderRadius: "0.25rem",
          }}
        ></textarea>
      </AvGroup>
      <label className="form-control-label" htmlFor="group_type">
        Group Type*
      </label>
      <AvRadioGroup inline name="group_type" value={formValue.group_type} onChange={handleChange} required>
        <AvRadio customInput label="Group by User" value="user" />
        <AvRadio customInput label="Group by Entity" value="entity" />
        <AvFeedback>Please select group type</AvFeedback>
      </AvRadioGroup>
      {formValue.group_type === "user" ? (
        <>
          <Box display="flex" alignItems="center">
            <FormControl sx={{ mb: 4, width: "50%" }}>
              <label className="form-control-label" htmlFor="select_users">
                Select Users* 
                <div style={{ fontSize: "14px", fontWeight: "200"}}>Create User Group filter by Users</div>
              </label>
              <Input
                onChange={handleSearchUserChange}
                name="personName"
                placeholder="Enter user name"
                required
                style={{ width: "450px" }}
              />
              {filteredUserList.length > 0 && (
                <div
                  className="position-absolute"
                  style={{
                    width: "450px",
                    background: "#fff",
                    top: "75px",
                    zIndex: "2",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {filteredUserList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="name-dropdown"
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleSelectUserData(item);
                        }}
                      >
                        {item.user_name} - {item.user_email}
                      </div>
                    );
                  })}
                </div>
              )}
            </FormControl>
            {personName?.length > 0 && 
              <Box sx={{ marginLeft: "1em", marginBottom: "3em", width: "50%" }}>
                <label className="form-control-label" htmlFor="select_users" sx={{ marginBottom: "2em" }}>
                  Selected Users
                </label>
                <Stack direction="row" spacing={1}>
                  {personName.map((data) => 
                    <Chip label={data.user_name} onDelete={() => handleDeleteUser(data)}/>
                  )}
                </Stack>
              </Box>
            }
          </Box>
        </>
      ) : formValue.group_type === "entity" ? (
        <Box display="flex" alignItems="center" gap="15px">
          <FormControl sx={{ mb: 4, width: "33%" }}>
            <label className="form-control-label" htmlFor="select_users">
              Select Companies
              <div style={{ fontSize: "14px", fontWeight: "200"}}>Create User Group filter by Company</div>
            </label>
            <Select
              name="select_companies"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={companyName}
              onChange={handleChange}
              displayEmpty
              multiple
              inputProps={{ 'aria-label': 'Without label' }}
              renderValue={(companyName) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {companyName?.map((value) => (
                    <Chip key={value.company_id} label={value.company_name} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {companies.map((name) => (
                <MenuItem
                  key={name.company_id}
                  value={name}
                >
                  {name.company_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mb: 4, width: "35%" }}>
            <label className="form-control-label" htmlFor="select_directorates">
              Select Directorates
              <div style={{ fontSize: "14px", fontWeight: "200"}}>Create User Group filter by Directorate</div>
            </label>
            <Select
              displayEmpty
              name="select_directorates"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={directorateName}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
              renderValue={(directorateName) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {directorateName?.map((value) => (
                    <Chip key={value.directorate_id} label={value.directorate_name} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {directorates.map((name) => (
                <MenuItem
                  key={name.directorate_id}
                  value={name}
                >
                  {name.directorate_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mb: 4, width: "35%" }}>
            <label className="form-control-label" htmlFor="select_department">
              Select Departments
              <div style={{ fontSize: "14px", fontWeight: "200"}}>Create User Group filter by Department</div>
            </label>
            <Select
              displayEmpty
              name="select_department"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={departmentName}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
              renderValue={(departmentName) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {departmentName.map((value) => (
                    <Chip key={value.department_id} label={value.department_name} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {departments.map((name) => (
                <MenuItem
                  key={name.department_id}
                  value={name}
                >
                  {name.department_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mb: 4, width: "35%" }}>
            <label className="form-control-label" htmlFor="select_department">
              Select Divisions
              <div style={{ fontSize: "14px", fontWeight: "200"}}>Create User Group filter by Division</div>
            </label>
            <Select
              displayEmpty
              name="select_division"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={divisionName}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
              renderValue={(divisionName) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {divisionName.map((value) => (
                    <Chip key={value.division_id} label={value.division_name} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {divisions.map((name) => (
                <MenuItem
                  key={name.division_id}
                  value={name}
                >
                  {name.division_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : null}
      <div>
        {type === "edit" ? (
          <Button color="info" type="submit" size="md">
            Edit User Group
          </Button>
        ) : type === "add" ? (
          <Button color="info" type="submit" size="md">
            Add User Group
          </Button>
        ) : null}
      </div>
    </AvForm>
  );
}

export default AddUserGroupForm;
