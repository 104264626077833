/* eslint-disable react-hooks/exhaustive-deps */
import AddRoomForm from "components/bookingRoom/roomManagement/AddRoomForm";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Swal from "sweetalert2";
import Axios from "utils/axiosService";

function AddRoom({ setTitle, history }) {
  const [createdRoomId, setCreatedRoomId] = useState(null);
  const [formValue, setFormValue] = useState({
    facilities: [
      {
        room_id: null,
        item_name: "",
        description: "",
        image: "",
      },
    ],
  });
  const [formattedSelectedGroup, setFormattedSelectedGroup] = useState([]);
  useEffect(() => {
    setTitle("Add Room");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formValue.name &&
      formValue.area_id &&
      formValue.location &&
      formValue.capacity &&
      formValue.type &&
      formValue.active !== undefined &&
      formValue.facilities.length > 0 &&
      formValue.facilities.every((f)=>f.item_name && f.description)
    ) {
      const postImagesArr = [];
      const facilityImages = [];
      let roomImage = ""
      if (formValue.facilities.some((f) => f.image !== "")) {
        formValue.facilities.forEach((f, i) => {
          if (f.image) {
            postImagesArr.push(postImages(f.image));
          }
        });
      }

      Promise.all(postImagesArr)
        .then((res) => {
          for (const r of res) {
            if (r.data.errorStatus) {
              throw new Error("Failed to upload image");
            } else {
              const index = formValue.facilities.findIndex(
                (f) => f.image?.name === r?.config?.data.get("image_url")?.name
              );
              facilityImages.push({ index, image: r.data.data.url });
            }
          }
        })
        .then(() => {
          postImages(formValue.image)
            .then((r) => {
              if(r){
                if (r.data.errorStatus) {
                  throw new Error("Failed to upload image");
                } else {
                  roomImage = r.data.data.url
                }
              }
            })
            .then(() => {
              let facilitiesWithImage = [...formValue.facilities];
              facilityImages.forEach((val)=>{
                facilitiesWithImage[val.index].image = val.image
              })
                Axios({
                  method: "POST",
                  url: "/room-facilities",
                  data: {
                    name: formValue.name,
                    area_id: parseInt(formValue.area_id),
                    location_detail: formValue.location,
                    capacity: parseInt(formValue.capacity),
                    type: formValue.type,
                    active: formValue.active === "true",
                    image: roomImage,
                    description: formValue.description ?? "",
                    note: formValue.note ?? "",
                    room_facilities: facilitiesWithImage,
                    user_groups_id: formValue.selectedGroup,
                  },
                })
                  .then((r) => {
                    if (r.data.errorStatus) {
                      throw new Error("Something went wrong");
                    } else {
                      setCreatedRoomId(r.data.data.room.id);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
            });
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            icon: "error",
            text: "Failed to add a room",
            confirmButtonColor: "#30C1FF",
          });
        });
    }
  };

  const postImages = async (imagePath) => {
    if (!imagePath) {
      return Promise.resolve()
    }
    let formData = new FormData();
    formData.append("image_url", imagePath);
    const postImg = await Axios({
      method: "POST",
      url: "/file-upload",
      data: formData,
    });

    return postImg;
  };

  useEffect(() => {
    if (createdRoomId) {
      Swal.fire({
        icon: "success",
        text: `Success, room ${formValue.name} berhasil ditambahkan`,
        confirmButtonColor: "#30C1FF",
      });
      setTimeout(() => {
        history.push("/booking-room/room-list/" + createdRoomId);
      }, 1000);
    }
  }, [createdRoomId]);

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          <AddRoomForm
            title="Tambah Room"
            handleSubmit={handleSubmit}
            formValue={formValue}
            setFormValue={setFormValue}
            formattedSelectedGroup={formattedSelectedGroup}
            setFormattedSelectedGroup={setFormattedSelectedGroup}
          />
        </CardBody>
      </Card>
    </Container>
  );
}

export default AddRoom;
