import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import BinIcon from "components/Icons/BinIcon";
import UploadIcon from "components/Icons/UploadIcon";
import { Button, Input } from "reactstrap";

function ImageFormFacility({ facilityIndex, setFormValue, formValue }) {
  const [dragActive, setDragActive] = useState(false);
  const [dataImage, setDataImage] = useState({});
  const inputRef = useRef(null);

  const dragHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const validateImage = (image) => {
    let statusValidation = false;
    if (!image.type.includes("image")) {
      Swal.fire({
        icon: "error",
        text: "Please attach valid image file only",
        confirmButtonColor: "#30C1FF",
      });
    } else if (image.size > 5194304) {
      Swal.fire({
        icon: "error",
        text: "File size must be lower than 5 MB",
        confirmButtonColor: "#30C1FF",
      });
    } else {
      statusValidation = true;
    }

    return statusValidation;
  };

  const dropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let checkImageData = validateImage(e.dataTransfer.files[0]);
      if (checkImageData) {
        setDataImage({
          selectedFile: e.dataTransfer.files[0],
          selectedFileName: e.dataTransfer.files[0].name,
          imageSrc: window.URL.createObjectURL(e.dataTransfer.files[0]),
          value: e.dataTransfer.value,
        });
      }
    }
  };

  const handleChangeImage = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let checkImageData = validateImage(e.target.files[0]);
      if (checkImageData) {
        setDataImage({
          selectedFile: e.target.files[0],
          selectedFileName: e.target.files[0].name,
          imageSrc: window.URL.createObjectURL(e.target.files[0]),
          value: e.target.value,
        });
      }
    }
  };

  useEffect(() => {
    if (dataImage.selectedFile !== undefined) {
      let newFacilities = [...formValue.facilities];
      newFacilities[facilityIndex] = {
        ...newFacilities[facilityIndex],
        image: dataImage.selectedFile,
      };
      setFormValue({ ...formValue, facilities: newFacilities });
    }
    // eslint-disable-next-line
  }, [dataImage]);

  return (
    <div
      onDragEnter={dragHandler}
      className="mt-2"
      style={{
        margin: "0 auto",
        width: "100%",
        height: "400px",
        position: "relative",
        background: dragActive ? "#ccc" : "#fff",
        borderRadius: "5px",
        border: "1px dashed #444",
      }}
    >
      <Input
        ref={inputRef}
        type="file"
        id={`image-${facilityIndex}`}
        name="image"
        accept="image/png, image/gif, image/jpeg"
        onChange={handleChangeImage}
        hidden
      />
      {!formValue?.facilities[facilityIndex]?.image && !dataImage.imageSrc && (
        <label
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          htmlFor={`image-${facilityIndex}`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UploadIcon />
            <p className="mb-0 mt-2 font-weight-bold">
              Drag and Drop to Upload File
            </p>
            <p className="mb-0 font-weight-bold">or</p>
            <p className="mb-0 font-weight-bold">Click to Upload File</p>
            <p className="mb-0">Accepts image file formats</p>
            <p className="mb-0">Size Limit 4 MB</p>
          </div>
        </label>
      )}
      {(dataImage.imageSrc || formValue?.facilities[facilityIndex]?.image) && (
        <div
          style={{
            maxWidth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={
              dataImage.imageSrc || formValue?.facilities[facilityIndex]?.image
            }
            style={{
              maxHeight: "100%",
              objectFit: "contain",
            }}
            alt={
              dataImage.selectedFileName ||
              formValue?.facilities[facilityIndex]?.image
            }
          />
        </div>
      )}
      {(dataImage.imageSrc || formValue?.facilities[facilityIndex]?.image) && (
        <Button
          className="position-absolute border-0 shadow-none"
          style={{
            right: "5px",
            top: "5px",
          }}
          onClick={(e) => {
            e.preventDefault();
            setDataImage({
              selectedFile: null,
              selectedFileName: null,
              imageSrc: null,
              value: "",
            });
          }}
        >
          <BinIcon />
        </Button>
      )}
      {dragActive && (
        <div
          style={{
            position: "absolute",
            inset: "0px 0px 0px 0px",
            width: "100%",
            height: "100%",
          }}
          onDragEnter={dragHandler}
          onDragLeave={dragHandler}
          onDragOver={dragHandler}
          onDrop={dropHandler}
        ></div>
      )}
    </div>
  );
}

export default ImageFormFacility;
