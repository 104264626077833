/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import AddUserGroupForm from "components/bookingRoom/userGroupManagement/AddUserGroupForm";

function AddUserGroup({ match, history }) {
  const id = useMemo(() => match.params?.id, []);
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    group_name: "",
    description: "",
    group_type: "",
    included_users: [],
    included_companies: [],
    included_directorates: [],
    included_divisions: [],
  });

  const [inputType, setInputType] = React.useState("");
  const [personName, setPersonName] = React.useState([]);
  const [companyName, setCompanyName] = React.useState([]);
  const [directorateName, setDirectorateName] = React.useState([]);
  const [departmentName, setDepartmentName] = React.useState([]);
  const [divisionName, setDivisionName] = React.useState([]);

  const [initialUserList, setInitialUserList] = React.useState([]);
  const [filteredUserList, setUserList] = React.useState([]);
  const [companies, setCompanyList] = React.useState([]);
  const [directorates, setDirectorateList] = React.useState([]);
  const [departments, setDepartmentList] = React.useState([]);
  const [divisions, setDivisionList] = React.useState([]);
  const [userInputValue, setUserInputValue] = React.useState('');

  useEffect(() => {
    if (userInputValue) {
      Axios({
        method: "GET",
        url: `/user-simple?limit=10&sort=user_name,asc&q=${userInputValue.toUpperCase()}`,
      }).then((r) => {
        setUserList(r.data.data.data);
      });
    } else {
      setUserList([]);
    }
  }, [userInputValue])

  const getAllUserData = () => {
    Axios({
      method: "GET",
      url: `/user-simple?sort=user_name,asc`,
    }).then((r) => {
      setInitialUserList(r.data.data.data);
    });
  }

  const getAllCompanyData = () => {
    Axios({
      method: "GET",
      url: `/company`,
    }).then((r) => {
      setCompanyList(r.data.data.data);
    });
  }

  const getAllDirectorateData = () => {
    Axios({
      method: "GET",
      url: `/directorate`,
    }).then((r) => {
      setDirectorateList(r.data.data.data);
    });
  }

  const getAllDepartmentData = () => {
    Axios({
      method: "GET",
      url: `/department`,
    }).then((r) => {
      setDepartmentList(r.data.data.data);
    });
  }

  const getAllDivisionData = () => {
    Axios({
      method: "GET",
      url: `/division`,
    }).then((r) => {
      setDivisionList(r.data.data.data);
    });
  }

  useEffect(() => {
    getAllUserData();
    getAllCompanyData();
    getAllDirectorateData();
    getAllDepartmentData();
    getAllDivisionData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      setInputType("edit");
      Axios({
        method: "GET",
        url: "/user-group/" + id,
      })
        .then((r) => {
          if (!r.data.errorStatus) {
            setFormValue(r.data.data.userGroups)
            processFormData(r.data.data.userGroups)
          } else {
            throw new Error("Error fetching data");
          }
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setInputType("add");
      setIsLoading(false);
    }
  }, [inputType, id, companies, directorates, departments, divisions, initialUserList]);

  const processFormData = (userGroupData) => {
    const {
      group_name,
      group_type,
      description,
      included_users,
      included_companies,
      included_directorates,
      included_departments,
      included_divisions,
      id,
    } = userGroupData;
    if (included_users.length > 0 && initialUserList.length > 0) {
      const userData = initialUserList.filter(comp =>
        included_users.includes(parseInt(comp.user_id))
      )
      setFormValue({
        ...formValue,
        select_users: userData,
      })
      setPersonName(userData)
    }
    if (included_companies.length > 0) {
      const companyData = companies.filter(comp =>
        included_companies.includes(comp.company_id)
      )
      setFormValue({
        ...formValue,
        select_companies: companyData,
      })
      setCompanyName(companyData)
    }
    if (included_directorates.length > 0) {
      const directorateData = directorates.filter(comp =>
        included_directorates.includes(parseInt(comp.directorate_id))
      )
      setFormValue({
        ...formValue,
        select_directorates: directorateData,
      })
      setDirectorateName(directorateData)
    }
    if (included_departments.length > 0) {
      const deptData = departments.filter(comp =>
        included_departments.includes(parseInt(comp.department_id))
      )
      setFormValue({
        ...formValue,
        select_department: deptData,
      })
      setDepartmentName(deptData)
    }
    if (included_divisions.length > 0) {
      const divisionData = divisions.filter(comp =>
        included_divisions.includes(parseInt(comp.division_id))
      )
      setFormValue({
        ...formValue,
        select_department: divisionData,
      })
      setDivisionName(divisionData)
    }
    formValue.group_name = group_name
    formValue.group_type = group_type
    formValue.description = description
    setFormValue({
      ...formValue,
      included_users,
      included_companies,
      included_directorates,
      included_departments,
      included_divisions,
      id,
    });
  }

  const handleSelectUserData = (item) => {
    let tempPersonItems = [...personName, item];
    setFormValue({
      ...formValue,
      select_users: tempPersonItems,
    });
    setPersonName(tempPersonItems)
    setUserList([]);
  }

  const handleSearchUserChange = (event) => {
    const {
      target: { value },
    } = event;
    setUserInputValue(value)
  }

  const handleDeleteUser = (deletedUserData) => {
    const newPersonName = personName.filter(item => item.user_id !== deletedUserData.user_id);
    setFormValue({
      ...formValue,
      "select_users": newPersonName,
    });
    setPersonName(newPersonName)
  }
  
  const handleChange = (e) => {
    const {
      target: { value, name },
    } = e;

    if (name.includes("select")) {
      setFormValue({ ...formValue, [name]: value });
      switch (name) {
        case "select_companies":
          setDirectorateName([]);
          setDivisionName([]);
          setDepartmentName([]);
          setCompanyName(value);
          // getDirectoratesByCompany(value);
          break;
        case "select_directorates":
          setDivisionName([]);
          setDepartmentName([]);
          setDirectorateName(value);
          // getDepartmentsByDirectorates(value);
          break;
        case "select_department":
          setDivisionName([]);
          setDepartmentName(value);
          break;
        case "select_division":
          setDivisionName(value);
          break;
        default:
          break;
      }
    } else {
      setFormValue({
        ...formValue,
        [name]: value,
      });
    }

    if (name === "group_type" && value === "groupByUser") {
      setCompanyName([]);
      setDirectorateName([]);
      setDepartmentName([]);
      setDivisionName([]);
    } else if (name === "group_type" && value === "groupByEntity") {
      setPersonName([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let selectedUsers = [];
    let selectedCompanies = [];
    let selectedDirectorates = [];
    let selectedDept = [];
    let selectedDiv = [];

    let data = {
      group_name: formValue.group_name,
      group_type: formValue.group_type,
      description: formValue.description
    };

    if (formValue.select_users) {
      formValue.select_users.forEach((element) => {
        selectedUsers.push(parseInt(element.user_id));
      });
      data['included_users'] = selectedUsers
    }

    if (formValue.select_companies) {
      formValue.select_companies.forEach((element) => {
        selectedCompanies.push(parseInt(element.company_id));
      });
      data['included_companies'] = selectedCompanies
    }

    if (formValue.select_directorates) {
      formValue.select_directorates.forEach((element) => {
        selectedDirectorates.push(parseInt(element.directorate_id));
      });
      data['included_directorates'] = selectedDirectorates
    }

    if (formValue.select_department) {
      formValue.select_department.forEach((element) => {
        selectedDept.push(parseInt(element.department_id));
      });
      data['included_departments'] = selectedDept
    }

    if (formValue.select_division) {
      formValue.select_division.forEach((element) => {
        selectedDiv.push(parseInt(element.division_id));
      });
      data['included_divisions'] = selectedDiv
    }

    if (inputType === "edit") {
      if (
        formValue ||
        (formValue.group_name &&
          formValue.group_type &&
          formValue.description &&
          formValue.select_users &&
          formValue.select_companies &&
          formValue.select_directorates &&
          formValue.select_department &&
          formValue.select_division)
      ) {
        Axios({
          method: "PATCH",
          url: "/user-group/" + id,
          data,
        })
          .then((r) => {
            if (!r?.data?.errorStatus) {
              Swal.fire({
                icon: "success",
                text: `Berhasil mengubah user group`,
                confirmButtonColor: "#30C1FF",
              });
              setTimeout(() => {
                history.goBack();
              }, 1000);
            } else {
              throw new Error("Something went wrong");
            }
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              icon: "error",
              text: `Gagal mengubah user group`,
              confirmButtonColor: "#30C1FF",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        Swal.fire({
          icon: "error",
          text: `Gagal mengubah user group`,
          confirmButtonColor: "#30C1FF",
        });
        setIsLoading(false);
      }
    } else {
      Axios({
        method: "POST",
        url: "/user-group",
        data,
      })
        .then((r) => {
          if (!r?.data?.errorStatus) {
            Swal.fire({
              icon: "success",
              text: `Berhasil menambahkan user group`,
              confirmButtonColor: "#30C1FF",
            });
            setIsLoading(false);
            setTimeout(() => {
              history.goBack();
            }, 1000);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            text: `Gagal menambahkan user group`,
            confirmButtonColor: "#30C1FF",
          });
        })
    }
  };

  // TODO: Map Directorates and Departements Data
  // const getDirectoratesByCompany = (companyData) => {
  //   let selectedDirectorateData = [];
  //   let filteredData = companies.filter(
  //     (company) => company.companyName === companyData.companyName
  //   );
  //   selectedDirectorateData = directorates.filter(
  //     (directorate) => directorate.companyId === filteredData[0].id
  //   );
  //   setDirectorateList(selectedDirectorateData);
  // };

  // const getDepartmentsByDirectorates = (directorateName) => {
  //   let filteredData = [];

  //   filteredData = directorateName
  //     .map((element) => {
  //       return divisions.filter(
  //         (dept) => dept.directorateId === element.id
  //       );
  //     })
  //     .flat();

  //   setDepartmentList(filteredData);
  // };

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <AddUserGroupForm
              type={inputType}
              filteredUserList={filteredUserList}
              companies={companies}
              directorates={directorates}
              departments={departments}
              divisions={divisions}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleSelectUserData={handleSelectUserData}
              handleSearchUserChange={handleSearchUserChange}
              handleDeleteUser={handleDeleteUser}
              formValue={formValue}
              personName={personName}
              companyName={companyName}
              directorateName={directorateName}
              departmentName={departmentName}
              divisionName={divisionName}
            />
          )}
          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default AddUserGroup;
