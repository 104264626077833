import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import OptionForm from "./OptionForm";

import ThreeDotsIcon from "components/Icons/ThreeDotsIcon";

function CardMenu({
  menuList,
  setMenuList,
  item,
  activeOptionForm,
  setActiveOptionForm,
  handleRefetch,
  userData,
  isArchivedWorkspace,
  handleDeleteWorkspace
}) {
  const [showOptionButton, setShowOptionButton] = useState(false);
  const [willFormOverFlow, setWillFormOverFlow] = useState(false);

  const toggle = (e) => {
    if (window.innerWidth - e.clientX < 320) {
      setWillFormOverFlow(true);
    }
    if (activeOptionForm === item.id) {
      setActiveOptionForm(null);
    } else {
      setActiveOptionForm(item.id);
    }
  };

  const href = `/${item.name.toLowerCase().split(" ").join("-")}`;

  return (
    <Card
      className="col-xs-6 col-sm-4 col-md-3 col-xl-3 text-center"
      onMouseEnter={() => setShowOptionButton(true)}
      onMouseLeave={() => setShowOptionButton(false)}
      style={{
        boxShadow: "none",
      }}
    >
      <div>
        {(showOptionButton || activeOptionForm === item.id) && (
          <Dropdown
            isOpen={activeOptionForm === item.id}
            toggle={(event) => {
              toggle(event);
            }}
            style={{
              position: "absolute",
              right: "25px",
              top: "10px",
              zIndex: 2,
            }}
          >
            <DropdownToggle
              className="m-0 p-0 d-flex justify-content-center align-items-center"
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                width: "17px",
                height: "17px",
              }}
            >
              <ThreeDotsIcon />
            </DropdownToggle>
            <DropdownMenu
              className="bg-transparent shadow-none"
              style={{
                position: "relative",
              }}
            >
              <OptionForm
                willFormOverFlow={willFormOverFlow}
                menuList={menuList}
                setMenuList={setMenuList}
                item={item}
                activeOptionForm={activeOptionForm}
                setActiveOptionForm={setActiveOptionForm}
                handleRefetch={handleRefetch}
                userData={userData}
                isArchivedWorkspace={isArchivedWorkspace}
                handleDeleteWorkspace={handleDeleteWorkspace}
              />
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
      <Link to={href ?? ""}>
        <CardBody
          className="p-0"
          style={{
            borderRadius: "12px",
            color: "rgba(0, 0, 0, 0.7)",
            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              background: isArchivedWorkspace ? "#b8a88a" : item.color,
              borderRadius: "12px 12px 0 0",
              height: "116px",
            }}
          >
            <div style={{ color: "#fff", fontSize: "40px" }}>
              <i className={item.icon}></i>
            </div>
          </div>
          <CardTitle
            className="mb-0 py-2"
            style={{ fontWeight: "600", fontSize: "20px" }}
          >
            {item.name}
          </CardTitle>
        </CardBody>
      </Link>
    </Card>
  );
}

export default CardMenu;
