// import Aduan from "views/pages/booking_room/Aduan";
import BookingDetail from "views/pages/booking_room/BookingDetail";
// import DetailAduan from "views/pages/booking_room/aduan/DetailAduan";
import BookingHistory from "views/pages/booking_room/BookingHistory";
import ContactPerson from "views/pages/booking_room/content_management/ContactPerson";
// import Dashboard from "views/pages/booking_room/Dashboard";
import MonitoringAula from "views/pages/booking_room/MonitoringAula";
import MonitoringNonAula from "views/pages/booking_room/MonitoringNonAula";
import AddRoom from "views/pages/booking_room/room_management/AddRoom";
import DetailRoom from "views/pages/booking_room/room_management/DetailRoom";
import EditRoom from "views/pages/booking_room/room_management/EditRoom";
import ListRoom from "views/pages/booking_room/room_management/ListRoom";
// import AddUser from "views/pages/booking_room/user_management/AddUser";
// import ListUser from "views/pages/booking_room/user_management/ListUser";
import AddContactPerson from "views/pages/booking_room/content_management/AddContactPerson";
import EditContactPerson from "views/pages/booking_room/content_management/EditContactPerson";
import TermsConditions from "views/pages/booking_room/content_management/TermsConditions";
import AddTermCondition from "views/pages/booking_room/content_management/AddTermCondition";
import EditTermCondition from "views/pages/booking_room/content_management/EditTermCondition";
import UserGroupList from "views/pages/booking_room/usergroup_management/ListGroup";
import AddUserGroup from "views/pages/booking_room/usergroup_management/AddGroup";

const routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "ni ni-shop text-primary",
  //   component: Dashboard,
  //   layout: "/booking-room",
  // },
  {
    path: "/monitoring-aula",
    name: "Monitoring Aula",
    icon: "ni ni-shop text-primary",
    component: MonitoringAula,
    layout: "/booking-room",
  },
  {
    path: "/monitoring-non-aula",
    name: "Monitoring Non Aula",
    icon: "ni ni-shop text-primary",
    component: MonitoringNonAula,
    layout: "/booking-room",
  },
  {
    path: "/history",
    name: "Booking History",
    icon: "ni ni-shop text-primary",
    component: BookingHistory,
    layout: "/booking-room",
  },
  {
    redirect: true,
    path: "/booking/:id",
    component: BookingDetail,
    layout: "/booking-room",
  },
  {
    collapse: true,
    name: "Room Management",
    icon: "ni ni-ungroup text-orange",
    state: "roomCollapse",
    views: [
      {
        path: "/room-list",
        name: "List Room",
        exact: true,
        miniName: "L",
        component: ListRoom,
        layout: "/booking-room",
      },
      {
        path: "/add-room",
        name: "Add Room",
        miniName: "A",
        component: AddRoom,
        layout: "/booking-room",
      },
    ],
  },
  {
    redirect: true,
    path: "/room-list/:id_room/edit",
    component: EditRoom,
    layout: "/booking-room",
  },
  {
    redirect: true,
    path: "/room-list/:id_room",
    component: DetailRoom,
    layout: "/booking-room",
  },
  // {
  //   collapse: true,
  //   name: "Admin Management",
  //   icon: "ni ni-ungroup text-orange",
  //   state: "userCollapse",
  //   views: [
  //     {
  //       path: "/user-list",
  //       name: "Admin List",
  //       miniName: "U",
  //       component: ListUser,
  //       layout: "/booking-room",
  //     },
  //     {
  //       path: "/user-add",
  //       name: "Add Admin",
  //       miniName: "UA",
  //       component: AddUser,
  //       layout: "/booking-room",
  //     },
  //   ],
  // },
  // {
  //   redirect: true,
  //   path: "/admin-list/:id_admin/edit",
  //   component: EditAdmin,
  //   layout: "/booking-room",
  // },
  // {
  //   redirect: true,
  //   path: "/aduan/:id",
  //   component: DetailAduan,
  //   layout: "/booking-room",
  // },
  // {
  //   path: "/aduan",
  //   name: "Aduan",
  //   icon: "ni ni-shop text-primary",
  //   component: Aduan,
  //   layout: "/booking-room",
  // },
  {
    redirect: true,
    path: "/contact-person/add",
    component: AddContactPerson,
    layout: "/booking-room",
  },
  {
    redirect: true,
    path: "/contact-person/:id",
    component: EditContactPerson,
    layout: "/booking-room",
  },
  {
    redirect: true,
    path: "/terms-and-conditions/add",
    component: AddTermCondition,
    layout: "/booking-room",
  },
  {
    redirect: true,
    path: "/terms-and-conditions/:id",
    component: EditTermCondition,
    layout: "/booking-room",
  },
  {
    collapse: true,
    name: "Content Management",
    icon: "ni ni-ungroup text-orange",
    state: "contentCollapse",
    views: [
      {
        path: "/terms-and-conditions",
        name: "Terms and Conditions",
        miniName: "SK",
        component: TermsConditions,
        layout: "/booking-room",
      },
      {
        path: "/contact-person",
        name: "Contact Person",
        miniName: "CP",
        component: ContactPerson,
        layout: "/booking-room",
      },
    ],
  },
  {
    redirect: true,
    path: "/user-groups/add",
    component: AddUserGroup,
    layout: "/booking-room",
  },
  {
    redirect: true,
    path: "/user-groups/:id",
    component: AddUserGroup,
    layout: "/booking-room",
  },
  {
    collapse: true,
    name: "User Groups",
    icon: "ni ni-world text-orange",
    state: "userGroupCollapse",
    views: [
      {
        path: "/user-groups",
        name: "User Groups List",
        miniName: "UGL",
        component: UserGroupList,
        layout: "/booking-room",
      },
      {
        path: "/user-groups/add",
        name: "Add User Groups",
        miniName: "AGL",
        component: AddUserGroup,
        layout: "/booking-room",
      },
    ],
  },
];

export default routes;
