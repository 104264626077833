import React from "react";

function ArrowSwapIcon() {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.27686 0.0838878L6.61727 0.088548C6.89927 0.0924087 7.12475 0.324143 7.12089 0.606148L7.01603 8.2653L8.03725 8.27928C8.49185 8.2855 8.71142 8.83962 8.38639 9.15589L6.66105 10.8346C6.45891 11.0313 6.13561 11.0269 5.93894 10.8247L4.26021 9.09941C3.9452 8.77558 4.1767 8.22642 4.63319 8.23267L5.65441 8.24665L5.75926 0.587507C5.76312 0.305502 5.99486 0.0800271 6.27686 0.0838878V0.0838878ZM2.90086 10.422L3.00572 2.76287L4.02694 2.77685C4.4804 2.78306 4.71699 2.23597 4.39994 1.91012L2.7212 0.184783C2.52452 -0.0173397 2.20122 -0.0217657 1.9991 0.174898L0.273762 1.85363C-0.0503205 2.16898 0.166792 2.724 0.622873 2.73025L1.64409 2.74423L1.53924 10.4034C1.53538 10.6854 1.76085 10.9171 2.04286 10.921L2.38326 10.9256C2.66527 10.9295 2.897 10.704 2.90086 10.422Z"
        fill="black"
      />
    </svg>
  );
}

export default ArrowSwapIcon;
