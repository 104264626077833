/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Button,
  Row,
  Col
} from "reactstrap";
// core components

class RequestApprove extends React.Component {
  componentDidMount(){
    this.props.setTitle("Pemilihan Kamar Mess")
  }
  render() {
    return (
      <>
        <Container className="mt--6" fluid>
        <Row>
        <Col>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">Detail Pemohon</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Nama
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="Adinda Gadis"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Nomer Telfon
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="08111222333"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Request
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="Booking"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Lokasi kerja
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="Head Office"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
               <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Kategori mess
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="Single"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
               <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Jangka waktu
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="Permanent"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
               <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Check In 
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="17 November 2020"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                 <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Check Out
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="One of three cols"
                      defaultValue="-"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
             
              
            </CardBody>
          </Card>
          </Col>

          <Col>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">FORM PENEMPATAN</h3>
            </CardHeader>
            <CardBody>
            <Row>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      Kategori mess
                    </label>
                    <Input
                        type="select"
                        name="category_mess"
                    >
                      <option>Pilih kategori...</option>
                      <option>Single</option>
                      <option>Couple</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      Lokasi kerja
                    </label>
                    <Input
                        type="select"
                        name="work_location"
                    >
                      <option>Pilih lokasi kerja..</option>
                      <option>Paragon 1</option>
                      <option>Paragon 2</option>
                      <option>Paragon 3</option>
                      <option>Paragon 4</option>
                      <option>Paragon 5</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
               <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      Mess Penempatan
                    </label>
                    <Input
                        type="select"
                        name="mess_location"
                    >
                      <option>Choose Mess...</option>
                      <option>Mess A</option>
                      <option>Mess F</option>
                      <option>Mess I</option>
                      <option>Mess J</option>
                      <option>Other</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
                  <Row>
                    <Card>
                    <CardHeader>
                    <h4 className="mb-0">Informasi Mess</h4>
                    </CardHeader>
                    <CardBody>
                    <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Kamar tersedia
                        </label>
                        <Input
                          id="example3cols1Input"
                          placeholder="One of three cols"
                          defaultValue="3/12"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Jumlah penghuni
                        </label>
                        <Input
                          id="example3cols1Input"
                          placeholder="One of three cols"
                          defaultValue="6/24"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          PJ Mess
                        </label>
                        <Input
                          id="example3cols1Input"
                          placeholder="One of three cols"
                          defaultValue="Catur"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          
                        </label>
                        <Input
                          id="example3cols1Input"
                          placeholder="One of three cols"
                          defaultValue="081321982455"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                </Card>
              </Row>
              <Row>
               <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      Penempatan kamar
                    </label>
                    <Input
                        type="select"
                        name="room_number"
                    >
                      <option>Pilih kamar...</option>
                      <option>Kamar 1</option>
                      <option>Kamar 2</option>
                      <option>Kamar 3</option>
                      <option>Kamar 4</option>
                      <option>Kamar 5</option>
                      <option>Lainnya</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
             
              
              <hr className="my-4" />
              <Row>
              <Col className="text-right" xs="6">
                <Button
                  color="primary"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                  size="md"
                >
                    Send
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  color="danger"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                  size="md"
                >
                    Cancel
                </Button>
              </Col>
              
              </Row>
            </CardBody>
          </Card>
          </Col>

          </Row>
        
        </Container>
      </>
    );
  }
}
export default RequestApprove;
