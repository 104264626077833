import React, { useState, useEffect } from "react";
import Axios from "utils/axiosService";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";

const initialBookingState = {
  user: {
    name: "",
    contact: "",
  },
  car: {
    licensePlate: "",
    name: "",
    brand: "",
    capacity: "",
    fuel: "",
    licenseExpiry: "",
    currentLocation: "",
    locationURL: "",
    carStatus: "",
    color: "",
    lastService: "",
  },
  bookingStatus: "",
  fuelStart: "",
  odoStart: "",
  preUseInspection: "",
  afterUseInspection: "",
  licenseExpiry: "",
  fuelEnd: "",
  odoEnd: "",
  startUsage: "",
  finishUsage: "",
  actualStartUsage: "",
  actualFinishUsage: "",
  purpose: "",
};

const BookingCarDetailModal = ({ isOpen, toggle, bookingId }) => {
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  const [bookingData, setBookingData] = useState(initialBookingState);

  const toggleWithResetState = () => {
    toggle();
  };
  const history = useHistory();

  const role = localStorage.getItem("role");
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingFetch(true);
      await Axios({
        method: "GET",
        url: `/car/booking/${bookingId}`,
      })
        .then((r) => {
          const booking = r.data.data.data;
          setBookingData({
            user: {
              name: booking.user.user_name,
              contact: booking.user.user_phone,
            },
            car: {
              licensePlate: booking.car.license_plate,
              name: booking.car.name,
              brand: booking.car.brand,
              capacity: booking.car.capacity,
              fuel: booking.car.fuel,
              licenseExpiry: booking.car.license_expiry,
              currentLocation: booking.car.current_location,
              locationURL: booking.car.location_url,
              color: booking.car.color,
              lastService: "",
            },
            bookingStatus: booking.booking_status,
            fuelStart: booking.fuel_start ? booking.fuel_start + "%" : "N/A",
            odoStart: booking.odo_start
              ? thousandSeparator(booking.odo_start) + " Km"
              : "N/A",
            preUseInspection: booking.pre_use_inspection,
            afterUseInspection: booking.after_use_inspection,
            fuelEnd: booking.fuel_end ? booking.fuel_end + "%" : "N/A",
            odoEnd: booking.odo_end
              ? thousandSeparator(booking.odo_end) + " Km"
              : "N/A",
            startUsage: booking.start_usage,
            finishUsage: booking.finish_usage,
            actualStartUsage: booking.actual_start_usage,
            actualFinishUsage: booking.actual_finish_usage,
            purpose: booking.purpose,
          });
          setIsLoadingFetch(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    if (isOpen) fetchData();
  }, [bookingId, isOpen]);

  if (isLoadingFetch) {
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        fade={false}
        style={{
          height: "100vh",
          minWidth: "60vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          padding: "0",
        }}
      >
        <ModalBody style={{ paddingTop: "0" }}>
          <div
            className="w-100 d-flex justify-content-center "
            style={{
              alignContent: "center",
              padding: "50px 0px",
            }}
          >
            <Loader type="Oval" color="#30C1FF" height={100} width={100} />
          </div>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleWithResetState}
      fade={false}
      style={{
        height: "100vh",
        minWidth: "40vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        padding: "0",
      }}
    >
      {isLoadingFetch}
      <ModalHeader
        className="border-0"
        close={
          <CloseButton
            status={bookingData.bookingStatus}
            toggle={toggleWithResetState}
          />
        }
      >
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-bold mb-0" style={{ fontSize: "18px" }}>
              {bookingData.car.licensePlate}
            </p>
            <p className="fw-bold mb-0" style={{ fontSize: "18px" }}>
              {bookingData.car.brand + " " + bookingData.car.name}
            </p>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="pt-0" style={{ paddingTop: "0" }}>
        <Section className={"mb-4"} heading={"Detail User"}>
          <SectionRow>
            <Field head="Nama User">{bookingData.user.name}</Field>
            <Field head="Kontak User">{bookingData.user.contact}</Field>
          </SectionRow>
        </Section>

        <Section className={"mb-2"} heading={"Detail Mobil"}>
          <SectionRow>
            <Field head="Nama Mobil">{bookingData.car.name}</Field>
            <Field head="Plat Nomor">{bookingData.car.licensePlate}</Field>
          </SectionRow>
          <SectionRow>
            <Field head="Kapasitas">{bookingData.car.capacity}</Field>
            <Field head="Expired">
              {formatDate(bookingData.car.licenseExpiry)}
            </Field>
          </SectionRow>
          <SectionRow>
            <Field head="Jenis Bensin">{bookingData.car.fuel}</Field>
            <Field head="Warna Mobil">
              {bookingData.car.color ? bookingData.car.color : "-"}
            </Field>
          </SectionRow>
          <SectionRow>
            <Field head="Servis Terakhir">
              {formatDate(bookingData.car.lastService)}
            </Field>
            <div className="col"></div>
          </SectionRow>
          <SectionRow>
            <Field head="Bensin Awal">{bookingData.fuelStart}</Field>
            <Field head="Bensin Akhir">{bookingData.fuelEnd}</Field>
          </SectionRow>
          <SectionRow>
            <Field head="Kilometer Awal">{bookingData.odoStart}</Field>
            <Field head="Kilometer Akhir">{bookingData.odoEnd}</Field>
          </SectionRow>
          <SectionRow>
            {bookingData.preUseInspection === true ? (
              <FieldGreen head="Inspeksi Awal">Selesai</FieldGreen>
            ) : bookingData.preUseInspection === false ? (
              <FieldRed head="Inspeksi Awal">Tidak Dilakukan</FieldRed>
            ) : (
              <FieldRed head="Inspeksi Awal">Belum Dilakukan</FieldRed>
            )}
            {bookingData.afterUseInspection === true ? (
              <FieldGreen head="Inspeksi Akhir">Selesai</FieldGreen>
            ) : bookingData.afterUseInspection === false ? (
              <FieldRed head="Inspeksi Akhir">Tidak Dilakukan</FieldRed>
            ) : (
              <FieldRed head="Inspeksi Akhir">Belum Dilakukan</FieldRed>
            )}
          </SectionRow>
        </Section>

        <hr />

        <Section className={"mt-2 mb-1"} heading={"Detail Booking"}>
          <SectionRow>
            <Field head="Booking Mulai">
              {formatDateHour(bookingData.startUsage)}
            </Field>
            <Field head="Booking Selesai">
              {formatDateHour(bookingData.finishUsage)}
            </Field>
          </SectionRow>
          <SectionRow>
            <Field head="Waktu pergi">
              {formatDateHour(bookingData.actualStartUsage)}
            </Field>
            <Field head="Waktu Pulang">
              {formatDateHour(bookingData.actualFinishUsage)}
            </Field>
          </SectionRow>
          <SectionRow></SectionRow>
        </Section>

        <p className="font-weight-bold m-0" style={{ fontSize: "12px" }}>
          Keperluan Booking
        </p>
        <p style={{ fontSize: "12px", marginBottom: "16px" }}>
          {bookingData.purpose}
        </p>
      </ModalBody>
      {role === "Satpam" && bookingData.bookingStatus === "In Progress" && (
        <ModalFooter className="border-0">
          <Button
            type="submit"
            className="border-0 text-white p-0 w-100"
            style={{
              background: "#3182CE",
              height: "32px",
              fontSize: "14px",
            }}
            onClick={() => {
              history.push(`/booking-car/return/${bookingId}`);
            }}
          >
            ISI FORMULIR KELENGKAPAN
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

const Section = ({ className, children, heading }) => {
  return (
    <div className={"container m-0 p-0 " + className}>
      <h4 style={{ fontSize: "16px" }}>{heading}</h4>
      <div className="row" style={{ fontSize: "12px" }}>
        {children}
      </div>
    </div>
  );
};

const SectionRow = ({ children }) => {
  return <div className="row">{children}</div>;
};

const Field = ({ head, children }) => {
  return (
    <div className="col d-flex justify-content-between">
      <p className="font-weight-bold m-0" style={{ fontSize: "12px" }}>
        {head}
      </p>
      <p className="m-0" style={{ fontSize: "12px" }}>
        {children}
      </p>
    </div>
  );
};

const FieldGreen = ({ head, children }) => {
  return (
    <div className="col d-flex justify-content-between">
      <p className="font-weight-bold m-0" style={{ fontSize: "12px" }}>
        {head}
      </p>
      <p
        className="m-0 text-success font-weight-bold"
        style={{ fontSize: "12px" }}
      >
        {children}
      </p>
    </div>
  );
};

const FieldRed = ({ head, children }) => {
  return (
    <div className="col d-flex justify-content-between">
      <p className="font-weight-bold m-0" style={{ fontSize: "12px" }}>
        {head}
      </p>
      <p
        className="m-0 text-danger font-weight-bold"
        style={{ fontSize: "12px" }}
      >
        {children}
      </p>
    </div>
  );
};

const StatusCapsule = ({ status }) => {
  let inside = <></>;
  switch (status) {
    case "In Progress":
      inside = (
        <div
          className="badge-pill text-white"
          style={{ background: "#3182CE" }}
        >
          Sedang Digunakan
        </div>
      );
      break;
    case "Done":
      inside = <div className="badge-pill bg-success text-white">Selesai</div>;
      break;
    case "Booking Cancelled":
      inside = <div className="badge-pill bg-dark text-white">Dibatalkan</div>;
      break;
    case "Booking Created":
      inside = <div className="badge-pill bg-dark text-white">Mendatang</div>;
      break;
    default:
      inside = <></>;
      break;
  }
  return <div style={{ fontSize: "12px" }}>{inside}</div>;
};

const CloseButton = ({ status, toggle }) => {
  return (
    <div className="d-flex">
      <StatusCapsule status={status} />
      <button onClick={toggle} type="button" class="close" aria-label="Close">
        <span aria-hidden="true">x</span>
      </button>
    </div>
  );
};

const formatDateHour = (date) => {
  if (!date || date === "") return "-";
  const d = new Date(date);
  const dFormat =
    formatDate(date) + " " + [n(d.getHours()), n(d.getMinutes())].join(":");
  return dFormat;
};

const formatDate = (date) => {
  if (!date || date === "") return "-";
  const d = new Date(date);
  const dFormat = [n(d.getDate()), n(d.getMonth() + 1), d.getFullYear()].join(
    "/"
  );
  return dFormat;
};

const thousandSeparator = (x) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
};

const n = (num, len = 2) => {
  return `${num}`.padStart(len, "0");
};

export default BookingCarDetailModal;
