import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  numberFilter,
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { Button } from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

function RoomListTable({ data, csv_header, handleEditRow, handleViewRow }) {
  const { SearchBar } = Search;

  const headerStyle = (column, colIndex) => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };

  const availableOptions = (col) => [
    { value: "true", label: "Available" },
    { value: "false", label: "Not Available" },
  ];

  return (
    <>
      <ToolkitProvider
        data={data}
        keyField="id"
        search
        columns={[
          {
            dataField: "id",
            text: "ID",
            headerStyle: headerStyle,
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
              if (order === "asc") return a - b;
              else return b - a;
            },
          },
          {
            dataField: "name",
            text: "Name",
            filter: textFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "office_area.name",
            text: "Location",
            filter: textFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "capacity",
            text: "Capacity",
            filter: numberFilter(),
            sort: true,
            headerStyle: (col, colIdx)=>{
              const styles = headerStyle(col, colIdx)
              return {
                ...styles,
                width: '100px',
              }
            },
            headerClasses: "header-style-admin-number",
          },
          {
            dataField: "type",
            text: "Type",
            filter: textFilter(),
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
          },
          {
            dataField: "active",
            text: "Available",
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
            filter: selectFilter({
              options: availableOptions,
            }),
            formatter: (cell) => {
              return cell ? "Available" : "Not Available";
            },
          },
          {
            dataField: "user_groups_id",
            text: "User Groups Assigned?",
            sort: true,
            headerStyle: headerStyle,
            headerClasses: "header-style-admin",
            formatter: (cell) => {
              return cell.length > 0 ? "Yes" : "No";
            },
          },
          {
            dataField: "id",
            text: "Action",
            headerStyle: headerStyle,
            formatter: (cell, row) => {
              return (
                <div>
                  <Button
                    className="border-0 text-white p-0"
                    style={{
                      background: "#4ABA9F",
                      width: "68px",
                      height: "22px",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      handleViewRow(row.id);
                    }}
                  >
                    View
                  </Button>
                  <Button
                    className="border-0 text-white p-0"
                    style={{
                      background: "#DAA743",
                      width: "68px",
                      height: "22px",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      handleEditRow(row.id);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              );
            },
          },
        ]}
      >
        {(props) => (
          <div className="table-responsive">
            <div className="d-flex w-100 justify-content-between">
              <div className="p-3">
                <label className="mr-3" style={{ fontSize: "15px" }}>
                  Search:
                </label>
                <SearchBar
                  className="form-control-sm"
                  placeholder="Search"
                  {...props.searchProps}
                />
              </div>
              <CSVLink
                data={data}
                headers={csv_header}
                filename={"List Room.csv"}
                className="p-3"
              >
                <Button
                  style={{ background: "#3E7CA8" }}
                  size="sm"
                  className="border-0 text-white"
                >
                  Export as CSV
                </Button>
              </CSVLink>
            </div>
            <BootstrapTable
              {...props.baseProps}
              filter={filterFactory()}
              bordered={false}
              id="react-bs-table"
              noDataIndication="No Room Found"
              rowClasses={(row, rowIndex) => "row-style-admin"}
              pagination={pagination}
              bootstrap4={true}
            />
          </div>
        )}
      </ToolkitProvider>
      <style jsx>
        {`
          .row-style-admin td {
            padding: 12px;
            vertical-align: middle;
          }
          .header-style-admin label input {
            height: 30px;
          }
          .header-style-admin label select {
            height: 30px;
            padding: 6px;
          }
          .header-style-admin-number div label input {
            height: 30px;
            min-width: 50px;
            width: 100%;
            padding: 4px;
          }
          .header-style-admin-number div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
        `}
      </style>
    </>
  );
}

export default RoomListTable;
