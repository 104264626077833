/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import Chip from '@mui/material/Chip';
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UploadIcon from "components/Icons/UploadIcon";
import BinIcon from "components/Icons/BinIcon";
import Axios from "utils/axiosService";
import ImageFormFacility from "./ImageFormFacility";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddRoomForm({
  handleSubmit,
  formValue,
  setFormValue,
  title,
  isEdit,
  handleDeleteFacility,
  formattedSelectedGroup,
  setFormattedSelectedGroup
}) {
  const [dragActive, setDragActive] = useState(false);
  const [dataImage, setDataImage] = useState({});
  const [areaData, setAreaData] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    Axios({
      method: "GET",
      url: "/area",
    })
      .then((r) => {
        setAreaData(r.data.data.data);
      })
      .catch((er) => {
        console.error(er.response);
      });
  }, []);

  useEffect(() => {
    Axios({
      method: "GET",
      url: "/user-group",
    })
    .then((r) => {
      const allUserGroups = r.data.data.userGroups
      let selectedGroups = []
      if(formValue.user_groups_id.length > 0) {
        formValue.user_groups_id.forEach(userGroupId => {
          selectedGroups = allUserGroups.filter((a) => {
            return a.id === userGroupId
          })
        });
      }
      setFormattedSelectedGroup(selectedGroups)
      setUserGroups(r.data.data.userGroups)
    })
    .catch((error) => {
      console.error(error.response);
    })
  }, []);

  const handleChange = (e) => {
    if(e.target.name === "selectedGroup") {
      const selectedGroup = e.target.value
      const groups = selectedGroup.map((group) => group.id)
      setFormValue({ ...formValue, [e.target.name]: groups });
      setFormattedSelectedGroup(selectedGroup)
    } else {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }
  };

  const handleChangeFacilities = (e) => {
    const index = e.target.name.split("-")[2];
    const name = e.target.name.split("-")[1];
    let newFacilities = [...formValue.facilities];
    newFacilities[index] = {
      ...newFacilities[index],
      [name]: e.target.value,
    };
    setFormValue({ ...formValue, facilities: newFacilities });
  };

  const validateImage = (image) => {
    let statusValidation = false
    if (!image.type.includes("image")) {
      Swal.fire({
        icon: "error",
        text: "Please attach valid image file only",
        confirmButtonColor: "#30C1FF",
      });
    } else if (image.size > 5194304) {
      Swal.fire({
        icon: "error",
        text: "File size must be lower than 5 MB",
        confirmButtonColor: "#30C1FF",
      });
    } else {
      statusValidation = true
    }

    return statusValidation
  }

  const handleChangeImage = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let checkImageData = validateImage(e.target.files[0]);
      if (checkImageData) {
        setDataImage({
          selectedFile: e.target.files[0],
          selectedFileName: e.target.files[0].name,
          imageSrc: window.URL.createObjectURL(e.target.files[0]),
          value: e.target.value,
        });
      }
    }
  };

  const roomType = [
    {
      value: "Aula",
      label: "Aula",
    },
    {
      value: "Ruang Meeting",
      label: "Ruang Meeting",
    },
  ];

  const dragHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const dropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let checkImageData = validateImage(e.dataTransfer.files[0]);
      if (checkImageData) {
        setDataImage({
          selectedFile: e.dataTransfer.files[0],
          selectedFileName: e.dataTransfer.files[0].name,
          imageSrc: window.URL.createObjectURL(e.dataTransfer.files[0]),
          value: e.dataTransfer.value,
        });
      }
    }
  };

  useEffect(() => {
    if (dataImage.selectedFile !== undefined) {
      setFormValue({ ...formValue, image: dataImage.selectedFile });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataImage]);

  return (
    <AvForm onSubmit={handleSubmit}>
      <h2 className="mb-4">{title}</h2>
      <AvGroup>
        <label className="form-control-label" htmlFor="name">
          Nama Room*
        </label>
        <AvInput
          type="text"
          placeholder="Nama Ruangan"
          name="name"
          value={formValue.name}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input room name</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="area_id">
          Office Area*
        </label>
        <AvField
          type="select"
          name="area_id"
          onChange={handleChange}
          value={formValue.type}
          validate={{
            required: {
              value: true,
              errorMessage: "Please Select Office Area",
            },
          }}
        >
          <option value="" selected disabled>
            Pilh Office Area
          </option>
          {areaData.map((area, index) => {
            return (
              <option key={index} value={area.id}>
                {area.name} ({area.description})
              </option>
            );
          })}
        </AvField>
        <AvFeedback>Please input office area</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="location">
          Lokasi*
        </label>
        <AvInput
          type="text"
          placeholder="Lokasi Ruangan"
          name="location"
          value={formValue.location}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input room location</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="capacity">
          Kapasitas*
        </label>
        <AvInput
          type="number"
          placeholder="Kapasitas Ruangan"
          name="capacity"
          value={formValue.capacity}
          onChange={handleChange}
          required
        />
        <AvFeedback>Please input room capacity</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="type">
          Tipe Ruangan*
        </label>
        <AvField
          type="select"
          name="type"
          onChange={handleChange}
          value={formValue.type}
          validate={{
            required: {
              value: true,
              errorMessage: "Please Select Type",
            },
          }}
        >
          <option value="" selected disabled>
            Pilh Tipe Ruangan
          </option>
          {roomType.map((room, index) => {
            return (
              <option key={index} value={room.value}>
                {room.label}
              </option>
            );
          })}
        </AvField>
        <AvFeedback>Please input room type</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="active">
          Status Aktif*
        </label>
        <AvField
          type="select"
          name="active"
          onChange={handleChange}
          value={formValue.active}
          validate={{
            required: {
              value: true,
              errorMessage: "Please Select Active Status",
            },
          }}
        >
          <option value="" selected disabled>
            Pilh Status Aktif Ruangan
          </option>
          <option value={true}>Aktif</option>
          <option value={false}>Tidak Aktif</option>
        </AvField>
        <AvFeedback>Please input room type</AvFeedback>
      </AvGroup>
      <FormControl sx={{ mb: 4, width: "100%" }}>
        <label className="form-control-label" htmlFor="selectedGroup">
          Pilih User Group
        </label>
        <Select
          displayEmpty
          name="selectedGroup"
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={formattedSelectedGroup}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(formattedSelectedGroup) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {formattedSelectedGroup?.map((value) => (
                <Chip key={value.id} label={value.group_name} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {userGroups.map((userGroup) => (
            <MenuItem
              key={userGroup.id}
              value={userGroup}
            >
              {userGroup.group_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <AvGroup>
        <label className="form-control-label">Fasilitas</label>
        <Button
          className="border-0 ml-3 p-0"
          style={{
            width: "20px",
            height: "20px",
          }}
          onClick={(e) => {
            e.preventDefault();
            let newFacilities = formValue?.facilities
              ? [...formValue.facilities]
              : [];
            newFacilities.push({
              room_id: null,
              item_name: "",
              description: "",
              image: "",
            });
            setFormValue({ ...formValue, facilities: newFacilities });
          }}
        >
          <strong>+</strong>
        </Button>
        <AvGroup className="ml-4">
          {formValue.facilities &&
            formValue.facilities.length > 0 &&
            formValue.facilities.map((facility, index) => (
              <Fragment key={index}>
                <div className="d-flex mt-3">
                  <label className="form-control-label" htmlFor="fasilitas-0">
                    Fasilitas {index + 1}
                  </label>
                  {((index !== 0 && !isEdit) || isEdit) && (
                    <Button
                      className="border-0 ml-3 p-0 d-flex justify-content-center align-items-center"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (facility.id) {
                          handleDeleteFacility(facility.id);
                        }
                        let newFacilities = [...formValue.facilities];
                        newFacilities.splice(index, 1);
                        if (newFacilities.length === 0) {
                          newFacilities = [
                            {
                              room_id: null,
                              item_name: "",
                              description: "",
                              image: "",
                            },
                          ];
                        }
                        setFormValue({
                          ...formValue,
                          facilities: newFacilities,
                        });
                      }}
                    >
                      <BinIcon width="10px" height="10.5px" />
                    </Button>
                  )}
                </div>
                <AvInput
                  type="text"
                  placeholder="Nama Fasilitas"
                  name={`fasilitas-item_name-${index}`}
                  value={facility.item_name}
                  onChange={handleChangeFacilities}
                  required
                />
                {/* <AvFeedback>Please input facilities name</AvFeedback> */}
                <AvInput
                  className="mt-2"
                  type="text"
                  placeholder="Deskripsi Fasilitas"
                  name={`fasilitas-description-${index}`}
                  value={facility.description}
                  onChange={handleChangeFacilities}
                  required
                />
                {/* <AvFeedback>Please input facilities description</AvFeedback> */}
                <ImageFormFacility
                  facilityIndex={index}
                  setFormValue={setFormValue}
                  formValue={formValue}
                />
              </Fragment>
            ))}
        </AvGroup>
        <AvFeedback>Please input room capacity</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="note">
          Note
        </label>
        <AvInput
          type="text"
          placeholder="Note"
          name="note"
          value={formValue.note}
          onChange={handleChange}
        />
      </AvGroup>
      <AvGroup>
        <label className="form-control-label" htmlFor="description">
          Deskripsi
        </label>
        <AvInput
          placeholder="Deskripsi Ruangan"
          name="description"
          value={formValue.description}
          onChange={handleChange}
        />
        <AvFeedback>Please input room capacity</AvFeedback>
      </AvGroup>
      <AvGroup>
        <label className="form-control-label">Foto ruangan</label>
        <AvFeedback>Please upload room image</AvFeedback>
        <div
          onDragEnter={dragHandler}
          style={{
            margin: "0 auto",
            width: "100%",
            height: "500px",
            position: "relative",
            background: dragActive ? "#ccc" : "#fff",
            borderRadius: "5px",
            border: "1px dashed #444",
          }}
        >
          <AvInput
            ref={inputRef}
            type="file"
            id="image-room"
            name="image"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleChangeImage}
            hidden
          />
          {!dataImage.imageSrc && !formValue.image && (
            <label
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              htmlFor="image-room"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UploadIcon />
                <p className="mb-0 mt-2 font-weight-bold">
                  Drag and Drop to Upload Image
                </p>
                <p className="mb-0 font-weight-bold">or</p>
                <p className="mb-2 font-weight-bold">Click to Upload Image</p>
                <p className="mb-0">Accepts image file formats</p>
                <p className="mb-0">Size Limit 4 MB</p>
              </div>
            </label>
          )}
          {(dataImage.imageSrc || formValue.image) && (
            <div
              style={{
                maxWidth: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={dataImage.imageSrc || formValue.image}
                style={{
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
                alt={dataImage.imageSrc || formValue.image}
              />
            </div>
          )}
          {(dataImage.imageSrc || formValue.image) && (
            <Button
              className="position-absolute border-0 shadow-none"
              style={{
                right: "5px",
                top: "5px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setDataImage({
                  selectedFile: null,
                  selectedFileName: null,
                  imageSrc: null,
                  value: "",
                });
                setFormValue({ ...formValue, image: "" });
              }}
            >
              <BinIcon />
            </Button>
          )}
          {dragActive && (
            <div
              style={{
                position: "absolute",
                inset: "0px 0px 0px 0px",
                width: "100%",
                height: "100%",
              }}
              onDragEnter={dragHandler}
              onDragLeave={dragHandler}
              onDragOver={dragHandler}
              onDrop={dropHandler}
            ></div>
          )}
        </div>
      </AvGroup>
      <div>
        <Button color="info" type="submit" size="md">
          {isEdit ? "Edit" : "Add"} Room
        </Button>
      </div>
    </AvForm>
  );
}

export default AddRoomForm;
