import React, { useState, useCallback } from "react";
import Axios from "utils/axiosService";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Button } from "@mui/material";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";
import { utils, writeFile } from "xlsx";

import AddCarModal from "components/bookingCar/AddCarModal";
import EditCarModal from "components/bookingCar/EditCarModal";

import "bootstrap/dist/css/bootstrap.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import "moment/locale/id";

moment.locale("id");

export const generateQueryFilter = (filter) => {
  let queryFilter = "";
  const { statuses, categories, query } = filter;

  const maskedCategories = categories.map((category) => {
    switch (category) {
      case "operational":
        return "Operasional";
      case "perjadin":
        return "Perjadin";
      case "shuttle":
        return "Shuttle";
      default:
        return category;
    }
  });

  const maskedStatuses = statuses.map((status) => {
    switch (status) {
      case "available":
        return "Tersedia";
      case "in_use":
        return "Sedang Digunakan";
      case "booked":
        return "Dibooking";
      case "maintenance":
        return "Maintenance";
      case "inactive":
        return "Inactive";
      default:
        return status;
    }
  });

  if (maskedStatuses.length > 0) {
    queryFilter += `&status=${maskedStatuses.join(",")}`;
  }

  if (maskedCategories.length > 0) {
    queryFilter += `&car_category=${maskedCategories.join(",")}`;
  }

  if (query) {
    queryFilter += `&query=${query}`;
  }

  return queryFilter;
};

function CarManagementTable({
  data,
  filter,
  setFilter,
  page,
  sizePerPage,
  totalSize,
  setPage,
  setSizePerPage,
  toggleRefetch,
}) {
  // RESET FILTER
  const handleResetFilter = () => {
    setPage(1);
    setFilter({ statuses: [], categories: [], query: "" });
  };

  const isAnyFilterActive = () => {
    return (
      filter.statuses.length > 0 ||
      filter.categories.length > 0 ||
      filter.query !== ""
    );
  };

  // SEARCH BAR
  const [queryTemp, setQueryTemp] = useState(filter.query);

  const handleSearchChange = (event) => {
    setQueryTemp(event.target.value);
    debouncedSearch(event.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setFilter({ ...filter, query: value });
    }, 1000),
    []
  );

  // STATUS DROPDOWN
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [statuses, setStatuses] = useState({
    available: filter.statuses.includes("available"),
    in_use: filter.statuses.includes("in_use"),
    booked: filter.statuses.includes("booked"),
    maintenance: filter.statuses.includes("maintenance"),
    inactive: filter.statuses.includes("inactive"),
  });

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen((prevState) => !prevState);
    const isOpenState = !isStatusDropdownOpen;
    if (!isOpenState) {
      setPage(1);
      setFilter({
        ...filter,
        statuses: Object.keys(statuses).filter((status) => statuses[status]),
      });
    }
  };

  const handleStatusCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newStatuses = { ...statuses, [name]: checked };
    setStatuses(newStatuses);
  };

  const statusItems = [
    {
      id: "check_category_available",
      name: "available",
      label: "Tersedia",
    },
    {
      id: "check_category_in_use",
      name: "in_use",
      label: "Sedang Digunakan",
    },
    {
      id: "check_category_booked",
      name: "booked",
      label: "Dibooking",
    },
    {
      id: "check_category_maintenance",
      name: "maintenance",
      label: "Maintenance",
    },
    {
      id: "check_category_inactive",
      name: "inactive",
      label: "Inactive",
    },
  ];

  const CheckboxItem = ({ id, name, checked, onChange, label }) => (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );

  // CATEGORY DROPDOWN
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [categories, setCategories] = useState({
    operational: filter.categories.includes("operational"),
    perjadin: filter.categories.includes("perjadin"),
    shuttle: filter.categories.includes("shuttle"),
  });

  const toggleCategoryDropdown = () => {
    setIsCategoryDropdownOpen((prevState) => !prevState);
    const isOpenState = !isCategoryDropdownOpen;
    if (!isOpenState) {
      setPage(1);
      setFilter({
        ...filter,
        categories: Object.keys(categories).filter(
          (category) => categories[category]
        ),
      });
    }
  };

  const handleCategoryCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newCategories = { ...categories, [name]: checked };
    setCategories(newCategories);
  };

  const categoryItems = [
    {
      id: "check_category_operational",
      name: "operational",
      label: "Operasional",
    },
    {
      id: "check_category_perjadin",
      name: "perjadin",
      label: "Perjadin",
    },
    {
      id: "check_category_shuttle",
      name: "shuttle",
      label: "Shuttle",
    },
  ];

  // DOWNLOAD
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const handleDownload = async () => {
    setIsLoadingDownload(true);

    const queryFilter = generateQueryFilter(filter);
    await Axios({
      method: "GET",
      url: "/admin/car/cars/export?" + queryFilter,
    })
      .then((r) => {
        const cars = r.data.data.cars;
        const formattedCars = cars.map((car) => ({
          id: car.id,
          brand: car.brand,
          name: car.name,
          license_plate: car.license_plate,
          license_expiry: car.license_expiry,
          fuel: car.fuel,
          capacity: car.capacity,
          image: car.image,
          is_active: car.is_active,
          car_status: car.car_status,
          status: car.status,
          current_location: car.current_location,
          license_plate_type: car.license_plate_type,
          location_url: car.location_url,
          car_category: car.car_category,
          current_odo: car.current_odo,
          current_fuel: car.current_fuel,
        }));

        const worksheet = utils.json_to_sheet(formattedCars);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Cars");

        const date = new Date();
        const dateString = date
          .toISOString()
          .replace(/[-T:.Z]/g, "")
          .slice(0, 14);
        const filename = `Booking Car - Data Mobil - ${dateString}.xlsx`;

        writeFile(workbook, filename);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoadingDownload(false);
      });
  };

  // ADD EDIT CAR
  const [selectedCar, setSelectedCar] = useState({});
  const [isOpenAddCarModal, setIsOpenAddCarModal] = useState(false);
  const [isOpenEditCarModal, setIsOpenEditCarModal] = useState(false);
  const toggleAddCarModal = () =>
    setIsOpenAddCarModal((prevState) => !prevState);
  const toggleEditCarModal = () => {
    setIsOpenEditCarModal((prevState) => !prevState);
    if (isOpenEditCarModal) {
      setSelectedCar({});
    }
  };

  const headerStyle = () => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };

  const formatDate = (date) => {
    return moment(date, "YYYY-MM-DD").format("DD-MMMM-YYYY");
  };

  const getDateStyle = (date) => {
    return moment(date, "YYYY-MM-DD").isSameOrBefore(moment(), "day")
      ? {
          backgroundColor: "#D17D78",
          borderRadius: "100px",
          padding: "4px 8px",
          color: "white",
        }
      : {};
  };

  const pageButtonRenderer = ({
    page,
    active,
    disabled,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const style = {
      color: "#184973",
      background: "white",
      fontSize: "12px",
      border: "1px solid #184973",
      textTransform: "none",
      "font-weight": "bold",
    };

    if (active) {
      style.background = "#184973";
      style.color = "white";
    }
    if (typeof page !== "number" && !disabled) {
      style.border = "1px solid #184973";
    } else if (typeof page !== "number" && disabled) {
      style.color = "rgba(0, 0, 0, .3)";
      style.border = "1px solid rgba(0, 0, 0, .3)";
    }

    return (
      <li className="page-item ml-2">
        <button
          disabled={disabled}
          onClick={handleClick}
          type="button"
          style={style}
          className="btn btn-square-md rounded-sm"
        >
          {page}
        </button>
      </li>
    );
  };

  const options = {
    page,
    sizePerPage,
    totalSize,
    showTotal: true,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    pageButtonRenderer,
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setSizePerPage(sizePerPage);
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                setPage(1);
                setSizePerPage(e.target.value);
              }}
              value={sizePerPage}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
    },
    {
      dataField: "name",
      text: "Nama",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell, row) => `${row.brand} ${cell}`,
      style: {
        maxWidth: "200px",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    {
      dataField: "license_plate",
      text: "Plat Nomor",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
    },
    {
      dataField: "license_expiry",
      text: "Plat Expired",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell) =>
        cell ? (
          <span style={getDateStyle(cell)}>{formatDate(cell)}</span>
        ) : (
          <span>-</span>
        ),
    },
    {
      dataField: "capacity",
      text: "Kapasitas",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell) => <span>{cell} Orang</span>,
    },
    {
      dataField: "current_location",
      text: "Lokasi",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      style: {
        maxWidth: "250px",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    {
      dataField: "current_odo",
      text: "Kilometer",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell) =>
        cell ? (
          <span>
            {cell.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Km
          </span>
        ) : (
          <span>-</span>
        ),
    },
    {
      dataField: "current_fuel",
      text: "Bensin(%)",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell) => (cell ? <span>{cell}%</span> : <span>-</span>),
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (status) => {
        let color;

        switch (status) {
          case "Sedang Digunakan":
            color = "#3E7CA8";
            break;
          case "Tersedia":
            color = "#0FB900";
            break;
          case "Maintenance":
            color = "#B3261E";
            break;
          case "Inactive":
            color = "black";
            break;
          default:
            color = "black";
        }

        return (
          <span style={{ color: color, fontWeight: "bold" }}>{status}</span>
        );
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell, row) => (
        <Button
          id={`btn-edit-${row.id}`}
          variant="outlined"
          type="button"
          style={{
            color: "#3E7CA8",
            background: "white",
            fontSize: "12px",
            border: "2px solid #3E7CA8",
            textTransform: "none",
          }}
          onClick={() => {
            setSelectedCar(row);
            setIsOpenEditCarModal(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <ToolkitProvider data={data} keyField="id" columns={columns}>
        {(props) => (
          <div className="table-responsive overflow-x-hidden">
            <div className="d-flex" style={{ background: "#F8F9FE" }}>
              <div className="d-flex w-100 justify-content-between py-3 px-1">
                <div>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <input
                      className="form-control-sm font-size-16"
                      placeholder="Type to search..."
                      style={{
                        height: "42px",
                        color: "#929191",
                        padding: "8px 32px 8px 8px",
                        background: "white",
                        borderRadius: "8px",
                        border: "2px solid #EFEFEF",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                      value={queryTemp}
                      onChange={handleSearchChange}
                    />
                    <i
                      className="bi bi-search"
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: "10px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex gap-2">
                  {isAnyFilterActive() && (
                    <Button
                      variant="text"
                      style={{
                        color: "#3E7CA8",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                      onClick={handleResetFilter}
                    >
                      Reset Filter
                    </Button>
                  )}

                  <Dropdown
                    isOpen={isStatusDropdownOpen}
                    toggle={toggleStatusDropdown}
                  >
                    <DropdownToggle
                      caret
                      color="black"
                      style={{
                        height: "42px",
                        background: "white",
                        border: "2px solid #EFEFEF",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <label
                        className="mr-4 my-0 font-size-16 cursor-pointer"
                        style={{ color: "#929191" }}
                      >
                        <i className="bi bi-car-front-fill"></i> Status
                      </label>
                    </DropdownToggle>
                    <DropdownMenu className="p-2 font-size-14">
                      {statusItems.map((status) => (
                        <CheckboxItem
                          key={status.name}
                          id={status.id}
                          name={status.name}
                          checked={statuses[status.name]}
                          onChange={handleStatusCheckboxChange}
                          label={status.label}
                        />
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                  <Dropdown
                    isOpen={isCategoryDropdownOpen}
                    toggle={toggleCategoryDropdown}
                  >
                    <DropdownToggle
                      caret
                      color="black"
                      style={{
                        height: "42px",
                        background: "white",
                        border: "2px solid #EFEFEF",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <label
                        className="mr-4 my-0 font-size-16 cursor-pointer"
                        style={{ color: "#929191" }}
                      >
                        <i className="bi bi-car-front-fill"></i> Kategori
                      </label>
                    </DropdownToggle>
                    <DropdownMenu className="p-2 font-size-14">
                      {categoryItems.map((category) => (
                        <CheckboxItem
                          key={category.name}
                          id={category.id}
                          name={category.name}
                          checked={categories[category.name]}
                          onChange={handleCategoryCheckboxChange}
                          label={category.label}
                        />
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                  <Button
                    variant="outlined"
                    style={{
                      height: "42px",
                      display: "flex",
                      columnGap: "8px",
                      color: "#3E7CA8",
                      background: "white",
                      padding: "8px 16px",
                      fontSize: "16px",
                      borderRadius: "8px",
                      border: "2px solid #3E7CA8",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      textTransform: "none",
                    }}
                    onClick={handleDownload}
                    disabled={isLoadingDownload}
                  >
                    {isLoadingDownload ? (
                      <>
                        <Loader
                          type="Oval"
                          color="white"
                          height={20}
                          width={20}
                        />{" "}
                        Download
                      </>
                    ) : (
                      <>
                        <i class="bi bi-download mr-2"></i> Download
                      </>
                    )}
                  </Button>

                  <Button
                    variant="contained"
                    style={{
                      height: "42px",
                      color: "white",
                      background: "#3E7CA8",
                      padding: "8px 16px",
                      fontSize: "16px",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      border: "2px solid #3E7CA8",
                      textTransform: "none",
                    }}
                    onClick={toggleAddCarModal}
                  >
                    <i class="bi bi-plus-circle mr-2"></i> Tambah Mobil
                  </Button>
                </div>
              </div>
            </div>
            <div
              style={{
                background: "white",
                margin: "4px",
                borderRadius: "8px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                paddingTop: "16px",
                color: "#8898AA",
              }}
            >
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                remote
                onTableChange={() => {}}
                bordered={false}
                id="react-bs-table"
                bootstrap4={true}
                noDataIndication="No User Found"
                rowClasses={(row, rowIndex) => "row-style-admin"}
                pagination={paginationFactory(options)}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>

      <AddCarModal isOpen={isOpenAddCarModal} toggle={toggleAddCarModal} />
      <EditCarModal
        isOpen={isOpenEditCarModal}
        toggle={toggleEditCarModal}
        carId={selectedCar.id}
        refetchData={toggleRefetch}
      />

      <style jsx>
        {`
          .row-style-admin td {
            padding: 12px;
            vertical-align: middle;
            color: #8898aa;
            max-width: 300px;
          }
          .header-style-admin {
            background-color: #f6f9fc !important;
            color: #8898aa !important;
          }
          .header-style-admin label input {
            height: 30px;
          }
          .header-style-admin label select {
            height: 30px;
            padding: 6px;
          }
          .header-style-admin-number div label input {
            height: 30px;
            min-width: 50px;
            width: 100%;
            padding: 4px;
          }
          .header-style-admin-number div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
          .header-style-admin-date div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
          .header-style-admin-date div label input {
            height: 30px;
            min-width: 120px;
            width: 100%;
            padding: 4px;
          }
          .page-item.active .page-link {
            background-color: #3e7ca8;
            border-color: #3e7ca8;
          }
          .page-link {
            color: #3e7ca8;
          }
        `}
      </style>
    </>
  );
}

export default CarManagementTable;
