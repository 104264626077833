import React, { useState, useCallback } from "react";
import Axios from "utils/axiosService";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import { Button } from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useSnackbar } from "components/Snackbar/snackbar";

import Loader from "react-loader-spinner";
import { debounce } from "lodash";
import { utils, writeFile } from "xlsx";

import "bootstrap/dist/css/bootstrap.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import "moment/locale/id";

moment.locale("id");

export const generateQueryFilter = (filter) => {
  let queryFilter = "";
  const { statuses, query } = filter;

  if (statuses.length > 0) {
    queryFilter += `&status=${statuses.join(",")}`;
  }

  if (query) {
    queryFilter += `&query=${query}`;
  }

  return queryFilter;
};

function UserManagementTable({
  data,
  filter,
  setFilter,
  page,
  sizePerPage,
  totalSize,
  setPage,
  setSizePerPage,
  toggleRefetch,
}) {
  const { setSnackbarState } = useSnackbar();

  // RESET FILTER
  const handleResetFilter = () => {
    setPage(1);
    setFilter({ statuses: [], query: "" });
  };

  const isAnyFilterActive = () => {
    return filter.statuses.length > 0 || filter.query;
  };

  // SEARCH BAR
  const [queryTemp, setQueryTemp] = useState(filter.query);

  const handleSearchChange = (event) => {
    setQueryTemp(event.target.value);
    debouncedSearch(event.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setFilter({ ...filter, query: value });
    }, 1000),
    []
  );

  // STATUS DROPDOWN
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [statuses, setStatuses] = useState({
    requested: filter.statuses.includes("requested"),
    accepted: filter.statuses.includes("accepted"),
    rejected: filter.statuses.includes("rejected"),
    inactive: filter.statuses.includes("inactive"),
  });

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen((prevState) => !prevState);
    const isOpenState = !isStatusDropdownOpen;
    if (!isOpenState) {
      setPage(1);
      setFilter({
        ...filter,
        statuses: Object.keys(statuses).filter((status) => statuses[status]),
      });
    }
  };

  const handleStatusCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newStatuses = { ...statuses, [name]: checked };
    setStatuses(newStatuses);
  };

  const maskStatus = (status) => {
    switch (status) {
      case "requested":
        return "Diajukan";
      case "accepted":
        return "Aktif";
      case "rejected":
        return "Ditolak";
      case "inactive":
        return "Tidak Aktif";
      default:
        return status;
    }
  };

  const CheckboxItem = ({ id, name, checked, onChange, label }) => (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );

  const statusItems = [
    {
      id: "check_status_requested",
      name: "requested",
      label: maskStatus("requested"),
    },
    {
      id: "check_status_accepted",
      name: "accepted",
      label: maskStatus("accepted"),
    },
    {
      id: "check_status_rejected",
      name: "rejected",
      label: maskStatus("rejected"),
    },
    {
      id: "check_status_inactive",
      name: "inactive",
      label: maskStatus("inactive"),
    },
  ];

  // DOWNLOAD
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const handleDownload = async () => {
    setIsLoadingDownload(true);
    const queryFilter = generateQueryFilter(filter);
    await Axios({
      method: "GET",
      url: "/admin/car/user/export?" + queryFilter,
    })
      .then((r) => {
        const users = r.data.data.users;
        const formattedUsers = users.map((user) => ({
          id: user.id,
          user_name: user.user_name,
          user_email: user.user_email,
          user_contact: user.user_contact,
          license_number: user.license_number,
          license_expired: user.license_expired,
          license_image: user.license_image,
          status: user.status,
          position_id_fk: user.user.position_id_fk,
          department_id_fk: user.user.department_id_fk,
          division_id_fk: user.user.division_id_fk,
          directorate_id_fk: user.user.directorate_id_fk,
          user_company: user.user.user_company,
          user_gender: user.user.user_gender,
          work_location_id_fk: user.user.work_location_id_fk,
          user_nik: user.user.user_nik,
          user_status: user.user.user_status,
          updated_by: user.user.updated_by,
          updated_at: user.user.updated_at,
        }));

        const worksheet = utils.json_to_sheet(formattedUsers);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Users");

        const date = new Date();
        const dateString = date
          .toISOString()
          .replace(/[-T:.Z]/g, "")
          .slice(0, 14);
        const filename = `Booking Car - Data User - ${dateString}.xlsx`;

        writeFile(workbook, filename);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoadingDownload(false);
      });
  };

  // TABLE ROWS
  const [isOpenSIMModal, setIsOpenSIMModal] = useState(false);
  const [SIMModalImage, setSIMModalImage] = useState("");
  const [popoversOpen, setPopoversOpen] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] = useState(false);

  const toggleSIM = () => setIsOpenSIMModal(!isOpenSIMModal);
  const toggleActionPopover = (rowId) => {
    setPopoversOpen({
      ...popoversOpen,
      [rowId]: !popoversOpen[rowId],
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleRowAction = async (id, action, userId, userEmail) => {
    try {
      setIsLoadingUpdateStatus(true);
      let requestData = { user_id: userId, username: userEmail };

      switch (action) {
        case "approve":
          requestData.status = "accepted";
          requestData.active = "true";
          break;
        case "tolak":
          requestData.status = "rejected";
          requestData.active = "true";
          break;
        case "nonaktifkan":
          requestData.active = "false";
          break;
        case "aktifkan":
          requestData.active = "true";
          break;
        default:
          break;
      }

      Axios({
        method: "PATCH",
        url: `/driver/request/${id}`,
        data: requestData,
      })
        .then((r) => {
          setSnackbarState({
            open: true,
            message: "Success updating status",
            severity: "success",
          });
        })
        .catch((e) => {
          setSnackbarState({
            open: true,
            message: `Status update failed: ${e}`,
            severity: "error",
          });
        })
        .finally(() => {
          setIsLoadingUpdateStatus(false);
          setPopoversOpen(false);
          toggleRefetch();
        });
    } catch (e) {
      setSnackbarState({
        open: true,
        message: `Status update failed: ${e}`,
        severity: "error",
      });
    }
  };

  const headerStyle = (column, colIndex) => {
    return {
      textTransform: "capitalize",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "normal",
      verticalAlign: "top",
    };
  };

  const formatDate = (date) => {
    return moment(date, "DD-MM-YYYY").format("DD-MMMM-YYYY");
  };

  const getDateStyle = (date) => {
    return moment(date, "DD-MM-YYYY").isSameOrBefore(moment(), "day")
      ? {
          backgroundColor: "#D17D78",
          borderRadius: "100px",
          padding: "4px 8px",
          color: "white",
        }
      : {};
  };

  const getActionsWithIcons = () => {
    switch (selectedStatus) {
      case "requested":
        return [
          { action: "approve", icon: <i className="bi bi-check-circle"></i> },
          { action: "tolak", icon: <i className="bi bi-x-circle"></i> },
          { action: "nonaktifkan", icon: <i className="bi bi-ban"></i> },
        ];
      case "accepted":
        return [
          { action: "tolak", icon: <i className="bi bi-x-circle"></i> },
          { action: "nonaktifkan", icon: <i className="bi bi-ban"></i> },
        ];
      case "rejected":
        return [
          { action: "approve", icon: <i className="bi bi-check-circle"></i> },
          { action: "nonaktifkan", icon: <i className="bi bi-ban"></i> },
        ];
      case "inactive":
        return [
          { action: "approve", icon: <i className="bi bi-check-circle"></i> },
          { action: "tolak", icon: <i className="bi bi-x-circle"></i> },
          { action: "aktifkan", icon: <i className="bi bi-plus-circle"></i> },
        ];
      default:
        return [];
    }
  };

  const options = {
    page,
    sizePerPage,
    totalSize,
    showTotal: true,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setSizePerPage(sizePerPage);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>Show {sizePerPage} entries.</label>
      </div>
    ),
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
    },
    {
      dataField: "user_name",
      text: "Nama User",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
    },
    {
      dataField: "directorate",
      text: "Direktorat",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
    },
    {
      dataField: "user_contact",
      text: "Nomor Kontak",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
    },
    {
      dataField: "license_number",
      text: "Nomor SIM",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
    },
    {
      dataField: "license_expired",
      text: "SIM Expired",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell) => (
        <span style={getDateStyle(cell)}>{formatDate(cell)}</span>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (status) => {
        let color;
        let text = maskStatus(status);

        switch (status) {
          case "requested":
            color = "#3E7CA8";
            break;
          case "accepted":
            color = "#0FB900";
            break;
          case "rejected":
            color = "#B3261E";
            break;
          case "inactive":
            color = "#8898AA";
            break;
          default:
            color = "inherit";
        }

        return <span style={{ color: color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      dataField: "license_image",
      text: "Gambar SIM",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell) => (
        <Button
          variant="text"
          className="btn-lg"
          style={{ color: "#222222" }}
          onClick={() => {
            setSIMModalImage(cell);
            toggleSIM();
          }}
        >
          <i className="bi bi-eye"></i>
        </Button>
      ),
    },
    {
      dataField: "",
      text: "",
      sort: false,
      headerStyle: headerStyle,
      headerClasses: "header-style-admin",
      formatter: (cell, row) => (
        <Button
          id={`button-popover-${row.id}`}
          variant="text"
          aria-controls={open ? `menu-popover-${row.id}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{ padding: "0", color: "#222222" }}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setSelectedId(row.id);
            setSelectedStatus(row.status);
            setSelectedUserId(row.user_id);
            setSelectedUserEmail(row.user_email);
            toggleActionPopover(row.id);
          }}
        >
          <i className="bi bi-three-dots"></i>
        </Button>
      ),
    },
  ];

  return (
    <>
      {!isLoadingUpdateStatus && (
        <ToolkitProvider data={data} keyField="id" columns={columns}>
          {(props) => (
            <div className="table-responsive overflow-x-hidden">
              <div className="d-flex" style={{ background: "#F8F9FE" }}>
                <div className="d-flex w-100 justify-content-between py-3 px-1">
                  <div>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <input
                        className="form-control-sm font-size-16"
                        placeholder="Type to search..."
                        style={{
                          height: "42px",
                          color: "#929191",
                          padding: "8px 32px 8px 8px",
                          background: "white",
                          borderRadius: "8px",
                          border: "2px solid #EFEFEF",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                        value={queryTemp}
                        onChange={handleSearchChange}
                      />
                      <i
                        className="bi bi-search"
                        style={{
                          position: "absolute",
                          right: "12px",
                          top: "10px",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-flex gap-2">
                    {isAnyFilterActive() && (
                      <Button
                        variant="text"
                        style={{
                          color: "#3E7CA8",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                        onClick={handleResetFilter}
                      >
                        Reset Filter
                      </Button>
                    )}

                    <Dropdown
                      isOpen={isStatusDropdownOpen}
                      toggle={toggleStatusDropdown}
                    >
                      <DropdownToggle
                        caret
                        color="black"
                        style={{
                          height: "42px",
                          background: "white",
                          border: "2px solid #EFEFEF",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <label
                          className="mr-4 my-0 font-size-16 cursor-pointer"
                          style={{ color: "#929191" }}
                        >
                          <i className="bi bi-people-fill"></i> Status
                        </label>
                      </DropdownToggle>
                      <DropdownMenu className="p-2 font-size-14">
                        {statusItems.map((status) => (
                          <CheckboxItem
                            key={status.name}
                            id={status.id}
                            name={status.name}
                            checked={statuses[status.name]}
                            onChange={handleStatusCheckboxChange}
                            label={status.label}
                          />
                        ))}
                      </DropdownMenu>
                    </Dropdown>

                    <Button
                      variant="contained"
                      style={{
                        height: "42px",
                        display: "flex",
                        columnGap: "8px",
                        background: "#3E7CA8",
                        padding: "8px 16px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        border: "2px solid #3E7CA8",
                        textTransform: "none",
                      }}
                      className="border-0 text-white"
                      onClick={handleDownload}
                      disabled={isLoadingDownload}
                    >
                      {isLoadingDownload ? (
                        <>
                          <Loader
                            type="Oval"
                            color="white"
                            height={20}
                            width={20}
                          />{" "}
                          Download
                        </>
                      ) : (
                        <>
                          <i class="bi bi-download mr-2"></i> Download
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "white",
                  margin: "4px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  paddingTop: "16px",
                  color: "#8898AA",
                }}
              >
                <BootstrapTable
                  {...props.baseProps}
                  filter={filterFactory()}
                  remote
                  onTableChange={() => {}}
                  bordered={false}
                  id="react-bs-table"
                  bootstrap4={true}
                  noDataIndication="No User Found"
                  rowClasses={(row, rowIndex) => "row-style-admin"}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      )}
      {isLoadingUpdateStatus && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Loader type="Oval" color="#30C1FF" height={50} width={50} />
        </div>
      )}

      <Modal
        isOpen={isOpenSIMModal}
        toggle={toggleSIM}
        fade={false}
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          padding: "0",
        }}
      >
        <ModalHeader toggle={toggleSIM}></ModalHeader>
        <ModalBody style={{ paddingTop: "0" }}>
          <img src={SIMModalImage} alt="License" style={{ width: "100%" }} />
        </ModalBody>
      </Modal>

      {!isLoadingUpdateStatus &&
        // cant render popover inside bootstrap table
        data.map((row) => (
          <div>
            <Button
              id={`button-popover-${row.id}`}
              aria-controls={open ? `menu-popover-${row.id}` : undefined}
              aria-expanded={open ? "true" : undefined}
              style={{ display: "none" }}
            ></Button>

            <Menu
              id={`menu-popover-${row.id}`}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {getActionsWithIcons().map(({ action, icon }) => (
                <MenuItem
                  key={action}
                  style={{ gap: "4px" }}
                  onClick={() => {
                    setAnchorEl(null);
                    handleRowAction(
                      selectedId,
                      action,
                      selectedUserId,
                      selectedUserEmail
                    );
                  }}
                >
                  {icon} {action.charAt(0).toUpperCase() + action.slice(1)}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ))}

      <style jsx>
        {`
          .row-style-admin td {
            padding: 12px;
            vertical-align: middle;
            color: #8898aa;
          }
          .header-style-admin {
            background-color: #f6f9fc !important;
            color: #8898aa !important;
          }
          .header-style-admin label input {
            height: 30px;
          }
          .header-style-admin label select {
            height: 30px;
            padding: 6px;
          }
          .header-style-admin-number div label input {
            height: 30px;
            min-width: 50px;
            width: 100%;
            padding: 4px;
          }
          .header-style-admin-number div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
          .header-style-admin-date div label select {
            height: 30px;
            width: 44px !important;
            padding: 2px;
          }
          .header-style-admin-date div label input {
            height: 30px;
            min-width: 120px;
            width: 100%;
            padding: 4px;
          }
          .page-item.active .page-link {
            background-color: #3e7ca8;
            border-color: #3e7ca8;
          }
          .page-link {
            color: #3e7ca8;
          }
          .MuiPopover-paper {
            padding: 0;
            border-radius: 8px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          }
        `}
      </style>
    </>
  );
}

export default UserManagementTable;
