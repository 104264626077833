/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import LobbyIcon from "components/Icons/LobbyIcon";
import SignOutIcon from "components/Icons/SignOutIcon";
import Axios from "utils/axiosService";

function AdminNavbar(props) {
  const { theme, sidenavOpen, toggleSidenav, entryPoint } = props;
  const { pathname } = useLocation();
  const [appData, setAppData] = useState([]);
  const [userData, setUserData] = useState();

  useEffect(() => {
    let title = pathname.split("/")[1];
    title = title
      .split("-")
      .map((t) => t.charAt(0).toUpperCase() + t.slice(1))
      .join(" ");
    document.title = title;
  }, [pathname]);

  useEffect(() => {
    const id_user = localStorage.getItem("id_user");
    Axios({
      method: "GET",
      url: `/admin?user_id=${id_user}`,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          setUserData(r.data.data.data[0]);
        } else {
          throw new Error("Error fetching user data");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.assign("./logout");
        }
      });
  }, []);

  useEffect(() => {
    if (userData) {
      if (
        userData.role.toLowerCase() === "superadmin" ||
        userData.role.toLowerCase() === "satpam"
      ) {
        Axios({
          method: "GET",
          url: `/app?status=true`,
        })
          .then((r) => {
            if (r.data.errorStatus) {
              throw new Error("Error fetching menu");
            } else {
              const role = localStorage.getItem("role");
              if (role === "Satpam") {
                const filteredData = r.data.data.data.filter(
                  (item) => item.slug === "booking-car"
                );
                setAppData(filteredData);
              } else {
                setAppData(r.data.data.data);
              }
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        Axios({
          method: "GET",
          url: `/admin-relation-app?user_admin_id=${userData.id}`,
        })
          .then((r) => {
            if (r.data.errorStatus) {
              throw new Error("Error fetching menu");
            } else {
              const data = r?.data?.data?.data ?? [];
              setAppData(data.map((item) => item.list_application));
            }
          })
          .catch((e) => {
            console.error(e);
            if (e.response.status === 401) {
              window.location.assign("./logout");
            }
          });
      }
    }
  }, [userData]);

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-light bg-white": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center d-xl-none" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "light" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none"></NavItem>
            </Nav>
            <Nav
              className="w-100 ml-auto ml-md-0 justify-content-between"
              navbar
            >
              <div
                className="d-flex align-items-center justify-content-start p-0"
                style={{ gap: "24px" }}
              >
                {appData.map((item, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{
                        whiteSpace: "nowrap",
                        textDecoration: "none",
                      }}
                      className={`nav-text ${
                        entryPoint === item.slug
                          ? "active-nav"
                          : "nonactive-nav"
                      }`}
                      to={"/" + item.slug}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </div>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link p-0" color="" tag="a">
                  <Media className="align-items-center">
                    <img
                      className="rounded-circle"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      alt="..."
                      src={require("assets/img/menu/avatar.png")}
                    />
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <Link to={"/"}>
                    <DropdownItem>
                      <LobbyIcon />
                      <span>Lobby Page</span>
                    </DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/logout" onClick={() => localStorage.clear()}>
                    <DropdownItem>
                      <SignOutIcon />
                      <span>Logout</span>
                    </DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <style jsx>
        {`
          .active-nav {
            color: #3e7ca8;
            background-color: #f5f4f4;
          }
          .nonactive-nav {
            color: #535353;
          }
          .nav-text {
            padding: 12px 24px;
            font-weight: 700;
            border-radius: 4px;
          }
          .nav-text:hover {
            background-color: #f5f4f4;
            color: #3e7ca8;
          }
        `}
      </style>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
