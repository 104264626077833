/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

// import AdminLayout from "layouts/Admin.js";
import LoginPage from "views/pages/Login.js";
import LobbyMenu from "views/pages/LobbyMenu";
import ManageAdminPage from "views/pages/manage_admin";
import AdminLayoutNew from "layouts/AdminLayoutNew";
import axios from "axios";
import { workSpaces } from "const/workSpaces";

import { SnackbarProvider } from "components/Snackbar/snackbar";

// local server
// axios.defaults.baseURL = 'http://localhost/gomess-backend/index.php/api';

// deployed server
axios.defaults.baseURL = "https://parastay-api.pti-cosmetics.com/index.php/api";

const login = localStorage.getItem("login");
const role = localStorage.getItem("role");

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      role === "Satpam" &&
      !props.location.pathname.startsWith("/booking-car") ? (
        <Redirect to="/booking-car" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

if (login === "true") {
  ReactDOM.render(
    <BrowserRouter>
      <SnackbarProvider>
        <Switch>
          <Route exact path="/logout" component={LoginPage} />
          <ProtectedRoute
            exact
            path="/booking-room"
            component={(props) => (
              <Redirect to="/booking-room/monitoring-aula" />
            )}
          />
          <ProtectedRoute
            exact
            path="/parastay"
            component={(props) => <Redirect to="/parastay/dashboard" />}
          />
          <ProtectedRoute
            exact
            path="/booking-car"
            component={(props) => (
              <Redirect to="/booking-car/booking-monitoring" />
            )}
          />
          {workSpaces.map((workSpace, index) => (
            <ProtectedRoute
              key={index}
              path={workSpace.path}
              component={(props) => (
                <AdminLayoutNew {...props} sidebarLogo={workSpace.logo} />
              )}
            />
          ))}
          <ProtectedRoute
            path="/manage-admin"
            exact
            component={ManageAdminPage}
          />
          <ProtectedRoute path="/" exact component={LobbyMenu} />
        </Switch>
      </SnackbarProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/" component={LoginPage} />
      </Switch>
    </BrowserRouter>,
    document.getElementById("root")
  );
}
