/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { CSVLink } from "react-csv";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      alert: null,
      isLoading: true,
      isEmpty: false,
    };
  }

  componentDidMount() {
    this.props.setTitle("User List");
    axios
      .get(`/userlist?`)
      .then((response) => {
        this.setState({
          ...this.state,
          users: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error.response);

        if (error.response.status === 404) {
          this.setState({
            ...this.state,
            isLoading: false,
            isEmpty: true,
          });

          Swal.fire({
            icon: "info",
            text: "List is empty!",
            confirmButtonColor: "#30C1FF",
          });
        }
      });
  }
  render() {
    const { users, isLoading, alert, isEmpty } = this.state;

    const headers_table = [
      { label: "Nama", key: "user_name" },
      { label: "Email", key: "user_email" },
      { label: "Role", key: "role_name" },
    ];

    return (
      <>
        {alert}
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                {isLoading && !isEmpty ? (
                  <div
                    className="justify-content-center text-center"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      opacity: "0.5",
                      height: "100%",
                      width: "100%",
                      zIndex: "1100",
                    }}
                  >
                    <Loader
                      type="Bars"
                      color="#30C1FF"
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <>
                    {isEmpty ? (
                      <CardBody>
                        <h1>User List is Empty !</h1>
                      </CardBody>
                    ) : (
                      <ToolkitProvider
                        data={users}
                        keyField="name"
                        columns={[
                          {
                            dataField: "user_id",
                            text: "ID",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "user_name",
                            text: "Name",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "user_email",
                            text: "Email",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "role_name",
                            text: "Role",
                            sort: true,
                            editable: true,
                          },
                          {
                            dataField: "databasePkey",
                            text: "Details",
                            editable: false,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <UserDetail detail_id_user={row.user_id} />
                                  <Link
                                    className="table-action mr-3"
                                    to={`/parastay/user-update/${row.user_id}`}
                                    style={{ color: "#30C1FF" }}
                                  >
                                    <i className="fas fa-user-edit" />
                                  </Link>
                                  <UserDelete detail_id_user={row.user_id} />
                                </>
                              );
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <Container fluid>
                              <Row>
                                <Col>
                                  <Link to={"/parastay/user-add/"}>
                                    <Button color="info" size="md">
                                      Add User
                                    </Button>
                                  </Link>
                                </Col>
                                <Col>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <CSVLink
                                      data={users}
                                      headers={headers_table}
                                      filename={"User list.csv"}
                                    >
                                      <Button
                                        style={{ marginRight: "5px" }}
                                        color="default"
                                        size="sm"
                                        className="buttons-copy buttons-html5"
                                        id="print-tooltip"
                                      >
                                        Export to CSV
                                      </Button>
                                    </CSVLink>

                                    <ButtonGroup>
                                      <Button
                                        className="buttons-copy buttons-html5"
                                        color="default"
                                        size="sm"
                                        id="copy-tooltip"
                                        onClick={() =>
                                          this.copyToClipboardAsTable(
                                            document.getElementById(
                                              "react-bs-table"
                                            )
                                          )
                                        }
                                      >
                                        <span>Copy</span>
                                      </Button>
                                      <ReactToPrint
                                        trigger={() => (
                                          <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                          >
                                            Print
                                          </Button>
                                        )}
                                        content={() => this.componentRef}
                                      />
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="print-tooltip"
                                    >
                                      This will open a print page with the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="copy-tooltip"
                                    >
                                      This will copy to your clipboard the
                                      visible rows of the table.
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-right"
                                  >
                                    <label>
                                      Search:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={(el) => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                              id="react-bs-table"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default UserList;

const UserDetail = (props) => {
  const [modal, setModal] = useState(false);
  const [detailuser, setDetailuser] = React.useState(null);
  const [toggleOpen, setToggleopen] = React.useState(null);

  const toggle = () => setModal(!modal);

  const id_user = props.detail_id_user;

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const handleClick = () => {
    axios
      .get(`/userlist?id_user=${id_user}`)
      .then((response) => {
        setDetailuser(response.data.data[0]);
        setToggleopen(toggle);
      })
      .catch((error) => {});
  };

  if (detailuser === null)
    return (
      <>
        <Button
          className="table-action mr-3"
          style={{ color: "#30C1FF" }}
          onClick={handleClick}
        >
          <i class="fas fa-eye"></i>
        </Button>
      </>
    );
  return (
    <>
      <Button
        className="table-action mr-3"
        style={{ color: "#30C1FF" }}
        onClick={handleClick}
      >
        <i class="fas fa-eye"></i>
      </Button>
      <Modal isOpen={modal} toggle={toggleOpen} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Detail User
        </ModalHeader>
        <ModalBody>
          <Table borderless size="sm" style={{ marginTop: "-30px" }}>
            <tbody>
              <tr>
                <td className="text-muted">Nama Lengkap</td>
                <td className="text-wrap">{detailuser.user_name}</td>
              </tr>
              <tr>
                <td className="text-muted">Jenis kelamin</td>
                <td className="text-wrap">{detailuser.user_gender}</td>
              </tr>
              {detailuser.user_role !== null ? (
                <tr>
                  <td className="text-muted">Role</td>
                  <td className="text-wrap">{detailuser.role_name}</td>
                </tr>
              ) : null}
              <tr>
                <td className="text-muted">Direktorat</td>
                <td className="text-wrap">{detailuser.directorate_id_fk}</td>
              </tr>
              <tr>
                <td className="text-muted">Departemen</td>
                <td className="text-wrap">{detailuser.department_id_fk}</td>
              </tr>
              <tr>
                <td className="text-muted">Divisi</td>
                <td className="text-wrap">{detailuser.division_id_fk}</td>
              </tr>
              <tr>
                <td className="text-muted">Posisi</td>
                <td className="text-wrap">{detailuser.position_id_fk}</td>
              </tr>
              <tr>
                <td className="text-muted">Nomor Telepon</td>
                <td className="text-wrap">{detailuser.user_phone}</td>
              </tr>
              <tr>
                <td className="text-muted">Email</td>
                <td className="text-wrap">{detailuser.user_email}</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
};

const UserDelete = (props) => {
  const [modal, setModal] = useState(false);
  const [detailuser, setDetailuser] = React.useState(null);
  const [toggleOpen, setToggleopen] = React.useState(null);

  const toggle = () => setModal(!modal);

  const id_user = props.detail_id_user;

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const handleClick = () => {
    axios
      .get(`/Userlist?id_user=${id_user}`)
      .then((response) => {
        setDetailuser(response.data.data[0]);
        setToggleopen(toggle);
      })
      .catch((error) => {});
  };

  const handleDelete = () => {
    let params = new URLSearchParams();
    params.append("id_user", id_user);

    axios({
      method: "delete",
      url: "/Userlist",
      data: params,
    }).then((response) => {
      if (response.status === 204) {
        Swal.fire("Deleted!", `User Has been Deleted.`, "success");
        window.location.reload();
      } else {
        Swal.fire("Error!", "Error to delete User", "error");
      }
    });
  };

  if (detailuser === null)
    return (
      <>
        <Button
          className="table-action mr-3"
          style={{ color: "#f5365c" }}
          onClick={handleClick}
        >
          <i class="fas fa-trash"></i>
        </Button>
      </>
    );
  return (
    <>
      <Button
        className="table-action table-action-delete"
        style={{ color: "#f5365c" }}
        onClick={handleClick}
      >
        <i className="fas fa-trash" />
      </Button>
      <Modal isOpen={modal} toggle={toggleOpen} centered>
        <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
        <ModalBody>
          <h3 className="text-center" style={{ marginTop: "-30px" }}>
            Hapus User Ini?
          </h3>
          <h4 className="text-center mb-4">
            Pastikan kamu menghapus user yang benar
          </h4>
          <Table borderless size="sm">
            <tbody>
              <tr>
                <td className="text-muted">Nama Lengkap</td>
                <td className="text-wrap">{detailuser.user_name}</td>
              </tr>
              {detailuser.user_role !== null ? (
                <tr>
                  <td className="text-muted">Role</td>
                  <td className="text-wrap">{detailuser.role_name}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
