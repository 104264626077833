import React, { useRef } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";

const RequiredMarks = () => <span style={{ color: "#E6001C" }}>*</span>;

export const isValidLicensePlate = (licensePlate) => {
  const pattern =
    /^([A-Z]{1,3})\s([1-9][0-9]{0,3})\s([A-Z]{0,3}|[1-9][0-9]{1,2})$/;
  return pattern.test(licensePlate);
};

const CarFormFields = ({
  carData,
  setCarData,
  carImage,
  setCarImage,
  carImagePreview,
  setCarImagePreview,
}) => {
  const fileInputRef = useRef(null);
  const handleChange = (e) => {
    setCarData({ ...carData, [e.target.name]: e.target.value });
  };

  const fieldsStyle = (v) =>{
    return v !== "" ?{
      backgroundColor: "#F8F9FD",
      color: "black",
    }:{
        backgroundColor: "white",
        color: "#ADADAD",
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5242880) {
      setCarImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCarImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.error("File too large");
    }
  };

  const handleRemoveImage = () => {
    setCarImagePreview(null);
    setCarImage(null);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="d-flex" style={{ columnGap: "24px" }}>
      <div
        className="d-flex flex-column"
        style={{ rowGap: "12px", width: "35%" }}
      >
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="name">
            Nama
            <RequiredMarks />
          </Label>
          <Input
            required
            className="common-input"
            type="text"
            name="name"
            id="name"
            placeholder="cth: Avanza Veloz"
            value={carData.name}
            onChange={handleChange}
            style={fieldsStyle(carData.name)}
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="licensePlate">
            Plat Nomor
            <RequiredMarks />
          </Label>
          <Input
            required
            className="common-input"
            type="text"
            name="licensePlate"
            id="licensePlate"
            placeholder="cth: D 3456 SED"
            value={carData.licensePlate}
            onChange={handleChange}
            style={fieldsStyle(carData.licensePlate)}
          />
          {carData.licensePlate &&
            !isValidLicensePlate(carData.licensePlate) && (
              <small style={{ color: "#E6001C" }}>
                Format plat nomor tidak valid
              </small>
            )}
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="fuel">
            Jenis Bahan Bakar
            <RequiredMarks />
          </Label>
          <Input
            required
            className="common-input"
            type="text"
            name="fuel"
            id="fuel"
            placeholder="cth: Pertamax"
            value={carData.fuel}
            onChange={handleChange}
            style={fieldsStyle(carData.fuel)}
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="currentLocation">
            Alamat Mobil
            <RequiredMarks />
          </Label>
          <Input
            required
            className="common-input"
            type="text"
            name="currentLocation"
            id="currentLocation"
            placeholder="cth: Jl. Kp. Baru IV, No.4, Pesanggrahan, Ulujami, Jakarta Selatan 12250"
            value={carData.currentLocation}
            onChange={handleChange}
            style={fieldsStyle(carData.currentLocation)}
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="maintenanceStatus">
            Status Maintenance
            <RequiredMarks />
          </Label>
          <Input
            required
            type="select"
            className="form-select"
            name="maintenanceStatus"
            id="maintenanceStatus"
            value={carData.maintenanceStatus}
            onChange={handleChange}
            style={{
              ...fieldsStyle(carData.maintenanceStatus),
              appearance: "none",
              WebkitAppearance: "none",
              MozAppearance: "none",
            }}
          >
            <option value="">Pilih</option>
            <option value="Ya">Ya</option>
            <option value="Tidak">Tidak</option>
          </Input>
        </FormGroup>
      </div>
      <div
        className="d-flex flex-column"
        style={{ rowGap: "12px", width: "35%" }}
      >
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="brand">
            Brand
            <RequiredMarks />
          </Label>
          <Input
            required
            className="common-input"
            type="text"
            name="brand"
            id="brand"
            placeholder="cth: Toyota"
            value={carData.brand}
            onChange={handleChange}
            style={fieldsStyle(carData.brand)}
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="licenseExpiry">
            Plat Expired
            <RequiredMarks />
          </Label>
          <Input
            required
            className="common-input"
            type="date"
            name="licenseExpiry"
            id="licenseExpiry"
            value={carData.licenseExpiry}
            onChange={handleChange}
            style={fieldsStyle(carData.licenseExpiry)}
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="capacity">
            Kapasitas Mobil
            <RequiredMarks />
          </Label>
          <Input
            required
            type="select"
            className="form-select"
            name="capacity"
            id="capacity"
            value={carData.capacity}
            onChange={handleChange}
            style={fieldsStyle(carData.capacity)}
          >
            <option value="">Pilih</option>
            <option value="5">5 Orang</option>
            <option value="7">7 Orang</option>
          </Input>
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="locationURL">
            Link Alamat (Google Maps)
            <RequiredMarks />
          </Label>
          <Input
            required
            className="common-input"
            type="text"
            name="locationURL"
            id="locationURL"
            placeholder="https://maps.google.com/..."
            value={carData.locationURL}
            onChange={handleChange}
            style={fieldsStyle(carData.locationURL)}
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="fw-bold" for="carStatus">
            Status Mobil (Aktif/Tidak aktif)
            <RequiredMarks />
          </Label>
          <Input
            required
            type="select"
            className="form-select"
            name="carStatus"
            id="carStatus"
            value={carData.carStatus}
            onChange={handleChange}
            style={fieldsStyle(carData.carStatus)}
          >
            <option value="">Pilih</option>
            <option value="Aktif">Aktif</option>
            <option value="Tidak aktif">Tidak aktif</option>
          </Input>
        </FormGroup>
      </div>
      <div className="d-flex flex-column" style={{ width: "30%" }}>
        <FormGroup>
          <Label htmlFor="car-image" className="fw-bold">
            Foto Mobil
          </Label>
          <div style={{ display: "flex", gap: "8px" }}>
            {carImagePreview ? (
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "300px",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #3182CE",
                  padding: "8px",
                  borderRadius: "8px",
                  fontSize: "12px",
                  fontWeight: 400,
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow:
                    "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
                }}
              >
                <Button
                  type="button"
                  size="md"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    padding: "0 4px",
                    fontSize: "16px",
                    color: "white",
                    background: "black",
                    borderRadius: "100px",
                  }}
                  onClick={handleRemoveImage}
                >
                  <i className="bi bi-x"></i>
                </Button>
                <img
                  src={carImagePreview}
                  alt="Car Preview"
                  style={{ maxHeight: "200px", maxWidth: "100%" }}
                />
                {carImage && (
                  <>
                    <div style={{ color: "#222222" }}>{carImage.name}</div>
                    <div style={{ color: "#5E5E5E" }}>
                      {(carImage.size / 1024).toFixed(2)} KB
                    </div>
                  </>
                )}
              </div>
            ) : (
              <Label
                htmlFor="car-image"
                style={{ cursor: "pointer", width: "100%" }}
              >
                <div
                  style={{
                    minHeight: "300px",
                    display: "flex",
                    rowGap: "4px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px dashed #73777F",
                    padding: "16px 8px",
                    borderRadius: "8px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: 400,
                    boxShadow:
                      "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
                  }}
                >
                  <i
                    className="bi bi-file-earmark-arrow-up"
                    style={{ fontSize: "42px" }}
                  ></i>
                  <div style={{ color: "#222222" }}>
                    Drag & drop your image here
                  </div>
                  <div style={{ color: "#5E5E5E" }}>Maksimal 5MB</div>
                </div>
              </Label>
            )}
            <Input
              id="car-image"
              type="file"
              accept=".jpg, .jpeg, .png, .heic, .heif"
              onChange={handleImageChange}
              style={{
                visibility: "hidden",
                position: "absolute",
                pointerEvents: "none",
                width: "1px",
                height: "1px",
              }}
              innerRef={fileInputRef}
            />
          </div>
          <Button
            type="button"
            style={{
              marginTop: "8px",
              width: "100%",
              background: "white",
              border: "2px solid #3E7CA8",
              color: "#3E7CA8",
            }}
            onClick={handleButtonClick}
          >
            Pilih Foto
          </Button>
        </FormGroup>
      </div>
      <style jsx>{`
        .common-input::placeholder {
          color: #ADADAD;
        }
      `}</style>
    </div>
  );
};

export default CarFormFields;
