import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import EditTermConditionForm from "components/ContentManagement/term-and-condition/EditTermConditionForm ";

function EditTermCondition({ history, match }) {
  const id = useMemo(() => {
    return match.params.id;
    // eslint-disable-next-line
  }, []);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [appList, setAppList] = useState([]);
  const [formValue, setFormValue] = useState({
    app_id: "",
    content_title: "",
    content_text: "",
    content_author: localStorage.getItem("username"),
  });
  useEffect(() => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: `/app?status=true`,
    })
      .then((r) => {
        if (r.data.errorStatus) {
          throw new Error("Error fetching menu");
        } else {
          setAppList(r.data.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: "/terms-and-conditions/" + id,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          const data = r.data.data.data ?? {};
          const { app_id, content_author, content_title, content_text, id } =
            data;

          setFormValue({
            app_id,
            content_author,
            content_title,
            content_text,
            id,
          });
        } else {
          throw new Error("Error fetching data");
        }
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [appList]);

  useEffect(() => {
    console.log(formValue);
  }, [formValue]);

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleChangeQuil = (val) => {
    setFormValue({ ...formValue, content_text: val });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formValue.app_id &&
      formValue.content_author &&
      formValue.content_title &&
      formValue.content_text
    ) {
      setIsLoading(true);
      Axios({
        method: "PATCH",
        url: "/terms-and-conditions/" + id,
        data: {
          app_id: parseInt(formValue.app_id),
          content_author: formValue.content_author,
          content_title: formValue.content_title,
          content_text: formValue.content_text,
        },
      })
        .then((r) => {
          if (!r?.data?.errorStatus) {
            Swal.fire({
              icon: "success",
              text: `Berhasil mengubah terms and conditions`,
              confirmButtonColor: "#30C1FF",
            });
            setTimeout(() => {
              history.push("/booking-room/terms-and-conditions");
            }, 1000);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            text: `Gagal mengubah terms and conditions`,
            confirmButtonColor: "#30C1FF",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClickDelete = () => {
    setIsShowDeleteModal(true);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: "/terms-and-conditions/" + id,
    })
      .then((r) => {
        if (!r?.data?.errorStatus) {
          Swal.fire({
            icon: "success",
            text: `Berhasil menghapus terms and conditions`,
            confirmButtonColor: "#30C1FF",
          });

          setTimeout(() => {
            history.replace("/booking-room/terms-and-conditions");
          }, 1000);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: `Gagal menghapus terms and conditions`,
          confirmButtonColor: "#30C1FF",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <EditTermConditionForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              formValue={formValue}
              appList={appList}
              handleChangeQuil={handleChangeQuil}
              handleClickDelete={handleClickDelete}
            />
          )}

          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
          <Modal
            isOpen={isShowDeleteModal}
            fade={false}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            toggle={() => {
              setIsShowDeleteModal(!isShowDeleteModal);
            }}
          >
            <ModalBody
              style={{
                height: "150px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 className="text-center mb-0">
                Are you sure to delete this contact?
              </h2>
              <div className="d-flex justify-content-center">
                <Button
                  color="danger"
                  className="p-0 px-2"
                  style={{
                    height: "30px",
                  }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <Button
                  color="info"
                  className="p-0 px-2"
                  style={{
                    height: "30px",
                  }}
                  onClick={(e) => {
                    setIsShowDeleteModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Container>
  );
}

export default EditTermCondition;
