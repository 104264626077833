import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import Axios from "utils/axiosService";
import Loader from "react-loader-spinner";

import Swal from "sweetalert2";
import EditContactForm from "components/ContentManagement/contact/EditContactForm";

function EditContactPerson({ match, history }) {
  const id = useMemo(() => {
    return match.params.id;
    // eslint-disable-next-line
  }, []);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    contact_name: "",
    description: "",
    contact_number: "",
  });

  useEffect(() => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: "/contact-us/" + id,
    })
      .then((r) => {
        if (!r.data.errorStatus) {
          const data = r.data.data.data ?? {};
          const {
            contact_name,
            contact_link,
            contact_number,
            contact_type,
            description,
            id,
          } = data;
          setFormValue({
            contact_name,
            contact_link,
            contact_number,
            contact_type,
            description,
            id,
          });
        } else {
          throw new Error("Error fetching data");
        }
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formValue.contact_name &&
      formValue.contact_number &&
      formValue.contact_link &&
      formValue.contact_type
    ) {
      setIsLoading(true);
      Axios({
        method: "PATCH",
        url: "/contact-us/" + id,
        data: {
          contact_name: formValue.contact_name,
          contact_number: formValue.contact_number,
          description: formValue.description,
          contact_link: formValue.contact_link,
          contact_type: formValue.contact_type,
        },
      })
        .then((r) => {
          if (!r?.data?.errorStatus) {
            Swal.fire({
              icon: "success",
              text: `Berhasil mengubah contact`,
              confirmButtonColor: "#30C1FF",
            });
            setTimeout(() => {
              history.push("/booking-room/contact-person");
            }, 1000);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            text: `Gagal mengubah contact`,
            confirmButtonColor: "#30C1FF",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClickDelete = () => {
    setIsShowDeleteModal(true);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: "/contact-us/" + id,
    })
      .then((r) => {
        if (!r?.data?.errorStatus) {
          Swal.fire({
            icon: "success",
            text: `Berhasil menghapus contact`,
            confirmButtonColor: "#30C1FF",
          });

          setTimeout(() => {
            history.replace("/booking-room/contact-person");
          }, 1000);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: `Gagal menghapus contact`,
          confirmButtonColor: "#30C1FF",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <EditContactForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              formValue={formValue}
              handleClickDelete={handleClickDelete}
            />
          )}
          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isShowDeleteModal}
        fade={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowDeleteModal(!isShowDeleteModal);
        }}
      >
        <ModalBody
          style={{
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 className="text-center mb-0">
            Are you sure to delete this contact?
          </h2>
          <div className="d-flex justify-content-center">
            <Button
              color="danger"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              color="info"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                setIsShowDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default EditContactPerson;
