import React from "react";

function SuitcaseIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.05 2.6H9.1V1.95C9.1 1.43283 8.89455 0.936838 8.52886 0.571142C8.16316 0.205446 7.66717 0 7.15 0H5.85C5.33283 0 4.83684 0.205446 4.47114 0.571142C4.10545 0.936838 3.9 1.43283 3.9 1.95V2.6H1.95C1.43283 2.6 0.936838 2.80545 0.571142 3.17114C0.205446 3.53684 0 4.03283 0 4.55V10.4C0 10.9172 0.205446 11.4132 0.571142 11.7789C0.936838 12.1446 1.43283 12.35 1.95 12.35H11.05C11.5672 12.35 12.0632 12.1446 12.4289 11.7789C12.7946 11.4132 13 10.9172 13 10.4V4.55C13 4.03283 12.7946 3.53684 12.4289 3.17114C12.0632 2.80545 11.5672 2.6 11.05 2.6ZM5.2 1.95C5.2 1.77761 5.26848 1.61228 5.39038 1.49038C5.51228 1.36848 5.67761 1.3 5.85 1.3H7.15C7.32239 1.3 7.48772 1.36848 7.60962 1.49038C7.73152 1.61228 7.8 1.77761 7.8 1.95V2.6H5.2V1.95ZM11.7 10.4C11.7 10.5724 11.6315 10.7377 11.5096 10.8596C11.3877 10.9815 11.2224 11.05 11.05 11.05H1.95C1.77761 11.05 1.61228 10.9815 1.49038 10.8596C1.36848 10.7377 1.3 10.5724 1.3 10.4V7.1175H3.25V7.8C3.25 7.97239 3.31848 8.13772 3.44038 8.25962C3.56228 8.38152 3.72761 8.45 3.9 8.45C4.07239 8.45 4.23772 8.38152 4.35962 8.25962C4.48152 8.13772 4.55 7.97239 4.55 7.8V7.1175H8.45V7.8C8.45 7.97239 8.51848 8.13772 8.64038 8.25962C8.76228 8.38152 8.92761 8.45 9.1 8.45C9.27239 8.45 9.43772 8.38152 9.55962 8.25962C9.68152 8.13772 9.75 7.97239 9.75 7.8V7.1175H11.7V10.4ZM11.7 5.85H1.3V4.55C1.3 4.37761 1.36848 4.21228 1.49038 4.09038C1.61228 3.96848 1.77761 3.9 1.95 3.9H11.05C11.2224 3.9 11.3877 3.96848 11.5096 4.09038C11.6315 4.21228 11.7 4.37761 11.7 4.55V5.85Z"
        fill="black"
      />
    </svg>
  );
}

export default SuitcaseIcon;
