const iconOptions = [
    "fa-solid fa-car",
    "fa-solid fa-home",
    "fa-solid fa-bed",
    "fa-solid fa-square-parking",
    "fa-solid fa-desktop",
    "fa-solid fa-briefcase",
    "fa-solid fa-handshake-simple",
    "fa-solid fa-bath",
    "fa-solid fa-box-archive",
    "fa-solid fa-folder",
    "fa-solid fa-book",
    "fa-solid fa-bookmark",
    "fa-solid fa-bell",
    "fa-solid fa-car-side",
    "fa-solid fa-envelope",
    "fa-solid fa-map",
    "fa-solid fa-tv",
    "fa-solid fa-anchor",
    "fa-solid fa-gamepad",
    "fa-solid fa-cart-shopping",
    "fa fa-floppy-o",
    "fa fa-address-book-o",
    "fa fa-exclamation-triangle",
    "fa fa-cloud-upload",
    "fa fa-whatsapp",
    "fa fa-picture-o",
    "fa fa-arrow-circle-o-right",
    "fa fa-hand-lizard-o",
    "fa fa-user-circle",
    "fa fa-bus",
    "fa fa-mercury",
    "fa fa-forward",
    "fa fa-plus",
    "fa fa-css3",
    "fa fa-question-circle-o",
    "fa fa-building",
    "fa fa-fire-extinguisher",
    "fa fa-area-chart",
    "fa fa-wheelchair-alt",
    "fa fa-rub",
    "fa fa-backward",
    "fa fa-angellist",
    "fa fa-camera",
    "fa fa-first-order",
    "fa fa-qrcode",
    "fa fa-list-ol",
    "fa fa-envelope-o",
    "fa fa-television",
    "fa fa-maxcdn",
    "fa fa-envelope-open-o",
    "fa fa-calendar",
    "fa fa-wheelchair",
    "fa fa-caret-square-o-right",
    "fa fa-trello",
    "fa fa-rss-square",
    "fa fa-thermometer-quarter",
    "fa fa-facebook",
    "fa fa-amazon",
    "fa fa-empire",
    "fa fa-outdent",
    "fa fa-cloud-download",
    "fa fa-arrow-left",
    "fa fa-thumbs-o-up",
    "fa fa-bluetooth-b",
    "fa fa-file-word-o",
    "fa fa-medkit",
    "fa fa-calendar-times-o",
    "fa fa-cc-visa",
    "fa fa-indent",
    "fa fa-behance-square",
    "fa fa-gbp",
    "fa fa-pinterest",
    "fa fa-files-o",
    "fa fa-caret-square-o-left",
    "fa fa-battery-empty",
    "fa fa-yahoo",
    "fa fa-cogs",
    "fa fa-internet-explorer",
    "fa fa-tint",
    "fa fa-arrow-circle-left",
    "fa fa-futbol-o",
    "fa fa-clipboard",
    "fa fa-tumblr-square",
    "fa fa-hand-peace-o",
    "fa fa-linux",
    "fa fa-th-large",
    "fa fa-flask",
    "fa fa-star",
    "fa fa-drupal",
    "fa fa-file-powerpoint-o",
    "fa fa-industry",
    "fa fa-scissors",
    "fa fa-long-arrow-down",
    "fa fa-sellsy",
    "fa fa-file-code-o",
    "fa fa-chevron-circle-up",
    "fa fa-binoculars",
    "fa fa-sun-o",
    "fa fa-plane",
    "fa fa-bold",
    "fa fa-meh-o",
    "fa fa-align-left",
    "fa fa-tencent-weibo",
    "fa fa-sliders",
    "fa fa-upload",
    "fa fa-paper-plane",
    "fa fa-building-o",
    "fa fa-steam",
    "fa fa-folder-open",
    "fa fa-pied-piper",
    "fa fa-venus-double",
    "fa fa-user-plus",
    "fa fa-address-card",
    "fa fa-mouse-pointer",
    "fa fa-list",
    "fa fa-calendar-check-o",
    "fa fa-facebook-square",
    "fa fa-glide",
    "fa fa-refresh",
    "fa fa-bell-slash",
    "fa fa-wrench",
    "fa fa-long-arrow-right",
    "fa fa-mars-stroke",
    "fa fa-beer",
    "fa fa-meetup",
    "fa fa-sort-amount-desc",
    "fa fa-caret-down",
    "fa fa-viadeo-square",
    "fa fa-file-text-o",
    "fa fa-cc",
    "fa fa-tachometer",
    "fa fa-user-md",
    "fa fa-sort-alpha-asc",
    "fa fa-briefcase",
    "fa fa-plus-circle",
    "fa fa-umbrella",
    "fa fa-angle-up",
    "fa fa-flag-checkered",
    "fa fa-stumbleupon",
    "fa fa-music",
    "fa fa-arrows-v",
    "fa fa-git",
    "fa fa-arrow-right",
    "fa fa-pie-chart",
    "fa fa-toggle-off",
    "fa fa-ambulance",
    "fa fa-adjust",
    "fa fa-reddit-alien",
    "fa fa-vine",
    "fa fa-graduation-cap",
    "fa fa-search-minus",
    "fa fa-play",
    "fa fa-heartbeat",
    "fa fa-step-backward",
    "fa fa-file-audio-o",
    "fa fa-gitlab",
    "fa fa-twitter",
    "fa fa-caret-left",
    "fa fa-terminal",
    "fa fa-object-ungroup",
    "fa fa-arrows-h",
    "fa fa-bullhorn",
    "fa fa-text-width",
    "fa fa-angle-double-left",
    "fa fa-hourglass-half",
    "fa fa-circle-thin",
    "fa fa-university",
    "fa fa-magnet",
    "fa fa-mixcloud",
    "fa fa-rss",
    "fa fa-deaf",
    "fa fa-stop-circle",
    "fa fa-key",
    "fa fa-wordpress",
    "fa fa-fast-backward",
    "fa fa-copyright",
    "fa fa-calendar-o",
    "fa fa-sign-in",
    "fa fa-stop-circle-o",
    "fa fa-sitemap",
    "fa fa-soundcloud",
    "fa fa-paint-brush",
    "fa fa-ticket",
    "fa fa-thermometer-half",
    "fa fa-times-circle",
    "fa fa-hand-rock-o",
    "fa fa-comments-o",
    "fa fa-cloud",
    "fa fa-bullseye",
    "fa fa-low-vision",
    "fa fa-volume-control-phone",
    "fa fa-etsy",
    "fa fa-car",
    "fa fa-foursquare",
    "fa fa-shopping-bag",
    "fa fa-microchip",
    "fa fa-bell-o",
    "fa fa-spinner",
    "fa fa-street-view",
    "fa fa-sort-alpha-desc",
    "fa fa-eercast",
    "fa fa-renren",
    "fa fa-check-square-o",
    "fa fa-times",
    "fa fa-user-circle-o",
    "fa fa-mobile",
    "fa fa-cube",
    "fa fa-comment-o",
    "fa fa-crop",
    "fa fa-arrow-circle-o-up",
    "fa fa-codepen",
    "fa fa-square",
    "fa fa-thermometer-empty",
    "fa fa-file-text",
    "fa fa-taxi",
    "fa fa-superpowers",
    "fa fa-user-o",
    "fa fa-file-archive-o",
    "fa fa-italic",
    "fa fa-camera-retro",
    "fa fa-comment",
    "fa fa-archive",
    "fa fa-align-justify",
    "fa fa-commenting",
    "fa fa-sort-numeric-asc",
    "fa fa-hourglass-o",
    "fa fa-video-camera",
    "fa fa-check-square",
    "fa fa-arrow-circle-down",
    "fa fa-print",
    "fa fa-minus",
    "fa fa-pause-circle-o",
    "fa fa-snapchat-square",
    "fa fa-ship",
    "fa fa-chevron-right",
    "fa fa-pause",
    "fa fa-download",
    "fa fa-android",
    "fa fa-linkedin",
    "fa fa-neuter",
    "fa fa-viadeo",
    "fa fa-hand-o-up",
    "fa fa-free-code-camp",
    "fa fa-angle-double-up",
    "fa fa-signal",
    "fa fa-cc-stripe",
    "fa fa-fax",
    "fa fa-volume-off",
    "fa fa-hand-scissors-o",
    "fa fa-map-pin",
    "fa fa-paperclip",
    "fa fa-train",
    "fa fa-lemon-o",
    "fa fa-spoon",
    "fa fa-battery-quarter",
    "fa fa-file-excel-o",
    "fa fa-tty",
    "fa fa-lock",
    "fa fa-check-circle-o",
    "fa fa-cc-amex",
    "fa fa-th",
    "fa fa-cc-diners-club",
    "fa fa-yoast",
    "fa fa-truck",
    "fa fa-tag",
    "fa fa-female",
    "fa fa-circle",
    "fa fa-connectdevelop",
    "fa fa-long-arrow-up",
    "fa fa-list-alt",
    "fa fa-microphone",
    "fa fa-window-restore",
    "fa fa-github-alt",
    "fa fa-file-o",
    "fa fa-long-arrow-left",
    "fa fa-strikethrough",
    "fa fa-fighter-jet",
    "fa fa-paw",
    "fa fa-info-circle",
    "fa fa-pencil",
    "fa fa-align-center",
    "fa fa-transgender",
    "fa fa-paypal",
    "fa fa-chevron-circle-left",
    "fa fa-arrow-circle-o-left",
    "fa fa-braille",
    "fa fa-bicycle",
    "fa fa-rocket",
    "fa fa-digg",
    "fa fa-hand-pointer-o",
    "fa fa-wifi",
    "fa fa-mars-stroke-v",
    "fa fa-thumb-tack",
    "fa fa-delicious",
    "fa fa-bookmark",
    "fa fa-external-link-square",
    "fa fa-bomb",
    "fa fa-eur",
    "fa fa-modx",
    "fa fa-pinterest-p",
    "fa fa-cc-mastercard",
    "fa fa-sticky-note",
    "fa fa-usb",
    "fa fa-desktop",
    "fa fa-y-combinator",
    "fa fa-envelope",
    "fa fa-themeisle",
    "fa fa-times-circle-o",
    "fa fa-square-o",
    "fa fa-try",
    "fa fa-xing-square",
    "fa fa-handshake-o",
    "fa fa-retweet",
    "fa fa-shopping-cart",
    "fa fa-simplybuilt",
    "fa fa-trash",
    "fa fa-fonticons",
    "fa fa-headphones",
    "fa fa-krw",
    "fa fa-fire",
    "fa fa-mars-double",
    "fa fa-random",
    "fa fa-btc",
    "fa fa-hand-o-down",
    "fa fa-share",
    "fa fa-snapchat",
    "fa fa-skyatlas",
    "fa fa-align-right",
    "fa fa-cart-plus",
    "fa fa-caret-right",
    "fa fa-opencart",
    "fa fa-hand-spock-o",
    "fa fa-steam-square",
    "fa fa-shield",
    "fa fa-slideshare",
    "fa fa-optin-monster",
    "fa fa-folder-open-o",
    "fa fa-tumblr",
    "fa fa-flickr",
    "fa fa-html5",
    "fa fa-bed",
    "fa fa-star-o",
    "fa fa-keyboard-o",
    "fa fa-angle-right",
    "fa fa-hdd-o",
    "fa fa-leanpub",
    "fa fa-google-wallet",
    "fa fa-gg-circle",
    "fa fa-cc-discover",
    "fa fa-barcode",
    "fa fa-language",
    "fa fa-font-awesome",
    "fa fa-weixin",
    "fa fa-hashtag",
    "fa fa-american-sign-language-interpreting",
    "fa fa-chevron-circle-down",
    "fa fa-gift",
    "fa fa-home",
    "fa fa-suitcase",
    "fa fa-cc-paypal",
    "fa fa-balance-scale",
    "fa fa-expeditedssl",
    "fa fa-linkedin-square",
    "fa fa-smile-o",
    "fa fa-search-plus",
    "fa fa-gavel",
    "fa fa-h-square",
    "fa fa-forumbee",
    "fa fa-linode",
    "fa fa-dropbox",
    "fa fa-eraser",
    "fa fa-chevron-up",
    "fa fa-sort",
    "fa fa-codiepie",
    "fa fa-reply-all",
    "fa fa-font",
    "fa fa-minus-square",
    "fa fa-exchange",
    "fa fa-play-circle",
    "fa fa-clone",
    "fa fa-pencil-square",
    "fa fa-globe",
    "fa fa-filter",
    "fa fa-credit-card",
    "fa fa-twitch",
    "fa fa-instagram",
    "fa fa-underline",
    "fa fa-undo",
    "fa fa-hourglass-start",
    "fa fa-map-signs",
    "fa fa-gg",
    "fa fa-power-off",
    "fa fa-stack-exchange",
    "fa fa-xing",
    "fa fa-telegram",
    "fa fa-commenting-o",
    "fa fa-heart-o",
    "fa fa-genderless",
    "fa fa-star-half",
    "fa fa-paper-plane-o",
    "fa fa-object-group",
    "fa fa-tree",
    "fa fa-sort-amount-asc",
    "fa fa-github-square",
    "fa fa-paragraph",
    "fa fa-cubes",
    "fa fa-sort-numeric-desc",
    "fa fa-arrow-circle-o-down",
    "fa fa-google-plus-official",
    "fa fa-flag-o",
    "fa fa-certificate",
    "fa fa-flag",
    "fa fa-ban",
    "fa fa-window-close-o",
    "fa fa-history",
    "fa fa-glide-g",
    "fa fa-inbox",
    "fa fa-youtube",
    "fa fa-file-video-o",
    "fa fa-clock-o",
    "fa fa-level-down",
    "fa fa-battery-half",
    "fa fa-thumbs-up",
    "fa fa-cc-jcb",
    "fa fa-level-up",
    "fa fa-file-image-o",
    "fa fa-sign-language",
    "fa fa-envira",
    "fa fa-mars",
    "fa fa-dot-circle-o",
    "fa fa-cart-arrow-down",
    "fa fa-th-list",
    "fa fa-window-minimize",
    "fa fa-external-link",
    "fa fa-ioxhost",
    "fa fa-eject",
    "fa fa-hourglass",
    "fa fa-tasks",
    "fa fa-hand-o-right",
    "fa fa-search",
    "fa fa-git-square",
    "fa fa-map-o",
    "fa fa-behance",
    "fa fa-text-height",
    "fa fa-minus-circle",
    "fa fa-bar-chart",
    "fa fa-exclamation",
    "fa fa-github",
    "fa fa-phone-square",
    "fa fa-houzz",
    "fa fa-pied-piper-pp",
    "fa fa-medium",
    "fa fa-usd",
    "fa fa-cutlery",
    "fa fa-hospital-o",
    "fa fa-location-arrow",
    "fa fa-jsfiddle",
    "fa fa-cog",
    "fa fa-life-ring",
    "fa fa-table",
    "fa fa-viacoin",
    "fa fa-heart",
    "fa fa-question-circle",
    "fa fa-compass",
    "fa fa-blind",
    "fa fa-phone",
    "fa fa-unlock",
    "fa fa-pause-circle",
    "fa fa-arrow-circle-right",
    "fa fa-moon-o",
    "fa fa-sign-out",
    "fa fa-toggle-on",
    "fa fa-bell",
    "fa fa-dribbble",
    "fa fa-bookmark-o",
    "fa fa-assistive-listening-systems",
    "fa fa-book",
    "fa fa-check-circle",
    "fa fa-plus-square-o",
    "fa fa-youtube-play",
    "fa fa-puzzle-piece",
    "fa fa-question",
    "fa fa-id-badge",
    "fa fa-yelp",
    "fa fa-superscript",
    "fa fa-plus-square",
    "fa fa-money",
    "fa fa-link",
    "fa fa-inr",
    "fa fa-line-chart",
    "fa fa-product-hunt",
    "fa fa-transgender-alt",
    "fa fa-thermometer-full",
    "fa fa-ellipsis-h",
    "fa fa-bluetooth",
    "fa fa-code",
    "fa fa-angle-left",
    "fa fa-wpforms",
    "fa fa-caret-square-o-up",
    "fa fa-bell-slash-o",
    "fa fa-minus-square-o",
    "fa fa-hand-paper-o",
    "fa fa-hacker-news",
    "fa fa-users",
    "fa fa-arrow-up",
    "fa fa-snowflake-o",
    "fa fa-envelope-open",
    "fa fa-chrome",
    "fa fa-asterisk",
    "fa fa-i-cursor",
    "fa fa-arrows-alt",
    "fa fa-male",
    "fa fa-ellipsis-v",
    "fa fa-sort-asc",
    "fa fa-google-plus",
    "fa fa-columns",
    "fa fa-chevron-circle-right",
    "fa fa-volume-up",
    "fa fa-spotify",
    "fa fa-list-ul",
    "fa fa-ravelry",
    "fa fa-envelope-square",
    "fa fa-reddit-square",
    "fa fa-percent",
    "fa fa-tags",
    "fa fa-vimeo",
    "fa fa-creative-commons",
    "fa fa-fast-forward",
    "fa fa-shopping-basket",
    "fa fa-compress",
    "fa fa-info",
    "fa fa-file-pdf-o",
    "fa fa-thumbs-down",
    "fa fa-bitbucket-square",
    "fa fa-at",
    "fa fa-facebook-official",
    "fa fa-address-card-o",
    "fa fa-reddit",
    "fa fa-repeat",
    "fa fa-stop",
    "fa fa-quora",
    "fa fa-trademark",
    "fa fa-odnoklassniki",
    "fa fa-vk",
    "fa fa-reply",
    "fa fa-circle-o",
    "fa fa-id-card",
    "fa fa-quote-left",
    "fa fa-battery-three-quarters",
    "fa fa-newspaper-o",
    "fa fa-pinterest-square",
    "fa fa-venus-mars",
    "fa fa-address-book",
    "fa fa-angle-double-down",
    "fa fa-pied-piper-alt",
    "fa fa-unlock-alt",
    "fa fa-calendar-minus-o",
    "fa fa-magic",
    "fa fa-film",
    "fa fa-circle-o-notch",
    "fa fa-openid",
    "fa fa-angle-double-right",
    "fa fa-windows",
    "fa fa-laptop",
    "fa fa-bath",
    "fa fa-gamepad",
    "fa fa-contao",
    "fa fa-user",
    "fa fa-space-shuttle",
    "fa fa-opera",
    "fa fa-microphone-slash",
    "fa fa-thumbs-o-down",
    "fa fa-venus",
    "fa fa-grav",
    "fa fa-shirtsinbulk",
    "fa fa-eyedropper",
    "fa fa-coffee",
    "fa fa-database",
    "fa fa-apple",
    "fa fa-thermometer-three-quarters",
    "fa fa-road",
    "fa fa-bitbucket",
    "fa fa-star-half-o",
    "fa fa-chevron-left",
    "fa fa-podcast",
    "fa fa-adn",
    "fa fa-arrow-circle-up",
    "fa fa-eye-slash",
    "fa fa-youtube-square",
    "fa fa-frown-o",
    "fa fa-imdb",
    "fa fa-crosshairs",
    "fa fa-500px",
    "fa fa-caret-up",
    "fa fa-file",
    "fa fa-hand-o-left",
    "fa fa-lightbulb-o",
    "fa fa-hourglass-end",
    "fa fa-deviantart",
    "fa fa-exclamation-circle",
    "fa fa-universal-access",
    "fa fa-fort-awesome",
    "fa fa-header",
    "fa fa-ils",
    "fa fa-leaf",
    "fa fa-chain-broken",
    "fa fa-window-close",
    "fa fa-bandcamp",
    "fa fa-black-tie",
    "fa fa-child",
    "fa fa-firefox",
    "fa fa-folder-o",
    "fa fa-lastfm-square",
    "fa fa-stethoscope",
    "fa fa-twitter-square",
    "fa fa-google-plus-square",
    "fa fa-user-secret",
    "fa fa-quote-right",
    "fa fa-joomla",
    "fa fa-anchor",
    "fa fa-share-square-o",
    "fa fa-google",
    "fa fa-comments",
    "fa fa-tablet",
    "fa fa-map",
    "fa fa-arrows",
    "fa fa-birthday-cake",
    "fa fa-qq",
    "fa fa-calendar-plus-o",
    "fa fa-recycle",
    "fa fa-play-circle-o",
    "fa fa-folder",
    "fa fa-skype",
    "fa fa-share-square",
    "fa fa-gratipay",
    "fa fa-sticky-note-o",
    "fa fa-id-card-o",
    "fa fa-dashcube",
    "fa fa-weibo",
    "fa fa-map-marker",
    "fa fa-diamond",
    "fa fa-sort-desc",
    "fa fa-share-alt",
    "fa fa-eye",
    "fa fa-wikipedia-w",
    "fa fa-glass",
    "fa fa-check",
    "fa fa-wpexplorer",
    "fa fa-odnoklassniki-square",
    "fa fa-subscript",
    "fa fa-user-times",
    "fa fa-jpy",
    "fa fa-volume-down",
    "fa fa-expand",
    "fa fa-server",
    "fa fa-code-fork",
    "fa fa-mars-stroke-h",
    "fa fa-pencil-square-o",
    "fa fa-edge",
    "fa fa-wpbeginner",
    "fa fa-safari",
    "fa fa-subway",
    "fa fa-slack",
    "fa fa-stumbleupon-circle",
    "fa fa-step-forward",
    "fa fa-bolt",
    "fa fa-calculator",
    "fa fa-chevron-down",
    "fa fa-arrow-down",
    "fa fa-plug",
    "fa fa-lastfm",
    "fa fa-vimeo-square",
    "fa fa-bug",
    "fa fa-caret-square-o-down",
    "fa fa-angle-down",
    "fa fa-motorcycle",
    "fa fa-audio-description",
    "fa fa-stack-overflow",
    "fa fa-get-pocket",
    "fa fa-snapchat-ghost",
    "fa fa-trophy",
    "fa fa-share-alt-square",
    "fa fa-rebel",
    "fa fa-credit-card-alt",
    "fa fa-scribd",
    "fa fa-trash-o",
    "fa fa-registered",
    "fa fa-shower",
    "fa fa-window-maximize",
    "fa fa-battery-full",
    "fa fa-bars",
    "fa fa-pagelines",
    "fa fa-buysellads",
  ];

  export default iconOptions