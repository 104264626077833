/* eslint-disable react-hooks/exhaustive-deps */
import AddRoomForm from "components/bookingRoom/roomManagement/AddRoomForm";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import Axios from "utils/axiosService";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";

function EditRoom({ setTitle, match, history }) {
  const [formValue, setFormValue] = useState({});
  const [idRoom] = useState(match.params.id_room);
  const [isLoading, setIsLoading] = React.useState(true);
  const [facilityToBeDeleted, setFacilityToBeDeleted] = React.useState([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = React.useState(false);
  const [formEvent, setFormEvent] = useState();
  const [formattedSelectedGroup, setFormattedSelectedGroup] = useState([]);

  useEffect(() => {
    setTitle("Edit Room");
  }, []);

  useEffect(() => {
    if (idRoom) {
      setIsLoading(true);
      Axios({
        method: "GET",
        url: `/room/${idRoom}`,
      })
        .then((r) => {
          if (!r.data.errorStatus) {
            const data = r.data.data.data;
            setFormValue({
              name: data.name,
              area_id: parseInt(data.area_id),
              location: data.location_detail,
              capacity: parseInt(data.capacity),
              type: data.type,
              active: data.active,
              image: data.image ?? "",
              description: data.description ?? "",
              note: data.note ?? "",
              facilities: [...data.booking_room_facilities],
              user_groups_id: data.user_groups_id ?? [],
            });
          } else {
            throw new Error("Error fetching data");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [idRoom]);

  const submitValidation = (data) => {
    let checkData = {
      res: true,
      message: ""
    };

    if (!data.name) {
      checkData.res = false;
      checkData.message = "Name cannot be blank";
    } else if (!data.area_id) {
      checkData.res = false;
      checkData.message = "Area cannot be blank";
    } else if (!data.location) {
      checkData.res = false;
      checkData.message = "Location cannot be blank";
    } else if (!data.capacity) {
      checkData.res = false;
      checkData.message = "Capacity cannot be blank";
    } else if (!data.type) {
      checkData.res = false;
      checkData.message = "Type cannot be blank";
    } else if (data.active === undefined) {
      checkData.res = false;
      checkData.message = "Please set active state first";
    } else if (data.facilities.length < 1) {
      checkData.res = false;
      checkData.message = "Please insert facilities";
    } else if (!data.facilities.every((f) => f.item_name)) {
      checkData.res = false;
      checkData.message = "Facilities name cannot be blank";
    } else if (!data.facilities.every((f) => f.description)) {
      checkData.res = false;
      checkData.message = "Facilities description cannot be blank";
    }

    return checkData;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let submissionValidation = submitValidation(formValue);

    if (submissionValidation.res) {
      setIsLoading(true);
      let roomImage = "";
      let uploadImagePromise = Promise.resolve();
      if (formValue?.image?.name) {
        uploadImagePromise = postImages(formValue.image);
      } else {
        roomImage = formValue.image;
      }
      uploadImagePromise
        .then((r) => {
          if (r) {
            if (r.data.errorStatus) {
              throw new Error("Failed to upload image");
            } else {
              roomImage = r.data.data.url;
            }
          }
        })
        .then(() => {
          Axios({
            method: "PATCH",
            url: `/room/${idRoom}`,
            data: {
              name: formValue.name,
              area_id: parseInt(formValue.area_id),
              location_detail: formValue.location,
              capacity: parseInt(formValue.capacity),
              type: formValue.type,
              active: formValue.active,
              image: roomImage,
              description: formValue.description ?? "",
              note: formValue.note ?? "",
              user_groups_id: formValue.selectedGroup,
            },
          })
            .then((r) => {
              if (r.data.errorStatus) {
                Swal.fire({
                  icon: "error",
                  text: "Failed to edit room",
                  confirmButtonColor: "#30C1FF",
                });
                throw new Error("Failed to edit room data.");
              }
            })
            .then(() => {
              const postImagesArr = [];
              const facilityImages = [];
              if (formValue.facilities.some((f) => !!f.image?.name)) {
                formValue.facilities.forEach((f, i) => {
                  if (f.image?.name) {
                    postImagesArr.push(postImages(f.image));
                  }
                });
              }else{
                postImagesArr.push(Promise.resolve())
              }

              Promise.all(postImagesArr)
                .then((res) => {
                  if(res.length>0){
                    for (const r of res) {
                      if(r){
                        if (r.data.errorStatus) {
                          Swal.fire({
                            icon: "error",
                            text: "Failed to upload facility image.",
                            confirmButtonColor: "#30C1FF",
                          });
                          throw new Error("Failed to upload facility image");
                        } else {
                          const index = formValue.facilities.findIndex(
                            (f) =>
                              f.image?.name ===
                              r?.config?.data.get("image_url")?.name
                          );
                          facilityImages.push({ index, image: r.data.data.url });
                        }
                      }
                    }
                  }
                })
                .then(() => {
                  let facilitiesWithImage = [...formValue.facilities];
                  facilityImages.forEach((val) => {
                    facilitiesWithImage[val.index].image = val.image;
                  });

                  let postFacilities = [];
                  facilitiesWithImage.forEach((facility) => {
                      if (facility.id) {
                      const post = Axios({
                        method: "PATCH",
                        url: `/room/facility/${facility.id}`,
                        data: {
                          room_id: parseInt(idRoom),
                          item_name: facility.item_name,
                          image: facility?.image ?? "",
                          description: facility?.description ?? "",
                        },
                      });
                      postFacilities.push(post);
                    } else {
                      const post = Axios({
                        method: "POST",
                        url: "/room/facility",
                        data: {
                          room_id: parseInt(idRoom),
                          item_name: facility.item_name,
                          image: facility?.image ?? "",
                          description: facility?.description ?? "",
                        },
                      });
                      postFacilities.push(post);
                    }
                  });

                  Promise.all(postFacilities).then((r) => {
                    r.forEach((res) => {
                      if (res.data.errorStatus) {
                        Swal.fire({
                          icon: "error",
                          text: "Failed to update facility data.",
                          confirmButtonColor: "#30C1FF",
                        });
                        throw new Error("Something went wrong");
                      }
                    });
                  });
                })
                .then(() => {
                  if (facilityToBeDeleted.length > 0) {
                    let deleteFacility = [];
                    facilityToBeDeleted.forEach((item) => {
                      const del = Axios({
                        method: "DELETE",
                        url: `/room/facility/${item}`,
                      });
                      deleteFacility.push(del);
                    });

                    Promise.all(deleteFacility).then((r) => {
                      r.forEach((res) => {
                        if (res.data.errorStatus) {
                          throw new Error("Something went wrong");
                        }
                      });
                    });
                  }
                });
            });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setTimeout(() => {
            history.push("/booking-room/room-list/" + idRoom);
          }, 1000);
          setIsLoading(false);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: submissionValidation.message,
        confirmButtonColor: "#30C1FF",
      });
    }
  };

  const postImages = async (imagePath) => {
    if (!imagePath) {
      return Promise.resolve();
    }
    let formData = new FormData();
    formData.append("image_url", imagePath);
    const postImg = await Axios({
      method: "POST",
      url: "/file-upload",
      data: formData,
    });

    return postImg;
  };

  const handleDeleteFacility = (id) => {
    setFacilityToBeDeleted([...facilityToBeDeleted, id]);
  };

  const handleShowModal = (e) => {
    setFormEvent(e);
    setIsShowDeleteModal(true);
  };
  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody>
          {!isLoading && (
            <AddRoomForm
              title="Edit Room"
              handleSubmit={(e) => {
                handleShowModal(e);
              }}
              formValue={formValue}
              setFormValue={setFormValue}
              isEdit={true}
              handleDeleteFacility={handleDeleteFacility}
              formattedSelectedGroup={formattedSelectedGroup}
              setFormattedSelectedGroup={setFormattedSelectedGroup}
            />
          )}
          {isLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Loader type="Oval" color="#30C1FF" height={100} width={100} />
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isShowDeleteModal}
        fade={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        toggle={() => {
          setIsShowDeleteModal(!isShowDeleteModal);
        }}
      >
        <ModalBody
          style={{
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 className="text-center mb-0">Are you sure to edit this room?</h2>
          <div className="d-flex justify-content-center">
            <Button
              color="danger"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(formEvent);
                setIsShowDeleteModal(false);
              }}
            >
              Edit
            </Button>
            <Button
              color="info"
              className="p-0 px-2"
              style={{
                height: "30px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsShowDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default EditRoom;
