/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";


class ProfileHeader extends React.Component {
  render() {
    const username = localStorage.getItem('username')
    const id_user = localStorage.getItem('id_user')
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "500px",
            backgroundImage:
              'url("' + require("assets/img/theme/paragon.jpg") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          <span className="mask bg-gradient-info opacity-8" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="10">
                <h1 className="display-2 text-white">HELLO {(username).split(' ')[0]} !</h1>
                <p className="text-white mt-0 mb-5">
                  This is your profile page. You can see your general information
                </p>
                <Link to={`/parastay/user-update/${id_user}`}>
                <Button
                  className="btn-neutral"
                  color="default"
                >
                  Edit profile
                </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ProfileHeader;
